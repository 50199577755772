import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Card, Row, Col, Progress, Layout } from 'antd';
import { GoogleMap } from '../../components';
import {
    cardData,
    CONTRACTORS_OPTIONS,
    CONTRACTORS_SERIES,
    MANUFACTURE_RATING_OPTIONS,
    MANUFACTURE_RATING_SERIES,
    MARKET_INDICATOR_OPTIONS,
    MARKET_INDICATOR_SERIES,
    REVENUE_PERFORMANCE_OPTIONS,
    REVENUE_PERFORMANCE_SERIES,
} from './helper';
import './styles.scss';

const { Content } = Layout;

const Dashboard = () => {
    return (
        <Content className="fixed-layout">
            <div className="dashboard-container main-dashboard">
                <Row gutter={15}>
                    <Col span={6}>
                        <div className="page-content-container dashboard-card colorful-card bg-pink">
                            <h4 className="card-heading custom-card-heading">Total Projects</h4>
                            <h1 className="dashboard-card-text">457</h1>
                        </div>
                    </Col>
                    <Col span={6}>
                        <div className="page-content-container dashboard-card colorful-card bg-blue">
                            <h4 className="card-heading custom-card-heading">Active Projects</h4>
                            <h1 className="dashboard-card-text">54</h1>
                        </div>
                    </Col>
                    <Col span={6}>
                        <div className="page-content-container dashboard-card colorful-card bg-brown">
                            <h4 className="card-heading custom-card-heading">Contractors</h4>
                            <h1 className="dashboard-card-text">23</h1>
                        </div>
                    </Col>
                    <Col span={6}>
                        <div className="page-content-container dashboard-card colorful-card bg-light-blue">
                            <h4 className="card-heading custom-card-heading">Files</h4>
                            <h1 className="dashboard-card-text">41,107</h1>
                        </div>
                    </Col>
                </Row>
                <Row className="mt-20" gutter={20}>
                    <Col span={10}>
                        <div className="page-content-container mb-15">
                            <div className="chart-wrapper">
                                <div>
                                    <ReactApexChart
                                        options={REVENUE_PERFORMANCE_OPTIONS}
                                        series={REVENUE_PERFORMANCE_SERIES}
                                        type="line"
                                        height={500}
                                    />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col span={14}>
                        <div className="page-content-container mb-15">
                            <div className="chart-wrapper">
                                <div>
                                    <ReactApexChart
                                        options={MARKET_INDICATOR_OPTIONS}
                                        series={MARKET_INDICATOR_SERIES}
                                        type="area"
                                        height={500}
                                    />
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row gutter={15}>
                    <Col span={14}>
                        <div className="page-content-container mb-15">
                            <h4 className="card-heading">Growth Areas</h4>
                            <GoogleMap />
                        </div>
                    </Col>
                    <Col span={10}>
                        <div className="page-content-container mb-15">
                            <div className="chart-wrapper">
                                <div>
                                    <ReactApexChart
                                        options={MANUFACTURE_RATING_OPTIONS}
                                        series={MANUFACTURE_RATING_SERIES}
                                        type="line"
                                        height={483}
                                    />
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row gutter={15}>
                    <Col span={6}>
                        <div className="page-content-container dashboard-card colorful-card bg-pink">
                            <h4 className="card-heading custom-card-heading">Revenue</h4>
                            <h1 className="dashboard-card-text">$765 M</h1>
                        </div>
                    </Col>
                    <Col span={6}>
                        <div className="page-content-container dashboard-card colorful-card bg-blue">
                            <h4 className="card-heading custom-card-heading">Disputes</h4>
                            <h1 className="dashboard-card-text">2,567</h1>
                        </div>
                    </Col>
                    <Col span={6}>
                        <div className="page-content-container dashboard-card colorful-card bg-brown">
                            <h4 className="card-heading custom-card-heading">Change Orders</h4>
                            <h1 className="dashboard-card-text">5,003</h1>
                        </div>
                    </Col>
                    <Col span={6}>
                        <div className="page-content-container dashboard-card colorful-card bg-light-blue">
                            <h4 className="card-heading custom-card-heading">Repeat Orders</h4>
                            <h1 className="dashboard-card-text">73</h1>
                        </div>
                    </Col>
                </Row>
                <Row gutter={15}>
                    <Col span={14}>
                        <div className="page-content-container">
                            <div className="chart-wrapper">
                                <div>
                                    <ReactApexChart
                                        options={CONTRACTORS_OPTIONS}
                                        series={CONTRACTORS_SERIES}
                                        type="bar"
                                        height={500}
                                    />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col span={10}>
                        <div
                            className="page-content-container dashboard-card dashboard-down-cards"
                            style={{ height: 276 }}
                        >
                            <h4 className="card-heading custom-card-heading">
                                Projects Performance
                            </h4>
                            <ul className="dashboard-list">
                                <li>
                                    <div className="left-side">On-time</div>
                                    <p>312</p>
                                </li>
                                <li>
                                    <div className="left-side">Early</div>
                                    <p>44</p>
                                </li>
                                <li>
                                    <div className="left-side">Delayed</div>
                                    <p>101</p>
                                </li>
                            </ul>
                        </div>
                        <div
                            className="page-content-container dashboard-card dashboard-down-cards"
                            style={{ height: 276 }}
                        >
                            <h4 className="card-heading custom-card-heading">Projects Sentiment</h4>
                            <ul className="dashboard-list">
                                <li>
                                    <div className="left-side">
                                        <span className="happy"></span> Happy
                                    </div>
                                    <p>312</p>
                                </li>
                                <li className="mt-20">
                                    <div className="left-side">
                                        <span className="normal"></span> Satisfied
                                    </div>
                                    <p>49</p>
                                </li>
                                <li className="mt-20">
                                    <div className="left-side">
                                        <span className="sad"></span> Poor
                                    </div>
                                    <p>96</p>
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </div>
        </Content>
    );
};

export default Dashboard;
