import { RESET_STATE } from '../common';
import {
    PACKAGE_LIST,
    GET_ALL_PACKAGES_INITIATE,
    GET_ALL_PACKAGES_SUCCESS,
    ADD_PACKAGE_TO_LIST,
} from './types';

const initialState = {
    loading: false,
    error: null,
    roomPackages: [],
    individualPackages: [],
    allPackages: [],
    approvals: [],
    markups: [],
};

export const packageReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_ALL_PACKAGES_INITIATE:
            return { ...state, loading: true };

        case GET_ALL_PACKAGES_SUCCESS:
            return { ...state, allPackages: payload, loading: false };

        case PACKAGE_LIST:
            return {
                ...state,
                roomPackages: payload.room_packages,
                individualPackages: payload.individual_packages,
            };

        case ADD_PACKAGE_TO_LIST:
            return {
                ...state,
                allPackages: [...state.allPackages, payload],
            };

        case RESET_STATE:
            return initialState;

        default:
            return state;
    }
};
