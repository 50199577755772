import * as L from 'leaflet';
import { memo, useEffect, useState } from 'react';
import { Marker, Popup } from 'react-leaflet';
import { DATES_KEYS, DATES_KEYS_WITH_VALUES, DATE_FORMAT, makeTitle, PROJECTS } from '../../utils';
import './styles.css';

import { Badge } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const TagContent = ({
    name,
    operations,
    deadlines,
    tag,
    nearestDate,
    missingInfo,
    status,
    status_msg,
}) => {
    return (
        <div className="tag-content-body">
            <h4 className="mb-0">Name: </h4>
            <p>{tag.name}</p>
            <h4 className="mb-0">Description: </h4>
            <p> {tag.description || 'NA'} </p>
            <h4 className="mb-1">Last Round </h4>
            <p
                style={{
                    display: 'block',
                    marginTop: '1.33em',
                    marginBottom: '1.33em',
                    marginLeft: '0',
                    marginRight: '0',
                }}
            >
                <span
                    style={{
                        fontWeight: 'bold',
                    }}
                >
                    Status:
                </span>
                <span
                    style={{
                        float: 'right',
                    }}
                >
                    <Badge
                        count={'Good'}
                        style={{ backgroundColor: '#61bd4f', width: '50px', marginRight: '0px' }}
                    />
                </span>
            </p>
            <p
                style={{
                    display: 'block',
                    marginTop: '1.33em',
                    marginBottom: '1.33em',
                    marginLeft: '0',
                    marginRight: '0',
                }}
            >
                <span
                    style={{
                        fontWeight: 'bold',
                    }}
                >
                    #Comments:
                </span>
                <span
                    style={{
                        float: 'right',
                        marginRight: '20px',
                    }}
                >
                    2
                </span>
            </p>
            <p
                style={{
                    display: 'block',
                    marginTop: '1.33em',
                    marginBottom: '1.33em',
                    marginLeft: '0',
                    marginRight: '0',
                }}
            >
                <span
                    style={{
                        fontWeight: 'bold',
                    }}
                >
                    #Docs:
                </span>
                <span
                    style={{
                        float: 'right',
                        marginRight: '20px',
                    }}
                >
                    4
                </span>
            </p>
        </div>
    );
};

export const MapMarkers = memo(({ mapRef = null, coords = [], setCoords, markersDraggable }) => {
    const { activeProject, operations } = useSelector(({ common }) => common);
    const map = mapRef?.current?.leafletElement;
    const [customTags, setCustomTags] = useState([]);

    const history = useHistory();

    useEffect(() => {
        let timer1 = setTimeout(() => {
            const _tags = coords.map((tag) => {
                return {
                    ...tag,
                    ...map?.layerPointToLatLng([tag.coords?.x_axis, tag.coords?.y_axis]),
                };
            });
            setCustomTags(_tags);
        }, 1500);
        return () => {
            clearTimeout(timer1);
        };
    }, [coords]);

    const onTagClickHandler = (tag) => {
        let url = `${PROJECTS}/${tag?.project}/package/${tag?.packageroom[0]}`;
        if (operations) {
            url = `${PROJECTS}/${tag?.project}/operations/package/${tag?.packageroom[0]}`;
        }
        history.push(url);
    };

    const getDeadlines = (tag) => {
        const deadlines = [];
        const now = new Date().getTime();
        DATES_KEYS_WITH_VALUES.forEach(({ key, value }) => {
            if (!_.isEmpty(tag[key])) {
                if (new Date(tag[key]).getTime() < now && tag?.status < value) {
                    const title = makeTitle(key);
                    deadlines.push({ title, key });
                }
            }
        });
        return deadlines;
    };

    const getNearestDates = (tag) => {
        const now = new Date();
        let nearestDate;
        let date_key;
        DATES_KEYS.forEach((key) => {
            if (!_.isEmpty(tag[key])) {
                let diff = moment(tag[key]).diff(moment(now).format(DATE_FORMAT), 'days');
                if (diff > 0) {
                    if (nearestDate) {
                        if (moment(tag[key]).diff(moment(nearestDate), 'days') < 0) {
                            nearestDate = tag[key];
                            date_key = key;
                        }
                    } else {
                        nearestDate = tag[key];
                        date_key = key;
                    }
                }
            }
        });
        return nearestDate ? { title: makeTitle(date_key), key: date_key } : null;
    };

    const getMissingInformation = (tag) => {
        const missingInfo = [];
        DATES_KEYS.forEach((key) => {
            if (_.isEmpty(tag[key])) {
                const title = makeTitle(key);
                missingInfo.push({ title });
            }
        });
        return missingInfo;
    };

    const updateTag = (e, tag) => {
        const coordIndex = coords.findIndex((coord) => coord.id === tag.id);
        coords[coordIndex] = {
            ...coords[coordIndex],
            coords: {
                ...coords[coordIndex].coords,
                x_axis: e.target.dragging._draggable._newPos.x,
                y_axis: e.target.dragging._draggable._newPos.y,
            },
        };
        setCoords([...coords]);
    };

    const customMarkerIcon = (text, color) =>
        new L.divIcon({
            html: `<span class="singline-marker-container"></span>`,
            iconSize: [50, 25],
        });

    return customTags.map((tag, i) => {
        return (
            <Marker
                key={`${tag.name}-${i}`}
                position={[tag.lat, tag.lng]}
                ondragend={(e) => updateTag(e, tag)}
                draggable={markersDraggable}
                onMouseOver={(e) => {
                    e.target.openPopup();
                }}
                onMouseOut={(e) => {
                    e.target.closePopup();
                }}
                icon={customMarkerIcon(
                    tag.name,
                    operations ? tag?.operations_color : tag?.construction_color || '#1890ff',
                )}
                onClick={() => onTagClickHandler(tag)}
            >
                <Popup>
                    <TagContent
                        operations={operations}
                        {...tag}
                        status_msg={
                            operations ? tag.operations_status_msg : tag.construction_status_msg
                        }
                        tag={tag}
                        deadlines={!operations ? getDeadlines(tag) : []}
                        nearestDate={!operations ? getNearestDates(tag) : []}
                        missingInfo={!operations ? getMissingInformation(tag) : []}
                    />
                </Popup>{' '}
            </Marker>
        );
    });
});
