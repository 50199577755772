import React, { useEffect, useState } from 'react';
import { Row } from 'antd';
import { SwapOutlined } from '@ant-design/icons';
import '../../assets/styles/components/dnd.scss';
import DragNDrop from './drag-and-drop';

export const DragAndDropContainer = ({ items = [], getData = () => {}, selectedItems = [] }) => {
    const data = [
        {
            title: 'Select',
            items: items,
        },
        { title: 'Selected', items: [...selectedItems] },
    ];

    return (
        <div>
            <div className="right-arrow">
                <SwapOutlined />
            </div>
            <Row gutter={95}>
                <DragNDrop data={[...data]} getData={getData} />
            </Row>
        </div>
    );
};
