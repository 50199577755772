import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Button, Col, Row, Typography } from 'antd';
import moment from 'moment';
import { MyDateRangePicker } from '../../../../components/date-range-selector';
import './analytics.scss';
import { AREA_OPTIONS, AREA__SERIES } from '../../../../static/area-gantt';

const Analytics = ({ title, tagAnalytics }) => {
    const DataSource = [
        {
            name: 'Analytics',
            data: tagAnalytics,
        },
    ];

    return (
        <div className="chart-tab">
            <div className="chart-generator"></div>
            <div className="apexchart-container">
                <ReactApexChart
                    options={AREA_OPTIONS}
                    series={DataSource}
                    type="area"
                    height={400}
                />
            </div>
        </div>
    );
};

export default Analytics;
