import { CLEAR_DOCUMENT_STATE, FETCH_PROJECT_DOCUMENTS, RESET_STATE } from './types';

const initialState = {
    documents: [],
};

export const documentReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case FETCH_PROJECT_DOCUMENTS:
            return { ...state, documents: [...payload] };

        case RESET_STATE:
        case CLEAR_DOCUMENT_STATE:
            return initialState;

        default:
            return state;
    }
};
