import {
    TAG_LIST,
    UPDATE_TAG_SUCCESS,
    UPDATE_TAG_INITIATE,
    GET_MASTER_TAGS_LOADING,
    GET_MASTER_TAGS_SUCCESS,
} from './types';
import { fetchTagsByProject, fetchTagsBySline, updateTag } from '../../services';

export const setTag = (data) => (dispatch) => {
    dispatch({ type: TAG_LIST, payload: data });
};

export const getMasterTags = (projectId) => async (dispatch) => {
    try {
        dispatch({ type: GET_MASTER_TAGS_LOADING });
        const response = await fetchTagsByProject(projectId);
        dispatch({ type: GET_MASTER_TAGS_SUCCESS, payload: response });
    } catch (error) {
        console.log(error);
    }
};

export const getTagsBySline = (projectId, slineId) => async (dispatch) => {
    try {
        dispatch({ type: GET_MASTER_TAGS_LOADING });
        const response = await fetchTagsBySline(projectId, slineId);
        dispatch({ type: GET_MASTER_TAGS_SUCCESS, payload: response });
    } catch (error) {
        console.log(error);
    }
};

export const updateMasterTag =
    (data = {}, cb = () => {}) =>
    async (dispatch) => {
        try {
            dispatch({ type: UPDATE_TAG_INITIATE });
            await updateTag(data?.projectId, data?.id, data);
            dispatch({ type: UPDATE_TAG_SUCCESS });
            cb && cb();
        } catch (ex) {
            console.log(ex);
        }
    };
