import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Papaparse from 'papaparse';
import {
    DeleteTwoTone,
    DownloadOutlined,
    EditOutlined,
    EyeInvisibleOutlined,
    EyeOutlined,
    UploadOutlined,
    QuestionCircleOutlined,
    ConsoleSqlOutlined,
    ExpandOutlined,
    RestOutlined,
} from '@ant-design/icons';
import {
    Table,
    Tooltip,
    Select,
    Button,
    Space,
    Typography,
    Popconfirm,
    DatePicker,
    Form,
    Switch,
    Alert,
    notification,
    Modal,
    Input,
    Col,
    Upload,
    Row,
    AutoComplete,
    InputNumber,
} from 'antd';
import sampleCsv from '../../../assets/sample.csv';
import {
    DATE_FIELDS,
    INPUT_DATE_FORMAT,
    DISPLAY_DATE_FORMAT,
    SERVER_DATE_FORMAT,
    TAGS_CSV_HEADERS,
    DR_TAGS_CSV_HEADERS,
    CSV_DATE_FORMAT,
    DATES_KEYS,
    PROJECT_API_ENUM,
    DEFINITIONS,
    renderFrequency,
} from '../../../utils';
import {
    clearOldTags,
    createTags,
    deleteTags,
    fetchTagsByProject,
    getOrganizations,
    resetTagsOrder,
    updateProject,
    updateTag,
} from '../../../services';
import { Link } from 'react-router-dom';
import EquipmentModal from './equipmentModal';

const { Option } = Select;
const { Text } = Typography;

const ManageEquipments = ({
    tags = [],
    goBack = () => {},
    setTags = () => {},
    onFinish = () => {},
    setLoader = () => {},
    setEquipmentErrors = () => {},
    equipmentErrors = [],
    projectId = null,
    isDraft,
    modify,
}) => {
    const [addManualTags, setAddManualTags] = useState(false);
    const [showDesc, setShowDesc] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [form] = Form.useForm();
    const [form2] = Form.useForm();
    const [editingKey, setEditingKey] = useState('');
    const [formatError, setFormatError] = useState(false);
    const [isModalvisible, setIsModalVisible] = useState(false);
    const [equipmentRecord, setEquipmentRecord] = useState(null);
    const [organizations, setOrganizations] = useState([]);
    const [orgId, setOrgId] = useState([]);

    const isEditing = (record) => record.key === editingKey;

    const EditableCell = ({
        editing,
        dataIndex,
        title,
        inputType,
        record,
        index,
        children,
        ...restProps
    }) => {
        let inputNode = null;
        if (inputType === 'manufacturer') {
            inputNode = (
                <AutoComplete
                    onSelect={onSelect}
                    onSearch={fetchOrgs}
                    defaultValue={record?.manufacture?.name}
                    value={record?.manufacture?.name}
                >
                    {organizations.map((org) => (
                        <AutoComplete.Option
                            key={org.id}
                            value={org?.name}
                            org_type={org?.org_type?.name}
                            org_type_id={org?.org_type?.id}
                        >
                            {org?.name}
                        </AutoComplete.Option>
                    ))}
                </AutoComplete>
            );
        } else if (inputType === 'datepicker') {
            inputNode = <DatePicker format={INPUT_DATE_FORMAT} />;
        } else {
            if (title === 'Reading Required' && inputType === 'text') {
                inputNode = (
                    <Switch
                        disabled={false}
                        defaultChecked={record.is_reading_required ? true : false}
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                    />
                );
            } else if (title === 'Last Update Required' && inputType === 'text') {
                inputNode = (
                    <Switch
                        disabled={false}
                        defaultChecked={record.is_last_updated_required ? true : false}
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                    />
                );
            } else if (title === 'On Off Feature' && inputType === 'text') {
                inputNode = (
                    <Switch
                        disabled={false}
                        defaultChecked={record.on_off_feature ? true : false}
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                    />
                );
            } else if (title === 'Serial #' && inputType === 'text') {
                inputNode = <InputNumber />;
            } else {
                inputNode = <Input />;
            }
        }

        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item
                        name={dataIndex === 'manufacture' ? 'manufacture' : dataIndex}
                        style={{ margin: 0 }}
                    >
                        {inputNode}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };

    useEffect(() => {
        Promise.all([fetchTags(tags.currentPage, tags.pageSize), fetchOrgs()]);
    }, []);

    const fetchTags = async (page = 1, pageSize = 10) => {
        try {
            setLoader(true);
            const response = await fetchTagsByProject(projectId, page, pageSize);
            if (response.data?.length) {
                setTags({
                    data: [...response.data],
                    meta: { ...response.meta },
                    currentPage: page,
                    pageSize,
                });
            } else {
                setTags({
                    data: [],
                    meta: {},
                    currentPage: 1,
                    pageSize: 10,
                });
            }
        } catch (error) {
            console.log('error', error);
        } finally {
            setLoader(false);
        }
    };

    const fetchOrgs = async (val) => {
        try {
            const results = await getOrganizations(val);
            const filteredMfrs = results.data.filter(
                (item) => item.org_type.name === 'Manufacturer',
            );
            setOrganizations([...filteredMfrs]);
        } catch (error) {
            console.log(error);
        }
    };

    const onSelect = (val, option) => {
        setOrgId(option.key);
        form.setFieldsValue({
            manufacture: option.value,
        });
        form2.setFieldsValue({
            manufacture: option.value,
        });
    };

    const normFile = async (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        if (e.fileList.length) {
            readCsv(e.fileList[0].originFileObj);
        } else if (e.fileList.length === 0) {
            setTags([]);
        }
        return e && e.fileList;
    };

    const readCsv = (file) => {
        try {
            const reader = new FileReader();
            setLoader(true);
            reader.readAsBinaryString(file);
            reader.addEventListener('load', async function (e) {
                let _tags = [];
                let _errors = [];
                setEquipmentErrors([]);
                const data = e.target.result;
                Papaparse.parse(data, {
                    complete: function (results) {
                        let isFormatCorrect = false;
                        results.data.forEach((row, index) => {
                            if (index === 0) {
                                isFormatCorrect =
                                    _.isEqual(row, [
                                        ...DR_TAGS_CSV_HEADERS,
                                        'Area',
                                        'Reading Required',
                                        'Last Update Required',
                                        'On Off Feature',
                                    ]) ||
                                    _.isEqual(row, [
                                        ...DR_TAGS_CSV_HEADERS,
                                        'Area',
                                        'Reading Required',
                                        'Last Update Required',
                                        'On Off Feature',
                                        'Reference #',
                                        'Manufacturer',
                                        'Group',
                                        'Asset Type',
                                        'Warranty Date',
                                    ]);
                                if (!isFormatCorrect) {
                                    setFormatError(
                                        'Incorrect CSV Format, Download sample CSV for the reference',
                                    );
                                } else {
                                    setFormatError('');
                                }
                            }
                            if (index !== 0 && !_.isEmpty(row[1])) {
                                let tag;

                                const [
                                    serial_number,
                                    name,
                                    description,
                                    package_room,
                                    is_reading_required,
                                    is_last_updated_required,
                                    on_off_feature,
                                    reference_number,
                                    manufacture,
                                    group,
                                    asset_type,
                                    warranty_date,
                                ] = row;
                                tag = {
                                    serial_number: !_.isEmpty(serial_number) ? serial_number : null,
                                    name,
                                    description,
                                    is_reading_required:
                                        !_.isEmpty(is_reading_required) &&
                                        parseInt(is_reading_required)
                                            ? true
                                            : false,
                                    is_last_updated_required:
                                        !_.isEmpty(is_last_updated_required) &&
                                        parseInt(is_last_updated_required)
                                            ? true
                                            : false,
                                    reference_number: !_.isEmpty(reference_number)
                                        ? reference_number
                                        : null,
                                    manufacture: !_.isEmpty(manufacture) ? manufacture : null,
                                    package_room: !_.isEmpty(package_room) ? package_room : null,
                                    group: !_.isEmpty(group) ? group : null,
                                    asset_type: !_.isEmpty(asset_type) ? asset_type : null,
                                    warranty_date: !_.isEmpty(warranty_date) ? warranty_date : null,
                                    on_off_feature:
                                        !_.isEmpty(on_off_feature) && parseInt(on_off_feature)
                                            ? true
                                            : false,
                                };

                                _tags.push(tag);
                            }
                        });
                    },
                });

                if (!_.isEmpty(_tags)) {
                    _tags.forEach((tag) => {
                        Object.keys(tag).forEach((key) => {
                            if (DATES_KEYS.includes(key) && !_.isEmpty(tag[key])) {
                                tag[key] = moment(moment(tag[key], CSV_DATE_FORMAT)).format(
                                    SERVER_DATE_FORMAT,
                                );
                            }
                        });
                        return tag;
                    });
                }

                if (_tags.length) {
                    try {
                        await clearOldTags(projectId);
                        const response = await createTags(projectId, _tags);
                        await fetchTags(1, tags.pageSize);
                        _tags = [...response.data];
                    } catch (error) {
                        setFormatError(error.message);
                        setLoader(false);
                    }
                }

                // setTags([..._tags, ...tags]);
                setLoader(false);
            });
        } catch (error) {
            setLoader(false);
        }
    };

    const deleteTagHandler = async (tagIds) => {
        try {
            // const newTags = tags.data.filter((tag) => !tagIds.includes(tag.id));
            // setTags([...newTags]);
            setSelectedTags([]);
            await deleteTags(projectId, tagIds);
            await fetchTags(1, tags.pageSize);
            notification.success({ description: 'Removed successfully' });
        } catch (error) {
            console.log(error);
        }
    };

    const resetOrder = async () => {
        await resetTagsOrder(projectId);
        await fetchTags(1, tags.pageSize);
    };

    const expandModal = (record) => {
        setIsModalVisible(true);
        setEquipmentRecord(record);
    };

    const columns = [
        {
            title: 'Serial #',
            dataIndex: 'serial_number',
            editable: true,
        },
        {
            title: 'Tag Name *',
            dataIndex: 'name',
            editable: true,
            inputType: 'tag_name',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            editable: true,
            inputType: '',
            render: (data, record) => {
                return (
                    <Text ellipsis={true} style={{ wordWrap: 'wrap' }}>
                        {data.length > 50 ? data.substring(0, 50) + '...' : data}
                    </Text>
                );
            },
        },
        // {
        //     title: 'Manufacturer',
        //     dataIndex: 'manufacture',
        //     render: (data) =>
        //         data?.name !== undefined && data?.name.trim().length !== 0 ? data?.name : data,
        //     editable: true,
        //     inputType: 'manufacturer',
        // },
        // {
        //     title: 'Group',
        //     dataIndex: 'group',
        //     render: (data) => data || '-',
        //     editable: true,
        //     inputType: 'manufacturer',
        // },
        // {
        //     title: 'Warranty Date',
        //     dataIndex: 'warranty_date',
        //     render: (data) =>
        //         data ? moment(data, SERVER_DATE_FORMAT).format(DISPLAY_DATE_FORMAT) : '',
        //     editable: true,
        //     inputType: 'datepicker',
        // },
        {
            title: 'Reading Required',
            dataIndex: 'is_reading_required',
            editable: true,
            render: (value, record) => {
                return (
                    <Switch
                        checked={record.is_reading_required ? true : false}
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        onChange={(checked, event) => {}}
                        disabled
                        // disabled={!Permissions.can_edit_master_tags(permissions)}
                    />
                );
            },
        },
        {
            title: 'Last Update Required',
            dataIndex: 'is_last_updated_required',
            editable: true,
            render: (value, record) => {
                return (
                    <Switch
                        checked={record.is_last_updated_required ? true : false}
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        onChange={(checked, event) => {}}
                        disabled
                    />
                );
            },
        },
        {
            title: 'On Off Feature',
            dataIndex: 'on_off_feature',
            editable: true,
            render: (value, record) => {
                return (
                    <Switch
                        checked={record.on_off_feature ? true : false}
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        onChange={(checked, event) => {}}
                        disabled
                    />
                );
            },
        },
        {
            title: 'Action',
            dataIndex: '',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <Typography.Link
                            onClick={() => save(record.id)}
                            style={{
                                marginRight: 8,
                            }}
                        >
                            Save
                        </Typography.Link>
                        <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                            <a>Cancel</a>
                        </Popconfirm>
                    </span>
                ) : (
                    <>
                        <span style={{ marginRight: 15 }}>
                            <Tooltip title="Edit">
                                <EditOutlined
                                    disabled={editingKey !== ''}
                                    onClick={() => edit(record)}
                                />
                            </Tooltip>
                        </span>
                        {/* <span>
                            <Tooltip title="View">
                                <ExpandOutlined
                                    disabled={editingKey !== ''}
                                    onClick={() => expandModal(record)}
                                />
                            </Tooltip>
                        </span> */}
                    </>
                );
            },
        },
    ];

    const hideDescription = (name) => {
        const newShowDesc = showDesc.filter((desc) => desc !== name);
        setShowDesc([...newShowDesc]);
    };

    const rowSelection = {
        type: 'checkbox',
        selectedRowKeys: selectedTags,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedTags([...selectedRows.map((row) => row.id)]);
        },
        getCheckboxProps: (record) => ({
            name: record.name,
        }),
    };

    const edit = (record) => {
        Object.keys(record).forEach((key) => {
            if (DATE_FIELDS.includes(key)) {
                record[key] = record[key] ? moment(record[key]) : null;
            }
        });
        form.setFieldsValue({ ...record });
        setEditingKey(record.key);
    };

    const cancel = () => {
        setEditingKey('');
    };

    const updateTagHandler = async (tagId, data, cb) => {
        const response = await updateTag(projectId, tagId, data);
        if (response) {
            notification.success({
                message: 'Successfully updated.',
                placement: 'topRight',
            });
            await fetchTags(tags.currentPage, tags.pageSize);
            // cb && cb();
        }
    };

    const save = async (tagId) => {
        try {
            setLoader(true);
            const data = await form.validateFields();

            Object.keys(data).forEach((key) => {
                if (DATE_FIELDS.includes(key)) {
                    data[key] = !_.isEmpty(data[key])
                        ? moment(moment(data[key], INPUT_DATE_FORMAT)).format(SERVER_DATE_FORMAT)
                        : null;
                }
            });

            updateTagHandler(tagId, data, () => {
                const newData = [...tags];
                const index = newData.findIndex((item) => tagId === item.id);
                if (index > -1) {
                    const item = newData[index];
                    newData.splice(index, 1, { ...item, ...data });
                    setTags([...newData]);
                }
            });
            setEditingKey('');
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        } finally {
            setLoader(false);
        }
    };

    const mergedColumns = columns.map((col, indx) => {
        col.key = indx + 1;
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.inputType || 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    const onNext = () => {
        onFinish({ tags });
    };

    const addManualTagHandler = async (values) => {
        try {
            // const isTagExist = tags.data && tags.data.some((tag) => tag.name === values.name);
            // if (isTagExist) {
            //     form2.setFields([{ name: 'name', errors: ['Tag with this name already exists'] }]);
            //     return;
            // }

            Object.keys(values).forEach((key) => {
                if (DATE_FIELDS.includes(key)) {
                    values[key] = values[key]
                        ? moment(values[key], INPUT_DATE_FORMAT).format(SERVER_DATE_FORMAT)
                        : null;
                }
            });

            const response = await createTags(projectId, [values]);

            await fetchTags(1, tags.pageSize);

            // values.id = response.data[0].id;
            // setTags([values, ...tags]);
            notification.success({
                message: `${values.name} added`,
                description: 'The tag has been added successfully',
            });
            form2.resetFields();
            setAddManualTags(false);
        } catch (error) {
            console.log(error);
        }
    };

    const onChangePageAndSorting = async (pagination, _, sorter) => {
        const { current, pageSize } = pagination;

        if (current !== tags.currentPage || pageSize !== tags.pageSize) {
            await fetchTags(current, pageSize);
        }
    };

    return (
        <>
            <h2 className="step-heading mb-20">Upload Checkpoints Information</h2>
            <Form layout="vertical">
                <Row justify="space-between">
                    {isDraft && (
                        <Col span={6}>
                            <Form.Item
                                name="tagList"
                                valuePropName="tagList"
                                getValueFromEvent={normFile}
                            >
                                <Upload
                                    beforeUpload={() => false}
                                    listType="text"
                                    maxCount={1}
                                    showUploadList={false}
                                    accept=".csv"
                                >
                                    <Button type="primary">
                                        <UploadOutlined />
                                        Upload Checkpoints CSV
                                    </Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                    )}
                    <Col span={6}>
                        <Form.Item>
                            <Link
                                to={sampleCsv}
                                // download="Sample.csv"
                                target="_blank"
                            >
                                <Button type="default">
                                    <DownloadOutlined />
                                    Download Sample CSV
                                </Button>
                            </Link>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <div style={{ float: 'right' }}>
                            <Button
                                className="buttonAddEqip"
                                onClick={() => setAddManualTags(!addManualTags)}
                            >
                                Add Checkpoints
                            </Button>

                            <Button
                                type="primary"
                                style={{
                                    marginLeft: '2px',
                                }}
                                onClick={() => resetOrder()}
                                danger
                            >
                                <RestOutlined /> Reset Order
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Form>
            {formatError && (
                <div className="mb-15" style={{ marginTop: -13 }}>
                    <Text className="mt-" type="danger">
                        {formatError}
                    </Text>
                </div>
            )}
            {!_.isEmpty(equipmentErrors) && (
                <Alert
                    message="Tags Errors"
                    showIcon
                    className="mb-20"
                    description={equipmentErrors.map((error, idx) => (
                        <span
                            className="d-block"
                            key={idx}
                            dangerouslySetInnerHTML={{ __html: error.message }}
                        ></span>
                    ))}
                    type="error"
                />
            )}

            {selectedTags.length > 0 && (
                <div className="mb-10 text-right">
                    <Space>
                        <Button onClick={() => deleteTagHandler(selectedTags)}>
                            <DeleteTwoTone twoToneColor="#f56666" /> Delete ({selectedTags.length})
                        </Button>
                    </Space>
                </div>
            )}

            <Form form={form} component={false}>
                <Table
                    rowSelection={{
                        ...rowSelection,
                    }}
                    components={{
                        body: {
                            cell: EditableCell,
                        },
                    }}
                    dataSource={tags.data.map((data, idx) => ({
                        ...data,
                        key: data.id,
                    }))}
                    columns={mergedColumns}
                    className="taglist-table editable-row"
                    size="small"
                    onChange={onChangePageAndSorting}
                    pagination={{
                        total: tags.meta.total_count,
                        current: tags.currentPage,
                        pageSize: tags.pageSize,
                        showSizeChanger: true,
                    }}
                />
            </Form>
            <EquipmentModal
                isModalvisible={isModalvisible}
                setIsModalVisible={setIsModalVisible}
                record={{ ...equipmentRecord }}
            />
            <div className="text-right">
                <Space>
                    <Button
                        className="btn-lg btn-default save-btn mt-30"
                        type="default"
                        htmlType="button"
                        onClick={goBack}
                    >
                        Previous
                    </Button>
                    <Button
                        className="btn-lg btn-primary-lg save-btn mt-30"
                        type="primary"
                        onClick={onNext}
                    >
                        Save & Next
                    </Button>
                </Space>
            </div>
            <Modal
                title="Add Manual Tags"
                visible={addManualTags}
                onOk={form2.submit}
                okText="Add Tag"
                onCancel={() => setAddManualTags(false)}
            >
                <Form form={form2} layout="vertical" onFinish={addManualTagHandler}>
                    <Form.Item
                        name="name"
                        label="Tag Name"
                        rules={[{ required: true, message: 'Tag name is required' }]}
                    >
                        <Input />
                    </Form.Item>
                    {/* <Form.Item
                        name="manufacture"
                        label="Manufacture Name"
                        rules={[{ required: true, message: 'Manufacture name is required' }]}
                    >
                        <AutoComplete onSelect={onSelect} onSearch={fetchOrgs}>
                            {organizations.map((org) => (
                                <AutoComplete.Option
                                    key={org.id}
                                    value={org?.name}
                                    org_type={org?.org_type?.name}
                                    org_type_id={org?.org_type?.id}
                                >
                                    {org?.name}
                                </AutoComplete.Option>
                            ))}
                        </AutoComplete>{' '}
                    </Form.Item> */}
                    <Form.Item
                        name="description"
                        label="Description"
                        rules={[{ required: true, message: 'Description is required' }]}
                    >
                        <Input.TextArea />
                    </Form.Item>
                    <Form.Item name="serial_number" label="Serial #">
                        <InputNumber className="width-100" />
                    </Form.Item>
                    <>
                        <Row gutter={16}>
                            {/* <Col span={12}>
                                <Form.Item
                                    name="reference_number"
                                    label="Reference"
                                    rules={[{ required: true, message: 'Reference is required' }]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="asset_type" label="Asset Type">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="warranty_date" label="Warranty Date">
                                    <DatePicker
                                        format={INPUT_DATE_FORMAT}
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="group" label="Group">
                                    <Input />
                                </Form.Item>
                            </Col> */}

                            <Col span={12}>
                                <Form.Item name="is_reading_required" label="Reading Required">
                                    <Switch
                                        checkedChildren="Yes"
                                        unCheckedChildren="No"
                                        defaultChecked={false}
                                        onChange={(checked, event) => {}}
                                    ></Switch>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="is_last_updated_required"
                                    label="Last Updated Required"
                                >
                                    <Switch
                                        checkedChildren="Yes"
                                        unCheckedChildren="No"
                                        defaultChecked={false}
                                        onChange={(checked, event) => {}}
                                    ></Switch>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="on_off_feature" label="On Off Feature">
                                    <Switch
                                        checkedChildren="Yes"
                                        unCheckedChildren="No"
                                        defaultChecked={false}
                                        onChange={(checked, event) => {}}
                                    ></Switch>
                                </Form.Item>
                            </Col>
                        </Row>
                    </>
                </Form>
            </Modal>
        </>
    );
};

export default ManageEquipments;
