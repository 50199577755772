import moment from 'moment';
import { memo, useEffect } from 'react';
import { Modal, Form, DatePicker } from 'antd';
import { DISPLAY_DATE_FORMAT, INPUT_DATE_FORMAT, SERVER_DATE_FORMAT } from '../../utils';

const ShipDateModal = ({
    onSubmit = () => {},
    onCancel = () => {},
    visible = false,
    initialValues,
}) => {
    const [form] = Form.useForm();

    const onSubmitHandler = (values) => {
        values.ship_date = moment(values.ship_date, SERVER_DATE_FORMAT).format(DISPLAY_DATE_FORMAT);
        onSubmit(values);
        form.resetFields();
    };

    const cancleHandler = () => {
        onCancel();
    };

    useEffect(() => {
        if (initialValues?.ship_date) {
            form.setFieldsValue({
                ship_date: moment(initialValues.ship_date),
            });
        } else {
            form.resetFields();
        }
    }, [initialValues]);

    return (
        <Modal
            title="Add/Update Ship Date"
            visible={visible}
            onOk={form.submit}
            onCancel={cancleHandler}
            okText="Save"
        >
            <Form form={form} layout="vertical" onFinish={onSubmitHandler}>
                <Form.Item label="Ship Date" name="ship_date" required>
                    <DatePicker style={{ width: '100%' }} format={INPUT_DATE_FORMAT} />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default memo(ShipDateModal);
