import { Popover } from 'antd';
import GoogleMapReact from 'google-map-react';
import mapMarker from '../../assets/images/map-marker.png';

const places = [
    // {
    //     name: 'Warsaw Road Swing Bridge',
    //     description:
    //         'This project was started in April 2020 and its value is estimated to be around $8,000,000',
    //     lat: 41.242280721926996,
    //     lng: -85.85515213434131,
    // },
    // {
    //     name: 'Major Trans  Canada Highway Expansion',
    //     description:
    //         'In British Columbia, Canada, the Government has a program to upgrade essential transportation corridors.',
    //     lat: 41.234858367119244,
    //     lng: -85.80296707720437,
    // },
    {
        name: 'Rosendin Electric',
        description: '2653 SW High Desert',
        lat: 38.051866875543986,
        lng: -121.7714555539972,
    },
    {
        name: 'Rosendin Electric',
        description: 'Electrician',
        lat: 46.23168190992833,
        lng: -123.00429493905627,
    },
    // {
    //     name: "Rosendin Electric",
    //     description: "2653 SW High Desert",
    //     lat:
    //     lng:
    // },
    {
        name: 'Rosendin Electric',
        description: 'Corporate Office',
        lat: 46.12004,
        lng: -122.90698,
    },
    {
        name: 'Rosendin Electric',
        description: '1338 N Market Blvd Sacranento',
        lat: 39.22084,
        lng: -122.01677,
    },
    {
        name: 'Rosendin Electric',
        description: '2121 Oakdale Ave, San Francisco',
        lat: 38.0311,
        lng: -122.36608,
    },
];

const Marker = ({ name, description }) => (
    <div>
        <Popover
            content={() => <p>{description}</p>}
            title={name}
            trigger="hover"
            overlayClassName="tags-pop-over"
        >
            <img src={mapMarker} width="20" />
        </Popover>
    </div>
);

export const GoogleMap = () => {
    return (
        <div style={{ height: '442px', width: '100%' }}>
            <GoogleMapReact
                defaultZoom={4}
                bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_KEY_V3, region: 'CA' }}
                defaultCenter={[41.25480000139866, -85.85206222945915]}
                yesIWantToUseGoogleMapApiInternals
            >
                {places.map((place, idx) => (
                    <Marker {...place} key={idx} />
                ))}
            </GoogleMapReact>
        </div>
    );
};
