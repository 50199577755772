import React from 'react';
import { useState } from 'react';
import { Tabs } from 'antd';
import Analytics from './analytics';
import Status from './status';
import { EquipmentDocs } from '../../equipment_docs';

const { TabPane } = Tabs;

export const InstallationTab = ({
    isLoading = false,
    equipmentDocs = [],
    uploadDocs = () => {},
    uploadAfterImg,
    uploadBeforeImg,
    pkgId,
}) => {
    const [activeTab, setActiveTab] = useState('1');
    return (
        <div>
            <Tabs tabPosition={'left'} defaultActiveKey={activeTab} onChange={setActiveTab}>
                <TabPane className="side_tabs" tab="Status" key="1">
                    <Status
                        uploadBeforeImg={uploadBeforeImg}
                        uploadAfterImg={uploadAfterImg}
                    />
                </TabPane>
                <TabPane className="side_tabs" tab=" Analytics" key="2">
                    <Analytics />
                </TabPane>

                <TabPane tab="Documents" key="4">
                    <EquipmentDocs
                        pkgId={pkgId}
                        data={equipmentDocs}
                        isLoading={isLoading}
                        uploadDocs={uploadDocs}
                    />
                </TabPane>
            </Tabs>
        </div>
    );
};
