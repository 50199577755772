import _ from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { DeleteTwoTone, EditOutlined, EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import {
    Table,
    Tooltip,
    Select,
    Button,
    Space,
    Typography,
    Popconfirm,
    DatePicker,
    Form,
    Switch,
    Alert,
    notification,
    Modal,
    Input,
} from 'antd';
import {
    DATE_FIELDS,
    INPUT_DATE_FORMAT,
    DISPLAY_DATE_FORMAT,
    SERVER_DATE_FORMAT,
} from '../../../utils';
import { createTags, deleteTags, updateTag } from '../../../services';

const { Option } = Select;

const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
}) => {
    let inputNode = null;
    if (inputType === 'contractor') {
        inputNode = <Input />;
    } else if (inputType === 'manufacturer') {
        inputNode = <Input />;
    } else if (inputType === 'datepicker') {
        inputNode = <DatePicker format={INPUT_DATE_FORMAT} />;
    } else {
        inputNode = <Input />;
    }

    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item name={dataIndex} style={{ margin: 0 }}>
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

const ManageEquipments = ({
    tags = [],
    goBack = () => {},
    setTags = () => {},
    onFinish = () => {},
    setLoader = () => {},
    equipmentErrors = [],
    projectId = null,
}) => {
    const { operations } = useSelector(({ common }) => common);
    const [addManualTags, setAddManualTags] = useState(false);
    const [showDesc, setShowDesc] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [form] = Form.useForm();
    const [form2] = Form.useForm();
    const [editingKey, setEditingKey] = useState('');
    const [dates, setDates] = useState({});

    const isEditing = (record) => record.key === editingKey;

    const handleApprovalDrawing = (isChecked, record) => {
        const newTags = tags.map((tag) => {
            if (tag.id === record.id) {
                return {
                    ...tag,
                    approval_dwg: isChecked,
                };
            }
            return tag;
        });
        setTags([...newTags]);
        const data = { approval_dwg: isChecked };
        updateTagHandler(record.id, data);
    };

    const deleteTagHandler = async (tagIds) => {
        try {
            const newTags = tags.filter((tag) => !tagIds.includes(tag.id));
            setTags([...newTags]);
            setSelectedTags([]);
            await deleteTags(projectId, tagIds);
            notification.success({ description: 'Removed successfully' });
        } catch (error) {
            console.log(error);
        }
    };

    const columns = [
        {
            title: 'Tag Name',
            dataIndex: 'name',
            render: (data, record) => (
                <div className="description-td-edit">
                    <Tooltip placement="topLeft" title={data}>
                        <div className="description-td-edit">{data}</div>
                    </Tooltip>
                </div>
            ),
        },
        {
            title: 'Description',
            dataIndex: 'description',

            render: (data, record) =>
                showDesc.includes(record.name) ? (
                    <>
                        <Tooltip placement="topLeft" title={data}>
                            <div className="description-td-edit">{data}</div>
                        </Tooltip>

                        <Tooltip title="Hide Description">
                            <EyeInvisibleOutlined
                                className="cursor-pointer"
                                onClick={() => hideDescription(record.name)}
                            />
                        </Tooltip>
                    </>
                ) : (
                    <Tooltip title="Show Description">
                        <EyeOutlined
                            className="cursor-pointer"
                            onClick={() => setShowDesc([...showDesc, record.name])}
                        />
                    </Tooltip>
                ),
        },
        // {
        //     title: 'Contractor',
        //     dataIndex: 'contractor',
        //     render: (data) => data || '-',
        //     editable: true,
        //     inputType: 'contractor',
        // },
        {
            title: 'Approval',
            dataIndex: 'approval_dwg',
            render: (status, record) => (
                <Switch
                    checked={status}
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    onChange={(checked, event) => {
                        console.log('status: ', status);
                        event.stopPropagation();
                        handleApprovalDrawing(checked, record);
                    }}
                />
            ),
        },
        {
            title: 'Manufacture',
            dataIndex: 'manufacture',
            render: (data) => data?.name || '',
            editable: false,
            inputType: 'manufacturer',
        },
        {
            title: 'Order Date',
            dataIndex: 'order_date',
            render: (data) =>
                data ? moment(data, SERVER_DATE_FORMAT).format(DISPLAY_DATE_FORMAT) : '',
            editable: true,
            inputType: 'datepicker',
        },
        {
            title: 'Aprroval Date',
            dataIndex: 'approval_date',
            render: (data) =>
                data ? moment(data, SERVER_DATE_FORMAT).format(DISPLAY_DATE_FORMAT) : '',
            editable: true,
            inputType: 'datepicker',
        },
        {
            title: 'Release Date',
            dataIndex: 'release_date',
            render: (data) =>
                data ? moment(data, SERVER_DATE_FORMAT).format(DISPLAY_DATE_FORMAT) : '',
            editable: true,
            inputType: 'datepicker',
        },
        // {
        //     title: 'Final Date',
        //     dataIndex: 'final_date',
        //     render: data => (data ? moment(data, SERVER_DATE_FORMAT).format(DISPLAY_DATE_FORMAT) : ''),
        //     editable: true,
        //     inputType: 'datepicker',
        // },
        {
            title: 'Test Date',
            dataIndex: 'test_date',
            render: (data) =>
                data ? moment(data, SERVER_DATE_FORMAT).format(DISPLAY_DATE_FORMAT) : '',
            editable: true,
            inputType: 'datepicker',
        },
        {
            title: 'Ship Date',
            dataIndex: 'ship_date',
            render: (data) =>
                data ? moment(data, SERVER_DATE_FORMAT).format(DISPLAY_DATE_FORMAT) : '',
            editable: true,
            inputType: 'datepicker',
        },
        {
            title: '',
            dataIndex: '',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <Typography.Link
                            onClick={() => {
                                save(record.id);
                            }}
                            style={{
                                marginRight: 8,
                            }}
                        >
                            Save
                        </Typography.Link>
                        <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                            <a>Cancel</a>
                        </Popconfirm>
                    </span>
                ) : (
                    <Space>
                        <Tooltip title="Edit">
                            <EditOutlined
                                disabled={editingKey !== ''}
                                onClick={() => edit(record)}
                            />
                        </Tooltip>
                        <Popconfirm
                            title="Are you sure?"
                            onConfirm={(ev) => {
                                ev.stopPropagation();
                                deleteTagHandler([record.id]);
                            }}
                        >
                            <Tooltip title="Delete">
                                <DeleteTwoTone twoToneColor="#f56666" />
                            </Tooltip>
                        </Popconfirm>
                    </Space>
                );
            },
        },
    ];

    const operation_columns = [
        {
            title: 'Tag Name',
            dataIndex: 'name',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            render: (data, record) =>
                showDesc.includes(record.name) ? (
                    <>
                        {data}{' '}
                        <Tooltip title="Hide Description">
                            <EyeInvisibleOutlined
                                className="cursor-pointer"
                                onClick={() => hideDescription(record.name)}
                            />
                        </Tooltip>
                    </>
                ) : (
                    <Tooltip title="Show Description">
                        <EyeOutlined
                            className="cursor-pointer"
                            onClick={() => setShowDesc([...showDesc, record.name])}
                        />
                    </Tooltip>
                ),
        },
        {
            title: 'Manufacture',
            dataIndex: 'manufacture',
            render: (data) => data || '',
            editable: false,
            inputType: 'manufacture',
        },
        {
            title: 'Installation Date',
            dataIndex: 'installation_date',
            render: (data) =>
                data ? moment(data, SERVER_DATE_FORMAT).format(DISPLAY_DATE_FORMAT) : '',
            editable: true,
            inputType: 'datepicker',
        },
        {
            title: 'Maintenance (# of days)',
            dataIndex: 'maintenance_duration',
            editable: true,
        },
        {
            title: 'Serial Number',
            dataIndex: 'serial_number',
            editable: true,
        },
        {
            title: '',
            dataIndex: '',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <Typography.Link
                            onClick={() => save(record.id)}
                            style={{
                                marginRight: 8,
                            }}
                        >
                            Save
                        </Typography.Link>
                        <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                            <a>Cancel</a>
                        </Popconfirm>
                    </span>
                ) : (
                    <Tooltip title="Edit">
                        <EditOutlined disabled={editingKey !== ''} onClick={() => edit(record)} />
                    </Tooltip>
                );
            },
        },
    ];

    const hideDescription = (name) => {
        const newShowDesc = showDesc.filter((desc) => desc !== name);
        setShowDesc([...newShowDesc]);
    };

    const rowSelection = {
        type: 'checkbox',
        selectedRowKeys: selectedTags,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedTags([...selectedRows.map((row) => row.id)]);
        },
        getCheckboxProps: (record) => ({
            name: record.name,
        }),
    };

    const onManufacturerSelect = (value) => {
        const newTags = tags.map((tag) => {
            if (selectedTags.includes(tag.name)) {
                return {
                    ...tag,
                    manufacturer: value,
                };
            }
            return tag;
        });
        setTags([...newTags]);
    };

    const onContactorSelect = (value) => {
        const newTags = tags.map((tag) => {
            if (selectedTags.includes(tag.name)) {
                return {
                    ...tag,
                    contractor: value,
                };
            }
            return tag;
        });
        setTags([...newTags]);
    };

    const edit = (record) => {
        Object.keys(record).forEach((key) => {
            if (DATE_FIELDS.includes(key)) {
                record[key] = record[key] ? moment(record[key]) : null;
            }
        });
        form.setFieldsValue({ ...record });
        setEditingKey(record.key);
    };

    const cancel = () => {
        setEditingKey('');
    };

    const updateTagHandler = async (tagId, data, cb) => {
        const response = await updateTag(projectId, tagId, data);
        if (response) {
            notification.success({
                message: 'Successfully updated.',
                placement: 'topRight',
            });
            cb && cb();
        }
    };

    const save = async (tagId) => {
        try {
            setLoader(true);
            const data = await form.validateFields();
            Object.keys(data).forEach((key) => {
                if (DATE_FIELDS.includes(key)) {
                    data[key] = !_.isEmpty(data[key])
                        ? moment(moment(data[key], INPUT_DATE_FORMAT)).format(SERVER_DATE_FORMAT)
                        : null;
                }
            });
            updateTagHandler(tagId, data, () => {
                const newData = [...tags];
                const index = newData.findIndex((item) => tagId === item.id);
                if (index > -1) {
                    const item = newData[index];
                    newData.splice(index, 1, { ...item, ...data });
                    setTags([...newData]);
                }
            });
            setEditingKey('');
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        } finally {
            setLoader(false);
        }
    };

    const mergedColumns = (operations ? operation_columns : columns).map((col, indx) => {
        col.key = indx + 1;
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.inputType || 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    const onNext = () => {
        onFinish({ tags });
    };

    const addManualTagHandler = async (values) => {
        try {
            const isTagExist = tags && tags.some((tag) => tag.name === values.name);
            if (isTagExist) {
                form2.setFields([{ name: 'name', errors: ['Tag with this name already exists'] }]);
                return;
            }
            Object.keys(values).forEach((key) => {
                if (DATE_FIELDS.includes(key)) {
                    values[key] = values[key]
                        ? moment(values[key], INPUT_DATE_FORMAT).format(SERVER_DATE_FORMAT)
                        : null;
                }
            });
            const response = await createTags(projectId, [values]);
            if (!_.isEmpty(values?.approval_date)) {
                values.approval_dwg = true;
            }
            values.id = response.data.id;
            setTags([values, ...tags]);
            notification.success({
                message: `${values.name} added`,
                description: 'The tag has been added successfully',
            });
            form2.resetFields();
            setAddManualTags(false);
        } catch (error) {
            console.log(error);
        }
    };

    const disabledDate = (current, end) => {
        if (end) {
            return current && current < end;
        }
    };

    return (
        <>
            <h2 className="step-heading mb-20">
                Edit Equipment Information{' '}
                <div className="text-right">
                    <Button
                        className="buttonAddEqip"
                        onClick={() => setAddManualTags(!addManualTags)}
                    >
                        Add Equipments
                    </Button>
                </div>
            </h2>
            {!_.isEmpty(equipmentErrors) && (
                <Alert
                    message="Tags Errors"
                    showIcon
                    className="mb-20"
                    description={equipmentErrors.map((error, idx) => (
                        <span
                            className="d-block"
                            key={idx}
                            dangerouslySetInnerHTML={{ __html: error.message }}
                        ></span>
                    ))}
                    type="error"
                />
            )}
            {selectedTags.length > 0 && (
                <div className="mb-10 text-right">
                    <Space>
                        <Button onClick={() => deleteTagHandler(selectedTags)}>
                            <DeleteTwoTone twoToneColor="#f56666" /> Delete ({selectedTags.length})
                        </Button>
                        {/* <Select placeholder="Select Contractor" onChange={onContactorSelect}>
                            <Option value="Test Manufacturers">Test Manufacturers</Option>
                            <Option value="Canadian Manufacturers">Canadian Manufacturers</Option>
                        </Select>
                        <Select placeholder="Select Manufacture" onChange={onManufacturerSelect}>
                            <Option value="Test Manufacturers">Test Manufacturers</Option>
                            <Option value="Canadian Manufacturers">Canadian Manufacturers</Option>
                        </Select> */}
                    </Space>
                </div>
            )}

            <Form form={form} component={false}>
                <Table
                    rowSelection={{
                        ...rowSelection,
                    }}
                    components={{
                        body: {
                            cell: EditableCell,
                        },
                    }}
                    dataSource={tags.map((tag, idx) => ({ ...tag, key: tag.id }))}
                    columns={mergedColumns}
                    className="taglist-table editable-row"
                    size="small"
                    pagination={tags.length > 10}
                />
            </Form>
            <div className="text-right">
                <Space>
                    <Button
                        className="btn-lg btn-default save-btn mt-30"
                        type="default"
                        htmlType="button"
                        onClick={goBack}
                    >
                        Previous
                    </Button>
                    <Button
                        className="btn-lg btn-primary-lg save-btn mt-30"
                        type="primary"
                        onClick={onNext}
                    >
                        Save & Next
                    </Button>
                </Space>
            </div>
            <Modal
                title="Add Manual Tags"
                visible={addManualTags}
                onOk={form2.submit}
                okText="Add Tag"
                onCancel={() => setAddManualTags(false)}
            >
                <Form form={form2} layout="vertical" onFinish={addManualTagHandler}>
                    <Form.Item
                        name="name"
                        label="Tag Name"
                        rules={[{ required: true, message: 'Tag name is required' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="manufacture"
                        label="Manufacture Name"
                        rules={[{ required: true, message: 'Manufacture name is required' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="description"
                        label="Description"
                        rules={[{ required: true, message: 'Description is required' }]}
                    >
                        <Input.TextArea />
                    </Form.Item>
                    {operations ? (
                        <>
                            <Form.Item name="installation_date" label="Installation Date">
                                <DatePicker format={INPUT_DATE_FORMAT} style={{ width: '100%' }} />
                            </Form.Item>
                            <Form.Item name="maintenance_duration" label="# of days (Maintainance)">
                                <Input type="number" />
                            </Form.Item>
                            <Form.Item name="serial_number" label="Serial Number">
                                <Input />
                            </Form.Item>
                        </>
                    ) : (
                        <>
                            <Form.Item name="order_date" label="Order Date">
                                <DatePicker
                                    format={INPUT_DATE_FORMAT}
                                    style={{ width: '100%' }}
                                    onChange={(val) => setDates({ ...dates, order_date: val })}
                                />
                            </Form.Item>
                            <Form.Item name="approval_date" label="Approval Date">
                                <DatePicker
                                    disabledDate={(current) =>
                                        disabledDate(current, dates?.order_date)
                                    }
                                    format={INPUT_DATE_FORMAT}
                                    style={{ width: '100%' }}
                                    onChange={(val) => setDates({ ...dates, approval_date: val })}
                                />
                            </Form.Item>
                            <Form.Item name="release_date" label="Release Date">
                                <DatePicker
                                    disabledDate={(current) =>
                                        disabledDate(current, dates?.approval_date)
                                    }
                                    format={INPUT_DATE_FORMAT}
                                    style={{ width: '100%' }}
                                    onChange={(val) => setDates({ ...dates, release_date: val })}
                                />
                            </Form.Item>
                            <Form.Item name="test_date" label="Test Date">
                                <DatePicker
                                    disabledDate={(current) =>
                                        disabledDate(current, dates?.release_date)
                                    }
                                    format={INPUT_DATE_FORMAT}
                                    style={{ width: '100%' }}
                                    onChange={(val) => setDates({ ...dates, test_date: val })}
                                />
                            </Form.Item>
                            <Form.Item name="ship_date" label="Ship Date">
                                <DatePicker
                                    disabledDate={(current) =>
                                        disabledDate(current, dates?.test_date)
                                    }
                                    format={INPUT_DATE_FORMAT}
                                    style={{ width: '100%' }}
                                    onChange={(val) => setDates({ ...dates, ship_date: val })}
                                />
                            </Form.Item>
                        </>
                    )}
                </Form>
            </Modal>
        </>
    );
};

export default ManageEquipments;
