import GoogleMapReact from 'google-map-react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Table, Steps, Input, Image, Collapse, Empty, Spin, notification } from 'antd';
import {
    fetchBeforeShipmentImages,
    detectImageStatusBefore,
    detectImageStatusAfter,
    fetchAfterShipmentImages,
    multiDetectImageStatusAfter,
} from '../../../../services/shipment';
import { showNotification } from '../../../../utils';
const { Panel } = Collapse;
const { Step } = Steps;

function Status({ uploadAfterImg, uploadBeforeImg }) {
    const { pkgId, projectId } = useParams();
    const [beforeImages, setBeforeImages] = useState([]);
    const [afterImages, setAfterImages] = useState([]);
    const [afterShipmentLoader, setAfterShipmentLoader] = useState(false);
    const [beforeShipmentLoader, setBeforeShipmentLoader] = useState(false);
    const [detectData, setDetectData] = useState([]);
    const [toggle, setToggle] = useState(false);
    const [loading, setLoading] = useState(false);

    const detectHandler = async (filePath, type) => {
        try {
            let response;
            const key = `${filePath}`;
            const formData = new FormData();
            formData.append('image_key', key);
            if (type === 'before') {
                setBeforeShipmentLoader(true);
                response = await detectImageStatusBefore(formData);
            } else {
                setAfterShipmentLoader(true);
                response = await detectImageStatusAfter(formData);
            }
            if (response.message !== 'No box detected!') {
                if (response[1].class === 0) {
                    showNotification('The item is damaged', 'error');
                } else {
                    showNotification('The item is in good condition');
                }
            } else {
                showNotification('No box is detected!', 'error');
            }
        } catch (error) {
            showNotification(error?.response?.data?.message, 'error');
        } finally {
            if (type === 'before') setBeforeShipmentLoader(false);
            else setAfterShipmentLoader(false);
        }
    };

    const getColumns = (type) => {
        const columns = [
            {
                title: 'Item #',
                dataIndex: 'id',
            },

            {
                title: 'Images',
                dataIndex: 'signed_url',
                render: (img) => <Image width={60} src={img} />,
            },

            // {
            //     title: 'Detect',
            //     dataIndex: 'file_path',
            //     render: (item) => (
            //         <Tooltip title="Detect" placement="right">
            //             <Button
            //                 className="buttonDetect"
            //                 type="default"
            //                 onClick={() => detectHandler(item, type)}
            //             >
            //                 <AimOutlined style={{ fontSize: 20 }} />
            //             </Button>
            //         </Tooltip>
            //     ),
            // },
            {
                title: 'File Name',
                dataIndex: 'file_name',
            },
        ];
        return columns;
    };

    const detectColumns = [
        {
            title: 'Images',
            dataIndex: 'object_url',
            render: (img) => <Image width={60} src={img} />,
        },

        {
            title: 'File Name',
            dataIndex: 'filename',
        },

        {
            title: 'Confidence',
            dataIndex: 'confidence',
            render: (prob) => (
                <>
                    <p>{`${prob.toFixed(2)}`}</p>
                </>
            ),
        },
    ];

    const getBeforeShipmentDocuments = async () => {
        try {
            setBeforeShipmentLoader(true);
            const response = await fetchBeforeShipmentImages(projectId, pkgId);
            setBeforeImages(response.data || []);
            setBeforeShipmentLoader(false);
        } catch (error) {
            setBeforeShipmentLoader(false);
        }
    };

    const getAfterShipmentDocuments = async () => {
        try {
            setAfterShipmentLoader(true);
            const response = await fetchAfterShipmentImages(projectId, pkgId);
            setAfterImages(response.data || []);
            setAfterShipmentLoader(false);
        } catch (error) {
            setAfterShipmentLoader(false);
        }
    };

    useEffect(() => {
        Promise.all([getBeforeShipmentDocuments(), getAfterShipmentDocuments()]);
    }, []);

    const handleImageUploadBefore = async (fileList) => {
        try {
            setBeforeShipmentLoader(true);
            await uploadBeforeImg(fileList);
            getBeforeShipmentDocuments();
        } catch (error) {
            console.log(error);
        }
    };

    const handleImageUploadAfter = async (fileList) => {
        try {
            setAfterShipmentLoader(true);
            await uploadAfterImg(fileList);
            getAfterShipmentDocuments();
        } catch (error) {
            console.log(error);
        }
    };

    const multiDetectHandler = async (afterImages) => {
        try {
            setLoading(true);
            const formData = new FormData();
            afterImages.map((file) => formData.append('image_keys', file.file_path));
            const response = await multiDetectImageStatusAfter(formData);
            setDetectData(response);
            setToggle(true);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            notification.error({
                message: `No data to process`,
            });
        }
    };

    return (
        <>
            <Card className="footer-body mt-10" title="Shipment Tracking">
                <div>
                    <div style={{ height: '442px', width: '100%' }}>
                        <GoogleMapReact
                            defaultZoom={4}
                            bootstrapURLKeys={{
                                key: process.env.REACT_APP_GOOGLE_MAP_KEY_V3,
                                region: 'CA',
                            }}
                            defaultCenter={[41.25480000139866, -85.85206222945915]}
                            yesIWantToUseGoogleMapApiInternals
                        ></GoogleMapReact>
                    </div>
                </div>
                <div className="stepper-body">
                    <Steps progressDot current={1}>
                        <Step title="Finished" description="This is a description." />
                        <Step title="In Progress" description="This is a description." />
                        <Step title="Waiting" description="This is a description." />
                    </Steps>
                </div>
            </Card>

            <Collapse bordered={false} className="tagsBody mt-10">
                <Panel className="tagsBodyPanel" header={<b>Images Before Shipment</b>} key="1">
                    <Card className="card-body">
                        <div className="file_upload_wrapper">
                            <Input
                                className="file_upload_field"
                                type="file"
                                multiple
                                accept="image/x-png,image/gif,image/jpeg,image/jpg"
                                onChange={(e) =>
                                    handleImageUploadBefore(Object.values(e.target.files))
                                }
                            />
                        </div>

                        <div className="thumb_body">
                            <Table
                                loading={beforeShipmentLoader}
                                rowKey="id"
                                dataSource={beforeImages}
                                columns={getColumns('before')}
                                pagination={beforeImages.length > 10}
                            />
                        </div>
                    </Card>
                </Panel>
            </Collapse>

            {/* <Card className="card-body mt-10" title="Shipped">
                <div style={{ height: '442px', width: '100%' }}>
                    <GoogleMapReact
                        defaultZoom={4}
                        bootstrapURLKeys={{
                            key: process.env.REACT_APP_GOOGLE_MAP_KEY,
                            region: 'CA',
                        }}
                        defaultCenter={[41.25480000139866, -85.85206222945915]}
                        yesIWantToUseGoogleMapApiInternals
                    ></GoogleMapReact>
                </div>
            </Card> */}

            <Collapse bordered={false} className="tagsBody mt-10">
                <Panel className="tagsBodyPanel" header={<b>Images After Shipment</b>} key="2">
                    <Card className="card-body">
                        <div className="file_upload_wrapper">
                            <Input
                                className="file_upload_field"
                                type="file"
                                multiple
                                accept="image/x-png,image/gif,image/jpeg,image/jpg"
                                onChange={(e) =>
                                    handleImageUploadAfter(Object.values(e.target.files))
                                }
                            />
                        </div>

                        <div className="thumb_body">
                            <Table
                                rowKey="id"
                                loading={afterShipmentLoader}
                                dataSource={afterImages}
                                columns={getColumns('after')}
                                pagination={afterImages.length > 10}
                            />
                        </div>
                    </Card>
                </Panel>
            </Collapse>

            <Collapse defaultActiveKey={['3']} bordered={false} className="tagsBody mt-10">
                <Panel className="tagsBodyPanel" header={<b>Detect Damaged Equipments</b>} key="3">
                    <div
                        className="button_detect mb-10"
                        type="default"
                        onClick={() => multiDetectHandler(afterImages)}
                    >
                        <span className="detect_text">Click to detect damaged equipments</span>
                    </div>
                    <Spin spinning={loading} tip="Loading...">
                        {toggle ? (
                            <Card className="card-body">
                                <div className="thumb_body">
                                    <Table
                                        rowKey="id"
                                        dataSource={detectData}
                                        columns={detectColumns}
                                        pagination={detectData.length > 10}
                                    />
                                </div>
                            </Card>
                        ) : (
                            <Empty />
                        )}
                    </Spin>
                </Panel>
            </Collapse>
        </>
    );
}

export default Status;
