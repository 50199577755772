import _ from 'lodash';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Layout, notification, Spin } from 'antd';
import { CSV_DATE_FORMAT, DATES_KEYS, SERVER_DATE_FORMAT } from '../../../utils';
import {
    createMultiplePackages,
    registeredUsers,
    createProject,
    saveProjectDocs,
} from '../../../services';
import BasicInfo from './basic-info';
import UserAccess from './user-access';
import EquipmentInfo from './equipment-info';
import ManageEquipments from './manage-equipments';
import Packages from './packages';
import Documentation from './documentation';

const { Content } = Layout;

const steps = [
    {
        title: 'Project Information',
        id: 1,
    },
    {
        title: 'Users Access',
        id: 2,
    },
    {
        title: 'Equipment Info',
        id: 3,
    },
    {
        title: 'Manage Equipments',
        id: 4,
    },
    {
        title: 'Manage Packages',
        id: 5,
    },
    {
        title: 'Add Documentation',
        id: 6,
    },
];

const CreateProject = ({ loadProjects, history }) => {
    const { user } = useSelector(({ auth }) => auth);
    const { operations } = useSelector(({ common }) => common);
    const [current, setCurrent] = useState(1);
    const [formValues, setFormValues] = useState({});
    const [existingUsers, setExistingUsers] = useState([]);
    const [loader, setLoader] = useState(false);
    const [showTip, setShowTip] = useState(false);
    const [tags, setTags] = useState([]);
    const [users, setUsers] = useState([]);
    const [roomPackages, setRoomPackages] = useState([]);
    const [organizations, setOrganizations] = useState([]);
    const [equipmentErrors, setEquipmentErrors] = useState([]);

    const fetchUsers = async () => {
        const response = await registeredUsers();
        setExistingUsers([...response.data.filter((_user) => _user.id !== user.id)]);
    };

    useEffect(() => {
        // fetchUsers();
    }, []);

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    const uploadProjectDocs = async (projectId, values) => {
        try {
            Promise.all(
                Object.keys(values).map(async (key) => {
                    if (!_.isEmpty(values[key])) {
                        const fileList = values[key].target.files;
                        const formData = new FormData();
                        formData.append('dir_key', key);
                        formData.append('id', projectId);
                        Object.values(fileList).forEach((file) => {
                            formData.append('files', file);
                        });
                        await saveProjectDocs(projectId, formData);
                    }
                }),
            ).then(actionCompleted);
        } catch (error) {
            console.log(error);
        }
    };

    const goBack = () => {
        setCurrent(current - 1);
    };

    const onFinish = (values) => {
        setFormValues({ ...formValues, ...values });
        setCurrent(current + 1);
    };

    const actionCompleted = () => {
        notification.success({
            message: 'Project Created',
            description: 'The project has been successfully created',
        });
        setCurrent(0);
        history.push('/projects');
        setShowTip(false);
        setLoader(false);
    };

    const onSubmit = async (values) => {
        try {
            if (!_.isEmpty(formValues?.tags)) {
                formValues.tags.forEach((tag) => {
                    Object.keys(tag).forEach((key) => {
                        if (DATES_KEYS.includes(key) && !_.isEmpty(tag[key])) {
                            tag[key] = moment(moment(tag[key], CSV_DATE_FORMAT)).format(
                                SERVER_DATE_FORMAT,
                            );
                        }
                    });
                    return tag;
                });
            }
            setLoader(true);
            setShowTip(true);
            if (formValues?.roomPackages) {
                formValues.roomPackages = roomPackages.map((room) => {
                    return {
                        ...room,
                        tags: room.tags.map(({ name }) => name),
                    };
                });
            }
            const response = await createProject({ ...formValues, operations });
            if (response) {
                if (!_.isEmpty(values)) {
                    await uploadProjectDocs(response.data.id, values);
                    return;
                }
                actionCompleted();
            }
        } catch (error) {
            setLoader(false);
            console.log('error');
        }
    };

    const createPackages = async (projectId) => {
        try {
            if (!_.isEmpty(roomPackages)) {
                const body = roomPackages.map((room) => {
                    return {
                        ...room,
                        tags: room.tags.map(({ name }) => name),
                    };
                });
                await createMultiplePackages(projectId, body);
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Content>
            <div style={{ width: '80%', margin: 'auto' }}>
                <div className="page-content-container">
                    <div className="project-steps">
                        {steps.map((item) => (
                            <div
                                className={`project-step ${item.id === current ? 'active' : ''}`}
                                key={item.title}
                            >
                                {item.title}
                            </div>
                        ))}
                    </div>
                    <div className="steps-content mt-30">
                        <Spin
                            spinning={loader}
                            tip={showTip ? 'Please wait, it will take a while' : ''}
                        >
                            {current === 1 ? (
                                <BasicInfo
                                    basicInfo={formValues}
                                    normFile={normFile}
                                    onFinish={onFinish}
                                />
                            ) : current === 2 ? (
                                <UserAccess
                                    existingUsers={existingUsers}
                                    organizations={organizations}
                                    setOrganizations={setOrganizations}
                                    users={users}
                                    goBack={goBack}
                                    setUsers={setUsers}
                                    onFinish={onFinish}
                                />
                            ) : current === 3 ? (
                                <EquipmentInfo
                                    tags={tags}
                                    goBack={goBack}
                                    setTags={setTags}
                                    onFinish={onFinish}
                                    equipmentErrors={equipmentErrors}
                                    setEquipmentErrors={setEquipmentErrors}
                                />
                            ) : current === 4 ? (
                                <ManageEquipments
                                    tags={tags}
                                    goBack={goBack}
                                    setTags={setTags}
                                    onFinish={onFinish}
                                    equipmentErrors={equipmentErrors}
                                />
                            ) : current === 5 ? (
                                <Packages
                                    goBack={goBack}
                                    tags={tags}
                                    users={users}
                                    setTags={setTags}
                                    roomPackages={roomPackages}
                                    setRoomPackages={setRoomPackages}
                                    onFinish={onFinish}
                                />
                            ) : current === 6 ? (
                                <Documentation goBack={goBack} onSubmit={onSubmit} />
                            ) : null}
                        </Spin>
                    </div>
                </div>
            </div>
        </Content>
    );
};

export default CreateProject;
