import { http } from '../../utils/http';
import {
    FETCH_SINGLELINES_SUCCESS,
    FETCH_SINGLELINES_INTIATE,
    FETCH_SINGLELINE_DETAIL_INITIATE,
    FETCH_SINGLELINE_DETAIL_SUCCESS,
    UPDATE_TAG_INITIATE,
    HIDE_LOADER,
} from './types';

export const fetchSinglelines = () => async (dispatch) => {
    try {
        dispatch({ type: FETCH_SINGLELINES_INTIATE });
        const { data } = await http.get('/slineview/slinelist/');
        dispatch({
            type: FETCH_SINGLELINES_SUCCESS,
            payload: data,
        });
    } catch (ex) {
        console.log(ex);
    }
};

export const fetchSinglelineDetails = (slineId) => async (dispatch) => {
    try {
        dispatch({ type: FETCH_SINGLELINE_DETAIL_INITIATE });
        const { data } = await http.get('/slineview/' + slineId);
        dispatch({
            type: FETCH_SINGLELINE_DETAIL_SUCCESS,
            payload: data,
        });
    } catch (ex) {
        console.log(ex);
    }
};

export const updateTag = (slineId, data, cb) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_TAG_INITIATE });
        const reqBody = {
            tags: [{ ...data }],
        };
        const response = await http.patch('/slineview/' + slineId, reqBody);
        if (response.data) {
            dispatch({ type: HIDE_LOADER });
            cb();
        }
    } catch (ex) {
        console.log(ex);
    }
};
