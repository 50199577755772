import { http, fileHttp } from '../utils/http';

export const login = async (body) => {
    try {
        const response = await http.post('/login/', body);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const register = async (body) => {
    try {
        const response = await http.post('/register/', body);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const registeredUsers = async () => {
    try {
        const response = await http.get('/users/');
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const updateUser = async (data) => {
    try {
        const response = await fileHttp.patch('/user/', data);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const requestResetPassword = async (body) => {
    try {
        const response = await http.post('/request-reset-password/', body);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const verifyResetPasswordToken = async (uidb64, token) => {
    try {
        const response = await http.get(`/reset-password/${uidb64}/${token}/`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const resetPassword = async (body) => {
    try {
        const response = await http.patch('/reset-password/', body);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const validateEmail = async (body) => {
    try {
        const response = await http.post('/user/validate-email/', body);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const fetchContacts = async () => {
    try {
        const response = await http.get('/user/contacts/');
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const fetchUserDetails = async () => {
    try {
        const response = await http.get('/user/');
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const fetchUsersWithBranches = async () => {
    try {
        const response = await http.get('/admin/users/');
        return response.data;
    } catch (error) {
        throw error;
    }
};