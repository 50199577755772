import React from 'react';
import { ViewMode } from 'gantt-task-react';
import 'gantt-task-react/dist/index.css';

export const ViewSwitcher = ({ onViewModeChange, hideDates, onViewChange }) => {
    return (
        <div className="ViewContainer">
            <button className="Button" onClick={() => onViewModeChange(ViewMode.Day)}>
                Day
            </button>
            <button className="Button" onClick={() => onViewModeChange(ViewMode.Week)}>
                Week
            </button>
            <button className="Button" onClick={() => onViewModeChange(ViewMode.Month)}>
                Month
            </button>

            <div className="Switch">
                <label className="Switch_Toggle">
                    <input
                        type="checkbox"
                        defaultChecked={hideDates}
                        onClick={() => onViewChange(!hideDates)}
                    />
                    <span className="Slider" />
                </label>
                Hide Dates
            </div>
        </div>
    );
};
