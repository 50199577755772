export const DASHBOARD = '/dashboard';
export const PROJECTS = '/projects';
export const SLINES = `/slines`;
export const TAGS = '/tags';
export const PACKAGE = '/package';
export const CONTACTS = '/contacts';
export const SETTING = '/setting';
export const PROFILE = '/profile';
export const HELP = '/help';
export const USERINFO = '/userinfo';
