import { RESET_STATE } from '../common';
import {
    TAG_LIST,
    UPDATE_TAG_SUCCESS,
    UPDATE_TAG_INITIATE,
    GET_MASTER_TAGS_LOADING,
    GET_MASTER_TAGS_SUCCESS,
} from './types';

const initialState = {
    list: [],
    masterTags: [],
    loading: false,
    error: null,
};

export const tagReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case TAG_LIST:
            return { ...state, list: payload };

        case GET_MASTER_TAGS_LOADING:
        case UPDATE_TAG_INITIATE:
            return { ...state, loading: true };

        case GET_MASTER_TAGS_SUCCESS:
            return { ...state, masterTags: payload, loading: false };

        case UPDATE_TAG_SUCCESS:
            return { ...state, loading: false };

        case RESET_STATE:
            return initialState;

        default:
            return state;
    }
};
