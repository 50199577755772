import _ from 'lodash';
import { Avatar, Select, Tooltip } from 'antd';
import { makeAvatar } from '../../utils';
import './styles.scss';

const { Option } = Select;

export const UsersIcon = ({
    heading = '',
    teamMembers = [],
    isReleased = false,
    tags = [],
    activeTag = {},
    onTagChange = () => {},
}) => {
    return (
        <div className="approval-process mb-10">
            {!_.isEmpty(tags) ? (
                <Select style={{ width: 200 }} value={activeTag?.id} onChange={onTagChange}>
                    {tags.map((tag) => (
                        <Option key={tag.id} value={tag.id}>
                            {tag.name}
                        </Option>
                    ))}
                </Select>
            ) : (
                <h1 className="mb-0">{heading}</h1>
            )}

            {isReleased && <h1>Review process is over</h1>}
            <div className="icon">
                <Avatar.Group>
                    {teamMembers &&
                        teamMembers.map((member) => (
                            <Tooltip
                                title={`${member.first_name} ${member.last_name}`}
                                key={member.id}
                            >
                                <Avatar
                                    className="circle-icon"
                                    src={
                                        member.image ||
                                        makeAvatar(member.first_name[0], member.last_name[0])
                                    }
                                />
                            </Tooltip>
                        ))}
                </Avatar.Group>
            </div>
        </div>
    );
};
