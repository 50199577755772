import _ from 'lodash';
import Highlighter from 'react-highlight-words';
import { useHistory } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Table,
    Button,
    Input,
    Tooltip,
    Row,
    Col,
    Collapse,
    notification,
    Space,
    Avatar,
    Popconfirm,
    Popover,
    Select,
    Form,
    Card,
    Spin,
} from 'antd';
import {
    SearchOutlined,
    EditOutlined,
    DeleteTwoTone,
    PlusOutlined,
    WarningOutlined,
} from '@ant-design/icons';
import {
    addMemberInPackage,
    deletePackage,
    fetchPackages,
    fetchPackagesForArea,
    fetchPackagesForAreaPaginated,
    fetchProjectUsers,
    updateProject,
    removeMemberFromPackage,
    updatePackage,
} from '../../../services';
import { setPackage, addPackageToList } from '../../../store/package/actions';
import {
    DATE_STATUSES,
    PROJECTS,
    PACKAGE,
    TAGS,
    makeAvatar,
    checkPackageManufactures,
    PROJECT_API_ENUM,
} from '../../../utils';
import CreatePackageModal from './create-package';
import * as Permissions from '../../../utils/permissions';

const { Panel } = Collapse;
const { Option } = Select;

const Packages = ({
    projectId,
    createPackageVisible = false,
    setCreatePackageVisible = () => {},
}) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const history = useHistory();
    const searchInput = useRef();
    const { operations } = useSelector(({ common }) => common);
    const { permissions } = useSelector(({ permission }) => permission);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchColumn] = useState('');
    const [ordering, setOrdering] = useState(null);
    // const [individualPackages, setIndividualPackages] = useState([]);
    // const [pkgsWithoutApprovalDwg, setPkgsWithoutApprovalDwg] = useState([]);
    const [individualPackages, setIndividualPackages] = useState({
        data: [],
        meta: {},
        currentPage: 1,
    });
    const [areas, setAreas] = useState({ data: [], meta: {}, currentPage: 1, pageSize: 10 });
    const [pkgsWithoutApprovalDwg, setPkgsWithoutApprovalDwg] = useState({ data: [], meta: {} });
    const [createPkgLoading, setCreatePkgLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isloading, setIsLoading] = useState(false);

    const [activePackage, setActivePackage] = useState({});
    const [displayAddMembers, setDisplayAddMembers] = useState('');
    const [users, setUsers] = useState([]);

    const showNotification = (message = '', type = 'success', placement = 'topRight') => {
        notification[type]({
            message,
            placement,
        });
    };

    const getProjectUsers = async () => {
        try {
            const response = await fetchProjectUsers(projectId);
            setUsers([...response.data]);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getPackages(1, areas.pageSize, searchText, searchedColumn, ordering);

        if (Permissions.can_edit_packages(permissions)) {
            getProjectUsers();
        }
    }, []);

    const getPackages = async (page, pageSize, searchText, searchedColumn, ordering = null) => {
        try {
            setIsLoading(true);
            const response = await fetchPackagesForArea(
                projectId,
                page,
                pageSize,
                searchText,
                searchedColumn,
                ordering,
            );
            setAreas({
                data: [...response.data],
                meta: { ...response.meta },
                currentPage: page,
                pageSize: pageSize,
            });
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            throw err;
        }
    };

    const onChangePageAndSorting = async (pagination, filters, sorter) => {
        let newOrdering = null;
        if (sorter.column) {
            newOrdering =
                sorter.order === 'ascend' ? sorter.column.dataIndex : '-' + sorter.column.dataIndex;
        }
        if (
            pagination.current !== areas.currentPage ||
            newOrdering !== ordering ||
            pagination.pageSize !== areas.pageSize
        ) {
            setOrdering(newOrdering);
            await getPackages(
                pagination.current,
                pagination.pageSize,
                searchText,
                searchedColumn,
                newOrdering,
            );
        }
    };

    // const getPackages = async () => {
    //     setLoading(true);
    //     const response = await newfetchProjectDetail(PROJECT_API_ENUM.PACKAGES, projectId, false);
    //     dispatch(setPackage(response.data));
    //     setIndividualPackages([...response.data.individual_packages]);
    //     setRoomPackages([...response.data.room_packages]);
    //     setPkgsWithoutApprovalDwg([...response.data.packages_without_approval_dwg]);
    //     setLoading(false);
    // };
    const handleEdit = (record) => {
        setActivePackage({ ...record });
        setCreatePackageVisible(true);
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8, width: 200 }}>
                <Row gutter={5}>
                    <Col span={24}>
                        <Input
                            ref={searchInput}
                            placeholder={`Search ${dataIndex}`}
                            value={selectedKeys[0]}
                            onChange={(e) =>
                                setSelectedKeys(e.target.value ? [e.target.value] : [])
                            }
                            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            style={{ marginBottom: 8, display: 'block' }}
                        />
                    </Col>
                    <Col span={12}>
                        <Button
                            onClick={() => handleReset(clearFilters, confirm)}
                            size="small"
                            style={{ width: '100%' }}
                        >
                            Reset
                        </Button>
                    </Col>
                    <Col span={12}>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: '100%' }}
                        >
                            Search
                        </Button>
                    </Col>
                </Row>
            </div>
        ),

        filterIcon: (filtered) => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : '#838383', fontSize: '18px' }} />
        ),

        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',

        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current.select(), 100);
            }
        },

        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchColumn(dataIndex);
        getPackages(1, areas.pageSize, selectedKeys[0], dataIndex, ordering);
    };

    const handleReset = (clearFilters, confirm) => {
        clearFilters();
        setSearchText('');
        confirm();
        getPackages(1, areas.pageSize, '', '', ordering);
    };

    const onCreatePackage = async (data, cb) => {
        try {
            let allTeamMembers = [];
            data.tags.forEach(({ team_members }) => {
                allTeamMembers = [...allTeamMembers, ...team_members];
            });
            const team_members = _.uniqBy(allTeamMembers, 'id');
            data.team_members = team_members.map(({ id }) => id);
            data.tags = data.tags.map(({ id }) => id);
            setCreatePkgLoading(true);
            const response = await updateProject(PROJECT_API_ENUM.PACKAGES, projectId, data);
            dispatch(addPackageToList(response.data));
            showNotification('Package successfully created');
        } catch (error) {
            console.log(error);
        } finally {
            setCreatePkgLoading(false);
            setCreatePackageVisible(false);
            if (cb) {
                cb();
            }
            getPackages(1, areas.pageSize, searchText, searchedColumn, ordering);
        }
    };

    const onUpdatePackage = async (data, cb) => {
        try {
            let allTeamMembers = [...data.team_members, ...activePackage.team_members];
            const team_members = _.uniqBy(allTeamMembers, 'id');
            data.team_members = team_members.map(({ id }) => id);
            data.tags = data.tags.map(({ id }) => id);
            setCreatePkgLoading(true);
            const response = await updatePackage(projectId, activePackage.id, data);
            showNotification('Package successfully updated');
        } catch (ex) {
            console.log(ex);
        } finally {
            setCreatePkgLoading(false);
            setCreatePackageVisible(false);
            if (cb) {
                cb();
            }
            getPackages(1, areas.pageSize, searchText, searchedColumn, ordering);
        }
    };

    const onDeletePackage = async (pkgId) => {
        try {
            setLoading(true);
            const response = await deletePackage(projectId, pkgId);
            if (response) {
                showNotification('Package successfully deleted');
            }
        } catch (ex) {
            console.log(ex);
        } finally {
            getPackages(1, areas.pageSize, searchText, searchedColumn, ordering);
        }
    };

    const onCancel = () => {
        setActivePackage({});
        setCreatePackageVisible(false);
    };

    const onAddMemberInPackage = async (values) => {
        try {
            const { pkg, member_id } = values;
            const user = users.find((user) => user.id === member_id);
            const isUserExist = pkg.team_members.some((team_member) => team_member.id === user.id);
            if (isUserExist) {
                form.setFields([{ name: 'member_id', errors: ['User already exist'] }]);
                return;
            }
            setLoading(true);
            const response = await addMemberInPackage(projectId, pkg.id, member_id);
            showNotification(response.message);
            setDisplayAddMembers('');
            form.resetFields();
            setLoading(false);
        } catch (error) {
            console.log(error);
        } finally {
            getPackages(1, areas.pageSize, searchText, searchedColumn, ordering);
        }
    };

    const addMembersContent = (pkg) => {
        return (
            <Form
                form={form}
                style={{ width: 200 }}
                layout="vertical"
                onFinish={(values) => onAddMemberInPackage({ ...values, pkg: pkg })}
            >
                <Form.Item name="member_id" rules={[{ required: true, message: 'Please select' }]}>
                    <Select
                        showSearch
                        placeholder="Please select"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().includes(input.toLowerCase())
                        }
                        allowClear
                        style={{ width: '100%' }}
                    >
                        {users.map((user) => (
                            <Option key={user.id} value={user.id}>
                                {`${user.first_name} ${user.last_name}`}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <div className="text-right mt-15">
                    <Space>
                        <Button type="default" onClick={() => setDisplayAddMembers('')}>
                            Cancel
                        </Button>
                        <Button type="primary" htmlType="submit">
                            Add
                        </Button>
                    </Space>
                </div>
            </Form>
        );
    };

    const removeMember = async (memberId, pkgId, section) => {
        try {
            setLoading(true);
            const response = await removeMemberFromPackage(projectId, pkgId, memberId);
            showNotification(response.message);
            setLoading(false);
            setDisplayAddMembers('');
        } catch (error) {
            setLoading(false);
            if (error?.response?.data?.message)
                showNotification(error.response.data.message, 'error');
        } finally {
            getPackages(1, areas.pageSize, searchText, searchedColumn, ordering);
        }
    };

    const goToPackageDetail = (record) => {
        return {
            onClick: () => {
                const id = _.has(record, 'packageroom') ? record.tags[0].packageroom[0] : record.id;
                let url = `${PROJECTS}/${projectId}${PACKAGE}/${id}`;
                if (operations) url = `${PROJECTS}/${projectId}/operations${PACKAGE}/${id}`;
                // history.push(url);
            },
        };
    };

    // const individualPackageColumns = [
    //     {
    //         title: '',
    //         dataIndex: 'warning',
    //         width: 0,
    //         render: (_, record) => checkPackageManufactures(record),
    //     },
    //     {
    //         title: 'Tag Name',
    //         dataIndex: 'name',
    //         key: 'name',
    //         width: 150,
    //         ...getColumnSearchProps('name'),
    //         render: (text) => {
    //             return {
    //                 props: {
    //                     className: 'cursor-pointer',
    //                 },
    //                 children: text,
    //             };
    //         },
    //         onCell: goToPackageDetail,
    //     },
    //     {
    //         title: 'Description',
    //         dataIndex: 'description',
    //         key: 'description',
    //         render: (text, record) => {
    //             return {
    //                 props: {
    //                     className: 'description-td cursor-pointer',
    //                 },
    //                 children: (
    //                     <Tooltip placement="topLeft" title={text}>
    //                         {text}
    //                     </Tooltip>
    //                 ),
    //             };
    //         },
    //         onCell: goToPackageDetail,
    //     },
    //     {
    //         title: 'No of comments',
    //         dataIndex: 'comments_count',
    //         width: 100,
    //     },
    //     {
    //         title: 'No of docs',
    //         dataIndex: 'docs_count',
    //         width: 100,
    //     },
    //     {
    //         title: 'No of issues',
    //         dataIndex: 'issues_count',
    //         width: 100,
    //     },
    //     {
    //         title: 'Team Members',
    //         dataIndex: 'team_members',
    //         key: 'team_members',
    //         render: (team_members, record) =>
    //             team_members && (
    //                 <div className="package-team-members">
    //                     <Avatar.Group>
    //                         {team_members.map((member, idx) => (
    //                             <Tooltip
    //                                 key={member.email}
    //                                 title={`${member.first_name} ${member.last_name}`}
    //                             >
    //                                 {Permissions.can_edit_packages(permissions) ? (
    //                                     <Popconfirm
    //                                         onConfirm={() =>
    //                                             removeMember(
    //                                                 member.id,
    //                                                 record.tags[0].packageroom[0],
    //                                             )
    //                                         }
    //                                         title="Are your want to remove this user?"
    //                                     >
    //                                         <Avatar
    //                                             className="circle-icon"
    //                                             src={
    //                                                 member.image ||
    //                                                 makeAvatar(
    //                                                     member.first_name[0],
    //                                                     member.last_name[0],
    //                                                 )
    //                                             }
    //                                         />
    //                                     </Popconfirm>
    //                                 ) : (
    //                                     <Avatar
    //                                         className="circle-icon"
    //                                         src={
    //                                             member.image ||
    //                                             makeAvatar(
    //                                                 member.first_name[0],
    //                                                 member.last_name[0],
    //                                             )
    //                                         }
    //                                     />
    //                                 )}
    //                             </Tooltip>
    //                         ))}
    //                         {Permissions.can_edit_packages(permissions) && (
    //                             <Tooltip title="Add Member">
    //                                 <Popover
    //                                     placement="rightTop"
    //                                     title="Add New Member"
    //                                     content={addMembersContent({
    //                                         ...record,
    //                                         id: record?.tags[0].packageroom[0],
    //                                     })}
    //                                     trigger="click"
    //                                     visible={displayAddMembers === record.id}
    //                                 >
    //                                     <Avatar
    //                                         className="circle-icon"
    //                                         style={{
    //                                             color: '#f56a00',
    //                                             backgroundColor: '#fde3cf',
    //                                         }}
    //                                         onClick={() => setDisplayAddMembers(record.id)}
    //                                     >
    //                                         <PlusOutlined />
    //                                     </Avatar>
    //                                 </Popover>
    //                             </Tooltip>
    //                         )}
    //                     </Avatar.Group>
    //                 </div>
    //             ),
    //     },
    // ];

    const groupsPackageColumns = [
        {
            title: '',
            dataIndex: 'warning',
            width: 0,
            render: (_, record) => checkPackageManufactures(record),
        },
        {
            title: 'Serial #',
            dataIndex: 'serial_number',
            width: 100,
            responsive: ['xl'],
            sorter: (a, b) => a.serial_number - b.serial_number,
        },
        {
            title: 'Room Name',
            dataIndex: 'name',
            key: 'name',
            width: 250,
            ...getColumnSearchProps('name'),
            render: (text) => {
                return {
                    props: {
                        className: 'cursor-pointer',
                    },
                    children: text,
                };
            },
            onCell: goToPackageDetail,
        },
        {
            title: 'Operating Range',
            key: 'description',
            responsive: ['lg'],
            dataIndex: 'description',
            render: (text, record) => {
                return {
                    props: {
                        className: 'description-td cursor-pointer',
                    },
                    children: (
                        <Tooltip placement="topLeft" title={text}>
                            {text}
                        </Tooltip>
                    ),
                };
            },
            onCell: goToPackageDetail,
        },
        {
            title: '# Comments',
            dataIndex: 'comments_count',
            width: 180,
            align: 'center',
            sorter: (a, b) => a.comments_count - b.comments_count,
            render: (text) => <p>{text || '0'}</p>,
        },
        {
            title: '# Documents',
            dataIndex: 'docs_count',
            width: 180,
            align: 'center',
            sorter: (a, b) => a.docs_count - b.docs_count,
            render: (text) => <p>{text || '0'}</p>,
        },
        {
            title: '# Issues',
            dataIndex: 'issues_count',
            width: 180,
            align: 'center',
            sorter: (a, b) => a.issues_count - b.issues_count,
            render: (text) => <p>{text || '0'}</p>,
        },
        {
            title: 'Total Checkpoints',
            key: 'tags_count',
            dataIndex: 'tags_count',
            responsive: ['lg'],
            sorter: (a, b) => a.tags_count - b.tags_count,
            align: 'center',
            render: (_, record) => {
                return {
                    props: {
                        className: 'cursor-pointer',
                    },
                    children: record.tags_count,
                };
            },
            onCell: goToPackageDetail,
        },
        {
            title: 'Team Members',
            dataIndex: 'team_members',
            key: 'team_members',
            render: (team_members, record) =>
                team_members && (
                    <div className="package-team-members">
                        <Avatar.Group>
                            {team_members.map((member, idx) => (
                                <Tooltip
                                    key={member.email}
                                    title={`${member.first_name} ${member.last_name}`}
                                >
                                    {Permissions.can_edit_packages(permissions) ? (
                                        <Popconfirm
                                            onConfirm={() => removeMember(member.id, record.id)}
                                            title="Are your want to remove this user?"
                                        >
                                            <Avatar
                                                className="circle-icon"
                                                src={
                                                    member.image ||
                                                    makeAvatar(
                                                        member.first_name[0],
                                                        member.last_name[0],
                                                    )
                                                }
                                            />
                                        </Popconfirm>
                                    ) : (
                                        <Avatar
                                            className="circle-icon"
                                            src={
                                                member.image ||
                                                makeAvatar(
                                                    member.first_name[0],
                                                    member.last_name[0],
                                                )
                                            }
                                        />
                                    )}
                                </Tooltip>
                            ))}
                            {/* {Permissions.can_edit_packages(permissions) && (
                                <Tooltip title="Add Member">
                                    <Popover
                                        placement="rightTop"
                                        title="Add New Member"
                                        content={addMembersContent(record)}
                                        trigger="click"
                                        visible={displayAddMembers === record.id}
                                    >
                                        <Avatar
                                            className="circle-icon"
                                            style={{
                                                color: '#f56a00',
                                                backgroundColor: '#fde3cf',
                                            }}
                                            onClick={() => setDisplayAddMembers(record.id)}
                                        >
                                            <PlusOutlined />
                                        </Avatar>
                                    </Popover>
                                </Tooltip>
                            )} */}
                        </Avatar.Group>
                    </div>
                ),
        },
        ,
    ];

    const nestedData = areas?.data.map((d, index) => ({ ...d, key: index }));
    const nestedTagsColumn = [
        {
            title: 'Tag Name',
            dataIndex: 'name',
            key: 'name',
            width: 150,
            ...getColumnSearchProps('name'),
        },
        {
            title: 'Operating Range',
            dataIndex: 'description',
            key: 'description',
            render: (text, record) => {
                return {
                    props: {
                        className: 'description-td',
                    },
                    children: (
                        <Tooltip placement="topLeft" title={text}>
                            {text}
                        </Tooltip>
                    ),
                };
            },
        },
    ];

    return (
        <>
            {!loading ? (
                <>
                    {/* {!_.isEmpty(area) && ( */}
                    <Collapse
                        className="packages-collapse alectify-collapse mb-15"
                        // onChange={() => getPackages('room_packages')}
                        defaultActiveKey="1"
                    >
                        <Panel header="Areas Monitored" key="1">
                            <Spin tip="Loading..." spinning={isloading}>
                                <Table
                                    // rowKey="id"
                                    size="small"
                                    loading={loading}
                                    columns={groupsPackageColumns}
                                    onChange={onChangePageAndSorting}
                                    pagination={{
                                        total: areas.meta.total_count,
                                        pageSize: areas.pageSize,
                                        showSizeChanger: true,
                                        current: areas.meta.currentPage,
                                    }}
                                    className="taglist-table"
                                    expandable={{
                                        expandedRowRender: (record) => (
                                            <Table
                                                columns={nestedTagsColumn}
                                                dataSource={record.tags}
                                                className="ant-table-container"
                                                pagination={record.tags.length > 10}
                                            />
                                        ),
                                    }}
                                    dataSource={nestedData}
                                />
                            </Spin>
                        </Panel>
                    </Collapse>
                </>
            ) : (
                <Card loading={true} bordered={false} style={{ background: 'transparent' }}></Card>
            )}

            {createPackageVisible && (
                <CreatePackageModal
                    title={`${activePackage ? 'Update' : 'Create'} Package`}
                    visible={createPackageVisible}
                    individualRoomsData={individualPackages}
                    loading={createPkgLoading}
                    initialData={{ ...activePackage }}
                    onSubmit={onCreatePackage}
                    onUpdate={onUpdatePackage}
                    onCancel={onCancel}
                />
            )}
        </>
    );
};

export default Packages;
