import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Button, Col, Empty, Row, Spin, Select, Typography } from 'antd';
import {
    fetchPackageData,
    fetchNotifications,
    getNotification,
    markAsReadNotification,
} from '../../../services';
import { RightOutlined } from '@ant-design/icons';
import { PROJECTS } from '../../../utils';

const { Option } = Select;
const { Text } = Typography;

const Notifications = ({ projectId, getNotificationsCount = () => {} }) => {
    const history = useHistory();
    const { operations } = useSelector(({ common }) => common);
    const [tags, setTags] = useState([]);
    const [allTags, setAllTags] = useState([]);
    const [activeTag, setActiveTag] = useState();
    const [tagsLoaders, setTagsLoader] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [searchParam, setSearchParam] = useState('');
    const [sortingParam, setSortingParam] = useState('');
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(1);
    let offset = 1;

    useEffect(() => {
        // if (_.isEmpty(tags)) {
        getNotifications(searchParam, sortingParam, page);
        // }
    }, [projectId]);

    const maxDate = (tag) => {
        return Math.max.apply(
            null,
            tag.notifications.map((n) => new Date(n.created_at)),
        );
    };

    const getNotifications = async (searchParam, sortingParam, page) => {
        try {
            setTagsLoader(true);
            const response = await fetchNotifications(projectId, searchParam, sortingParam, page);
            if (offset === 1) {
                setTags(response ? [...response.data] : []);
                setAllTags(response ? [...response.data] : []);
                setPageCount(response.meta.total_pages);
            } else {
                setTags(response ? (oldResonse) => [...oldResonse, ...response.data] : []);
                setAllTags(response ? (oldResonse) => [...oldResonse, ...response.data] : []);
                setPageCount(response.meta.total_pages);
            }
            setTagsLoader(false);
        } catch (error) {
            console.log(error);
            setTagsLoader(false);
        }
    };

    const sortBy = (value) => {
        if (value) {
            if (value === 'last_issues') {
                value = '-last_issues';
            }
            setSortingParam(value);
            getNotifications(searchParam, value, 1);
        } else {
            getNotifications(searchParam, sortingParam, 1);
        }
    };

    const searchInbox = (value) => {
        if (value) {
            setSearchParam(value);
            getNotifications(value, sortingParam, 1);
        } else {
            getNotifications(searchParam, sortingParam, 1);
        }
    };

    // const notificationReadHandler = (tag) => {
    //     return tag?.has_unread_notification ? 'unread' : 'read';
    // };

    const markedAsRead = async (pkgId) => {
        await markAsReadNotification(projectId, pkgId);
    };

    // @TODO: Add notifications views/detail functionality using separate API
    const onNotificationClick = async (pkg) => {
        const notification = await fetchPackageData(pkg.id);

        if (!_.isEmpty(notification) && notification.data.some(({ unread }) => unread)) {
            await markedAsRead(pkg.id);
            const readTags = tags.map((tag) => {
                if (tag.id === pkg.id) {
                    return {
                        ...tag,
                        has_unread_notification: false,
                    };
                }
                return tag;
            });
            setTags([...readTags]);
            getNotificationsCount();
        }

        setActiveTag(pkg);

        const data = notification.data.filter((obj) => {
            return obj.package_room === pkg.id;
        });
        setNotifications(data); // @TODO: set notifications of specific tag in this state
    };

    const goToPackage = () => {
        let url = `${PROJECTS}/${projectId}/package/${activeTag?.id}`;
        if (operations) url = `${PROJECTS}/${projectId}/operations/package/${activeTag?.id}`;
        history.push(url);
    };

    const handleScroll = (e) => {
        const bottom =
            Math.round(e.currentTarget.scrollHeight - e.currentTarget.scrollTop) ===
            Math.round(e.currentTarget.clientHeight);

        if (bottom) {
            // if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight) {
            offset = offset + 1;
            if (offset <= pageCount) {
                getNotifications(searchParam, sortingParam, offset);
            }
            // else if(e.target.scrollHeight ===e.target.clientHeight){
            //     getNotifications(searchParam,sortingParam,1)

            // }
        }
    };

    useEffect(() => {
        var el = document.getElementById('scrollableDiv');
        if (el) {
            el.addEventListener('scroll', handleScroll);
            return () => el.removeEventListener('scroll', handleScroll);
        }
    }, [searchParam, sortingParam, pageCount]);

    return (
        <Row gutter={20}>
            <Col span={6}>
                <Spin spinning={tagsLoaders}>
                    <div className="page-content-container" style={{ padding: 0 }}>
                        <div className="task-inbox-actions">
                            <Row>
                                <Col span={15}>
                                    <input
                                        type="search"
                                        className="inbox-search"
                                        placeholder="Search ..."
                                        onChange={({ target }) => searchInbox(target.value)}
                                    />
                                </Col>
                                <Col span={9}>
                                    <Select
                                        defaultValue="Last Issues"
                                        style={{ width: '100%' }}
                                        onChange={(value) => sortBy(value)}
                                    >
                                        <Option value="last_issues">Last Issues</Option>
                                        <Option value="name">Alphabetical</Option>
                                    </Select>
                                </Col>
                            </Row>
                        </div>

                        <div id="scrollableDiv" className="task-inbox-list custom-scroll-dark">
                            <ul>
                                {!_.isEmpty(tags) ? (
                                    tags.map((tag, idx) => (
                                        <li
                                            key={idx}
                                            onClick={() => onNotificationClick(tag)}
                                            id={activeTag?.id === tag.id ? 'active' : ''}
                                            // className={notificationReadHandler(tag)}
                                        >
                                            <div className="task-inbox-header">
                                                <h4>{tag?.name}</h4>
                                                <div className="actions">
                                                    {/* {true && (
                                                        <Button
                                                            type="primary"
                                                            shape="round"
                                                            size="small"
                                                            ghost
                                                        >
                                                            Issues: 6
                                                        </Button>
                                                    )} */}
                                                    {/* <Space>
                                                        <span className="notification-time">
                                                            2:20 AM
                                                        </span>
                                                    </Space> */}
                                                </div>
                                            </div>
                                            <p>{tag?.description}</p>
                                        </li>
                                    ))
                                ) : !tagsLoaders && _.isEmpty(tags) ? (
                                    <Empty
                                        description="You don't have any notification"
                                        className="mt-30"
                                    />
                                ) : null}
                            </ul>
                        </div>
                    </div>
                </Spin>
            </Col>
            <Col span={18}>
                {activeTag ? (
                    <div className="page-content-container have-nested-containers notifications-content">
                        <h4 className="card-heading step-heading">
                            {activeTag?.name} Notifications
                            <Button onClick={goToPackage}>
                                <RightOutlined />
                                Area Details
                            </Button>
                        </h4>
                        <div className="notifications-body custom-scroll-dark">
                            {!_.isEmpty(notifications) ? (
                                notifications.map((data) => {
                                    return data.comments.map((notification) => (
                                        <div className="notification" key={notification.id}>
                                            <Row align="top">{notification.text}</Row>
                                            <Row
                                                justify="start"
                                                align="top"
                                                style={{
                                                    marginTop: '1.25%',
                                                    justifyContent: 'space-between',
                                                }}
                                            >
                                                <Text style={{ width: '24%' }} className="date">
                                                    Reported on:{' '}
                                                    {moment(data.report_date).format(
                                                        'DD MMM, YYYY',
                                                    )}
                                                </Text>
                                                <Text style={{ width: '24%' }} className="date">
                                                    {' '}
                                                    Reported By: {data.user.name}
                                                </Text>
                                                <Text
                                                    style={{ width: '40%', flexWrap: 'wrap' }}
                                                    className="date"
                                                >
                                                    Reported for: {data.tag.name}
                                                </Text>
                                                <Text
                                                    className="date"
                                                    style={{ width: '12%', textAlign: 'right' }}
                                                >
                                                    Shift: {data.shift === 1 ? 'Night' : 'Day'}
                                                </Text>
                                            </Row>
                                        </div>
                                    ));
                                })
                            ) : (
                                <div className="placeholder-container custom-description">
                                    <Empty
                                        description={`Notifications not found for ${activeTag?.name}`}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                ) : !_.isEmpty(tags) ? (
                    <div className="placeholder-container custom-description">
                        <Empty description="Select package to see notifications" />
                    </div>
                ) : !tagsLoaders && _.isEmpty(tags) ? (
                    <div className="placeholder-container custom-description">
                        <Empty description="Notifications not found" />
                    </div>
                ) : null}
            </Col>
        </Row>
    );
};

export default Notifications;
