import { fetchProjectDocs } from '../../services';
import {
    FETCH_PROJECT_DOCUMENTS,
    SWITCH_TO_UPLOAD_DOCUMENTS_MODE,
    UPLOAD_DOCUMENTS_FROM_LOCAL,
} from './types';

export const saveDocumentsToStore = (documents, projectId) => (dispatch) => {
    dispatch({ type: UPLOAD_DOCUMENTS_FROM_LOCAL, payload: { documents, projectId } });
};

export const switchDocumentsMode = (payload) => (dispatch) => {
    dispatch({ type: SWITCH_TO_UPLOAD_DOCUMENTS_MODE, payload });
};

export const getProjectDocuments = (projectId) => async (dispatch) => {
    // const response = await fetchProjectDocs(projectId);
    // dispatch({
    //     type: FETCH_PROJECT_DOCUMENTS,
    //     payload: response.data,
    // });
};
