import { ConsoleSqlOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Layout, Row, Select, Space, notification } from 'antd';
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchUserDetails,
    getOrganizationTypes,
    getRoles,
    updateUser,
} from '../../../../app/services';
import { GET_AUTH_USER } from '../../../store/auth';

const ProfileEdit = () => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState('');
    const [file, setFile] = useState(null);
    const [organizationTypes, setOrganizationTypes] = useState([]);
    const [roles, setRoles] = useState([]);
    const { user } = useSelector(({ auth }) => auth);

    const fetchOrganizationTypes = async () => {
        try {
            const types = await getOrganizationTypes();
            setOrganizationTypes([...types.data]);
            const roles = await getRoles(user.organization.org_type);
            setRoles([...roles.data]);
        } catch (error) {
            console.log(error);
        }
    };

    const handleChange = (event) => {
        if (event.target.files.length > 0) {
            setFile(event.target.files[0]);
            setImageUrl(URL.createObjectURL(event.target.files[0]));
        } else {
            setImageUrl('');
            setFile(null);
        }
    };

    useEffect(() => {
        setImageUrl(user.image);
        console.log(user)
        console.log("********")
        fetchOrganizationTypes();
    }, []);

    const onFinish = async (values) => {
        try {
            const formData = new FormData();
            if (file) {
                formData.append('image_url', file);
            }
            formData.append('first_name', values.first_name);
            formData.append('last_name', values.last_name);
            formData.append('contact_number', values.phone);
           
            setLoading(true);
            const update = await updateUser(formData);
            const userDetails = await fetchUserDetails();
            dispatch({
                type: GET_AUTH_USER,
                payload: { ...userDetails.data, image_url: userDetails.data.image },
            });
            notification.success({
                message: 'Profile Updated',
                description: 'The profile has been successfully updated',
            });
            setLoading(false);
        } catch (ex) {
            const apiErrors = ex?.response?.data?.data;
            if (apiErrors) {
                const errors = Object.keys(apiErrors).map((key) => ({
                    name: key,
                    errors: apiErrors[key],
                }));
                form.setFields(errors);
            }
            setLoading(false);
        }
    };
    const formItemLayout = {
        labelCol: {
            span: 10,
        },
        wrapperCol: {
            span: 22,
        },
    };

    return (
        <Layout.Content>
            <div>
                <div className="page-content-container">
                    <h2 className="step-heading mb-20">Edit Profile</h2>

                    <Form
                        layout="vertical"
                        {...formItemLayout}
                        onFinish={onFinish}
                        initialValues={user}
                        size="default"
                    >
                        <Row justify="start">
                            <Col span={8}>
                                <Form.Item label="Profile Image" name="image_url">
                                    {imageUrl ? (
                                        <img
                                            style={{
                                                marginTop: '20px',
                                                marginBottom: '30px',
                                                borderRadius: '50%',
                                                objectFit: 'cover',
                                            }}
                                            src={imageUrl}
                                            alt="avatar"
                                            width={200}
                                            height={200}
                                        />
                                    ) : (
                                        ''
                                    )}

                                    <Input
                                        className="file_upload"
                                        type="file"
                                        name="file_upload"
                                        accept="image/*"
                                        onChange={handleChange}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={16}>
                                <Row>
                                    <Col span={12}>
                                        <Form.Item
                                            label="First Name"
                                            name="first_name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your first name!',
                                                },
                                            ]}
                                        >
                                            <Input placeholder="First Name" />
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item
                                            label="Last Name"
                                            name="last_name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your last name!',
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Last Name" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label="Email"
                                            name="email"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your email!',
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Email" disabled={true} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label="Organization Name"
                                            name={["organization", "name"]}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your organization name!',
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder="Organization Name"
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label="Type"
                                            name={["organization_type", "name"]}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please select the type',
                                                },
                                            ]}
                                        >
                                            <Select>
                                                {organizationTypes.map((org) => (
                                                    <Select.Option key={org.id} value={org.id}>
                                                        {org.name}
                                                    </Select.Option>
                                                ))}
                                                 <Select.Option key='null' value='null'>
                                                        Placeholder
                                                </Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label="Role"
                                            name={["role","name"]}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please select the role',
                                                },
                                            ]}
                                        >
                                            <Select>
                                                {roles.map((role) => (
                                                    <Select.Option key={role.id} value={role.id}>
                                                        {role.name}
                                                    </Select.Option>
                                                ))}
                                                <Select.Option key='null' value='null'>
                                                        Placeholder
                                                </Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="Phone Number" name="contact_number" rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your phone number!',
                                                },
                                            ]}>
                                            <Input pattern="[\d]{10}" placeholder="Phone Number" title="10 digit phone number"/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <div className="text-right">
                            <Space>
                                <Button
                                    className="btn-lg save-btn mt-30"
                                    type="primary"
                                    htmlType="submit"
                                    loading={loading}
                                >
                                    Submit
                                </Button>
                            </Space>
                        </div>
                    </Form>
                </div>
            </div>
        </Layout.Content>
    );
};

export default ProfileEdit;
