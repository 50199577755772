import { http } from '../utils/http';


export const createOrganizations = async (body) => {
    try {
        const response = await http.post('/organizations/', body);
        return response.data;
    } catch (ex) {
        throw ex;
    }
};

export const getOrganizations = async param => {
    try {
        const response = await http.get(`/organizations/?search=${param}`);
        return response.data;
    } catch (ex) {
        throw ex;
    }
};

export const createOwnerOrganization = async (body) => {
    try {
        const response = await http.post('/organizations/owner/', body);
        return response.data;
    } catch (ex) {
        throw ex;
    }
};

export const getOrganizationTypes = async param => {
    try {
        const response = await http.get(`/organization-types/`);
        return response.data;
    } catch (ex) {
        throw ex;
    }
};

export const getRoles = async orgType => {
    try {
        const response = await http.get(`/roles/?type=${orgType}`);
        return response.data;
    } catch (ex) {
        throw ex;
    }
};
