import { http } from '../utils/http';
import React, { useState } from 'react';


export const fetchPackageData = async ( pkgId ) => {
    try {
        const response = await http.get(
            `/data-rounds/packages/${pkgId}/comments/`,
        );
        return response.data;
    } catch (ex) {
        throw ex;
    }
};

export const fetchNotifications = async (projectId, search = '', sorting = '', page = 1) => {
    let perPage = 10;
    try {
        const response = await http.get(
            `projects/${projectId}/notifications/?page=${page}&per_page=${perPage}&search=${search}&ordering=${sorting}`,
        );
        return response.data;
    } catch (ex) {
        throw ex;
    }
};

export const markAsReadNotification = async (projectId, pkgId) => {
    try {
        const response = await http.patch(`projects/${projectId}/read/${pkgId}/notifications/`);
        return response.data;
    } catch (ex) {
        console.log(ex);
    }
};

export const getNotification = async (projectId, pkgId) => {
    try {
        const response = await http.get(`projects/${projectId}/${pkgId}/notifications/`);
        return response.data;
    } catch (ex) {
        console.log(ex);
    }
};

export const fetchNotificationsCount = async (projectId) => {
    try {
        const response = await http.get(`projects/${projectId}/notifications-count/`);
        return response.data;
    } catch (ex) {
        console.log(ex);
    }
};

export const createNotification = async (projectId, body) => {
    try {
        const response = await http.post(`projects/${projectId}/notifications/`, body);
        return response.data;
    } catch (ex) {
        console.log(ex);
    }
};

export const helpEnquiry = async (body) => {
    try {
        const response = await http.post(`contact-us/`, body);
        return response.data;
    } catch (ex) {
        throw ex;
    }
};

export const notificationSettings = async (body) => {
    try {
        const response = await http.post(`projects/notifications-setting/`, body);
        return response.data;
    } catch (ex) {
        throw ex;
    }
};
