import _ from 'lodash';
import moment from 'moment';
import { memo, useEffect, useState } from 'react';
import {
    Avatar,
    Button,
    Popover,
    Space,
    Table,
    Tooltip,
    Form,
    Select,
    Popconfirm,
    Collapse,
    Modal,
    Input,
    DatePicker,
    Tag,
    Upload,
    message,
    Menu,
    Dropdown,
    Tabs,
    Row,
    Col,
    Typography
} from 'antd';
import {
    CameraOutlined,
    CheckOutlined,
    DownOutlined,
    EditOutlined,
    FileProtectOutlined,
    PlusOutlined,
    ExpandOutlined,
    CheckCircleOutlined,
    NodeIndexOutlined
} from '@ant-design/icons';
import {
    addAssigneeInServiceHistory,
    fetchServiceHistories,
    removeAssigneeFromServiceHistory,
    updateServiceHistory,
    uploadServiceHistoryDoc,
} from '../../../../services';
import {
    DISPLAY_DATE_FORMAT,
    INPUT_DATE_FORMAT,
    OPERATIONS_DATE_FIELDS,
    SERVER_DATE_FORMAT,
    SERVICE_HISTORY_STATUS,
    DISPLAY_SERVICE_HISTORY_STATUS,
    showNotification,
    makeAvatar,
} from '../../../../utils';

const { Option } = Select;
const { Panel } = Collapse;
const { TabPane } = Tabs;
const { Text } = Typography;

const ServiceHistory = memo(({ projectId, pkgId, packageDetail, tagId = null }) => {
    const [form] = Form.useForm();
    const [editForm] = Form.useForm();
    const [internalHistories, setInternalHistories] = useState([]);
    const [externalHistories, setExternalHistories] = useState([]);
    const [internalPastHistories, setInternalPastHistories] = useState([]);
    const [externalPastHistories, setExternalPastHistories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [currentRecord, setCurrentRecord] = useState(null);
    const [displayAddMembers, setDisplayAddMembers] = useState('');
    const [showPMModal, setShowPMModal] = useState(false);
    const [pmData, setPMData] = useState(null);
    const [tagIdCheck, setTagIdCheck] = useState('')

    const addMembersContent = (serviceHistory) => {
        return (
            <Form
                form={form}
                style={{ width: 200 }}
                layout="vertical"
                onFinish={(values) => addAssignee({ ...values, serviceHistory })}
            >
                <Form.Item name="member_id" rules={[{ required: true, message: 'Please select' }]}>
                    <Select
                        showSearch
                        placeholder="Please select"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().includes(input.toLowerCase())
                        }
                        allowClear
                        style={{ width: '100%' }}
                    >
                        {packageDetail.team_members.map((user) => (
                            <Option key={user.id} value={user.id}>
                                {`${user.first_name} ${user.last_name}`}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <div className="text-right mt-15">
                    <Space>
                        <Button type="default" onClick={() => setDisplayAddMembers('')}>
                            Cancel
                        </Button>
                        <Button type="primary" htmlType="submit">
                            Add
                        </Button>
                    </Space>
                </div>
            </Form>
        );
    };

    const addAssignee = async (values) => {
        try {
            const { serviceHistory, member_id } = values;
            const user = packageDetail.team_members.find((user) => user.id === member_id);
            const isUserExist = serviceHistory.assignees.some(
                (team_member) => team_member.id === user.id,
            );
            if (isUserExist) {
                form.setFields([{ name: 'member_id', errors: ['User already exist'] }]);
                return;
            }
            setLoading(true);
            const response = await addAssigneeInServiceHistory(
                projectId,
                pkgId,
                serviceHistory.id,
                member_id,
            );
            showNotification(response.message);
            setDisplayAddMembers('');
            form.resetFields();
            getServicesHistories("internal");
            getPastServicesHistories('internal');
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const removeAssignee = async (serviceId, assigneeId) => {
        try {
            setLoading(true);
            const response = await removeAssigneeFromServiceHistory(
                projectId,
                pkgId,
                serviceId,
                assigneeId,
            );
            showNotification(response.message);
            getServicesHistories("internal");
            getPastServicesHistories('internal');
            setDisplayAddMembers('');
        } catch (error) {
            setLoading(false);
        }
    };

    const isOnGoing = ({ status }) => status === SERVICE_HISTORY_STATUS.ONGOING;

    const uploadDocumentsNew = async (e, key) => {
        try {
            const fileList = Object.values(e.fileList);
            const formData = new FormData();
            fileList.map((file, index) => formData.append('files', file.originFileObj));
            formData.append('dir_key', key);
            formData.append('tag_id', tagId);
            const response = await uploadServiceHistoryDoc(projectId, formData);
            message.success(response.message);
        } catch (error) {
            message.error(error.response.data.message);
        }
    }

    const menu = (
        <Menu
            items={[
                {
                    key: '1',
                    label: 'Mark as done',
                    onClick: () => { alert('done'); }
                },
                {
                    key: '2',
                    label: 'Mark as skip',
                    onClick: () => { alert('skip'); }
                },
            ]}
        />
    );
    const renderMenu = (record) => {
        return (
            <Menu
                items={[
                    {
                        key: '1',
                        label: 'Mark as done',
                        onClick: () => {
                            updateAndMapHistories(record.id, {
                                status: SERVICE_HISTORY_STATUS.DONE,
                            }, record.contract_type)
                        }
                    },
                    {
                        key: '2',
                        label: 'Mark as skip',
                        onClick: () => {
                            updateAndMapHistories(record.id, {
                                status: SERVICE_HISTORY_STATUS.SKIP,
                            }, record.contract_type)
                        }
                    },
                ]}
            />
        )
    }



    const columns = [
        {
            title: '',
            dataIndex: 'edit',
            width: 30,
            align: 'center',
            render: (_, record) =>
                isOnGoing(record) && (
                    <Tooltip title="Edit" className="cursor-pointer">
                        <EditOutlined
                            style={{ fontSize: 18 }}
                            onClick={() => {
                                setCurrentRecord(record.id);
                                record.due_date = moment(record.due_date);
                                record.created_at = moment(record.created_at);
                                editForm.setFieldsValue({ ...record });
                                setEditModal(true);
                            }}
                        />
                    </Tooltip>
                ),
        },
        {
            title: 'Work ID',
            dataIndex: 'work_id',
        },
        {
            title: 'Task Description',
            dataIndex: 'task_description',
        },
        {
            title: 'Assignees',
            dataIndex: 'assignees',
            render: (assignees, record) => (
                <Avatar.Group>
                    {assignees &&
                        assignees.map((assignee) =>
                            isOnGoing(record) ? (
                                <Popconfirm
                                    key={assignee.id}
                                    onConfirm={() => removeAssignee(record.id, assignee.id)}
                                    title="Are your want to remove this assignee?"
                                >
                                    <Tooltip
                                        className="cursor-pointer"
                                        title={`${assignee.first_name} ${assignee.last_name}`}
                                    >
                                        <Avatar className="circle-icon" src={assignee.image || makeAvatar(assignee?.first_name[0], assignee?.last_name[0])} />
                                    </Tooltip>
                                </Popconfirm>
                            ) : (
                                <Tooltip title={`${assignee.first_name} ${assignee.last_name}`}>
                                    <Avatar className="circle-icon" src={assignee.image || makeAvatar(assignee?.first_name[0], assignee?.last_name[0])} />
                                </Tooltip>
                            ),
                        )}
                    {isOnGoing(record) && (
                        <Tooltip title="Add Assignee" className="cursor-pointer">
                            <Popover
                                placement="rightTop"
                                title="Add New Member"
                                content={addMembersContent(record)}
                                trigger="click"
                                visible={displayAddMembers === record.id}
                            >
                                <Avatar
                                    className="circle-icon"
                                    style={{
                                        color: '#f56a00',
                                        backgroundColor: '#fde3cf',
                                    }}
                                    onClick={() => setDisplayAddMembers(record.id)}
                                >
                                    <PlusOutlined />
                                </Avatar>
                            </Popover>
                        </Tooltip>
                    )}
                </Avatar.Group>
            ),
        },
        {
            title: 'Due Date',
            dataIndex: 'due_date',
            width: 150,
            render: (value) =>
                value ? moment(value, SERVER_DATE_FORMAT).format(DISPLAY_DATE_FORMAT) : '',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            width: 150,
            render: (status) => (
                <Tag color={status == 0 ? 'blue' : (status == 1 ? 'green' : 'orange')}>
                    {DISPLAY_SERVICE_HISTORY_STATUS[status]}
                </Tag>
            ),
        },
        {
            title: 'Remarks',
            dataIndex: 'remarks',
        },
        {
            title: 'Actions',
            dataIndex: 'status',
            align: 'center',
            render: (_, record) => (
                <Space size={'middle'}>
                    {/* {isOnGoing(record) ? (
                        <Dropdown overlay={() => renderMenu(record)}>
                            <a>
                                More <DownOutlined />
                            </a>
                        </Dropdown>
                    ) : (
                        <div style={{ width: 18 }}></div>
                    )} */}

                    {/* {isOnGoing(record) ? (
                        <Tooltip title="Mark as done" className="cursor-pointer">
                            <Popconfirm
                                title="Are you sure?"
                                onConfirm={() =>
                                    updateAndMapHistories(record.id, {
                                        status: SERVICE_HISTORY_STATUS.DONE,
                                    })
                                }
                            >
                                <CheckOutlined style={{ fontSize: 18 }} />
                            </Popconfirm>
                        </Tooltip>
                    ) : (
                        <div style={{ width: 18 }}></div>
                    )} */}
                    {isOnGoing(record) ? <Tooltip title="Mark as done" className="cursor-pointer">
                        <CheckCircleOutlined
                            style={{ fontSize: 18 }}
                            onClick = {() => {
                                updateAndMapHistories(record.id, {
                                    status: SERVICE_HISTORY_STATUS.DONE,
                                }, record.contract_type)
                            }}
                        />
                    </Tooltip> : <div style={{width: 18}}> </div>}
                    {isOnGoing(record) ? <Tooltip title="Mark as skip" className="cursor-pointer">
                        <NodeIndexOutlined
                            style={{ fontSize: 18 }}
                            onClick= {() => {
                                updateAndMapHistories(record.id, {
                                    status: SERVICE_HISTORY_STATUS.SKIP,
                                }, record.contract_type)
                            }}
                        />
                    </Tooltip> : <div style={{width: 18}}> </div>}
                    <Tooltip title="View" className="cursor-pointer">
                        <ExpandOutlined onClick={() => handlePMModal(record)} />
                    </Tooltip>
                    <Tooltip title="Upload Image" className="cursor-pointer">
                        <Upload
                            beforeUpload={() => false}
                            onChange={(e) => uploadDocumentsNew(e, "images")}
                            showUploadList={false}
                            multiple
                        >
                            <Button
                                icon={<CameraOutlined style={{ fontSize: 18 }} />}
                                style={{ borderColor: '#fff' }}
                            />
                        </Upload>
                    </Tooltip>
                    <Tooltip title="Upload Service Report" className="cursor-pointer">
                        <Upload
                            beforeUpload={() => false}
                            onChange={(e) => uploadDocumentsNew(e, "test_reports")}
                            showUploadList={false}
                            multiple
                        >
                            <Button
                                icon={<FileProtectOutlined style={{ fontSize: 18 }} />}
                                style={{ borderColor: '#fff' }}
                            />
                        </Upload>
                    </Tooltip>
                </Space>
            ),
        },
    ];

    const handlePMModal = (record) => {
        setShowPMModal(true);
        setPMData(record)
    }
    const renderPmModal = () => {
        return (
            <Modal
                title="Preventive Maintenance"
                visible={showPMModal}
                onOk={() => setShowPMModal(false)}
                okText="Done"
                onCancel={() => {
                    setShowPMModal(false)
                }}
            >

                <Tabs defaultActiveKey="1" onChange={null}>
                    <TabPane tab="Maintenance Info" key="1">
                        <Row gutter={16} style={{ marginBottom: 10 }}>
                            <Col span={12}>
                                <Text><span style={{ fontWeight: 'bold' }}>Contract Type</span>: {pmData?.contract_type === 'internal' ? 'Internal' : 'External'}</Text>
                            </Col>
                            <Col span={12}>
                                <Text><span style={{ fontWeight: 'bold' }}>Work ID</span>: {pmData?.work_id}</Text>
                            </Col>

                        </Row>
                        <Row gutter={16} style={{ marginBottom: 10 }}>
                            <Col span={12}>
                                <Text><span style={{ fontWeight: 'bold' }}>Due Date</span>: {pmData?.due_date ? moment(pmData?.due_date, SERVER_DATE_FORMAT).format(DISPLAY_DATE_FORMAT) : ''}</Text>
                            </Col>
                            <Col span={12}>
                                <Text><span style={{ fontWeight: 'bold' }}>Task Description</span>: {pmData?.task_description}</Text>
                            </Col>
                        </Row>
                        <Row gutter={16} style={{ marginBottom: 10 }}>
                            <Col span={12}>
                                <Text><span style={{ fontWeight: 'bold' }}>Date Assigned</span>: {pmData?.created_at ? moment(pmData?.created_at, SERVER_DATE_FORMAT).format(DISPLAY_DATE_FORMAT) : ''}</Text>
                            </Col>
                            <Col span={12}>
                                <Text><span style={{ fontWeight: 'bold' }}>Remarks</span>: {pmData?.remarks}</Text>
                            </Col>
                        </Row>
                        <Row gutter={16} style={{ marginBottom: 10 }}>
                            <Col span={12}>
                                <Text><span style={{ fontWeight: 'bold' }}>Status</span>: <Tag color={pmData?.status == 0 ? 'blue' : (pmData?.status == 1 ? 'green' : 'orange')}>
                                    {DISPLAY_SERVICE_HISTORY_STATUS[pmData?.status]}
                                </Tag></Text>
                            </Col>
                            <Col span={12}>
                                <Text><span style={{ fontWeight: 'bold' }}>Date Finished</span>: {pmData?.finished_at ? moment(pmData?.finished_at, SERVER_DATE_FORMAT).format(DISPLAY_DATE_FORMAT) : ''}</Text>
                            </Col>

                        </Row>
                    </TabPane>
                    <TabPane tab="Images" key="2">
                        No image found.
                    </TabPane>
                    <TabPane tab="Reports" key="3">
                        No reports found.
                    </TabPane>
                </Tabs>

            </Modal>
        )
    }

    useEffect(() => {
        getServicesHistories("internal");
        getPastServicesHistories('internal');  
    }, [pkgId, tagId]);

    const getServicesHistories = async (contract_type) => {
        try {
            if (tagIdCheck !== tagId){
                setLoading(true);
                const response = await fetchServiceHistories(projectId, pkgId, tagId, 0, contract_type);
                if (contract_type === 'internal') {
                    setInternalHistories([...response.data])
                }
                else {
                    setExternalHistories([...response.data])
                }
                setLoading(false);
                setTagIdCheck(tagId)
            }
        } catch (error) {
            setLoading(false);
        }
    };

    const getPastServicesHistories = async (contract_type) => {
        try {
            if (tagIdCheck !== tagId){
                setLoading(true);
                const response = await fetchServiceHistories(projectId, pkgId, tagId, "1,3", contract_type);
                if (contract_type === 'internal') {
                    setInternalPastHistories([...response.data])
                }
                else {
                    setExternalPastHistories([...response.data])
                }
                setLoading(false);
                setTagIdCheck(tagId)
            }
        } catch (error) {
            setLoading(false);
        }
    };

    const updateAndMapHistories = async (serviceId, body, contractType) => {
        try {
            setLoading(true);
            const response = await updateServiceHistory(projectId, pkgId, serviceId, body);
            getServicesHistories(contractType);
            getPastServicesHistories(contractType);
            let histories = contractType === 'internal' ? internalHistories : externalHistories;

            const updatedHistories = histories.map((history) => {
                if (history.id == response.data.id) {
                    return response.data;
                }
                return history;
            });

            if (contractType === 'internal') {
                setInternalHistories(updatedHistories);
            }
            else {
                setExternalHistories(updatedHistories);
            }

            setLoading(false);
            showNotification(response.message);
        } catch (error) {
            setLoading(false);
        }
    };

    const onSubmit = async (values) => {
        Object.keys(values).map((key) => {
            if (OPERATIONS_DATE_FIELDS.includes(key) && !_.isEmpty(values[key])) {
                values[key] = moment(moment(values[key], INPUT_DATE_FORMAT)).format(
                    SERVER_DATE_FORMAT,
                );
            }
        });
        await updateAndMapHistories(currentRecord, values, values?.contract_type);
        setCurrentRecord(null);
        form.resetFields();
        setEditModal(false);
    };


    return (
        <>
            <Collapse bordered={false} className="tagsBody" defaultActiveKey={['1']}>
                <Panel className="tagsBodyPanel" header={<b>Preventive Maintenance</b>} key="1">
                    <Tabs defaultActiveKey="1" onChange={null} onTabClick={(key, event) => { getServicesHistories(key === '1' ? 'internal' : 'external') }}>
                        <TabPane tab="Internal" key="1">
                            <Table
                                rowKey="id"
                                size="small"
                                loading={loading}
                                columns={columns}
                                dataSource={internalHistories}
                                className="taglist-table"
                                pagination={internalHistories.length > 10}
                            />
                        </TabPane>
                        <TabPane tab="External" key="2">
                            <Table
                                rowKey="id"
                                size="small"
                                loading={loading}
                                columns={columns}
                                dataSource={externalHistories}
                                className="taglist-table"
                                pagination={externalHistories.length > 10}
                            />
                        </TabPane>
                    </Tabs>

                </Panel>
                <Panel className="tagsBodyPanel" header={<b>Past Preventive Maintenance</b>} key="2">
                    <Tabs defaultActiveKey="1" onChange={null} onTabClick={(key, event) => { getPastServicesHistories(key === '1' ? 'internal' : 'external') }}>
                        <TabPane tab="Internal" key="1">
                            <Table
                                rowKey="id"
                                size="small"
                                loading={loading}
                                columns={columns}
                                dataSource={internalPastHistories}
                                className="taglist-table"
                                pagination={internalPastHistories.length > 10}
                            />
                        </TabPane>
                        <TabPane tab="External" key="2">
                            <Table
                                rowKey="id"
                                size="small"
                                loading={loading}
                                columns={columns}
                                dataSource={externalPastHistories}
                                className="taglist-table"
                                pagination={externalPastHistories.length > 10}
                            />
                        </TabPane>
                    </Tabs>
                </Panel>
            </Collapse>
            <Modal
                title="Edit Service History"
                visible={editModal}
                onOk={editForm.submit}
                okText="Update"
                onCancel={() => {
                    setCurrentRecord(null);
                    editForm.resetFields();
                    setEditModal(false);
                }}
                confirmLoading={loading}
            >
                <Form form={editForm} layout="vertical" onFinish={onSubmit}>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="contract_type"
                                label="Contract Type"
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="work_id"
                                label="Work ID"
                                rules={[{ required: true, message: 'Required' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>


                    <Form.Item
                        name="task_description"
                        label="Task Description"
                        rules={[{ required: true, message: 'Required' }]}
                    >
                        <Input.TextArea />
                    </Form.Item>

                    <Form.Item name="created_at" label="Date Assigned">
                        <DatePicker format={INPUT_DATE_FORMAT} style={{ width: '100%' }} disabled />
                    </Form.Item>
                    <Form.Item name="due_date" label="Due Date">
                        <DatePicker format={INPUT_DATE_FORMAT} style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item
                        name="remarks"
                        label="Remarks"
                        rules={[{ required: true, message: 'Required' }]}
                    >
                        <Input.TextArea />
                    </Form.Item>
                </Form>
            </Modal>
            {renderPmModal()}

        </>
    );
});

export default ServiceHistory;
