import { http } from '../utils/http';

export const fetchRounds = async (projectId, shiftDate, shift) => {
    try {
        const response = await http.get(
            `/projects/${projectId}/web/round/?shift_date=${shiftDate}&shift=${shift}`,
        );
        return response.data;
    } catch (ex) {
        throw ex;
    }
};

export const fetchRoundComments = async (projectId, tagRoundId) => {
    try {
        const response = await http.get(
            `projects/${projectId}/app/taground/${tagRoundId}/comments/`,
        );
        return response.data;
    } catch (ex) {
        throw ex;
    }
};

export const fetchRoundDocuments = async (projectId, tagRoundId) => {
    try {
        const response = await http.get(
            `projects/${projectId}/app/taground/?tag_round=${tagRoundId}&files=true`,
        );
        return response.data;
    } catch (ex) {
        throw ex;
    }
};
