import { Card } from 'antd';

import detector from '../../../../assets/images/eaton_lv_swgr.gif';
import './analytics.scss';

function Analytics() {
    return (
        <>
            <Card title="Automatic Object Detection">
                <img src={detector} style={{ height: 700 }} />
            </Card>
        </>
    );
}

export default Analytics;
