import { http } from '../utils/http';

export const fetchFeatures = async () => {
    try {
        const response = await http.get(`/admin/companies/branches/features/`);
        return response.data;
    } catch (ex) {
        throw ex;
    }
};

export const updateFeature = async (featureId, body) => {
    try {
        const response = await http.patch(`/admin/companies/branches/features/${featureId}/`, body);
        return response.data;
    } catch (ex) {
        throw ex;
    }
};
