export const FETCH_SINGLELINES_INTIATE = 'FETCH_SINGLELINES_INTIATE';
export const FETCH_SINGLELINES_SUCCESS = 'FETCH_SINGLELINES_SUCCESS';
export const FETCH_SINGLELINES_FAILURE = 'FETCH_SINGLELINES_FAILURE';

export const FETCH_SINGLELINE_DETAIL_INITIATE = 'FETCH_SINGLELINE_DETAIL_INITIATE';
export const FETCH_SINGLELINE_DETAIL_SUCCESS = 'FETCH_SINGLELINE_DETAIL_SUCCESS';
export const FETCH_SINGLELINE_DETAIL_FAILURE = 'FETCH_SINGLELINE_DETAIL_FAILURE';

export const UPDATE_TAG_INITIATE = 'UPDATE_TAG_INITIATE';
export const UPDATE_TAG_SUCCESS = 'UPDATE_TAG_SUCCESS';
export const UPDATE_TAG_FAILURE = 'UPDATE_TAG_FAILURE';

export const HIDE_LOADER = "HIDE_LOADER";
