export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';

export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';

export const ACTIVE_PROJECT = 'ACTIVE_PROJECT';
export const ACTIVE_PROJECT_TAB = 'ACTIVE_PROJECT_TAB';

export const RESET_STATE = 'RESET_STATE';

export const SWITCHED_TO_OPERATIONS = 'SWITCHED_TO_OPERATIONS';
export const PROJECT_DOCS = 'PROJECT_DOCS';

export const PROJECT_SETUP = 'PROJECT_SETUP';

export const MAP_VIEW = 'MAP_VIEW';

export const TITLE_STATE = 'TITLE_STATE';
