import _ from 'lodash';
import moment from 'moment';
import Papaparse from 'papaparse';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import {
    Upload,
    Form,
    Button,
    Row,
    Col,
    Table,
    Input,
    Modal,
    DatePicker,
    notification,
    Space,
    Typography,
    Alert,
} from 'antd';
import sampleCsv from '../../../assets/sample.csv';
import operationSampleCsv from '../../../assets/sample.csv';
import {
    CSV_DATE_FORMAT,
    DATE_FIELDS,
    DISPLAY_DATE_FORMAT,
    INPUT_DATE_FORMAT,
    TAGS_CSV_HEADERS,
} from '../../../utils';
import { useSelector } from 'react-redux';

const { Text } = Typography;

const columns = [
    {
        title: 'S.no',
        dataIndex: 'key',
    },
    {
        title: 'Tag Name',
        dataIndex: 'name',
    },
    {
        title: 'Description',
        dataIndex: 'description',
    },
    {
        title: 'Manufacturer',
        dataIndex: 'manufacture',
        render: (data) => data || '-',
        editable: true,
        inputType: 'manufacture',
    },
    {
        title: 'Order Date',
        dataIndex: 'order_date',
        render: (data) => (data ? moment(data, CSV_DATE_FORMAT).format(DISPLAY_DATE_FORMAT) : ''),
    },
    {
        title: 'Aprroval Date',
        dataIndex: 'approval_date',
        render: (data) => (data ? moment(data, CSV_DATE_FORMAT).format(DISPLAY_DATE_FORMAT) : ''),
    },
    {
        title: 'Release Date',
        dataIndex: 'release_date',
        render: (data) => (data ? moment(data, CSV_DATE_FORMAT).format(DISPLAY_DATE_FORMAT) : ''),
    },
    // {
    //     title: 'Final Date',
    //     dataIndex: 'final_date',
    //     render: data => (data ? moment(data, CSV_DATE_FORMAT).format(DISPLAY_DATE_FORMAT) : ''),
    // },
    {
        title: 'Test Date',
        dataIndex: 'test_date',
        render: (data) => (data ? moment(data, CSV_DATE_FORMAT).format(DISPLAY_DATE_FORMAT) : ''),
    },
    {
        title: 'Ship Date',
        dataIndex: 'ship_date',
        render: (data) => (data ? moment(data, CSV_DATE_FORMAT).format(DISPLAY_DATE_FORMAT) : ''),
    },
];

const operation_columns = [
    {
        title: 'S.no',
        dataIndex: 'key',
    },
    {
        title: 'Tag Name',
        dataIndex: 'name',
    },
    {
        title: 'Description',
        dataIndex: 'description',
    },
    {
        title: 'Manufacturer',
        dataIndex: 'manufacture',
        render: (data) => data || '-',
        editable: true,
        inputType: 'manufacture',
    },
    {
        title: 'Installation Date',
        dataIndex: 'installation_date',
        render: (data) => (data ? moment(data, CSV_DATE_FORMAT).format(DISPLAY_DATE_FORMAT) : ''),
    },
    {
        title: 'Maintenance (# of days)',
        dataIndex: 'maintenance_duration',
    },
    {
        title: 'Serial Number',
        dataIndex: 'serial_number',
    },
];

const EquipmentInfo = ({
    tags = [],
    goBack = () => {},
    setTags = () => {},
    onFinish = () => {},
    equipmentErrors = [],
    setEquipmentErrors = () => {},
}) => {
    const [form] = Form.useForm();
    const { operations } = useSelector(({ common }) => common);
    const [addManualTags, setAddManualTags] = useState(false);
    const [formatError, setFormatError] = useState(false);
    const [dates, setDates] = useState({});

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        if (e.fileList.length) {
            readCsv(e.fileList[0].originFileObj);
        } else if (e.fileList.length === 0) {
            setTags([]);
        }
        return e && e.fileList;
    };

    const isDatePast = (date1, date2) => {
        if (date1 && date2) {
            return moment(date1, CSV_DATE_FORMAT).isBefore(moment(date2, CSV_DATE_FORMAT));
        }
    };

    const validateDates = (tag, index) => {
        const { name, order_date, approval_date, release_date, test_date, ship_date } = tag;
        const _errors = [];

        if (isDatePast(approval_date, order_date)) {
            _errors.push({
                message: `Row # ${index}: ${name.bold()} approval date should be greater than order date`,
            });
        }
        if (isDatePast(release_date, approval_date)) {
            _errors.push({
                message: `Row # ${index}: ${name.bold()} release date should be greater than approval date`,
            });
        }
        if (isDatePast(test_date, release_date)) {
            _errors.push({
                message: `Row # ${index}: ${name.bold()} test date should be greater than release date`,
            });
        }
        if (isDatePast(ship_date, test_date)) {
            _errors.push({
                message: `Row # ${index}: ${name.bold()} ship date should be greater than test date`,
            });
        }
        return _errors;
    };

    const readCsv = (file) => {
        const reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.addEventListener('load', function (e) {
            const _tags = [];
            let _errors = [];
            setEquipmentErrors([]);
            const data = e.target.result;
            Papaparse.parse(data, {
                complete: function (results) {
                    let isFormatCorrect = false;
                    results.data.forEach((row, index) => {
                        const [
                            name,
                            description,
                            manufacture,
                            order_date,
                            approval_date,
                            release_date,
                            final_date,
                            test_date,
                            ship_date,
                            installation_date,
                            maintenance_duration,
                            serial_number,
                        ] = row;
                        if (index === 0) {
                            if (operations) {
                                isFormatCorrect = _.isEqual(row, [
                                    ...TAGS_CSV_HEADERS,
                                    'Installation Date',
                                    'Maintainance (# of days)',
                                    'Serial Number',
                                ]);
                            } else {
                                isFormatCorrect = _.isEqual(row, TAGS_CSV_HEADERS);
                            }
                            setFormatError(!isFormatCorrect);
                        }
                        if (isFormatCorrect && index !== 0 && !_.isEmpty(row[0])) {
                            const tag = {
                                name,
                                description,
                                manufacture,
                                order_date: !_.isEmpty(order_date) ? order_date : null,
                                approval_date: !_.isEmpty(approval_date) ? approval_date : null,
                                approval_dwg: !_.isEmpty(approval_date) || operations,
                                release_date: !_.isEmpty(release_date) ? release_date : null,
                                final_date: !_.isEmpty(final_date) ? final_date : null,
                                test_date: !_.isEmpty(test_date) ? test_date : null,
                                ship_date: !_.isEmpty(ship_date) ? ship_date : null,
                                installation_date: !_.isEmpty(installation_date)
                                    ? installation_date
                                    : null,
                                maintenance_duration,
                                serial_number,
                            };
                            if (!operations) {
                                const fieldErrors = validateDates(tag, index);
                                _errors = [..._errors, ...fieldErrors];
                            }
                            _tags.push(tag);
                        }
                    });
                },
            });
            if (!operations) {
                setEquipmentErrors([..._errors]);
            }
            setTags([..._tags]);
        });
    };

    const addManualTagHandler = (values) => {
        const isTagExist = tags && tags.some((tag) => tag.name === values.name);
        if (isTagExist) {
            form.setFields([{ name: 'name', errors: ['Tag with this name already exists'] }]);
            return;
        }
        if (_.has(values, 'approval_date') && !_.isEmpty(values.approval_date)) {
            values.approval_dwg = true;
        } else {
            values.approval_dwg = false;
        }
        Object.keys(values).forEach((key) => {
            if (DATE_FIELDS.includes(key)) {
                values[key] = values[key]
                    ? moment(values[key], INPUT_DATE_FORMAT).format(CSV_DATE_FORMAT)
                    : null;
            }
        });
        setTags([...tags, values]);
        notification.success({
            message: `${values.name} added`,
            description: 'The tag has been added successfully',
        });
        form.resetFields();
        setAddManualTags(false);
    };

    const onNext = () => {
        onFinish({ tags });
    };

    const disabledDate = (current, end) => {
        if (end) {
            return current && current < end;
        }
    };

    return (
        <>
            <h2 className="step-heading mb-20">Upload Equipment Information</h2>
            <Form layout="vertical">
                <Row>
                    <Col span={7}>
                        <Form.Item
                            name="tagList"
                            valuePropName="tagList"
                            getValueFromEvent={normFile}
                        >
                            <Upload
                                beforeUpload={() => false}
                                listType="text"
                                maxCount={1}
                                showUploadList={false}
                                accept=".csv"
                            >
                                <Button type="primary">
                                    <UploadOutlined />
                                    Upload Equipments CSV
                                </Button>
                            </Upload>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item>
                            <Link
                                to={operations ? operationSampleCsv : sampleCsv}
                                download="Sample.csv"
                                target="_blank"
                            >
                                <Button type="default">
                                    <DownloadOutlined />
                                    Download Sample CSV
                                </Button>
                            </Link>
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <div className="text-right">
                            {/* disabled btn */}
                            {/* {!_.isEmpty(tags) && (
                                <Button onClick={() => setTags([])}>
                                    Clear CSV Tags
                                </Button>
                            )} */}
                            <Button
                                className="buttonAddEqip"
                                onClick={() => setAddManualTags(!addManualTags)}
                            >
                                Add Equipments
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Form>
            {formatError && (
                <div className="mb-15" style={{ marginTop: -13 }}>
                    <Text className="mt-" type="danger">
                        Incorrect CSV Format, Download sample CSV for the reference
                    </Text>
                </div>
            )}
            {!_.isEmpty(equipmentErrors) && (
                <Alert
                    message="Tags Errors"
                    showIcon
                    className="mb-20"
                    description={equipmentErrors.map((error, idx) => (
                        <span
                            className="d-block"
                            key={idx}
                            dangerouslySetInnerHTML={{ __html: error.message }}
                        ></span>
                    ))}
                    type="error"
                />
            )}
            <Table
                dataSource={tags.map((tag, idx) => ({ ...tag, key: idx + 1 }))}
                columns={operations ? operation_columns : columns}
                className="taglist-table"
                size="small"
                pagination={tags.length > 10}
            />
            <div className="text-right">
                <Space>
                    <Button
                        className="btn-lg btn-default save-btn mt-30"
                        type="default"
                        htmlType="button"
                        onClick={goBack}
                    >
                        Previous
                    </Button>
                    <Button
                        className="btn-lg btn-primary-lg save-btn mt-30"
                        type="primary"
                        onClick={onNext}
                    >
                        Next
                    </Button>
                </Space>
            </div>
            <Modal
                title="Add Manual Tags"
                visible={addManualTags}
                onOk={form.submit}
                okText="Add Tag"
                onCancel={() => setAddManualTags(false)}
            >
                <Form form={form} layout="vertical" onFinish={addManualTagHandler}>
                    <Form.Item
                        name="name"
                        label="Tag Name"
                        rules={[{ required: true, message: 'Tag name is required' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="manufacture"
                        label="Manufacture Name"
                        rules={[{ required: true, message: 'Manufacture name is required' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="description"
                        label="Description"
                        rules={[{ required: true, message: 'Description is required' }]}
                    >
                        <Input.TextArea />
                    </Form.Item>
                    <Form.Item
                        name="manufacture"
                        label="Manufacturer"
                        rules={[{ required: true, message: 'Manufacturer is required' }]}
                    >
                        <Input />
                    </Form.Item>
                    {operations ? (
                        <>
                            <Form.Item name="installation_date" label="Installation Date">
                                <DatePicker format={INPUT_DATE_FORMAT} style={{ width: '100%' }} />
                            </Form.Item>
                            <Form.Item name="maintenance_duration" label="# of days (Maintainance)">
                                <Input type="number" />
                            </Form.Item>
                            <Form.Item name="serial_number" label="Serial Number">
                                <Input />
                            </Form.Item>
                        </>
                    ) : (
                        <>
                            <Form.Item name="order_date" label="Order Date">
                                <DatePicker
                                    format={INPUT_DATE_FORMAT}
                                    style={{ width: '100%' }}
                                    onChange={(val) => setDates({ ...dates, order_date: val })}
                                />
                            </Form.Item>
                            <Form.Item name="approval_date" label="Approval Date">
                                <DatePicker
                                    disabledDate={(current) =>
                                        disabledDate(current, dates?.order_date)
                                    }
                                    format={INPUT_DATE_FORMAT}
                                    style={{ width: '100%' }}
                                    onChange={(val) => setDates({ ...dates, approval_date: val })}
                                />
                            </Form.Item>
                            <Form.Item name="release_date" label="Release Date">
                                <DatePicker
                                    disabledDate={(current) =>
                                        disabledDate(current, dates?.approval_date)
                                    }
                                    format={INPUT_DATE_FORMAT}
                                    style={{ width: '100%' }}
                                    onChange={(val) => setDates({ ...dates, release_date: val })}
                                />
                            </Form.Item>
                            <Form.Item name="test_date" label="Test Date">
                                <DatePicker
                                    disabledDate={(current) =>
                                        disabledDate(current, dates?.release_date)
                                    }
                                    format={INPUT_DATE_FORMAT}
                                    style={{ width: '100%' }}
                                    onChange={(val) => setDates({ ...dates, test_date: val })}
                                />
                            </Form.Item>
                            <Form.Item name="ship_date" label="Ship Date">
                                <DatePicker
                                    disabledDate={(current) =>
                                        disabledDate(current, dates?.test_date)
                                    }
                                    format={INPUT_DATE_FORMAT}
                                    style={{ width: '100%' }}
                                    onChange={(val) => setDates({ ...dates, ship_date: val })}
                                />
                            </Form.Item>
                        </>
                    )}
                </Form>
            </Modal>
        </>
    );
};

export default EquipmentInfo;
