import React from 'react';
import { Tabs } from 'antd';
import { useState } from 'react';
import { EquipmentDocs } from '../../equipment_docs';
import Arc from './arc';
// import VideoPunchList from './video-punch-list';
import { useParams } from 'react-router-dom';
import VideoPunchList from './video-punch-list';

const { TabPane } = Tabs;

export const OperationsTab = ({ isLoading = false, equipmentDocs = [], uploadDocs }) => {
    const [activeTab, setActiveTab] = useState('1');

    const { pkgId, projectId } = useParams();

    return (
        <Tabs tabPosition={'left'} defaultActiveKey={activeTab} onChange={setActiveTab}>
            <TabPane className="side_tabs" tab=" Status" key="1">
                <Arc />
            </TabPane>
            <TabPane tab="Video Punch List" key="2">
                <VideoPunchList pkgId={pkgId} projectId={projectId} />
            </TabPane>
            <TabPane tab="Documents" key="3">
                <EquipmentDocs
                    pkgId={pkgId}
                    data={equipmentDocs}
                    isLoading={isLoading}
                    uploadDocs={uploadDocs}
                />
            </TabPane>
        </Tabs>
    );
};
