import { videoPlayerHttp } from '../utils/http';

export const fetchVideoFiles = async (pkgId) => {
    try {
        const response = await videoPlayerHttp.get(`get_punch_list_by_video?package_id=${pkgId}`);
        return response;
    } catch (ex) {
        throw ex;
    }
};

export const uploadVideoFile = async (body) => {
    try {
        const response = await videoPlayerHttp.post(`punch_list_extractor`, body);
        return response;
    } catch (ex) {
        throw ex;
    }
};
