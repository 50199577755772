import { http } from '../utils/http';

export const addMemberInPackage = async (projectId, packageId, memberId) => {
    try {
        const response = await http.patch(
            `/projects/${projectId}/packages/${packageId}/team-members/${memberId}`,
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const removeMemberFromPackage = async (projectId, packageId, memberId) => {
    try {
        const response = await http.delete(
            `/projects/${projectId}/packages/${packageId}/team-members/${memberId}`,
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const fetchAllPackages = async (
    projectId,
    isIndividual,
    page,
    pageSize = 10,
    searchText,
    searchedColumn,
    ordering = null,
) => {
    try {
        let url = `/projects/${projectId}/packages/?`;
        if (isIndividual != undefined) {
            url += `is_individual=${isIndividual}&`;
        }
        if (page) {
            url += `page=${page}&per_page=${pageSize}&`;
        }
        if (searchedColumn) {
            url += `${searchedColumn}__icontains=${searchText}&`;
        }
        if (ordering) {
            url += `ordering=${ordering}`;
        }
        const response = await http.get(url);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const createPackage = async (projectId, body) => {
    try {
        const response = await http.post(`/projects/${projectId}/packages/`, body);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const updatePackage = async (projectId, pkgId, body) => {
    try {
        const response = await http.patch(`/projects/${projectId}/packages/${pkgId}/`, body);
        return response.data;
    } catch (ex) {
        throw ex;
    }
};

export const deletePackage = async (projectId, pkgId) => {
    try {
        const response = await http.delete(`/projects/${projectId}/packages/${pkgId}/`);
        return response.data;
    } catch (ex) {
        throw ex;
    }
};

export const fetchPackageDetails = async (projectId, pkgId) => {
    try {
        const response = await http.get(`/projects/${projectId}/package-rooms/${pkgId}/`);
        return response.data;
    } catch (ex) {
        console.log(ex);
    }
};

export const fetchPackageOperationsDetails = async (projectId, pkgId) => {
    try {
        const response = await http.get(`/projects/${projectId}/package-rooms/${pkgId}/status/`);
        return response.data;
    } catch (ex) {
        console.log(ex);
    }
};

export const deleteInactivePackages = async () => {
    try {
        const response = await http.delete(`/packages/delete-inactive/`);
        return response.data;
    } catch (ex) {
        throw ex;
    }
};

export const fetchTagData = async (tagId) => {
    try {
        const response = await http.get(`/projects/tag-data-round-report/${tagId}/`);
        return response.data;
    } catch (ex) {
        throw ex;
    }
};

export const resetPackagesOrder = async (projectId) => {
    try {
        const response = await http.post(`/projects/${projectId}/packages/reset-order/`);
        return response.data;
    } catch (ex) {
        console.log(ex);
    }
};
