const DATE1 = [
    ['2022-06-01 00:00:00', 26.0],
    ['2022-06-01 04:00:00', 25.1],
    ['2022-06-01 08:00:00', 24.7],
    ['2022-06-01 12:00:00', 24.5],
    ['2022-06-01 16:00:00', 24.8],
    ['2022-06-01 20:00:00', 23.8],
    ['2022-06-02 00:00:00', 23.5],
    ['2022-06-02 04:00:00', 23.9],
    ['2022-06-02 08:00:00', 24.1],
    ['2022-06-02 12:00:00', 24.2],
    ['2022-06-02 16:00:00', 24.0],
    ['2022-06-02 20:00:00', 23.6],
    ['2022-06-03 00:00:00', 23.6],
    ['2022-06-03 04:00:00', 23.9],
    ['2022-06-03 08:00:00', 24.1],
    ['2022-06-03 12:00:00', 23.9],
    ['2022-06-03 16:00:00', 24.2],
    ['2022-06-03 20:00:00', 23.8],
    ['2022-06-04 00:00:00', 23.5],
    ['2022-06-04 04:00:00', 23.2],
    ['2022-06-04 08:00:00', 23.6],
    ['2022-06-04 12:00:00', 23.6],
    ['2022-06-04 16:00:00', 23.8],
    ['2022-06-04 20:00:00', 23.6],
    ['2022-06-05 00:00:00', 23.4],
    ['2022-06-05 04:00:00', 23.6],
    ['2022-06-05 08:00:00', 23.6],
    ['2022-06-05 12:00:00', 23.6],
    ['2022-06-05 16:00:00', 23.8],
    ['2022-06-05 20:00:00', 23.9],
    ['2022-06-06 00:00:00', 23.6],
    ['2022-06-06 04:00:00', 23.8],
    ['2022-06-06 08:00:00', 24.1],
    ['2022-06-06 12:00:00', 24.1],
    ['2022-06-06 16:00:00', 23.9],
    ['2022-06-06 20:00:00', 24.2],
    ['2022-06-07 00:00:00', 24.1],
    ['2022-06-07 04:00:00', 24.3],
    ['2022-06-07 08:00:00', 24.1],
    ['2022-06-07 12:00:00', 24.6],
    ['2022-06-07 16:00:00', 24.3],
    ['2022-06-07 20:00:00', 23.6],
    ['2022-06-08 00:00:00', 23.6],
    ['2022-06-08 04:00:00', 24.0],
    ['2022-06-08 08:00:00', 24.2],
    ['2022-06-08 12:00:00', 25.0],
    ['2022-06-08 16:00:00', 24.5],
    ['2022-06-08 20:00:00', 24.1],
    ['2022-06-09 00:00:00', 23.8],
    ['2022-06-09 04:00:00', 23.9],
    ['2022-06-09 08:00:00', 24.0],
    ['2022-06-09 12:00:00', 24.1],
    ['2022-06-09 16:00:00', 24.5],
    ['2022-06-09 20:00:00', 23.8],
    ['2022-06-10 00:00:00', 23.6],
    ['2022-06-10 04:00:00', 23.9],
    ['2022-06-10 08:00:00', 24.3],
    ['2022-06-10 12:00:00', 24.5],
    ['2022-06-10 16:00:00', 24.5],
    ['2022-06-10 20:00:00', 23.9],
    ['2022-06-11 00:00:00', 23.9],
    ['2022-06-11 04:00:00', 24.1],
    ['2022-06-11 08:00:00', 24.5],
    ['2022-06-11 12:00:00', 24.7],
    ['2022-06-11 16:00:00', 24.8],
    ['2022-06-11 20:00:00', 23.9],
    ['2022-06-12 00:00:00', 24.1],
    ['2022-06-12 04:00:00', 24.2],
    ['2022-06-12 08:00:00', 24.2],
    ['2022-06-12 12:00:00', 24.7],
    ['2022-06-12 16:00:00', 24.9],
    ['2022-06-12 20:00:00', 23.9],
    ['2022-06-13 00:00:00', 23.8],
    ['2022-06-13 04:00:00', 24.0],
    ['2022-06-13 08:00:00', 24.3],
    ['2022-06-13 12:00:00', 24.8],
    ['2022-06-13 16:00:00', 24.7],
    ['2022-06-13 20:00:00', 24.2],
    ['2022-06-14 00:00:00', 23.9],
    ['2022-06-14 04:00:00', 23.9],
    ['2022-06-14 08:00:00', 24.7],
    ['2022-06-14 12:00:00', 26.1],
    ['2022-06-14 16:00:00', 26.1],
    ['2022-06-14 20:00:00', 25.1],
    ['2022-06-15 00:00:00', 23.9],
    ['2022-06-15 04:00:00', 24.3],
    ['2022-06-15 08:00:00', 24.5],
    ['2022-06-15 12:00:00', 25.0],
    ['2022-06-15 16:00:00', 25.4],
    ['2022-06-15 20:00:00', 24.9],
    ['2022-06-16 00:00:00', 24.6],
    ['2022-06-16 04:00:00', 24.2],
    ['2022-06-16 08:00:00', 27.5],
    ['2022-06-16 12:00:00', 27.8],
    ['2022-06-16 16:00:00', 28.5],
    ['2022-06-16 20:00:00', 28.1],
    ['2022-06-17 00:00:00', 27.0],
    ['2022-06-17 04:00:00', 24.8],
    ['2022-06-17 08:00:00', 25.1],
    ['2022-06-17 12:00:00', 26.1],
    ['2022-06-17 16:00:00', 24.9],
    ['2022-06-17 20:00:00', 24.3],
    ['2022-06-18 00:00:00', 24.0],
    ['2022-06-18 04:00:00', 23.8],
    ['2022-06-18 08:00:00', 24.0],
    ['2022-06-18 12:00:00', 23.7],
    ['2022-06-18 16:00:00', 24.5],
    ['2022-06-18 20:00:00', 24.7],
    ['2022-06-19 00:00:00', 24.8],
    ['2022-06-19 04:00:00', 25.0],
    ['2022-06-19 08:00:00', 25.1],
    ['2022-06-19 12:00:00', 25.2],
    ['2022-06-19 16:00:00', 24.8],
    ['2022-06-19 20:00:00', 24.7],
    ['2022-06-20 00:00:00', 25.0],
    ['2022-06-20 04:00:00', 25.1],
    ['2022-06-20 08:00:00', 25.2],
    ['2022-06-20 12:00:00', 25.8],
    ['2022-06-20 16:00:00', 25.8],
    ['2022-06-20 20:00:00', 25.8],
    ['2022-06-21 00:00:00', 25.8],
    ['2022-06-21 04:00:00', 24.8],
    ['2022-06-21 08:00:00', 25.5],
    ['2022-06-21 12:00:00', 28.3],
    ['2022-06-21 16:00:00', 28.3],
    ['2022-06-21 20:00:00', 27.1],
    ['2022-06-22 00:00:00', 24.8],
    ['2022-06-22 04:00:00', 25.1],
    ['2022-06-22 08:00:00', 28.3],
    ['2022-06-22 12:00:00', 28.2],
    ['2022-06-22 16:00:00', 28.1],
    ['2022-06-22 20:00:00', 26.5],
    ['2022-06-23 00:00:00', 24.5],
    ['2022-06-23 04:00:00', 24.3],
    ['2022-06-23 08:00:00', 25.2],
    ['2022-06-23 12:00:00', 26.5],
    ['2022-06-23 16:00:00', 26.9],
    ['2022-06-23 20:00:00', 24.6],
    ['2022-06-24 00:00:00', 24.1],
    ['2022-06-24 04:00:00', 24.2],
    ['2022-06-24 08:00:00', 25.0],
    ['2022-06-24 12:00:00', 26.3],
    ['2022-06-24 16:00:00', 26.8],
    ['2022-06-24 20:00:00', 25.4],
    ['2022-06-25 00:00:00', 24.4],
    ['2022-06-25 04:00:00', 24.5],
    ['2022-06-25 08:00:00', 27.4],
    ['2022-06-25 12:00:00', 28.4],
    ['2022-06-25 16:00:00', 28.0],
    ['2022-06-25 20:00:00', 27.6],
    ['2022-06-26 00:00:00', 24.6],
    ['2022-06-26 04:00:00', 24.6],
    ['2022-06-26 08:00:00', 25.7],
    ['2022-06-26 12:00:00', 27.6],
    ['2022-06-26 16:00:00', 28.4],
    ['2022-06-26 20:00:00', 27.9],
    ['2022-06-27 00:00:00', 24.4],
    ['2022-06-27 04:00:00', 24.2],
    ['2022-06-27 08:00:00', 24.2],
    ['2022-06-27 12:00:00', 23.3],
    ['2022-06-27 16:00:00', 23.6],
    ['2022-06-27 20:00:00', 22.8],
    ['2022-06-28 00:00:00', 23.5],
    ['2022-06-28 04:00:00', 23.9],
    ['2022-06-28 08:00:00', 23.1],
    ['2022-06-28 12:00:00', 23.9],
    ['2022-06-28 16:00:00', 24.8],
    ['2022-06-28 20:00:00', 24.1],
    ['2022-06-29 00:00:00', 23.7],
    ['2022-06-29 04:00:00', 24.1],
    ['2022-06-29 08:00:00', 24.2],
    ['2022-06-29 12:00:00', 24.8],
    ['2022-06-29 16:00:00', 24.7],
    ['2022-06-29 20:00:00', 24.1],
    ['2022-06-30 00:00:00', 23.9],
    ['2022-06-30 04:00:00', 24.1],
    ['2022-06-30 08:00:00', 24.6],
    ['2022-06-30 12:00:00', 26.7],
    ['2022-06-30 16:00:00', 27.3],
    ['2022-06-30 20:00:00', 25.7],
];
const DATE2 = [
    ['2022-06-01 00:00:00', 0],
    ['2022-06-01 04:00:00', 0],
    ['2022-06-01 08:00:00', 0],
    ['2022-06-01 12:00:00', 0],
    ['2022-06-01 16:00:00', 0],
    ['2022-06-01 20:00:00', 0],
    ['2022-06-02 00:00:00', 0],
    ['2022-06-02 04:00:00', 0],
    ['2022-06-02 08:00:00', 0],
    ['2022-06-02 12:00:00', 0],
    ['2022-06-02 16:00:00', 0],
    ['2022-06-02 20:00:00', 0],
    ['2022-06-03 00:00:00', 0],
    ['2022-06-03 04:00:00', 0],
    ['2022-06-03 08:00:00', 0],
    ['2022-06-03 12:00:00', 0],
    ['2022-06-03 16:00:00', 0],
    ['2022-06-03 20:00:00', 0],
    ['2022-06-04 00:00:00', 0],
    ['2022-06-04 04:00:00', 0],
    ['2022-06-04 08:00:00', 0],
    ['2022-06-04 12:00:00', 0],
    ['2022-06-04 16:00:00', 0],
    ['2022-06-04 20:00:00', 0],
    ['2022-06-05 00:00:00', 0],
    ['2022-06-05 04:00:00', 0],
    ['2022-06-05 08:00:00', 0],
    ['2022-06-05 12:00:00', 0],
    ['2022-06-05 16:00:00', 0],
    ['2022-06-05 20:00:00', 0],
    ['2022-06-06 00:00:00', 0],
    ['2022-06-06 04:00:00', 0],
    ['2022-06-06 08:00:00', 0],
    ['2022-06-06 12:00:00', 0],
    ['2022-06-06 16:00:00', 0],
    ['2022-06-06 20:00:00', 0],
    ['2022-06-07 00:00:00', 0],
    ['2022-06-07 04:00:00', 0],
    ['2022-06-07 08:00:00', 0],
    ['2022-06-07 12:00:00', 0],
    ['2022-06-07 16:00:00', 0],
    ['2022-06-07 20:00:00', 0],
    ['2022-06-08 00:00:00', 0],
    ['2022-06-08 04:00:00', 0],
    ['2022-06-08 08:00:00', 0],
    ['2022-06-08 12:00:00', 0],
    ['2022-06-08 16:00:00', 0],
    ['2022-06-08 20:00:00', 0],
    ['2022-06-09 00:00:00', 0],
    ['2022-06-09 04:00:00', 0],
    ['2022-06-09 08:00:00', 0],
    ['2022-06-09 12:00:00', 0],
    ['2022-06-09 16:00:00', 0],
    ['2022-06-09 20:00:00', 0],
    ['2022-06-10 00:00:00', 0],
    ['2022-06-10 04:00:00', 0],
    ['2022-06-10 08:00:00', 0],
    ['2022-06-10 12:00:00', 0],
    ['2022-06-10 16:00:00', 0],
    ['2022-06-10 20:00:00', 0],
    ['2022-06-11 00:00:00', 0],
    ['2022-06-11 04:00:00', 0],
    ['2022-06-11 08:00:00', 0],
    ['2022-06-11 12:00:00', 0],
    ['2022-06-11 16:00:00', 0],
    ['2022-06-11 20:00:00', 0],
    ['2022-06-12 00:00:00', 0],
    ['2022-06-12 04:00:00', 0],
    ['2022-06-12 08:00:00', 0],
    ['2022-06-12 12:00:00', 0],
    ['2022-06-12 16:00:00', 0],
    ['2022-06-12 20:00:00', 0],
    ['2022-06-13 00:00:00', 0],
    ['2022-06-13 04:00:00', 0],
    ['2022-06-13 08:00:00', 0],
    ['2022-06-13 12:00:00', 0],
    ['2022-06-13 16:00:00', 0],
    ['2022-06-13 20:00:00', 0],
    ['2022-06-14 00:00:00', 0],
    ['2022-06-14 04:00:00', 0],
    ['2022-06-14 08:00:00', 0],
    ['2022-06-14 12:00:00', 0],
    ['2022-06-14 16:00:00', 0],
    ['2022-06-14 20:00:00', 0],
    ['2022-06-15 00:00:00', 0],
    ['2022-06-15 04:00:00', 0],
    ['2022-06-15 08:00:00', 0],
    ['2022-06-15 12:00:00', 0],
    ['2022-06-15 16:00:00', 0],
    ['2022-06-15 20:00:00', 0],
    ['2022-06-16 00:00:00', 0],
    ['2022-06-16 04:00:00', 0],
    ['2022-06-16 08:00:00', 0],
    ['2022-06-16 12:00:00', 0],
    ['2022-06-16 16:00:00', 0],
    ['2022-06-16 20:00:00', 0],
    ['2022-06-17 00:00:00', 0],
    ['2022-06-17 04:00:00', 0],
    ['2022-06-17 08:00:00', 0],
    ['2022-06-17 12:00:00', 0],
    ['2022-06-17 16:00:00', 0],
    ['2022-06-17 20:00:00', 0],
    ['2022-06-18 00:00:00', 0],
    ['2022-06-18 04:00:00', 0],
    ['2022-06-18 08:00:00', 0],
    ['2022-06-18 12:00:00', 0],
    ['2022-06-18 16:00:00', 0],
    ['2022-06-18 20:00:00', 0],
    ['2022-06-19 00:00:00', 0],
    ['2022-06-19 04:00:00', 0],
    ['2022-06-19 08:00:00', 0],
    ['2022-06-19 12:00:00', 0],
    ['2022-06-19 16:00:00', 0],
    ['2022-06-19 20:00:00', 0],
    ['2022-06-20 00:00:00', 0],
    ['2022-06-20 04:00:00', 0],
    ['2022-06-20 08:00:00', 0],
    ['2022-06-20 12:00:00', 0],
    ['2022-06-20 16:00:00', 0],
    ['2022-06-20 20:00:00', 0],
    ['2022-06-21 00:00:00', 0],
    ['2022-06-21 04:00:00', 0],
    ['2022-06-21 08:00:00', 0],
    ['2022-06-21 12:00:00', 0],
    ['2022-06-21 16:00:00', 0],
    ['2022-06-21 20:00:00', 0],
    ['2022-06-22 00:00:00', 0],
    ['2022-06-22 04:00:00', 0],
    ['2022-06-22 08:00:00', 0],
    ['2022-06-22 12:00:00', 0],
    ['2022-06-22 16:00:00', 0],
    ['2022-06-22 20:00:00', 0],
    ['2022-06-23 00:00:00', 0],
    ['2022-06-23 04:00:00', 0],
    ['2022-06-23 08:00:00', 0],
    ['2022-06-23 12:00:00', 0],
    ['2022-06-23 16:00:00', 0],
    ['2022-06-23 20:00:00', 0],
    ['2022-06-24 00:00:00', 0],
    ['2022-06-24 04:00:00', 0],
    ['2022-06-24 08:00:00', 0],
    ['2022-06-24 12:00:00', 0],
    ['2022-06-24 16:00:00', 0],
    ['2022-06-24 20:00:00', 0],
    ['2022-06-25 00:00:00', 0],
    ['2022-06-25 04:00:00', 0],
    ['2022-06-25 08:00:00', 0],
    ['2022-06-25 12:00:00', 0],
    ['2022-06-25 16:00:00', 0],
    ['2022-06-25 20:00:00', 0],
    ['2022-06-26 00:00:00', 0],
    ['2022-06-26 04:00:00', 0],
    ['2022-06-26 08:00:00', 0],
    ['2022-06-26 12:00:00', 0],
    ['2022-06-26 16:00:00', 0],
    ['2022-06-26 20:00:00', 0],
    ['2022-06-27 00:00:00', 0],
    ['2022-06-27 04:00:00', 0],
    ['2022-06-27 08:00:00', 0],
    ['2022-06-27 12:00:00', 0],
    ['2022-06-27 16:00:00', 0],
    ['2022-06-27 20:00:00', 22.8],
    ['2022-06-28 00:00:00', 0],
    ['2022-06-28 04:00:00', 0],
    ['2022-06-28 08:00:00', 0],
    ['2022-06-28 12:00:00', 0],
    ['2022-06-28 16:00:00', 0],
    ['2022-06-28 20:00:00', 0],
    ['2022-06-29 00:00:00', 0],
    ['2022-06-29 04:00:00', 0],
    ['2022-06-29 08:00:00', 0],
    ['2022-06-29 12:00:00', 0],
    ['2022-06-29 16:00:00', 0],
    ['2022-06-29 20:00:00', 0],
    ['2022-06-30 00:00:00', 0],
    ['2022-06-30 04:00:00', 0],
    ['2022-06-30 08:00:00', 0],
    ['2022-06-30 12:00:00', 0],
    ['2022-06-30 16:00:00', 0],
    ['2022-06-30 20:00:00', 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
];

const THRESHOLD_MIN = [
    ['2022-06-01 00:00:00', 23],
    ['2022-06-01 04:00:00', 23],
    ['2022-06-01 08:00:00', 23],
    ['2022-06-01 12:00:00', 23],
    ['2022-06-01 16:00:00', 23],
    ['2022-06-01 20:00:00', 23],
    ['2022-06-02 00:00:00', 23],
    ['2022-06-02 04:00:00', 23],
    ['2022-06-02 08:00:00', 23],
    ['2022-06-02 12:00:00', 23],
    ['2022-06-02 16:00:00', 23],
    ['2022-06-02 20:00:00', 23],
    ['2022-06-03 00:00:00', 23],
    ['2022-06-03 04:00:00', 23],
    ['2022-06-03 08:00:00', 23],
    ['2022-06-03 12:00:00', 23],
    ['2022-06-03 16:00:00', 23],
    ['2022-06-03 20:00:00', 23],
    ['2022-06-04 00:00:00', 23],
    ['2022-06-04 04:00:00', 23],
    ['2022-06-04 08:00:00', 23],
    ['2022-06-04 12:00:00', 23],
    ['2022-06-04 16:00:00', 23],
    ['2022-06-04 20:00:00', 23],
    ['2022-06-05 00:00:00', 23],
    ['2022-06-05 04:00:00', 23],
    ['2022-06-05 08:00:00', 23],
    ['2022-06-05 12:00:00', 23],
    ['2022-06-05 16:00:00', 23],
    ['2022-06-05 20:00:00', 23],
    ['2022-06-06 00:00:00', 23],
    ['2022-06-06 04:00:00', 23],
    ['2022-06-06 08:00:00', 23],
    ['2022-06-06 12:00:00', 23],
    ['2022-06-06 16:00:00', 23],
    ['2022-06-06 20:00:00', 23],
    ['2022-06-07 00:00:00', 23],
    ['2022-06-07 04:00:00', 23],
    ['2022-06-07 08:00:00', 23],
    ['2022-06-07 12:00:00', 23],
    ['2022-06-07 16:00:00', 23],
    ['2022-06-07 20:00:00', 23],
    ['2022-06-08 00:00:00', 23],
    ['2022-06-08 04:00:00', 23],
    ['2022-06-08 08:00:00', 23],
    ['2022-06-08 12:00:00', 23],
    ['2022-06-08 16:00:00', 23],
    ['2022-06-08 20:00:00', 23],
    ['2022-06-09 00:00:00', 23],
    ['2022-06-09 04:00:00', 23],
    ['2022-06-09 08:00:00', 23],
    ['2022-06-09 12:00:00', 23],
    ['2022-06-09 16:00:00', 23],
    ['2022-06-09 20:00:00', 23],
    ['2022-06-10 00:00:00', 23],
    ['2022-06-10 04:00:00', 23],
    ['2022-06-10 08:00:00', 23],
    ['2022-06-10 12:00:00', 23],
    ['2022-06-10 16:00:00', 23],
    ['2022-06-10 20:00:00', 23],
    ['2022-06-11 00:00:00', 23],
    ['2022-06-11 04:00:00', 23],
    ['2022-06-11 08:00:00', 23],
    ['2022-06-11 12:00:00', 23],
    ['2022-06-11 16:00:00', 23],
    ['2022-06-11 20:00:00', 23],
    ['2022-06-12 00:00:00', 23],
    ['2022-06-12 04:00:00', 23],
    ['2022-06-12 08:00:00', 23],
    ['2022-06-12 12:00:00', 23],
    ['2022-06-12 16:00:00', 23],
    ['2022-06-12 20:00:00', 23],
    ['2022-06-13 00:00:00', 23],
    ['2022-06-13 04:00:00', 23],
    ['2022-06-13 08:00:00', 23],
    ['2022-06-13 12:00:00', 23],
    ['2022-06-13 16:00:00', 23],
    ['2022-06-13 20:00:00', 23],
    ['2022-06-14 00:00:00', 23],
    ['2022-06-14 04:00:00', 23],
    ['2022-06-14 08:00:00', 23],
    ['2022-06-14 12:00:00', 23],
    ['2022-06-14 16:00:00', 23],
    ['2022-06-14 20:00:00', 23],
    ['2022-06-15 00:00:00', 23],
    ['2022-06-15 04:00:00', 23],
    ['2022-06-15 08:00:00', 23],
    ['2022-06-15 12:00:00', 23],
    ['2022-06-15 16:00:00', 23],
    ['2022-06-15 20:00:00', 23],
    ['2022-06-16 00:00:00', 23],
    ['2022-06-16 04:00:00', 23],
    ['2022-06-16 08:00:00', 23],
    ['2022-06-16 12:00:00', 23],
    ['2022-06-16 16:00:00', 23],
    ['2022-06-16 20:00:00', 23],
    ['2022-06-17 00:00:00', 23],
    ['2022-06-17 04:00:00', 23],
    ['2022-06-17 08:00:00', 23],
    ['2022-06-17 12:00:00', 23],
    ['2022-06-17 16:00:00', 23],
    ['2022-06-17 20:00:00', 23],
    ['2022-06-18 00:00:00', 23],
    ['2022-06-18 04:00:00', 23],
    ['2022-06-18 08:00:00', 23],
    ['2022-06-18 12:00:00', 23],
    ['2022-06-18 16:00:00', 23],
    ['2022-06-18 20:00:00', 23],
    ['2022-06-19 00:00:00', 23],
    ['2022-06-19 04:00:00', 23],
    ['2022-06-19 08:00:00', 23],
    ['2022-06-19 12:00:00', 23],
    ['2022-06-19 16:00:00', 23],
    ['2022-06-19 20:00:00', 23],
    ['2022-06-20 00:00:00', 23],
    ['2022-06-20 04:00:00', 23],
    ['2022-06-20 08:00:00', 23],
    ['2022-06-20 12:00:00', 23],
    ['2022-06-20 16:00:00', 23],
    ['2022-06-20 20:00:00', 23],
    ['2022-06-21 00:00:00', 23],
    ['2022-06-21 04:00:00', 23],
    ['2022-06-21 08:00:00', 23],
    ['2022-06-21 12:00:00', 23],
    ['2022-06-21 16:00:00', 23],
    ['2022-06-21 20:00:00', 23],
    ['2022-06-22 00:00:00', 23],
    ['2022-06-22 04:00:00', 23],
    ['2022-06-22 08:00:00', 23],
    ['2022-06-22 12:00:00', 23],
    ['2022-06-22 16:00:00', 23],
    ['2022-06-22 20:00:00', 23],
    ['2022-06-23 00:00:00', 23],
    ['2022-06-23 04:00:00', 23],
    ['2022-06-23 08:00:00', 23],
    ['2022-06-23 12:00:00', 23],
    ['2022-06-23 16:00:00', 23],
    ['2022-06-23 20:00:00', 23],
    ['2022-06-24 00:00:00', 23],
    ['2022-06-24 04:00:00', 23],
    ['2022-06-24 08:00:00', 23],
    ['2022-06-24 12:00:00', 23],
    ['2022-06-24 16:00:00', 23],
    ['2022-06-24 20:00:00', 23],
    ['2022-06-25 00:00:00', 23],
    ['2022-06-25 04:00:00', 23],
    ['2022-06-25 08:00:00', 23],
    ['2022-06-25 12:00:00', 23],
    ['2022-06-25 16:00:00', 23],
    ['2022-06-25 20:00:00', 23],
    ['2022-06-26 00:00:00', 23],
    ['2022-06-26 04:00:00', 23],
    ['2022-06-26 08:00:00', 23],
    ['2022-06-26 12:00:00', 23],
    ['2022-06-26 16:00:00', 23],
    ['2022-06-26 20:00:00', 23],
    ['2022-06-27 00:00:00', 23],
    ['2022-06-27 04:00:00', 23],
    ['2022-06-27 08:00:00', 23],
    ['2022-06-27 12:00:00', 23],
    ['2022-06-27 16:00:00', 23],
    ['2022-06-27 20:00:00', 23],
    ['2022-06-28 00:00:00', 23],
    ['2022-06-28 04:00:00', 23],
    ['2022-06-28 08:00:00', 23],
    ['2022-06-28 12:00:00', 23],
    ['2022-06-28 16:00:00', 23],
    ['2022-06-28 20:00:00', 23],
    ['2022-06-29 00:00:00', 23],
    ['2022-06-29 04:00:00', 23],
    ['2022-06-29 08:00:00', 23],
    ['2022-06-29 12:00:00', 23],
    ['2022-06-29 16:00:00', 23],
    ['2022-06-29 20:00:00', 23],
    ['2022-06-30 00:00:00', 23],
    ['2022-06-30 04:00:00', 23],
    ['2022-06-30 08:00:00', 23],
    ['2022-06-30 12:00:00', 23],
    ['2022-06-30 16:00:00', 23],
    ['2022-06-30 20:00:00', 23],
    [0, 23],
    [0, 23],
    [0, 23],
    [0, 23],
    [0, 23],
    [0, 23],
];
const THRESHOLD_MAX = [
    ['2022-06-01 00:00:00', 27],
    ['2022-06-01 04:00:00', 27],
    ['2022-06-01 08:00:00', 27],
    ['2022-06-01 12:00:00', 27],
    ['2022-06-01 16:00:00', 27],
    ['2022-06-01 20:00:00', 27],
    ['2022-06-02 00:00:00', 27],
    ['2022-06-02 04:00:00', 27],
    ['2022-06-02 08:00:00', 27],
    ['2022-06-02 12:00:00', 27],
    ['2022-06-02 16:00:00', 27],
    ['2022-06-02 20:00:00', 27],
    ['2022-06-03 00:00:00', 27],
    ['2022-06-03 04:00:00', 27],
    ['2022-06-03 08:00:00', 27],
    ['2022-06-03 12:00:00', 27],
    ['2022-06-03 16:00:00', 27],
    ['2022-06-03 20:00:00', 27],
    ['2022-06-04 00:00:00', 27],
    ['2022-06-04 04:00:00', 27],
    ['2022-06-04 08:00:00', 27],
    ['2022-06-04 12:00:00', 27],
    ['2022-06-04 16:00:00', 27],
    ['2022-06-04 20:00:00', 27],
    ['2022-06-05 00:00:00', 27],
    ['2022-06-05 04:00:00', 27],
    ['2022-06-05 08:00:00', 27],
    ['2022-06-05 12:00:00', 27],
    ['2022-06-05 16:00:00', 27],
    ['2022-06-05 20:00:00', 27],
    ['2022-06-06 00:00:00', 27],
    ['2022-06-06 04:00:00', 27],
    ['2022-06-06 08:00:00', 27],
    ['2022-06-06 12:00:00', 27],
    ['2022-06-06 16:00:00', 27],
    ['2022-06-06 20:00:00', 27],
    ['2022-06-07 00:00:00', 27],
    ['2022-06-07 04:00:00', 27],
    ['2022-06-07 08:00:00', 27],
    ['2022-06-07 12:00:00', 27],
    ['2022-06-07 16:00:00', 27],
    ['2022-06-07 20:00:00', 27],
    ['2022-06-08 00:00:00', 27],
    ['2022-06-08 04:00:00', 27],
    ['2022-06-08 08:00:00', 27],
    ['2022-06-08 12:00:00', 27],
    ['2022-06-08 16:00:00', 27],
    ['2022-06-08 20:00:00', 27],
    ['2022-06-09 00:00:00', 27],
    ['2022-06-09 04:00:00', 27],
    ['2022-06-09 08:00:00', 27],
    ['2022-06-09 12:00:00', 27],
    ['2022-06-09 16:00:00', 27],
    ['2022-06-09 20:00:00', 27],
    ['2022-06-10 00:00:00', 27],
    ['2022-06-10 04:00:00', 27],
    ['2022-06-10 08:00:00', 27],
    ['2022-06-10 12:00:00', 27],
    ['2022-06-10 16:00:00', 27],
    ['2022-06-10 20:00:00', 27],
    ['2022-06-11 00:00:00', 27],
    ['2022-06-11 04:00:00', 27],
    ['2022-06-11 08:00:00', 27],
    ['2022-06-11 12:00:00', 27],
    ['2022-06-11 16:00:00', 27],
    ['2022-06-11 20:00:00', 27],
    ['2022-06-12 00:00:00', 27],
    ['2022-06-12 04:00:00', 27],
    ['2022-06-12 08:00:00', 27],
    ['2022-06-12 12:00:00', 27],
    ['2022-06-12 16:00:00', 27],
    ['2022-06-12 20:00:00', 27],
    ['2022-06-13 00:00:00', 27],
    ['2022-06-13 04:00:00', 27],
    ['2022-06-13 08:00:00', 27],
    ['2022-06-13 12:00:00', 27],
    ['2022-06-13 16:00:00', 27],
    ['2022-06-13 20:00:00', 27],
    ['2022-06-14 00:00:00', 27],
    ['2022-06-14 04:00:00', 27],
    ['2022-06-14 08:00:00', 27],
    ['2022-06-14 12:00:00', 27],
    ['2022-06-14 16:00:00', 27],
    ['2022-06-14 20:00:00', 27],
    ['2022-06-15 00:00:00', 27],
    ['2022-06-15 04:00:00', 27],
    ['2022-06-15 08:00:00', 27],
    ['2022-06-15 12:00:00', 27],
    ['2022-06-15 16:00:00', 27],
    ['2022-06-15 20:00:00', 27],
    ['2022-06-16 00:00:00', 27],
    ['2022-06-16 04:00:00', 27],
    ['2022-06-16 08:00:00', 27],
    ['2022-06-16 12:00:00', 27],
    ['2022-06-16 16:00:00', 27],
    ['2022-06-16 20:00:00', 27],
    ['2022-06-17 00:00:00', 27],
    ['2022-06-17 04:00:00', 27],
    ['2022-06-17 08:00:00', 27],
    ['2022-06-17 12:00:00', 27],
    ['2022-06-17 16:00:00', 27],
    ['2022-06-17 20:00:00', 27],
    ['2022-06-18 00:00:00', 27],
    ['2022-06-18 04:00:00', 27],
    ['2022-06-18 08:00:00', 27],
    ['2022-06-18 12:00:00', 27],
    ['2022-06-18 16:00:00', 27],
    ['2022-06-18 20:00:00', 27],
    ['2022-06-19 00:00:00', 27],
    ['2022-06-19 04:00:00', 27],
    ['2022-06-19 08:00:00', 27],
    ['2022-06-19 12:00:00', 27],
    ['2022-06-19 16:00:00', 27],
    ['2022-06-19 20:00:00', 27],
    ['2022-06-20 00:00:00', 27],
    ['2022-06-20 04:00:00', 27],
    ['2022-06-20 08:00:00', 27],
    ['2022-06-20 12:00:00', 27],
    ['2022-06-20 16:00:00', 27],
    ['2022-06-20 20:00:00', 27],
    ['2022-06-21 00:00:00', 27],
    ['2022-06-21 04:00:00', 27],
    ['2022-06-21 08:00:00', 27],
    ['2022-06-21 12:00:00', 27],
    ['2022-06-21 16:00:00', 27],
    ['2022-06-21 20:00:00', 27],
    ['2022-06-22 00:00:00', 27],
    ['2022-06-22 04:00:00', 27],
    ['2022-06-22 08:00:00', 27],
    ['2022-06-22 12:00:00', 27],
    ['2022-06-22 16:00:00', 27],
    ['2022-06-22 20:00:00', 27],
    ['2022-06-23 00:00:00', 27],
    ['2022-06-23 04:00:00', 27],
    ['2022-06-23 08:00:00', 27],
    ['2022-06-23 12:00:00', 27],
    ['2022-06-23 16:00:00', 27],
    ['2022-06-23 20:00:00', 27],
    ['2022-06-24 00:00:00', 27],
    ['2022-06-24 04:00:00', 27],
    ['2022-06-24 08:00:00', 27],
    ['2022-06-24 12:00:00', 27],
    ['2022-06-24 16:00:00', 27],
    ['2022-06-24 20:00:00', 27],
    ['2022-06-25 00:00:00', 27],
    ['2022-06-25 04:00:00', 27],
    ['2022-06-25 08:00:00', 27],
    ['2022-06-25 12:00:00', 27],
    ['2022-06-25 16:00:00', 27],
    ['2022-06-25 20:00:00', 27],
    ['2022-06-26 00:00:00', 27],
    ['2022-06-26 04:00:00', 27],
    ['2022-06-26 08:00:00', 27],
    ['2022-06-26 12:00:00', 27],
    ['2022-06-26 16:00:00', 27],
    ['2022-06-26 20:00:00', 27],
    ['2022-06-27 00:00:00', 27],
    ['2022-06-27 04:00:00', 27],
    ['2022-06-27 08:00:00', 27],
    ['2022-06-27 12:00:00', 27],
    ['2022-06-27 16:00:00', 27],
    ['2022-06-27 20:00:00', 27],
    ['2022-06-28 00:00:00', 27],
    ['2022-06-28 04:00:00', 27],
    ['2022-06-28 08:00:00', 27],
    ['2022-06-28 12:00:00', 27],
    ['2022-06-28 16:00:00', 27],
    ['2022-06-28 20:00:00', 27],
    ['2022-06-29 00:00:00', 27],
    ['2022-06-29 04:00:00', 27],
    ['2022-06-29 08:00:00', 27],
    ['2022-06-29 12:00:00', 27],
    ['2022-06-29 16:00:00', 27],
    ['2022-06-29 20:00:00', 27],
    ['2022-06-30 00:00:00', 27],
    ['2022-06-30 04:00:00', 27],
    ['2022-06-30 08:00:00', 27],
    ['2022-06-30 12:00:00', 27],
    ['2022-06-30 16:00:00', 27],
    ['2022-06-30 20:00:00', 27],
    [0, 27],
    [0, 27],
    [0, 27],
    [0, 27],
    [0, 27],
    [0, 27],
];

export const AREA_OPTIONS = {
    chart: {
        type: 'area',
        animations: {
            enabled: false,
        },
        stacked: false,
        zoom: {
            type: 'x',
            enabled: false,
            autoScaleYaxis: true,
        },
    },
    dataLabels: {
        enabled: false,
    },
    markers: {
        size: 5,
    },
    stroke: {
        dashArray: [0, 0, 3, 3],
    },
    fill: {
        type: 'gradient',
        gradient: {
            shade: 'light',
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0,
            opacityTo: 0,
            stops: [0, 90, 100],
        },
    },
    yaxis: {
        title: {
            text: '',
            style: {
                fontSize: '1rem',
                fontWeight: 'bold',
            },
        },
    },
    xaxis: {
        type: 'datetime',
    },
    tooltip: {
        shared: false,
    },
    noData: {
        text: 'No Data',
    },
};

export const AREA__SERIES = [
    {
        name: 'Analytics',
        data: DATE1,
    },
    // {
    //     name: 'Distribution below threshold',
    //     data: DATE2,
    // },
    // {
    //     name: 'AboveThreshold',
    //     data: THRESHOLD_MAX,
    // },
    // {
    //     name: 'Below Threshold',
    //     data: THRESHOLD_MIN,
    // },
];
