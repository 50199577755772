import _ from 'lodash';
import GoogleMapReact from 'google-map-react';
import { useEffect, useState } from 'react';
import { Form, Input, Button, notification } from 'antd';
import { Marker } from '../../../components';
import { createOwnerOrganization, updateProject } from '../../../services';
import SearchAddress from './search-address';

const { TextArea } = Input;

const BasicInfo = ({ onFinish, basicInfo = {}, projectId = null }) => {
    const [form] = Form.useForm();
    const [apiReady, setApiReady] = useState(false);
    const [map, setMap] = useState(null);
    const [mapApi, setMapApi] = useState(null);
    const [address, setAddress] = useState();
    const [zoom, setZoom] = useState();
    const [center, setCenter] = useState([]);
    const [lat, setLat] = useState();
    const [lng, setLng] = useState();
    const [places, setPlaces] = useState();
    const [draggable, setDraggable] = useState();

    const setCurrentLocation = () => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                setCenter([position.coords.latitude, position.coords.longitude]);
                setLat(position.coords.latitude);
                setLng(position.coords.longitude);
            });
        }
    };

    useEffect(() => {
        setCurrentLocation();
    }, []);

    useEffect(() => {
        form.setFieldsValue({ ...basicInfo });
    }, [basicInfo]);

    const handleApiLoaded = (map, maps) => {
        if (map && maps) {
            setApiReady(true);
            setMap(map);
            setMapApi(maps);
        }
    };

    const onNext = async (values) => {
        delete basicInfo.tags;
        delete basicInfo.users;
        if (!_.isEqual(basicInfo, values)) {
            const response = await updateProject(projectId, values);
            notification.success({ description: response.message });
        }
        const ownerOrg = {
            name: values.owner,
        };
        if (!_.isEmpty(values.owner) && basicInfo.owner !== values.owner) {
            const response = await createOwnerOrganization(ownerOrg);
            values.owner = response.data.name;
        } else {
            values.owner = basicInfo.owner;
        }
        onFinish(values);
    };

    const _generateAddress = () => {
        const geocoder = new mapApi.Geocoder();

        geocoder.geocode({ location: { lat: lat, lng: lng } }, (results, status) => {
            console.log(results);
            console.log(status);
            if (status === 'OK') {
                if (results[0]) {
                    setZoom(12);
                    setAddress(results[0].formatted_address);
                } else {
                    window.alert('No results found');
                }
            } else {
                window.alert('Geocoder failed due to: ' + status);
            }
        });
    };

    const onMarkerInteraction = (childKey, childProps, mouse) => {
        setDraggable(true);
        setLat(mouse.lat);
        setLng(mouse.lng);
    };

    const onMarkerInteractionMouseUp = (childKey, childProps, mouse) => {
        setDraggable(true);
        _generateAddress();
    };

    const _onChange = ({ center, zoom }) => {
        setZoom(zoom);
        setCenter(center);
    };

    const _onClick = (value) => {
        setLat(value.lat);
        setLng(value.lng);
    };

    const addPlace = (place) => {
        setPlaces([place]);
        setLat(place.geometry.location.lat());
        setLng(place.geometry.location.lng());
        _generateAddress();
    };

    return (
        <>
            <h2 className="step-heading mb-20">Add Project Information</h2>
            <Form form={form} layout="vertical" onFinish={onNext} initialValues={basicInfo}>
                <Form.Item
                    label="Name"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: 'Name is required',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Owner Organization"
                    name="owner"
                    rules={[
                        {
                            required: false,
                            message: 'Owner is required',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Site Address"
                    name="description"
                    rules={[
                        {
                            required: false,
                            message: 'Site address is required',
                        },
                    ]}
                >
                    {apiReady && <SearchAddress map={map} mapApi={mapApi} addplace={addPlace} />}
                </Form.Item>

                <div style={{ height: '442px', width: '100%' }}>
                    <GoogleMapReact
                        zoom={4}
                        center={center}
                        bootstrapURLKeys={{
                            key: process.env.REACT_APP_GOOGLE_MAP_KEY_V3,
                            libraries: ['places', 'geometry'],
                        }}
                        draggable={draggable}
                        onChange={_onChange}
                        onChildMouseDown={onMarkerInteraction}
                        onChildMouseUp={onMarkerInteractionMouseUp}
                        onChildMouseMove={onMarkerInteraction}
                        //    onChildClick={() => console.log('child click')}
                        onClick={_onClick}
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                    >
                        {lat && lng && <Marker text={address} lat={lat} lng={lng} />}
                    </GoogleMapReact>
                </div>
                <Form.Item className="text-right mb-0">
                    <Button
                        className="btn-lg btn-primary-lg save-btn mt-30"
                        type="primary"
                        htmlType="submit"
                    >
                        Save & Next
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
};

export default BasicInfo;
