import { PROJECT_API_ENUM } from '../utils';
import { http } from '../utils/http';

export const fetchAnalyticsByProject = async (projectId, tagId, shift, startDate, endDate) => {
    try {
        const response = await http.get(
            `/projects/${projectId}/app/${PROJECT_API_ENUM.TAGS}/${tagId}/reading-analytics/?shift=${shift}&start_date=${startDate}&end_date=${endDate}`,
        );
        return response.data;
    } catch (ex) {
        console.log(ex);
    }
};
