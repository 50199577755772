import { FormOutlined } from '@ant-design/icons';
import { Row, Col, Select, notification, message } from 'antd';
import React, { useState } from 'react';
import { Form, Input, Button } from 'antd';
import './help.scss';
import { helpEnquiry } from '../../services/notifications';
const Help = ({ handleOk }) => {
    const formItemLayout = {
        labelCol: { span: 4 },
    };
    const { Option } = Select;

    const prefixSelector = (
        <Form.Item name="prefix" noStyle>
            <Select style={{ width: 70 }}>
                <Option value="+1">+1</Option>
                <Option value="+91">+91</Option>
            </Select>
        </Form.Item>
    );

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const onFinish = async (values) => {
        try {
            const formData = new FormData();
            formData.append('description', values.description);
            formData.append('email', values.email);
            formData.append('name', values.name);
            formData.append('phone_number', values.phone_number);

            setLoading(true);
            const response = await helpEnquiry(formData);
            if (response.success) {
                message.success('The form has been successfully submited');
                handleOk();
            } else {
                message.error('The form is not submited');
            }

            setLoading(false);
        } catch (ex) {
            const apiErrors = ex?.response?.data?.data;
            if (apiErrors) {
                const errors = Object.keys(apiErrors).map((key) => ({
                    name: key,
                    errors: apiErrors[key],
                }));
                form.setFields(errors);
            }
            setLoading(false);
        }
    };

    return (
        <>
            <div className="parentBody">
                <div className="container">
                    <div className="icon">
                        <FormOutlined />
                    </div>
                    <div className="headingBody">
                        <h2>Quick Enquiry</h2>
                        <p>
                            Got a question? Contact us quickly and easily using the following form
                            and we will get back to you ASAP.
                        </p>
                        <Form
                            {...formItemLayout}
                            name="basic"
                            onFinish={onFinish}
                            autoComplete="off"
                            initialValues={{
                                name: '',
                                prefix: '+1',
                            }}
                        >
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        name="email"
                                        label="Email"
                                        rules={[
                                            {
                                                type: 'email',
                                                message: 'The input is not valid E-mail.',
                                            },
                                            {
                                                required: true,
                                                message: 'Please enter your email.',
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item name="name" label="Name">
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        name="phone_number"
                                        label="Phone"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your phone number!',
                                            },
                                        ]}
                                    >
                                        <Input addonBefore={prefixSelector} className="width-100" />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        name="description"
                                        label="Enquiry"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter your enquiry.',
                                            },
                                        ]}
                                    >
                                        <Input.TextArea rows={6} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item>
                                <Button className="buttonSend" type="primary" htmlType="submit">
                                    SEND ENQUIRY
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Help;
