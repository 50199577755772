import { RESET_STATE } from '../common';
import { GET_PERMISSIONS } from './types';

const initialState = {
    permissions: {},
};

export const permissionReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_PERMISSIONS:
            return { ...state, permissions: payload };

        case RESET_STATE:
            return initialState;

        default:
            return state;
    }
};
