/* eslint-disable jsx-a11y/anchor-is-valid */
import { DollarTwoTone, PlusOutlined, DownloadOutlined } from '@ant-design/icons';
import WebViewer from '@pdftron/webviewer';
import {
    Avatar,
    Badge,
    Button,
    Card,
    Col,
    Collapse,
    Comment,
    Empty,
    Form,
    Input,
    List,
    Popconfirm,
    Popover,
    Row,
    Select,
    Slider,
    Space,
    Spin,
    Tooltip,
    message,
} from 'antd';
import React, { memo, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import Logo from '../../../../assets/images/logo.png';
import Low from '../../../../assets/images/low.svg';
import Lowest from '../../../../assets/images/lowest.svg';
import Medium from '../../../../assets/images/medium.svg';
import High from '../../../../assets/images/high.svg';
import Highest from '../../../../assets/images/highest.svg';
import {
    fetchMarkup,
    fetchMarkupsDetails,
    fetchProjectDetail,
    updateMarkup,
    createComment,
    generateCsvReport,
} from '../../../../services';
import { DISPLAY_DATETIME_FORMAT, makeAvatar, PROJECT_API_ENUM } from '../../../../utils';
import './kanban.scss';
import { content } from './static-content';

const { Panel } = Collapse;
const { Option } = Select;

const costValue = {
    '-3': '-$',
    0: '0',
    3: '+$',
};

const delayValue = {
    '-3': '-',
    0: '0',
    3: '+',
};

const complexity = {
    1: 'Low',
    2: 'Medium',
    3: 'High',
};

const popoverData = (filename, markup) => {
    if (filename === 'mechanical_redacted.pdf') {
        return content[markup];
    } else {
        return (
            <div
                style={{
                    fontSize: '12px',
                }}
            >
                <h3>Current Estimates</h3>
                <p>
                    <span style={{ fontSize: '16px' }}>
                        <DollarTwoTone twoToneColor="#61bd4f" />
                        <DollarTwoTone twoToneColor="#61bd4f" />
                    </span>{' '}
                    <Badge
                        count={'Delayed by +25 days'}
                        style={{ backgroundColor: '#eb5a46', marginBottom: '4px' }}
                    />
                </p>
                <h3> Recommendations </h3>
                <p>1. Use Circuit Breaker Of ABB</p>
                <p>
                    <span style={{ fontSize: '16px' }}>
                        <DollarTwoTone twoToneColor="#61bd4f" />
                        <DollarTwoTone twoToneColor="#61bd4f" />
                        <DollarTwoTone twoToneColor="#61bd4f" />
                    </span>{' '}
                    <Badge
                        count={'Finsh Early 15 days'}
                        style={{ backgroundColor: '#61bd4f', marginBottom: '4px' }}
                    />
                </p>
                <p>2. Use GE 2000A</p>
                <p>
                    <span style={{ fontSize: '16px' }}>
                        <DollarTwoTone twoToneColor="#61bd4f" />
                    </span>{' '}
                    <Badge
                        count={'Finish on Time'}
                        style={{ backgroundColor: '#fc9f19', marginBottom: '4px' }}
                    />
                </p>{' '}
            </div>
        );
    }
};

const Editor = ({ onChange, onSubmit, submitting, value, markId, markupId, markupIndex }) => (
    <div className="full-width">
        <Form.Item>
            <Input.TextArea rows={3} onChange={onChange} value={value} />
        </Form.Item>
        <Form.Item>
            <Button
                htmlType="submit"
                loading={submitting}
                onClick={() => onSubmit(markId, markupId, markupIndex)}
                type="primary"
                size="small"
                style={{
                    float: 'right',
                }}
            >
                Add Comment
            </Button>
        </Form.Item>
    </div>
);

const Kanban = memo(({ projectId, pkgId, markupDetail, setMarkupDetail }) => {
    const [form] = Form.useForm();
    const { user } = useSelector(({ auth }) => auth);
    const viewer = useRef(null);
    const [pdfInstance, setPdfInstance] = useState(null);
    const [loader, setLoader] = useState(false);
    const [markUploader, setMarkUploader] = useState(false);
    const [markUps, setMarkups] = useState({});
    const [mainLoader, setMainLoader] = useState(false);
    const [users, setUsers] = useState([]);
    const [displayAddMembers, setDisplayAddMembers] = useState('');
    const [comment, setComment] = useState('');
    const [commentLoader, setCommentLoader] = useState(false);

    useEffect(() => {
        if (!pdfInstance) {
            pdfWebViewer();
        }
        fetchUsers();
    }, []);

    useEffect(() => {
        fetchKanbanMarkup();
        if (pdfInstance) pdfInstance.UI.closeDocument();
    }, [pkgId]);

    const fetchUsers = async () => {
        const response = await fetchProjectDetail(PROJECT_API_ENUM.USERS, projectId);
        setUsers([...response.data]);
    };
    const generateCSV = async () => {
        try {
            if (projectId && pkgId) {
                setMainLoader(true);
                const response = await generateCsvReport(projectId, pkgId);
                if (response.data) {
                    const link = document.createElement('a');
                    link.href = response.data;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    setMainLoader(false);
                } else {
                    message.success('No data to generate');
                    setMainLoader(false);
                }
            } else {
                message.success('No data to generate');
            }
        } catch (error) {
            message.error('Technical issue');
        }
    };

    const fetchKanbanMarkup = async () => {
        try {
            setMainLoader(true);
            const response = await fetchMarkup(projectId, pkgId);
            let dataMarkups = {};
            if (response.data.length) {
                response.data.map((markup, index) => {
                    dataMarkups[markup.id] = [];
                });
            }
            setMarkupDetail(response.data);
            setMarkups({ ...dataMarkups });
            setMainLoader(false);
        } catch (error) {
            setMarkupDetail([]);
            setMarkups({});
            setMainLoader(false);
            console.log(error);
        }
    };

    const onChangeCollapse = async (activeKeys) => {
        setMarkUploader(true);
        if (activeKeys.length) {
            const markupId = markupDetail[activeKeys[activeKeys.length - 1]];
            const getMarkupsDetails = await fetchMarkupsDetails(markupId?.id);
            setMarkups({ ...markUps, [markupId.id]: [...getMarkupsDetails.data] });
        }
        setMarkUploader(false);
    };

    const pdfWebViewer = () => {
        WebViewer(
            {
                path: '/webviewer/lib',
            },
            viewer.current,
        )
            .then((instance) => {
                setPdfInstance(instance);
            })
            .catch((err) => {
                console.log('err', err);
            });
    };

    const pdfOpen = (page, pdfUrl) => {
        try {
            setLoader(true);
            pdfInstance.loadDocument(pdfUrl);
            pdfInstance.Core.documentViewer.addEventListener('documentLoaded', () => {
                pdfInstance.Core.documentViewer.setCurrentPage(page);
                setLoader(false);
            });
        } catch (e) {
            setLoader(false);
        }
    };

    const selectOptions = {
        1: 'card-select-red',
        2: 'card-select-yellow',
        3: 'card-select-green',
    };

    const onFieldChange = async (markId, markupId, markupIndex, value, keyName) => {
        const data = [...markUps[markId]];

        updateMarkup(markupId, { [keyName]: value });
        data[markupIndex] = { ...markUps[markId][markupIndex], [keyName]: value };

        setMarkups({
            ...markUps,
            [markId]: [...data],
        });
    };

    const addMemberForm = (markId) => {
        setDisplayAddMembers(markId);
    };

    const addAndRemoveAssignee = async (markId, markup, markupIndex, assigneeId, type) => {
        const data = [...markUps[markId]];

        let assignee;
        if (type === 'REMOVE') {
            assignee = markup.assignee
                .filter((assigne) => assigne.id != assigneeId)
                .map((assignee) => assignee.id);
        } else {
            assignee = [...markup.assignee.map((assigne) => assigne.id), assigneeId];
        }
        const response = await updateMarkup(markup.id, { assignee: assignee });
        data[markupIndex] = { ...markUps[markId][markupIndex], assignee: response.data.assignee };

        form.resetFields();
        setDisplayAddMembers('');
        setMarkups({ ...markUps, [markId]: [...data] });
    };

    const addComment = async (markId, markupId, markupIndex) => {
        setCommentLoader(true);

        const data = [...markUps[markId]];

        const response = await createComment({ user: user.id, text: comment, task: markupId });

        data[markupIndex] = {
            ...markUps[markId][markupIndex],
            comments: [...markUps[markId][markupIndex].comments, { ...response.data, user: user }],
        };

        setMarkups({ ...markUps, [markId]: [...data] });

        setCommentLoader(false);
        setComment('');
    };

    const addAssigneeContent = (markId, markup, markupIndex) => {
        return (
            <Form
                form={form}
                style={{ width: 200 }}
                layout="vertical"
                onFinish={(values) =>
                    addAndRemoveAssignee(markId, markup, markupIndex, values.assignee_id, 'ADD')
                }
            >
                <Form.Item
                    name="assignee_id"
                    rules={[{ required: true, message: 'Please select' }]}
                >
                    <Select
                        showSearch
                        placeholder="Please select"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().includes(input.toLowerCase())
                        }
                        allowClear
                        style={{ width: '100%' }}
                    >
                        {users.map((user) => (
                            <Option key={user.id} value={user.id}>
                                {`${user.first_name} ${user.last_name}`}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <div className="text-right mt-15">
                    <Space>
                        <Button type="default" onClick={() => setDisplayAddMembers('')}>
                            Cancel
                        </Button>
                        <Button type="primary" htmlType="submit">
                            Add
                        </Button>
                    </Space>
                </div>
            </Form>
        );
    };

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    width: '100%',
                }}
            >
                <div className="custom-scroll-dark  card-viewer">
                    <Button
                        style={{ marginBottom: '2%' }}
                        type="primary"
                        shape="round"
                        icon={<DownloadOutlined />}
                        onClick={generateCSV}
                    >
                        Generate Report
                    </Button>
                    <Spin
                        spinning={mainLoader}
                        style={{ minHeight: 200 }}
                        tip={mainLoader ? 'Please wait, it will take a while' : ''}
                    >
                        <Collapse className="collapse-padding" onChange={onChangeCollapse}>
                            {markupDetail?.length ? (
                                markupDetail.map((mark, markupIndex) => {
                                    return (
                                        <Panel header={mark.file_name} key={markupIndex}>
                                            {markUps[mark.id] &&
                                                markUps[mark.id].map((mark_up, index) => (
                                                    <Row key={index} className="mt-15">
                                                        <Col span={24}>
                                                            <Card
                                                                size="small"
                                                                title={
                                                                    <span
                                                                        onClick={() =>
                                                                            pdfOpen(
                                                                                mark_up.page,
                                                                                mark.signed_url,
                                                                            )
                                                                        }
                                                                    >
                                                                        {mark_up.mark_up}
                                                                    </span>
                                                                }
                                                                extra={
                                                                    <Select
                                                                        className={`card-select ${
                                                                            selectOptions[
                                                                                mark_up.task_status
                                                                            ]
                                                                        }`}
                                                                        onChange={(val) =>
                                                                            onFieldChange(
                                                                                mark.id,
                                                                                mark_up.id,
                                                                                index,
                                                                                val,
                                                                                'task_status',
                                                                            )
                                                                        }
                                                                        value={mark_up.task_status}
                                                                    >
                                                                        <Option
                                                                            value={1}
                                                                            className="button-red"
                                                                        >
                                                                            Todo
                                                                        </Option>
                                                                        <Option
                                                                            value={2}
                                                                            className="button-yellow"
                                                                        >
                                                                            Doing
                                                                        </Option>
                                                                        <Option
                                                                            value={3}
                                                                            className="button-green"
                                                                        >
                                                                            Done
                                                                        </Option>
                                                                    </Select>
                                                                }
                                                                style={{ width: '38vh' }}
                                                                actions={[
                                                                    mark_up.assignee && (
                                                                        <div className="package-team-members">
                                                                            <Avatar.Group>
                                                                                {mark_up.assignee.map(
                                                                                    (
                                                                                        member,
                                                                                        idx,
                                                                                    ) => (
                                                                                        <Tooltip
                                                                                            key={
                                                                                                user?.email
                                                                                            }
                                                                                            title={`${user?.first_name[0]} ${user?.last_name[0]}`}
                                                                                        >
                                                                                            <Popconfirm
                                                                                                onConfirm={() =>
                                                                                                    addAndRemoveAssignee(
                                                                                                        mark.id,
                                                                                                        mark_up,
                                                                                                        index,
                                                                                                        member.id,
                                                                                                        'REMOVE',
                                                                                                    )
                                                                                                }
                                                                                                title="Are your want to remove this user?"
                                                                                            >
                                                                                                <Avatar
                                                                                                    className="circle-icon"
                                                                                                    src={makeAvatar(
                                                                                                        member?.first_name,
                                                                                                        member?.last_name,
                                                                                                    )}
                                                                                                />
                                                                                            </Popconfirm>
                                                                                        </Tooltip>
                                                                                    ),
                                                                                )}

                                                                                <Tooltip title="Add Member">
                                                                                    <Popover
                                                                                        placement="rightTop"
                                                                                        title="Add New Member"
                                                                                        content={addAssigneeContent(
                                                                                            mark.id,
                                                                                            mark_up,
                                                                                            index,
                                                                                        )}
                                                                                        trigger="click"
                                                                                        onClick={(
                                                                                            e,
                                                                                        ) =>
                                                                                            addMemberForm(
                                                                                                mark_up.id,
                                                                                            )
                                                                                        }
                                                                                        visible={
                                                                                            displayAddMembers ===
                                                                                            mark_up.id
                                                                                        }
                                                                                    >
                                                                                        <Avatar
                                                                                            className="circle-icon"
                                                                                            style={{
                                                                                                color: '#f56a00',
                                                                                                backgroundColor:
                                                                                                    '#fde3cf',
                                                                                            }}
                                                                                            onClick={() =>
                                                                                                setDisplayAddMembers(
                                                                                                    user,
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <PlusOutlined />
                                                                                        </Avatar>
                                                                                    </Popover>
                                                                                </Tooltip>
                                                                            </Avatar.Group>
                                                                        </div>
                                                                    ),

                                                                    <Select
                                                                        style={{
                                                                            width: '80%',
                                                                        }}
                                                                        value={mark_up.priority}
                                                                        onChange={(val) =>
                                                                            onFieldChange(
                                                                                mark.id,
                                                                                mark_up.id,
                                                                                index,
                                                                                val,
                                                                                'priority',
                                                                            )
                                                                        }
                                                                    >
                                                                        <Option value={1}>
                                                                            <span>
                                                                                <img
                                                                                    src={Lowest}
                                                                                    alt="Lowest"
                                                                                    width={22}
                                                                                    height={22}
                                                                                />
                                                                            </span>
                                                                        </Option>
                                                                        <Option value={2}>
                                                                            <span>
                                                                                <img
                                                                                    src={Low}
                                                                                    alt="Low"
                                                                                    width={22}
                                                                                    height={22}
                                                                                />
                                                                            </span>
                                                                        </Option>
                                                                        <Option value={3}>
                                                                            <span>
                                                                                <img
                                                                                    src={Medium}
                                                                                    alt="Medium"
                                                                                    width={22}
                                                                                    height={22}
                                                                                />
                                                                            </span>
                                                                        </Option>
                                                                        <Option value={4}>
                                                                            <span>
                                                                                <img
                                                                                    src={High}
                                                                                    alt="High"
                                                                                    width={22}
                                                                                    height={22}
                                                                                />
                                                                            </span>
                                                                        </Option>
                                                                        <Option value={5}>
                                                                            <span>
                                                                                <img
                                                                                    src={Highest}
                                                                                    alt="Highest"
                                                                                    width={22}
                                                                                    height={22}
                                                                                />
                                                                            </span>
                                                                        </Option>
                                                                    </Select>,

                                                                    <Popover
                                                                        content={() =>
                                                                            popoverData(
                                                                                mark.file_name,
                                                                                mark_up.mark_up,
                                                                            )
                                                                        }
                                                                        placement="right"
                                                                        trigger="hover"
                                                                    >
                                                                        <img
                                                                            src={Logo}
                                                                            alt="Esitmates"
                                                                            width={30}
                                                                            height={30}
                                                                            style={{
                                                                                backgroundColor:
                                                                                    '#233044',
                                                                                borderRadius: '50%',
                                                                            }}
                                                                        />
                                                                    </Popover>,
                                                                ]}
                                                            >
                                                                <br />

                                                                <div className="slider-div">
                                                                    <span
                                                                        style={{
                                                                            marginRight: '2%',
                                                                            width: '35%',
                                                                        }}
                                                                    >
                                                                        Complexity
                                                                    </span>
                                                                    <Slider
                                                                        marks={complexity}
                                                                        defaultValue={
                                                                            mark_up.complexity
                                                                        }
                                                                        max={3}
                                                                        min={1}
                                                                        style={{
                                                                            width: '65%',
                                                                            padding: '0px',
                                                                            marginTop: '7px',
                                                                        }}
                                                                        onChange={(val) =>
                                                                            onFieldChange(
                                                                                mark.id,
                                                                                mark_up.id,
                                                                                index,
                                                                                val,
                                                                                'complexity',
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                                <br />

                                                                <div className="slider-div">
                                                                    <span
                                                                        style={{
                                                                            marginRight: '2%',
                                                                            width: '35%',
                                                                        }}
                                                                    >
                                                                        Cost
                                                                    </span>

                                                                    <Slider
                                                                        marks={costValue}
                                                                        defaultValue={mark_up.cost}
                                                                        max={3}
                                                                        min={-3}
                                                                        style={{
                                                                            width: '65%',
                                                                            padding: '0px',
                                                                            marginTop: '7px',
                                                                        }}
                                                                        onChange={(val) =>
                                                                            onFieldChange(
                                                                                mark.id,
                                                                                mark_up.id,
                                                                                index,
                                                                                val,
                                                                                'cost',
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                                <br />
                                                                <div className="slider-div">
                                                                    <span
                                                                        style={{
                                                                            marginRight: '2%',
                                                                            width: '35%',
                                                                        }}
                                                                    >
                                                                        Delay
                                                                    </span>

                                                                    <Slider
                                                                        marks={delayValue}
                                                                        defaultValue={
                                                                            mark_up.time_impact
                                                                        }
                                                                        max={3}
                                                                        min={-3}
                                                                        style={{
                                                                            width: '65%',
                                                                            padding: '0px',
                                                                            marginTop: '7px',
                                                                        }}
                                                                        onChange={(val) =>
                                                                            onFieldChange(
                                                                                mark.id,
                                                                                mark_up.id,
                                                                                index,
                                                                                val,
                                                                                'time_impact',
                                                                            )
                                                                        }
                                                                    />
                                                                </div>

                                                                <br />
                                                            </Card>
                                                        </Col>
                                                        <Collapse
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                        >
                                                            <Panel header="Comments">
                                                                <List
                                                                    className="comment-list"
                                                                    header={`${mark_up.comments.length} replies`}
                                                                    itemLayout="horizontal"
                                                                    dataSource={mark_up.comments}
                                                                    renderItem={(item) => (
                                                                        <li>
                                                                            <Comment
                                                                                author={
                                                                                    item.user
                                                                                        .first_name +
                                                                                    item.user
                                                                                        .last_name
                                                                                }
                                                                                avatar={makeAvatar(
                                                                                    item.user
                                                                                        .first_name +
                                                                                        item.user
                                                                                            .last_name,
                                                                                )}
                                                                                content={item.text}
                                                                                datetime={moment(
                                                                                    item.createdAt,
                                                                                ).format(
                                                                                    DISPLAY_DATETIME_FORMAT,
                                                                                )}
                                                                            />
                                                                        </li>
                                                                    )}
                                                                />
                                                                <Editor
                                                                    onChange={(e) =>
                                                                        setComment(e.target.value)
                                                                    }
                                                                    onSubmit={addComment}
                                                                    submitting={commentLoader}
                                                                    value={comment}
                                                                    markId={mark.id}
                                                                    markupId={mark_up.id}
                                                                    markupIndex={index}
                                                                />
                                                            </Panel>
                                                        </Collapse>
                                                    </Row>
                                                ))}
                                        </Panel>
                                    );
                                })
                            ) : (
                                <div
                                    style={{
                                        textAlign: 'center',
                                        marginTop: 'calc(77vh / 2 )',
                                    }}
                                >
                                    <Empty description="Kanban data not found" />
                                </div>
                            )}
                        </Collapse>
                    </Spin>
                </div>

                <div className="site-card-border-less-wrapper pdf-viewer">
                    <Spin spinning={loader} tip={loader ? 'Please wait, it will take a while' : ''}>
                        <div className="webviewer" ref={viewer} style={{ height: '100vh' }}></div>
                    </Spin>
                </div>
            </div>
        </>
    );
});

export default Kanban;
