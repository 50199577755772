import {
    PACKAGE_LIST,
    GET_ALL_PACKAGES_INITIATE,
    GET_ALL_PACKAGES_SUCCESS,
    ADD_PACKAGE_TO_LIST,
} from './types';
import { fetchAllPackages } from '../../services';

export const setPackage = (data) => (dispatch) => {
    dispatch({ type: PACKAGE_LIST, payload: data });
};

export const addPackageToList = (payload) => async (dispatch) => {
    dispatch({ type: ADD_PACKAGE_TO_LIST, payload });
};
