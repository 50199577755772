export const DATE_FORMAT = 'DD-MM-YYYY';
export const DISPLAY_DATE_FORMAT = 'MMM DD, YYYY';
export const DISPLAY_DATETIME_FORMAT = 'MMM DD, YYYY  h:mm a';
export const CSV_DATE_FORMAT = 'MM/DD/YYYY';
export const INPUT_DATE_FORMAT = 'MM-DD-YYYY';
export const SERVER_DATE_FORMAT = 'YYYY-MM-DD';

export const DATES_KEYS = [
    'order_date',
    'approval_date',
    'release_date',
    'installation_date',
    'ship_date',
    'test_date',
    'pm_start_internal',
    'pm_start_external',
    'pm_end_external',
    'warranty_date',
];

export const PROJECT_ATTACHMENTS = [
    {
        heading: 'Electrical Packages',
        key: 'electricalPackages',
        dir_key: 'electrical_packages',
        order: 1,
    },
    {
        heading: 'Addendums',
        key: 'addendums',
        dir_key: 'addendums',
        order: 2,
    },
    {
        heading: 'Power Studies',
        key: 'powerStudies',
        dir_key: 'power_studies',
        order: 3,
    },
    {
        heading: 'Others',
        key: 'others',
        dir_key: 'others',
        order: 4,
    },
    {
        heading: 'Single Lines',
        key: 'single_lines',
        dir_key: 'single_lines',
        order: 5,
    },
    {
        heading: 'Site Layout',
        key: 'site_layout',
        dir_key: 'site_layout',
        order: 6,
    },
];

export const DATES_KEYS_WITH_VALUES = [
    { key: 'approval_date', value: 2 },
    { key: 'release_date', value: 3 },
    // { key: 'final_date', value: 4 },
    { key: 'ship_date', value: 5 },
    // { key: 'test_date', value: 6 },
];

export const DATE_STATUSES = [
    {
        label: 'Pre Approval',
        value: 1,
    },
    {
        label: 'Approval Done',
        value: 2,
    },
    {
        label: 'Release Done',
        value: 3,
    },
    // {
    //     label: 'Final Done',
    //     value: 4,
    // },
    // {
    //     label: 'Test Done',
    //     value: 5,
    // },
    {
        label: 'Ship Done',
        value: 6,
    },
];

export const DATE_FIELDS = [
    'order_date',
    'approval_date',
    'release_date',
    'final_date',
    'installation_date',
    'test_date',
    'ship_date',
    'pm_start_internal',
    'pm_start_external',
    'pm_end_external',
    'warranty_date',
];

export const MARKUP_COMMENTS = [
    {
        label: 'Approved',
        value: 1,
    },
    {
        label: 'Approved with comment',
        value: 2,
    },
    {
        label: 'Resubmit',
        value: 3,
    },
    // {
    //     label: 'Changes needed',
    //     value: 4,
    // },
    // {
    //     label: 'More info needed',
    //     value: 5,
    // },
];

export const COMMENTS_VALUE = {
    1: 'Approved',
    2: 'Approved with comment',
    3: 'Resubmit',
    // 4: 'Changes needed',
    // 5: 'More info needed',
};

export const TAGS_CSV_HEADERS = [
    'Reference #',
    'Tag',
    'Description',
    'Manufacturer',
    'Order Date',
    'Approval Drawings',
    'Release For Manufacture',
    'Final Date',
    'Test Date',
    'Ship Date',
];

export const DR_TAGS_CSV_HEADERS = ['Serial #', 'Checkpoint', 'Description'];

export const PACKAGE_MENU = ['Review', 'Manufacturing', 'Shipment', 'Operations'];

export const APPROVAL_STATUSES = {
    ORDERED: 1,
    SAVE_DRAFT: 2,
    SEND_FOR_APPROVAL: 3,
};

export const DISPLAY_SERVICE_HISTORY_STATUS = {
    0: 'Ongoing',
    1: 'Done',
    2: 'Delayed',
    3: 'Skip',
};

export const SERVICE_HISTORY_STATUS = {
    ONGOING: 0,
    DONE: 1,
    DELAYED: 2,
    SKIP: 3,
};

export const OPERATIONS_DATE_FIELDS = [
    'installation_date',
    'created_at',
    'finished_at',
    'due_date',
];

export const MAINTENANCE_INVENTORY_STATUSES = {
    1: 'Available',
    2: 'Low',
    3: 'Not Available',
};

export const OPERATIONS_COMMON_TEXT = {
    installation_date: 'Installation date:',
    maintenance: 'Maintenance:',
    serial_number: 'Serial number:',
    last_Preventive_Maintenance: 'Last preventive maintenance:',
    next_Due_Date: 'Next due on:',
};

export const DEFINITIONS = {
    TAG_NAME:
        'TAG is used to identify the equipment, normally what the consultant has labeled the equipment on the plans',
    APPROVAL_DATE:
        'If date of approval is known or in the past, please put here. Equipment with Approval requires review and approval of the manufacturers drawings by consultant or other before put into production',
    RELEASE_DATE:
        'If date of release is known, please enter here. Release is short for Release for Manufacture and is date which the manufacture gets the go ahead to start production of equipment identified in release.',
    TEST_DATE:
        'If date of test is known, please enter here. This is the optional date which is arranged with Manufacture to test the equipment at end of production. Sometimes called witness test when others are present to see testing procedures.',
    SHIP_DATE:
        'If date of shipment is known, please enter here. This is date which the equipment is expected to ship after all production and test are finished.',
};

export const MARKUP_TYPES = {
    INFORMATION: 'Information',
    APPROVAL: 'Approval',
};

export const DOCUMENT_CATEGORIES = {
    PROJECT_DOCUMENTS: 'project_documents',
    EQUIPMENT_DOCUMENTS: 'equipment_documents',
};

export const PROJECT_SUB_FOLDERS = {
    ELECTRICAL_PACKAGES: 'electrical_packages',
    ADDENDUMS: 'addendums',
    POWER_STUDIES: 'power_studies',
    OTHERS: 'others',
    SINGLE_LINES: 'single_lines',
    SITE_PLAN: 'site_plan',
    PRIVATE: 'private',
};

export const EQUIPMENT_SUB_FOLDERS = {
    FINAL_DRAWINGS: 'final_drawings',
    INSTRUCTION_MANUAL: 'instruction_manual',
    TEST_REPORTS: 'test_reports',
    IMAGES: 'images',
    VIDEOS: 'videos',
};

export const APPROVAL_TYPES = {
    APPROVAL_DRAWINGS: 'approval_drawings',
    INFORMATION_APPROVAL: 'information_for_approval',
    CONSTRUCTION_DRAWINGS: 'construction_drawings',
};

export const CRITICAL_TAGS_COUNT = '5';

export const CALENDAR_COLOR = {
    red: '#d73b32',
    orange: '#EF8200',
    disabled: '#D3D3D3',
    txtBgColor: '#04abfd',
};
export const SHIFT_OPTIONS = [
    // { id: 2, name: 'All' },
    { id: 0, name: 'Day' },
    { id: 1, name: 'Night' },
];
