import _ from 'lodash';
import GoogleMapReact from 'google-map-react';
import { Button, Form, Input, notification } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Marker } from '../../../components';
import { createProject, updateProject } from '../../../services';
import { PROJECT_API_ENUM } from '../../../utils';
import SearchAddress from './search-address';

const BasicInfo = ({
    onFinish,
    basicInfo = {},
    projectId = null,
    setLoader = () => {},
    history,
    modify,
}) => {
    const { operations } = useSelector(({ common }) => common);
    const { user } = useSelector(({ auth }) => auth);
    const [form] = Form.useForm();
    const [apiReady, setApiReady] = useState(false);
    const [map, setMap] = useState(null);
    const [mapApi, setMapApi] = useState(null);
    const [address, setAddress] = useState();
    const [center, setCenter] = useState([]);
    const [lat, setLat] = useState();
    const [lng, setLng] = useState();
    const [draggable, setDraggable] = useState();
    const [isBtnDisabled, setIsBtnDisabled] = useState(true);

    const setCurrentLocation = () => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                setCenter([position.coords.latitude, position.coords.longitude]);
                setLat(position.coords.latitude);
                setLng(position.coords.longitude);
            });
        }
    };

    useEffect(() => {
        form.setFieldsValue({ ...basicInfo });
        if (basicInfo?.address) {
            setIsBtnDisabled(false);
        }
        if (basicInfo?.latitude && basicInfo?.longitude) {
            setCenter([basicInfo.latitude, basicInfo.longitude]);
            setLat(basicInfo.latitude);
            setLng(basicInfo.longitude);
        } else {
            setCurrentLocation();
        }
    }, [basicInfo]);

    const handleApiLoaded = (map, maps) => {
        if (map && maps) {
            setApiReady(true);
            setMap(map);
            setMapApi(maps);
        }
    };

    const onNext = async (values) => {
        setLoader(true);
        delete basicInfo.tags;
        delete basicInfo.users;
        values.latitude = lat;
        values.longitude = lng;
        values.branch = user?.branch || null;
        let response;
        if (!_.isEqual(basicInfo, values)) {
            if (projectId) {
                response = await updateProject(PROJECT_API_ENUM.BASIC_INFO, projectId, values);
                notification.success({
                    message: 'Project Updated',
                    description: 'The project basic info has been successfully updated',
                });
            } else {
                response = await createProject({ ...values, operations });
                notification.success({
                    message: 'Project Created',
                    description: 'The project has been successfully created',
                });
                history.push({
                    pathname: `/projects/${response.data.id}`,
                    search: '?activeTab=2',
                });
            }
        }
        setLoader(false);

        onFinish(values);
    };

    const _generateAddress = (_lat, _lng) => {
        const geocoder = new mapApi.Geocoder();
        geocoder.geocode({ location: { lat: _lat, lng: _lng } }, (results, status) => {
            if (status === 'OK') {
                if (results[0]) {
                    setAddress(results[0].formatted_address);
                    form.setFieldsValue({ address: results[0].formatted_address });
                } else {
                    window.alert('No results found');
                }
            } else {
                window.alert('Geocoder failed due to: ' + status);
            }
        });
    };

    const onMarkerInteraction = (childKey, childProps, mouse) => {
        const { lat, lng } = mouse;
        setDraggable(true);
        setLat(lat);
        setLng(lng);
        _generateAddress(lat, lng);
    };

    const onMarkerInteractionMouseUp = (childKey, childProps, mouse) => {
        const { lat, lng } = mouse;
        setDraggable(true);
        setLat(lat);
        setLng(lng);
        _generateAddress(lat, lng);
    };

    const _onChange = ({ center, zoom }) => {
        setCenter(center);
    };

    const _onClick = (value) => {
        const { lat, lng } = value;
        setLat(lat);
        setLng(lng);
        _generateAddress(lat, lng);
    };

    const addPlace = (place) => {
        setLat(place.geometry.location.lat());
        setLng(place.geometry.location.lng());
        setAddress(place.formatted_address);
        form.setFieldsValue({
            address: place.formatted_address,
        });
    };

    const isFilled = (myref) => {
        if (myref.current.value == '') {
            setIsBtnDisabled(true);
        } else if (myref.current.value !== '' && myref.current.value !== undefined) {
            setIsBtnDisabled(false);
        }
    };

    return (
        <>
            <h2 className="step-heading mb-20">Add Project Information</h2>
            <Form form={form} layout="vertical" onFinish={onNext} initialValues={basicInfo}>
                <Form.Item
                    label="Name"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: 'Required',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Description"
                    name="description"
                    rules={[
                        {
                            required: false,
                            message: 'Required',
                        },
                    ]}
                >
                    <Input.TextArea />
                </Form.Item>

                <Form.Item
                    label="Owner Organization"
                    tooltip="The company to which the equipment will be owned (also known as End User)"
                    name="owner"
                    rules={[
                        {
                            required: false,
                            message: 'Owner is required',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Site Address"
                    name="address"
                    rules={[
                        {
                            required: modify ? true : false,
                            message: 'Site address is required',
                        },
                    ]}
                >
                    {apiReady && (
                        <SearchAddress
                            map={map}
                            mapApi={mapApi}
                            addplace={addPlace}
                            onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : '')}
                            disableBtn={isFilled}
                        />
                    )}
                </Form.Item>

                <div style={{ height: '442px', width: '100%' }}>
                    <GoogleMapReact
                        zoom={4}
                        center={center}
                        bootstrapURLKeys={{
                            key: process.env.REACT_APP_GOOGLE_MAP_KEY_V3,
                            libraries: ['places', 'geometry'],
                        }}
                        onChange={_onChange}
                        draggable={false}
                        // draggable={draggable}
                        // onChildMouseDown={onMarkerInteraction}
                        // onChildMouseUp={onMarkerInteractionMouseUp}
                        // onChildMouseMove={onMarkerInteraction}
                        onClick={_onClick}
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                    >
                        {lat && lng && <Marker text={address} lat={lat} lng={lng} />}
                    </GoogleMapReact>
                </div>
                <Form.Item className="text-right mb-0">
                    <Button
                        disabled={modify && isBtnDisabled}
                        className="btn-lg btn-primary-lg save-btn mt-30"
                        type="primary"
                        htmlType="submit"
                    >
                        Save & Next
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
};

export default BasicInfo;
