import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Layout, Card, Breadcrumb, Tabs, Spin, Collapse, Typography } from 'antd';
import { LeftOutlined, RightOutlined, CaretRightOutlined } from '@ant-design/icons';
import { SelectPackage } from '../../../tags/select-package';
import { CometChatMessages } from '../../../../../lib/comet-chat';
import {
    fetchPackageDetails,
    fetchPackageOperationsDetails,
    fetchTagData,
    saveEquipmentDocs,
} from '../../../../services';
import { UsersIcon } from '../../../../components/users-icon';
import { EquipmentDocs } from '../../../tags/equipment_docs';
import { getEquipmentDocuments, uploadEquipmentDocuments } from '../../../../store/equipment-docs';
import { setActiveProjectTab } from '../../../../store/common';
import ServiceHistory from './service-history';
import Status from './status';
import CriticalParts from './critical-parts';
import '../../../../assets/styles/components/package-detail.scss';
import { isEmpty } from 'lodash';
import { makeAvatar } from '../../../../utils';
import moment from 'moment';
import { fetchTagHistoryDatewise, fetchWebTagDetails } from '../../../../services/package-detail';
import Analytics from './analytics';

const { Content } = Layout;
const { TabPane } = Tabs;
const { Panel } = Collapse;
const { Text } = Typography;

const PackageUser = ({ activeTag = {}, packageDetail, onTagChange = () => {} }) => (
    <UsersIcon
        onTagChange={onTagChange}
        activeTag={activeTag}
        tags={packageDetail?.tags}
        teamMembers={packageDetail?.team_members}
    />
);

const OperationsPackageDetail = ({ history }) => {
    const dispatch = useDispatch();
    const { pkgId, projectId } = useParams();
    const { allPackages } = useSelector(({ packages }) => packages);
    const [activeTab, setActiveTab] = useState('1');
    const [activeTag, setActiveTag] = useState('');
    const [packageDetail, setPackageDetail] = useState({});
    const [loading, setLoading] = useState(false);
    const [check, setCheck] = useState(true);
    const [lastRoundData, setLastRoundData] = useState({});
    const [historyData, setHistoryData] = useState({});
    const [tagData, setTagData] = useState([]);
    const [tagComments, setTagComments] = useState([]);
    const [tagImages, setTagImages] = useState([]);
    const [issues, setIssues] = useState([]);
    const [activeTagDropDown, setActiveTagDropDown] = useState('');

    const getOperationsPackageDetail = async () => {
        try {
            setLoading(true);
            const response = await fetchPackageDetails(projectId, pkgId);
            setTagData(response.data.tags);
            setActiveTag(response.data.tags[0]);
            setActiveTagDropDown(response.data.tags[0].id);
            setPackageDetail(response.data);

            setLoading(false);
        } catch (ex) {
            setLoading(false);
        }
    };

    useEffect(() => {
        Promise.all([getOperationsPackageDetail(), getTagLastRound(), getWebTagHistory()]);
    }, [pkgId]);

    useEffect(() => {
        getTagLastRound();
        getWebTagHistory();
    }, [activeTagDropDown]);

    const onTagChange = (tagId) => {
        const tag = packageDetail.tags.find((tag) => tag.id === tagId);
        setActiveTagDropDown(tagId);
        setActiveTag({ ...tag });
    };

    const getWebTagDetails = async (tagId, section, startDate, endDate) => {
        return await fetchWebTagDetails(projectId, tagId, section, startDate, endDate);
    };

    const getWebTagHistory = async (startDate = '', endDate = '') => {
        const defaultRange = {
            startDate: moment().subtract(30, 'days').format('yyyy-MM-DD'),
            endDate: moment().format('yyyy-MM-DD'),
        };
        if (!isEmpty(activeTagDropDown)) {
            const [history, issues, docs, comments] = await Promise.all([
                getWebTagDetails(
                    activeTagDropDown,
                    'history',
                    startDate || defaultRange.startDate,
                    endDate || defaultRange.endDate,
                ),
                getWebTagDetails(
                    activeTagDropDown,
                    'issues',
                    startDate || defaultRange.startDate,
                    endDate || defaultRange.endDate,
                ),
                getWebTagDetails(
                    activeTagDropDown,
                    'docs',
                    startDate || defaultRange.startDate,
                    endDate || defaultRange.endDate,
                ),
                getWebTagDetails(
                    activeTagDropDown,
                    'comments',
                    startDate || defaultRange.startDate,
                    endDate || defaultRange.endDate,
                ),
            ]);
            setHistoryData({ ...history.data });
            setTagComments([...comments.data]);
            setTagImages([...docs.data]);
            setIssues([...issues.data]);
        }
    };

    const getTagLastRound = async () => {
        try {
            if (!isEmpty(activeTagDropDown)) {
                const response = await getWebTagDetails(activeTagDropDown, 'last_round');
                setLastRoundData({ ...response.data });
            }
        } catch (error) {
            throw error;
        }
    };

    return (
        <Content>
            <Spin spinning={loading}>
                <Card className="card_body">
                    <div className="main_body" style={{ width: '100%' }}>
                        <div className="bread">
                            <SelectPackage
                                history={history}
                                pkgId={pkgId}
                                list={allPackages}
                                projectId={projectId}
                            />

                            <Breadcrumb separator=">" className="mb-20 ml-20">
                                <Breadcrumb.Item
                                    onClick={() => {
                                        dispatch(setActiveProjectTab('1'));
                                        history.push('/projects');
                                    }}
                                >
                                    <span className="cursor-pointer">Alectify</span>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item
                                    onClick={() => {
                                        dispatch(setActiveProjectTab('5'));
                                        history.push('/projects');
                                    }}
                                >
                                    <span className="cursor-pointer">Area</span>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item href="">{packageDetail?.name}</Breadcrumb.Item>
                            </Breadcrumb>
                        </div>

                        <Tabs
                            tabPosition={'left'}
                            activeKey={activeTab}
                            defaultActiveKey={activeTab}
                            onChange={setActiveTab}
                        >
                            <TabPane tab="Status" key="1">
                                <PackageUser
                                    activeTag={activeTag}
                                    onTagChange={onTagChange}
                                    packageDetail={packageDetail}
                                />
                                <Status
                                    detail={activeTag}
                                    lastRoundData={lastRoundData}
                                    historyData={historyData}
                                    teamMembers={packageDetail?.team_members}
                                    tagComments={tagComments}
                                    tagImages={tagImages}
                                    issues={issues}
                                    activeTag={activeTag}
                                    getWebTagHistory={getWebTagHistory}
                                    tagData={tagData}
                                />
                            </TabPane>
                            <TabPane tab="Analytics" key="2">
                                <PackageUser
                                    activeTag={activeTag}
                                    onTagChange={onTagChange}
                                    packageDetail={packageDetail}
                                />
                                <Analytics />
                            </TabPane>
                            {/* <TabPane tab="Preventive Maintenance" key="2">
                                <PackageUser
                                    activeTag={activeTag}
                                    heading="Servicing History"
                                    onTagChange={onTagChange}
                                    packageDetail={packageDetail}
                                />
                                <ServiceHistory
                                    tagId={activeTag?.id}
                                    pkgId={pkgId}
                                    projectId={projectId}
                                    packageDetail={packageDetail}
                                />
                            </TabPane>
                            <TabPane tab="Critical Spare Parts" key="3">
                                <PackageUser
                                    activeTag={activeTag}
                                    onTagChange={onTagChange}
                                    heading="Critical Spare Parts"
                                    packageDetail={packageDetail}
                                />
                                <CriticalParts />
                            </TabPane>
                            <TabPane tab="Documents" key="4">
                                <PackageUser
                                    heading="Documents"
                                    activeTag={activeTag}
                                    onTagChange={onTagChange}
                                    packageDetail={packageDetail}
                                />
                                <EquipmentDocs
                                    pkgId={pkgId}
                                    projectId={projectId}
                                    data={documents.equipmentDocs.list}
                                    isLoading={documents.loading || loading}
                                    uploadDocs={uploadEquipmentDocs}
                                />
                            </TabPane> */}
                        </Tabs>
                    </div>
                    {packageDetail?.comet_guid && (
                        <>
                            <input
                                className="checkBox-input"
                                type="checkbox"
                                name=""
                                id="check"
                                checked={check}
                                onChange={() => setCheck(!check)}
                            />
                            <div className="quick-chat">
                                <label htmlFor="check">
                                    <span className="show" id="show">
                                        <LeftOutlined />
                                    </span>
                                    <span className="show" id="hide">
                                        <RightOutlined />
                                    </span>
                                </label>
                                <div className="comet-chat-body">
                                    <CometChatMessages chatWithGroup={packageDetail?.comet_guid} />
                                </div>
                            </div>
                        </>
                    )}
                </Card>
            </Spin>
        </Content>
    );
};

export default OperationsPackageDetail;
