import { Col, Row } from 'antd';
import moment from 'moment';
import { GanttChart } from '../../../components';
import { DATE_STATUSES, sanitizeText } from '../../../utils';
import Timeline from './timeline';

const Overview = ({ detail, packageRoom = false }) => {
    return (
        <>
            {/* <div className="page-content-container" style={{ minHeight: 'auto' }}>
                <h1 className="card-heading">{detail?.name}</h1>
                <Row>
                    <Col span={12}>
                        <label className="font-weight-600">Description:</label>
                        <p>{detail?.description}</p>
                    </Col>
                    <Col span={12}>
                        <label className="font-weight-600">Status:</label>
                        <p>
                            {detail?.status &&
                                DATE_STATUSES.find((st) => st.value === detail?.status).label}
                        </p>
                    </Col>
                    <Col span={12}>
                        <label className="font-weight-600">Order Placed:</label>
                        <p>{detail?.is_order_placed ? 'Yes' : 'No'}</p>
                    </Col>
                    <Col span={12}>
                        <label className="font-weight-600">Approval Drawings:</label>
                        <p>{detail?.is_approval_dwg ? 'Yes' : 'No'}</p>
                    </Col>
                    <Col span={12}>
                        <label className="font-weight-600">Source:</label>
                        <p>{sanitizeText(detail?.supplier?.affiliation)}</p>
                    </Col>
                    <Col span={12}>
                        <label className="font-weight-600">Supplier Name:</label>
                        <p>
                            {sanitizeText(detail?.supplier?.first_name)}{' '}
                            {detail?.supplier?.last_name}
                        </p>
                    </Col>
                    <Col span={12}>
                        <label className="font-weight-600">Supplier Email:</label>
                        <p>{sanitizeText(detail?.supplier?.email)}</p>
                    </Col>
                    <Col span={12}>
                        <label className="font-weight-600">Approval Date:</label>
                        <p>
                            {detail?.approval_date
                                ? moment(detail?.approval_date).format('DD MMM, YYYY')
                                : 'N/A'}
                        </p>
                    </Col>
                    <Col span={12}>
                        <label className="font-weight-600">Release Date:</label>
                        <p>
                            {detail?.release_date
                                ? moment(detail?.release_date).format('DD MMM, YYYY')
                                : 'N/A'}
                        </p>
                    </Col>
                    <Col span={12}>
                        <label className="font-weight-600">Final Date:</label>
                        <p>
                            {detail?.final_date
                                ? moment(detail?.final_date).format('DD MMM, YYYY')
                                : 'N/A'}
                        </p>
                    </Col>
                    <Col span={12}>
                        <label className="font-weight-600">Test Date:</label>
                        <p>
                            {detail?.test_date
                                ? moment(detail?.test_date).format('DD MMM, YYYY')
                                : 'N/A'}
                        </p>
                    </Col>
                    <Col span={12}>
                        <label className="font-weight-600">Ship Date:</label>
                        <p>
                            {detail?.ship_date
                                ? moment(detail?.ship_date).format('DD MMM, YYYY')
                                : 'N/A'}
                        </p>
                    </Col>
                    <Col span={12}>
                        <label className="font-weight-600">Approval Drawings:</label>
                        <p>
                            <a href="#" className="d-block">
                                1.jpg
                            </a>
                            <a href="#" className="d-block">
                                2.jpg
                            </a>
                        </p>
                    </Col>
                </Row>
            </div> */}
            <div className="page-content-container">
                <GanttChart heading={detail?.name}>
                    <p>{detail?.description}</p>
                </GanttChart>
            </div>
        </>
    );
};

export default Overview;
