import axios from 'axios';
import { getFromLocal, removeFromLocal, getBaseUrl } from './';
import { notification } from 'antd';
import { store } from '../store';
import { RESET_STATE } from '../store/common';

const BASE_URL = getBaseUrl();

class HttpService {
    constructor(baseURL, headers = { 'X-Custom-Header': 'foobar' }) {
        this._axios = axios.create({
            baseURL,
            headers,
        });
    }

    get(endpoint) {
        return this._axios.get(endpoint).catch(this.errorHandling);
    }

    post(endpoint, body) {
        return this._axios.post(endpoint, body).catch(this.errorHandling);
    }

    put(endpoint, data) {
        return this._axios({
            method: 'put',
            url: endpoint,
            data,
        }).catch(this.errorHandling);
    }

    patch(endpoint, data) {
        return this._axios({
            method: 'patch',
            url: endpoint,
            data,
        }).catch(this.errorHandling);
    }

    delete(endpoint, data) {
        return this._axios({
            method: 'delete',
            url: endpoint,
            data,
        }).catch(this.errorHandling);
    }

    errorHandling(err) {
        console.log('API Error: ', { err });
        if (err?.response?.status === 403 || err?.response?.data?.detail === 'Token is expired') {
            store.dispatch({ type: RESET_STATE });
            removeFromLocal('token');
            notification.info({
                message: 'Session expired',
                description: 'Your session has been expired please login again',
            });
            setTimeout(() => {
                window.location.href = '/login';
            }, 2000);
        }
        throw err;
    }
}

export const http = new HttpService(BASE_URL, {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getFromLocal('token')}`,
});

export const fileHttp = new HttpService(BASE_URL, {
    'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>',
    Authorization: `Bearer ${getFromLocal('token')}`,
});

export const detectHttp = new HttpService('https://cardboard-detect.alectify.com', {
    'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>',
    Authorization: `Bearer ${getFromLocal('token')}`,
});

export const videoPlayerHttp = new HttpService('https://video-punch-list.alectify.com', {
    'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>',
    Authorization: `Bearer ${getFromLocal('token')}`,
});
