import _ from 'lodash';
import Highlighter from 'react-highlight-words';
import { useEffect, useRef, useState } from 'react';
import {
    Table,
    Button,
    Input,
    Tooltip,
    Row,
    Col,
    Collapse,
    notification,
    Space,
    Checkbox,
    Avatar,
    Popover,
    Form,
    Select,
    Popconfirm,
} from 'antd';
import {
    SearchOutlined,
    DeleteTwoTone,
    PlusOutlined,
    QuestionCircleOutlined,
} from '@ant-design/icons';
import { checkPackageManufactures, makeAvatar } from '../../../utils';
import CreatePackageModal from '../listing/create-package';

const { Option } = Select;
const { Panel } = Collapse;

const Packages = ({
    tags = [],
    users = [],
    roomPackages = [],
    goBack = () => {},
    setTags = () => {},
    onFinish = () => {},
    setRoomPackages = () => {},
}) => {
    const [form] = Form.useForm();
    const searchInput = useRef();
    const [createPackageVisible, setCreatePackageVisible] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchColumn] = useState('');
    const [activePackage, setActivePackage] = useState({});
    const [allInOnePackage, setAllInOnePackage] = useState(false);
    const [displayAddMembers, setDisplayAddMembers] = useState('');

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8, width: 200 }}>
                <Row gutter={5}>
                    <Col span={24}>
                        <Input
                            ref={searchInput}
                            placeholder={`Search ${dataIndex}`}
                            value={selectedKeys[0]}
                            onChange={(e) =>
                                setSelectedKeys(e.target.value ? [e.target.value] : [])
                            }
                            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            style={{ marginBottom: 8, display: 'block' }}
                        />
                    </Col>
                    <Col span={12}>
                        <Button
                            onClick={() => handleReset(clearFilters)}
                            size="small"
                            style={{ width: '100%' }}
                        >
                            Reset
                        </Button>
                    </Col>
                    <Col span={12}>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: '100%' }}
                        >
                            Search
                        </Button>
                    </Col>
                </Row>
            </div>
        ),

        filterIcon: (filtered) => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : '#838383', fontSize: '18px' }} />
        ),

        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',

        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current.select(), 100);
            }
        },

        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const onCreatePackage = async (data, cb) => {
        const tagNames = data.tags.map(({ name }) => name);
        let allTeamMembers = [];
        data.tags.forEach(({ team_members }) => {
            allTeamMembers = [...allTeamMembers, ...team_members];
        });
        const team_members = _.uniqBy(allTeamMembers, 'email');
        data.team_members = team_members;
        const _tags = tags.map((tag) => {
            if (tagNames.includes(tag.name)) {
                return {
                    ...tag,
                    isPackage: true,
                };
            }
            return tag;
        });
        setTags([..._tags]);
        setRoomPackages([...roomPackages, data]);
        notification.success({
            message: 'Package successfully created',
            placement: 'topRight',
        });
        if (cb) {
            cb();
        }
        setCreatePackageVisible(false);
    };

    const onUpdatePackage = async (data, cb) => {};

    const onDeletePackage = async (pkg) => {
        const tagNames = pkg.tags.map(({ name }) => name);
        const packages = roomPackages.filter((room) => room.name !== pkg.name);
        const _tags = tags.map((tag) => {
            if (tagNames.includes(tag.name)) {
                return {
                    ...tag,
                    isPackage: false,
                };
            }
            return tag;
        });
        setTags([..._tags]);
        setRoomPackages([...packages]);
        notification.success({
            message: 'Package removed',
            placement: 'topRight',
        });
    };

    const onCancel = () => {
        setActivePackage({});
        setCreatePackageVisible(false);
    };

    const getManufactures = (tag) => {
        const manufacturers = users.filter((user) => {
            return (
                user.organization_name.toLowerCase() == tag.manufacture.toLowerCase() ||
                user.organization_name
                    .toLowerCase()
                    .split(' ')
                    .includes(tag.manufacture.toLowerCase()) ||
                tag.manufacture
                    .toLowerCase()
                    .split(' ')
                    .includes(user.organization_name.toLowerCase())
            );
        });
        return manufacturers;
    };

    const addTeamMembers = (tag) => {
        const other_members = users.filter(
            (user) => user.organization_type_name !== 'Manufacturer',
        );
        const manufacturers = getManufactures(tag);
        const team_members = [...other_members, ...manufacturers];
        return {
            ...tag,
            team_members: team_members,
        };
    };

    const managePackagesTeams = () => {
        const packages_with_team = tags.map(addTeamMembers);
        setTags([...packages_with_team]);
    };

    useEffect(() => {
        managePackagesTeams();
    }, []);

    const onAddMemberInPackage = (values, type = 'tag') => {
        const { tag, member_email } = values;
        const user = users.find((user) => user.email === member_email);
        const isUserExist = tag.team_members.some(
            (team_member) => team_member.email === user.email,
        );
        if (isUserExist) {
            form.setFields([{ name: 'member_email', errors: ['User already exist'] }]);
            return;
        }
        const _tags = (type === 'tag' ? tags : roomPackages).map((t) => {
            if (t.name === tag.name) {
                return {
                    ...t,
                    team_members: [...t.team_members, user],
                };
            }
            return t;
        });
        if (type === 'tag') {
            setTags([..._tags]);
        } else {
            setRoomPackages([..._tags]);
        }
        form.resetFields();
    };

    const addMembersContent = (tag, type = 'tag') => {
        return (
            <Form
                form={form}
                style={{ width: 200 }}
                layout="vertical"
                onFinish={(values) => onAddMemberInPackage({ ...values, tag: tag }, type)}
            >
                <Form.Item
                    name="member_email"
                    rules={[{ required: true, message: 'Please select' }]}
                >
                    <Select
                        showSearch
                        placeholder="Please select"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().includes(input.toLowerCase())
                        }
                        allowClear
                        style={{ width: '100%' }}
                    >
                        {users.map((user) => (
                            <Option key={user.email} value={user.email}>
                                {`${user.first_name} ${user.last_name}`}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <div className="text-right mt-15">
                    <Space>
                        <Button type="default" onClick={() => setDisplayAddMembers('')}>
                            Cancel
                        </Button>
                        <Button type="primary" htmlType="submit">
                            Add
                        </Button>
                    </Space>
                </div>
            </Form>
        );
    };

    const removeMember = (member_email, tag, type = 'tag') => {
        const team_members = tag.team_members.filter((m) => m.email !== member_email);
        const _tags = (type === 'tag' ? tags : roomPackages).map((t) => {
            if (t.name === tag.name) {
                return {
                    ...t,
                    team_members,
                };
            }
            return t;
        });
        setDisplayAddMembers('');
        if (type === 'tag') {
            setTags([..._tags]);
        } else {
            setRoomPackages([..._tags]);
        }
    };

    const individualPackageColumns = [
        {
            title: '',
            dataIndex: 'warning',
            width: 0,
            render: (_, record) => checkPackageManufactures(record, 'create_project'),
        },
        {
            title: 'Tag Name',
            dataIndex: 'name',
            key: 'name',
            width: 150,
            ...getColumnSearchProps('name'),
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            render: (text, record) => {
                return {
                    props: {
                        className: 'description-td',
                    },
                    children: (
                        <Tooltip placement="topLeft" title={text}>
                            {text}
                        </Tooltip>
                    ),
                };
            },
        },
        {
            title: 'Team Members',
            dataIndex: 'team_members',
            key: 'team_members',
            render: (team_members, record) =>
                team_members && (
                    <div className="package-team-members">
                        <Avatar.Group>
                            {team_members.map((member, idx) => (
                                <Tooltip
                                    key={member.email}
                                    title={`${member.first_name} ${member.last_name}`}
                                >
                                    <Popconfirm
                                        onConfirm={() => removeMember(member.email, record)}
                                        title="Are your want to remove this user?"
                                    >
                                        <Avatar
                                            className="circle-icon"
                                            src={makeAvatar(
                                                member.first_name[0],
                                                member.last_name[0],
                                            )}
                                        />
                                    </Popconfirm>
                                </Tooltip>
                            ))}
                            <Tooltip title="Add Member">
                                <Popover
                                    placement="rightTop"
                                    title="Add New Member"
                                    content={addMembersContent(record)}
                                    trigger="click"
                                    visible={displayAddMembers === record.name}
                                >
                                    <Avatar
                                        className="circle-icon"
                                        style={{
                                            color: '#f56a00',
                                            backgroundColor: '#fde3cf',
                                        }}
                                        onClick={() => setDisplayAddMembers(record.name)}
                                    >
                                        <PlusOutlined />
                                    </Avatar>
                                </Popover>
                            </Tooltip>
                        </Avatar.Group>
                    </div>
                ),
        },
    ];

    const groupsPackageColumns = [
        {
            title: '',
            dataIndex: 'warning',
            width: 0,
            render: (_, record) => checkPackageManufactures(record, 'create_project'),
        },
        {
            title: 'Room Name 2',
            dataIndex: 'name',
            key: 'name',
            width: 250,
            ...getColumnSearchProps('name'),
        },
        {
            title: 'Description',
            key: 'description',
            dataIndex: 'description',
            render: (text, record) => {
                return {
                    props: {
                        className: 'description-td',
                    },
                    children: (
                        <Tooltip placement="topLeft" title={text}>
                            {text}
                        </Tooltip>
                    ),
                };
            },
        },
        {
            title: 'Total Tags',
            key: 'tags',
            dataIndex: 'tags',
            render: (_, record) => record.tags.length,
        },
        {
            title: 'Team Members',
            dataIndex: 'team_members',
            key: 'team_members',
            render: (team_members, record) =>
                team_members && (
                    <div className="package-team-members">
                        <Avatar.Group>
                            {team_members.map((member, idx) => (
                                <Tooltip
                                    key={member.email}
                                    title={`${member.first_name} ${member.last_name}`}
                                >
                                    <Popconfirm
                                        onConfirm={() =>
                                            removeMember(member.email, record, 'package')
                                        }
                                        title="Are your want to remove this user?"
                                    >
                                        <Avatar
                                            className="circle-icon"
                                            src={makeAvatar(
                                                member.first_name[0],
                                                member.last_name[0],
                                            )}
                                        />
                                    </Popconfirm>
                                </Tooltip>
                            ))}
                            <Tooltip title="Add Member">
                                <Popover
                                    placement="rightTop"
                                    title="Add New Member"
                                    content={addMembersContent(record, 'package')}
                                    trigger="click"
                                    visible={displayAddMembers === record.name}
                                >
                                    <Avatar
                                        className="circle-icon"
                                        style={{
                                            color: '#f56a00',
                                            backgroundColor: '#fde3cf',
                                        }}
                                        onClick={() => setDisplayAddMembers(record.name)}
                                    >
                                        <PlusOutlined />
                                    </Avatar>
                                </Popover>
                            </Tooltip>
                        </Avatar.Group>
                    </div>
                ),
        },
        {
            title: '',
            key: 'edit',
            dataIndex: 'edit',
            render: (_, record) => (
                <>
                    <Tooltip title="Delete Package">
                        <DeleteTwoTone
                            onClick={(ev) => {
                                ev.stopPropagation();
                                onDeletePackage(record);
                            }}
                            twoToneColor="#f56666"
                            style={{ fontSize: 18, cursor: 'pointer' }}
                        />
                    </Tooltip>
                </>
            ),
        },
    ];

    const nestedTagsColumn = [
        {
            title: 'Tag Name',
            dataIndex: 'name',
            key: 'name',
            width: 150,
            ...getColumnSearchProps('name'),
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            render: (text, record) => {
                return {
                    props: {
                        className: 'description-td',
                    },
                    children: (
                        <Tooltip placement="topLeft" title={text}>
                            {text}
                        </Tooltip>
                    ),
                };
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            // render: (value) => DATE_STATUSES.find((status) => status.value === value).label,
        },
    ];

    const validatePackageName = (pkgName) => {
        return roomPackages.some((pkg) => pkg.name === pkgName);
    };

    const onNext = () => {
        onFinish({ roomPackages, tags });
    };

    const handleAllInOnePackage = (isChecked) => {
        if (isChecked && _.isEmpty(tags)) {
            notification.warning({
                message: 'No tags found',
                description: "You haven't upload or add any tag.",
            });
            return;
        } else if (isChecked && !_.isEmpty(roomPackages)) {
            notification.warning({
                message: "Can't perform this action",
                description:
                    "You can't keep all tags in one package because you already created a package below, remove that package if you want to keep all in one package.",
                duration: 10,
            });
            return;
        } else if (isChecked) {
            const _tags = tags.map((tag) => ({ ...tag, isPackage: true }));
            const packageRoom = {
                name: 'All In One Package',
                description: '',
                tags: _tags,
            };
            let allTeamMembers = [];
            _tags.forEach(({ team_members }) => {
                allTeamMembers = [...allTeamMembers, ...team_members];
            });
            const team_members = _.uniqBy(allTeamMembers, 'email');
            packageRoom.team_members = team_members;
            setTags([..._tags]);
            setRoomPackages([...roomPackages, packageRoom]);
        } else {
            const _tags = tags.map((tag) => ({ ...tag, isPackage: false }));
            setTags([..._tags]);
            setRoomPackages([]);
        }
        setAllInOnePackage(isChecked);
    };

    return (
        <>
            <h2 className="step-heading align-item-center align-items-center">
                <span>
                    Manage Equipment Packages <br />{' '}
                    <Checkbox
                        checked={allInOnePackage}
                        onChange={({ target }) => handleAllInOnePackage(target.checked)}
                    >
                        <span className="text-grey text-size-s">Keep all tags in one package </span>
                    </Checkbox>
                    <Tooltip title="Check this box if all equipments in this order should be tracked as one">
                        <QuestionCircleOutlined
                            className="text-grey"
                            style={{ fontSize: 19, verticalAlign: 'middle' }}
                        />
                    </Tooltip>
                </span>
                <Button
                    className="btn-tab"
                    type="primary"
                    onClick={() => setCreatePackageVisible(true)}
                    disabled={allInOnePackage}
                >
                    <PlusOutlined /> Create Bundle
                </Button>
            </h2>
            <Space direction="vertical" size={15} style={{ width: '100%' }}>
                <Collapse className="packages-collapse alectify-collapse document-collapse">
                    <Panel header="Package Equipments" key="1">
                        <Table
                            size="small"
                            columns={groupsPackageColumns}
                            pagination={roomPackages.length > 10}
                            className="taglist-table"
                            expandable={{
                                expandedRowRender: (record) => (
                                    <Table
                                        rowKey="id"
                                        columns={nestedTagsColumn}
                                        dataSource={record.tags}
                                        className="ant-table-container"
                                        pagination={record.tags.length > 10}
                                    />
                                ),
                            }}
                            dataSource={roomPackages.map((room) => ({
                                ...room,
                                key: room?.id || room?.name,
                            }))}
                        />
                    </Panel>
                </Collapse>
                <Collapse className="packages-collapse alectify-collapse document-collapse">
                    <Panel header="Individual Equipments with Approval Drawings" key="2">
                        <Table
                            size="small"
                            columns={individualPackageColumns}
                            dataSource={tags
                                .filter((tag) => tag.approval_dwg && !tag?.isPackage)
                                .map((tag) => ({ ...tag, key: tag?.id || tag?.name }))}
                            className="taglist-table"
                            pagination={tags.length > 10}
                        />
                    </Panel>
                </Collapse>

                <Collapse className="packages-collapse alectify-collapse document-collapse">
                    <Panel header="Individual Equipments without Approval Drawings" key="3">
                        <Table
                            size="small"
                            columns={individualPackageColumns}
                            // dataSource={individualPackages.map((tag) => ({ ...tag, key: tag.id }))}
                            className="taglist-table"
                            // pagination={individualPackages.length > 10}
                        />
                    </Panel>
                </Collapse>
            </Space>
            <div className="text-right">
                <Space>
                    <Button
                        className="btn-lg btn-default save-btn mt-30"
                        type="default"
                        htmlType="button"
                        onClick={goBack}
                    >
                        Previous
                    </Button>
                    <Button
                        className="btn-lg btn-primary-lg save-btn mt-30"
                        type="primary"
                        onClick={onNext}
                    >
                        Next
                    </Button>
                </Space>
            </div>

            {createPackageVisible && (
                <CreatePackageModal
                    title={`${!_.isEmpty(activePackage) ? 'Update' : 'Create'} Bundle`}
                    visible={createPackageVisible}
                    individualRoomsData={tags.filter((tag) => tag.approval_dwg && !tag?.isPackage)}
                    initialData={{ ...activePackage }}
                    onSubmit={onCreatePackage}
                    onUpdate={onUpdatePackage}
                    onCancel={onCancel}
                    validatePackageName={validatePackageName}
                    projectSetup
                />
            )}
        </>
    );
};

export default Packages;
