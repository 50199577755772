import _ from 'lodash';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Tooltip, Collapse, Table, Avatar } from 'antd';
import { APPROVAL_STATUSES, DISPLAY_DATETIME_FORMAT, makeAvatar } from '../../../../utils';
import './review-status.scss';

const { Panel } = Collapse;

const MARKUP_COLUMNS = [
    { title: 'Item #', dataIndex: 'id', key: 'id' },
    {
        title: 'File Name',
        dataIndex: 'markup_file_name',
        key: 'markup_file_name',
    },
    {
        title: 'Due Date',
        dataIndex: 'approval_due_date',
        render: (value) => moment(value).format(DISPLAY_DATETIME_FORMAT),
    },
    {
        title: 'Released date',
        dataIndex: 'created_at',
        render: (value) => moment(value).format(DISPLAY_DATETIME_FORMAT),
    },
    {
        title: 'Submitted by',
        key: 'Submitted_by',
        render: (_, record) => (
            <Tooltip title={`${record.account.first_name} ${record.account.last_name}`}>
                <Avatar
                    className="circle-icon m-auto"
                    src={
                        record?.account?.image ||
                        makeAvatar(record?.account?.first_name[0], record?.account?.last_name[0])
                    }
                />
            </Tooltip>
        ),
    },
];

const APPROVAL_COLUMNS = [
    {
        title: 'Item #',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: 'File Name',
        dataIndex: 'approval_file_name',
        key: 'approval_file_name',
    },
    {
        title: 'Due Date',
        dataIndex: 'approval_due_date',
        render: (value) => moment(value).format(DISPLAY_DATETIME_FORMAT),
    },
    {
        title: 'Submitted on',
        dataIndex: 'created_at',
        render: (value) => moment(value).format(DISPLAY_DATETIME_FORMAT),
    },
    {
        title: 'Submitted by',
        key: 'submittedBy',
        align: 'center',
        render: (_, record) => (
            <Tooltip title={`${record.account.first_name} ${record.account.last_name}`}>
                <Avatar
                    className="circle-icon m-auto"
                    src={
                        record?.account?.image ||
                        makeAvatar(record?.account?.first_name[0], record?.account?.last_name[0])
                    }
                />
            </Tooltip>
        ),
    },
];

const Status = ({ tags = [], pkgId }) => {
    const { approvals, markups } = useSelector(({ approval }) => approval);

    const filteredApprovals = approvals.filter(
        (approval) => approval.approval_status === APPROVAL_STATUSES.SEND_FOR_APPROVAL,
    );

    return (
        <div className="mb-30">
            <Collapse bordered={false} className="tagsBody">
                <Panel className="tagsBodyPanel" header={<b>List of Equipments</b>} key="1">
                    <Table
                        dataSource={[...tags]}
                        columns={[
                            { title: 'Tag Name', dataIndex: 'name' },
                            { title: 'Description', dataIndex: 'description' },
                        ]}
                        pagination={tags.length > 10}
                    />
                </Panel>
            </Collapse>
            <Collapse bordered={false} className="tagsBody mt-20">
                <Panel
                    className="tagsBodyPanel"
                    header={<b>History of Approval Documents</b>}
                    key="1"
                >
                    <Table
                        dataSource={filteredApprovals}
                        columns={APPROVAL_COLUMNS}
                        title={() => `Approvals (${filteredApprovals?.length})`}
                        pagination={filteredApprovals.length > 10}
                    />
                </Panel>
            </Collapse>
            <Collapse bordered={false} className="tagsBody mt-20">
                <Panel
                    className="tagsBodyPanel"
                    header={<b>History of Reviewed Documents</b>}
                    key="1"
                >
                    <Table
                        dataSource={markups}
                        title={() => `Markups (${markups?.length})`}
                        rowKey="id"
                        columns={MARKUP_COLUMNS}
                        pagination={markups.length > 10}
                    ></Table>
                </Panel>
            </Collapse>
        </div>
    );
};

export default Status;
