import _ from 'lodash';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import {
    CreateProject,
    ProjectListing,
    SinglelineDetail,
    TagDetail,
    Dashboard,
    PackageDetail,
    EditProject,
    EditProfile,
    OperationsPackageDetail,
    CreateEditProject,
    ProjectsAdmin,
} from './app/pages';
import {
    PROJECTS,
    SLINES,
    TAGS,
    PACKAGE,
    DASHBOARD,
    CONTACTS,
    CHAT,
    PROFILE,
    SETTING,
    HELP,
    USERINFO,
} from './app/utils';
import { Login, Signup, ForgotPassword, ResetPassword } from './web/pages';
import { AlectifyLayout } from './app/components';
import * as Permissions from './app/utils/permissions';
import Contacts from './app/pages/contacts';
import { useEffect } from 'react';
import {
    APP_COMET_CHAT_APP_ID,
    APP_COMET_CHAT_AUTH_KEY,
    APP_COMET_CHAT_REGION,
} from './app/comet-chat/configs';
import { launchCometChat } from './app/comet-chat';
import Setting from './app/pages/setting';
import Help from './app/pages/help';
import { feature_inbox_one_to_one_chats } from './app/utils/features';
import UserInfo from './app/pages/user-info';

const Routes = () => {
    return (
        <Router>
            <Switch>
                <Route exact path="/" component={() => <Redirect to="/login" />} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/signup" component={Signup} />
                <Route exact path="/forgot-password" component={ForgotPassword} />
                <Route exact path="/reset-password/:uidb64/:token" component={ResetPassword} />
                <PrivateRoute component={ProtectedRoutes} />
            </Switch>
        </Router>
    );
};

const PrivateRoute = ({ component: Component, ...rest }) => {
    const { user } = useSelector(({ auth }) => auth);
    return (
        <Route
            {...rest}
            render={(props) => {
                if (_.isEmpty(user)) {
                    return <Redirect to={{ pathname: '/login' }} />;
                } else {
                    return <Component {...props} />;
                }
            }}
        />
    );
};

const ProtectedRoutes = () => {
    const { permissions } = useSelector(({ permission }) => permission);
    const { user } = useSelector(({ auth }) => auth);

    useEffect(() => {
        if (user && window.CometChatWidget) {
            try {
                // window.CometChatWidget.init({
                //     appID: APP_COMET_CHAT_APP_ID,
                //     appRegion: APP_COMET_CHAT_REGION,
                //     authKey: APP_COMET_CHAT_AUTH_KEY,
                // }).then(() => {
                //     window.CometChatWidget.login({
                //         uid: user.comet_uid,
                //         authKey: APP_COMET_CHAT_AUTH_KEY,
                //     });
                //     if (feature_inbox_one_to_one_chats(user.features)) {
                //          launchCometChat(user);
                //     }
                // });
            } catch (error) {
                console.log('error', error);
            }
        }
    }, []);

    return (
        <AlectifyLayout>
            <Switch>
                {Permissions.can_view_dashboard(permissions) && (
                    <Route exact path={DASHBOARD} component={Dashboard} />
                )}
                <Route exact path={PROJECTS} component={ProjectListing} />
                {/* <Route exact path={`${PROJECTS}/map`} component={MapListing} /> */}
                <Route exact path={CONTACTS} component={Contacts} />
                <Route exact path={PROFILE} component={EditProfile} />
                <Route exact path={SETTING} component={Setting} />
                <Route exact path={HELP} component={Help} />
                <Route exact path={USERINFO} component={UserInfo} />

                {Permissions.can_view_singlelines(permissions) && (
                    <Route
                        exact
                        path={`${PROJECTS}/:projectId${SLINES}/:slineId`}
                        component={SinglelineDetail}
                    />
                )}
                {Permissions.can_view_master_tags(permissions) && (
                    <Route
                        exact
                        path={`${PROJECTS}/:projectId${TAGS}/:tagId`}
                        component={TagDetail}
                    />
                )}
                {Permissions.can_view_packages(permissions) && (
                    <Route
                        exact
                        path={`${PROJECTS}/:projectId${PACKAGE}/:pkgId`}
                        component={PackageDetail}
                    />
                )}
                {Permissions.can_view_packages(permissions) && (
                    <Route
                        exact
                        path={`${PROJECTS}/:projectId/operations/package/:pkgId`}
                        component={OperationsPackageDetail}
                    />
                )}
                {/* {Permissions.can_add_project(permissions) && ( */}
                <Route exact path={`${PROJECTS}/create`} component={CreateEditProject} />
                {/* )} */}
                <Route exact path={`${PROJECTS}/:projectId`} component={CreateEditProject} />
                <Route exact path={`${PROJECTS}-admin`} component={ProjectsAdmin} />
                <Redirect to="/" />
            </Switch>
        </AlectifyLayout>
    );
};

export default Routes;
