import { DownloadOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, notification, Row, Select } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { CSVLink } from 'react-csv';

import '../../../../app/assets/styles/reports.scss';
import { MyDateRangePicker } from '../../../components/date-range-selector';
import { MyFullCalendar } from '../../../components/my-full-calendar';
import { fetchGenerateDateWiseShiftReportData, fetchGenerateReportDataV2 } from '../../../services';
import { SHIFT_OPTIONS } from '../../../utils';
const { Option } = Select;
export const ViewReports = ({ projectId }) => {
    const [filterData, setFilterDate] = useState({
        package: 'all',
        shift: 2,
        dateRange: {
            startDate: moment().subtract(30, 'days').format('yyyy-MM-DD'),
            endDate: moment().format('yyyy-MM-DD'),
        },
    });
    const [filterDataRoundWise, setFilterDateRoundWise] = useState({
        shift: 0,
        date: moment().format('yyyy-MM-DD'),
    });
    const [showGenerateRepButton, setShowGenerateRepButton] = useState(false);
    const [reportsData, setReportsData] = useState([]);

    const csvLinkProps = {
        filename: 'Shift-Report.csv',
        headers: [
            { label: 'Project Name', key: 'project__name' },
            { label: 'Package Name', key: 'package_room__name' },
            { label: 'Tag Name', key: 'tag__name' },
            { label: 'Operator Name', key: 'operator_name' },
            { label: 'Report Date', key: 'report_date' },
            { label: 'Shift', key: 'shift' },
            { label: 'Comments', key: 'comments_count' },
            { label: 'Docs', key: 'docs_count' },
            { label: 'Issues', key: 'issues_count' },
        ],
        data: reportsData,
    };

    const handlePackageChangeOption = (value) => {
        setFilterDate({ ...filterData, package: value });
        setShowGenerateRepButton(false);
    };

    const handleShiftChangeOption = (value) => {
        setFilterDateRoundWise({ ...filterData, shift: value });
        setShowGenerateRepButton(false);
    };

    const handleGenerateReport = async () => {
        try {
            const response = await fetchGenerateReportDataV2(projectId, filterData);
            const anchor = document.createElement('a');
            const blob = new Blob([Buffer.from(response.data.encoded, 'base64').toString('utf8')], {
                type: response.data.file_type,
            });
            const url = URL.createObjectURL(blob);
            anchor.href = url;
            anchor.download = response.data.file_name;
            anchor.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            notification.error({
                message: 'Round is not available.',
                placement: 'topRight',
            });
            console.log('error', error);
        }
    };

    const handleDateWiseShiftGenerateReport = async () => {
        try {
            const response = await fetchGenerateDateWiseShiftReportData(
                projectId,
                filterDataRoundWise.date,
                filterDataRoundWise.shift,
            );
            const anchor = document.createElement('a');
            const blob = new Blob([Buffer.from(response.data.encoded, 'base64').toString('utf8')], {
                type: response.data.file_type,
            });
            const url = URL.createObjectURL(blob);
            anchor.href = url;
            anchor.download = response.data.file_name;
            anchor.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            notification.error({
                message: 'Round is not available.',
                placement: 'topRight',
            });
            console.log('error', error);
        }
    };

    const handleDateChange = (date) => {
        setFilterDateRoundWise({ ...filterDataRoundWise, date: date.format('yyyy-MM-DD') });
    };
    const handleDateWiseShiftChangeOption = (value) => {
        setFilterDateRoundWise({ ...filterDataRoundWise, shift: value });
        setShowGenerateRepButton(false);
    };
    const handleDateRangeChange = (dateObj) => {
        setFilterDate({ ...filterData, dateRange: dateObj });
        setShowGenerateRepButton(false);
    };

    return (
        <div className="reports-tab">
            <div className="report-generator" style={{ backgroundColor: '#e8f3ff' }}>
                <h4
                    style={{
                        margin: '8px',
                        borderBottom: '1px solid #0098EF',
                        color: '#555',
                        fontSize: '20px',
                    }}
                >
                    Report Generator Shift Wise
                </h4>

                <Row
                    className="report-container"
                    justify="space-between"
                    align="center"
                    // gutter={[10]}
                >
                    <Col md={5} lg={4}>
                        <p>Date: </p>
                        <DatePicker
                            value={moment(filterDataRoundWise.date)}
                            onChange={handleDateChange}
                        />
                    </Col>

                    <Col md={6} lg={6}>
                        <p>Shift: </p>
                        <Select
                            size="middle"
                            value={filterDataRoundWise.shift}
                            onChange={handleDateWiseShiftChangeOption}
                            style={{ width: 150 }}
                        >
                            {SHIFT_OPTIONS &&
                                SHIFT_OPTIONS.length &&
                                SHIFT_OPTIONS.map((shft) => (
                                    <Option key={shft.id} value={shft.id}>
                                        {shft.name}
                                    </Option>
                                ))}
                        </Select>
                    </Col>

                    <Col md={6} lg={6} className="button-reports">
                        <Button type="primary" onClick={handleDateWiseShiftGenerateReport}>
                            Generate Report
                        </Button>
                        {showGenerateRepButton && reportsData.length ? (
                            <CSVLink {...csvLinkProps}>
                                <Button type="primary" icon={<DownloadOutlined />}></Button>
                            </CSVLink>
                        ) : (
                            <></>
                        )}
                    </Col>
                </Row>
            </div>

            <div className="report-generator" style={{ backgroundColor: '#e8f3ff' }}>
                <h4
                    style={{
                        margin: '8px',
                        borderBottom: '1px solid #0098EF',
                        color: '#555',
                        fontSize: '20px',
                    }}
                >
                    Report Generator
                </h4>

                <Row
                    className="report-container"
                    justify="space-between"
                    align="center"
                    // gutter={[10]}
                >
                    {/* <Col md={4} lg={5}>
                        <p>Area: </p>
                        <DropdownSelect
                            options={[{ id: 'all', name: 'All' }, ...packagesList]}
                            parentCallback={handlePackageChangeOption}
                        ></DropdownSelect>
                    </Col>
                    <Col md={4} lg={5}>
                        <p>Shift: </p>
                        <DropdownSelect
                            options={SHIFT_OPTIONS}
                            parentCallback={handleShiftChangeOption}
                        ></DropdownSelect>
                    </Col> */}
                    <Col md={10} lg={8}>
                        <p>Range: </p>
                        <MyDateRangePicker
                            dateRange={filterData.dateRange}
                            parentCallback={handleDateRangeChange}
                        ></MyDateRangePicker>
                    </Col>
                    <Col md={7} lg={6} className="button-reports">
                        <Button type="primary" onClick={handleGenerateReport}>
                            Generate Report
                        </Button>
                        {showGenerateRepButton && reportsData.length ? (
                            <CSVLink {...csvLinkProps}>
                                <Button type="primary" icon={<DownloadOutlined />}></Button>
                            </CSVLink>
                        ) : (
                            <></>
                        )}
                    </Col>
                </Row>
            </div>
            <MyFullCalendar projectId={projectId} />
        </div>
    );
};
