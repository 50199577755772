import React, {useState} from 'react';
import { Checkbox, Tabs, Button, Form, InputNumber, Row, Col, notification, message,Input} from 'antd';
import './setting.scss';
import { notificationSettings } from '../../services';
const Setting = () => {

    const { TabPane } = Tabs;
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const onFinish = async (values) => {
        console.log('value',values)
        try {
            const formData = {};
            formData.before = values.before == undefined ? 1 : values.before
            formData.after =values.after == undefined ? 1 : values.after
            formData.on_day= values.on_day == undefined ? false : values.on_day
            formData.release= values.release == undefined ? false : values.release
            formData.send_approval= values.send_approval == undefined ? false : values.send_approval
            formData.service_history_days=values.service_history_days == undefined ? 1 : values.service_history_days
            formData.preventive_maintenance_days= values.preventive_maintenance_days == undefined ? 1 : values.preventive_maintenance_days
            setLoading(true);
            const response = await notificationSettings(formData);
            if (response.success){
                message.success('The notification form has been successfully submited')}
            else{
                message.error('The form is not submited')
            }
            
            setLoading(false);
        } catch (ex) {
            const apiErrors = ex?.response?.data?.data;
            if (apiErrors) {
                const errors = Object.keys(apiErrors).map((key) => ({
                    name: key,
                    errors: apiErrors[key]
                }));
                form.setFields(errors);
            }
            setLoading(false);
        }
    };
 

    return (
        <div className="setting-main">
            <div className="side-heading">
                <h1>Settings</h1>
            </div>

            <div className="card-container">
                <Tabs tabPosition="left" className="tabs-sidebar">
                    <TabPane tab="Construction" key="1">
                        <div className="notification-header">
                            <h2>Construction</h2>
                        </div>
                        <div className="setting-card-body">
                            <Form name="basic"
                                onFinish={onFinish}>
                                <p className="p-titles">Review</p>
                                <Row gutter={[16, 8]}>
                                    <Col className="p-10">
                                        <Form.Item name="send_approval" label="Send Approval" valuePropName="checked">
                                            <Checkbox />
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Form.Item name="release" label="Release" valuePropName="checked">
                                            <Checkbox />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <br />
                                <p className="p-titles">Equipment Deadline</p>

                                <Row>
                                    <Col span={8}>
                                        <Form.Item 
                                        name="before" label="Days before">
                                            <InputNumber
                                                className="inputNumber-body"
                                                min={1}
                                                max={10}
                                            />

                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item name="after" label="Days after">
                                            <InputNumber
                                                className="inputNumber-body"
                                                min={1}
                                                max={10}
                                            />

                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item name="on_day" label="On Day" valuePropName="checked">
                                            <Checkbox/>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Form.Item>
                                    <div className="submit-button-body">
                                        <Button
                                            className="submit-button"
                                            type="primary"
                                            htmlType="submit"
                                        >
                                            Submit
                                        </Button>
                                    </div>
                                </Form.Item>
                            </Form>
                        </div>
                    </TabPane>
                    <TabPane tab="Operation" key="2">
                        <div className="notification-header">
                            <h2>Operation</h2>
                        </div>
                        <div className="setting-card-body ">
                            <Form onFinish={onFinish}>
                                <p className="p-titles">Equipment Deadline</p>
                                <Row>
                                    <Col span={8}>
                                        <Form.Item name="before" label="Days Before">
                                            <InputNumber
                                                className="inputNumber-body"
                                                min={1}
                                                max={10}
                                            />

                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item name="after" label="Days After">
                                            <InputNumber
                                                className="inputNumber-body"
                                                min={1}
                                                max={10}
                                            />

                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item name="on_day" valuePropName="checked" label= "On Day">
                                            <Checkbox/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <br />
                                <p className="p-titles">Service History</p>
                                <Form.Item name="service_history_days" label="Days before deadline report created">
                                    <InputNumber className="inputNumber-body" min={1} max={10}/>
                                </Form.Item>
                                <br />
                                <p className="p-titles">Preventive Maintenance</p>

                                <Form.Item name="preventive_maintenance_days" label="Days Before">

                                    <InputNumber className="inputNumber-body" min={1} max={10}/>

                                </Form.Item>
                                <Form.Item>
                                    <div className="submit-button-body">
                                        <Button
                                            className="submit-button"
                                            type="primary"
                                            htmlType="submit"
                                        >
                                            Submit
                                        </Button>
                                    </div>
                                </Form.Item>
                            </Form>
                        </div>
                    </TabPane>
                </Tabs>
            </div>
        </div>
    );
};
export default Setting;
