import { http } from '../utils/http';

export const fetchInsights = async (projectId) => {
    try {
        const response = await http.get(`/projects/${projectId}/web/insights/`);
        return response.data;
    } catch (ex) {
        throw ex;
    }
};

export const fetchInsightsDateWise = async (projectId, start, end) => {
    try {
        const response = await http.get(
            `/projects/${projectId}/web/insights/datewise/?shift_date__gte=${end}&shift_date__lte=${start}`,
        );
        return response.data;
    } catch (ex) {
        throw ex;
    }
};

export const fetchInsightsDatefromRound = async (projectId) => {
    try {
        const response = await http.get(`/projects/${projectId}/app/round/`);
        return response.data;
    } catch (ex) {
        throw ex;
    }
};

export const fetchRoundDetails = async (projectId, id) => {
    try {
        const response = await http.get(`/projects/${projectId}/web/insights/round/${id}/`);
        return response.data;
    } catch (ex) {
        throw ex;
    }
};
