import React from 'react';
import { Select } from 'antd';
import { useSelector } from 'react-redux';
import { PROJECTS, PACKAGE } from '../../utils';
import '../../assets/styles/components/package-detail.scss';

const { Option } = Select;

export const SelectPackage = ({ history, projectId, list, pkgId }) => {
    const { operations } = useSelector(({ common }) => common);

    const handleChange = (pkgId) => {
        let url = `${PROJECTS}/${projectId}${PACKAGE}/${pkgId}`;
        if (operations) url = `${PROJECTS}/${projectId}/operations${PACKAGE}/${pkgId}`;
        history.push(url);
    };

    return (
        <div className="dropdown">
            <Select defaultValue={pkgId} style={{ width: 120 }} onChange={handleChange}>
                {Array.isArray(list) &&
                    list.map((item, i) => (
                        <Option key={i} value={item.id}>
                            {item.name}
                        </Option>
                    ))}
            </Select>
        </div>
    );
};
