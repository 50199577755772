import { http } from '../utils/http';

export const fetchCompanies = async () => {
    try {
        const response = await http.get(`/admin/companies/`);
        return response.data;
    } catch (ex) {
        throw ex;
    }
};

export const createCompany = async (body) => {
    try {
        const response = await http.post(`/admin/companies/`, body);
        return response.data;
    } catch (ex) {
        throw ex;
    }
};