const {
    REACT_APP_STAGE,
    REACT_APP_DEV_BASE_URL,
    REACT_APP_PRDO_BASE_URL,
    REACT_APP_STAGE_BASE_URL,
    REACT_APP_SIGMA_BASE_URL,
    REACT_APP_URBACON_BASE_URL,
    REACT_APP_STAGE_BUCKET_NAME,
    REACT_APP_PROD_BUCKET_NAME,
    REACT_APP_SIGMA_BUCKET_NAME,
    REACT_APP_URBACON_BUCKET_NAME,
    REACT_APP_DEV_BUCKET_NAME,
    REACT_APP_STAGE_COMET_CHAT_AUTH_KEY,
    REACT_APP_STAGE_COMET_CHAT_APP_ID,
    REACT_APP_PROD_COMET_CHAT_AUTH_KEY,
    REACT_APP_PROD_COMET_CHAT_APP_ID,
    REACT_APP_SIGMA_COMET_CHAT_AUTH_KEY,
    REACT_APP_SIGMA_COMET_CHAT_APP_ID,
    REACT_APP_COMET_CHAT_APP_ID,
    REACT_APP_COMET_CHAT_AUTH_KEY,
    REACT_APP_URBACON_COMET_CHAT_AUTH_KEY,
    REACT_APP_URBACON_COMET_CHAT_APP_ID,
} = process.env;

export const getBaseUrl = () => {
    switch (REACT_APP_STAGE) {
        case 'stage':
            return REACT_APP_STAGE_BASE_URL;
        case 'production':
            return REACT_APP_PRDO_BASE_URL;
        case 'sigma':
            return REACT_APP_SIGMA_BASE_URL;
        case 'urbacon':
            return REACT_APP_URBACON_BASE_URL;
        default:
            return REACT_APP_DEV_BASE_URL;
    }
};

export const getBucketName = () => {
    switch (REACT_APP_STAGE) {
        case 'stage':
            return REACT_APP_STAGE_BUCKET_NAME;
        case 'production':
            return REACT_APP_PROD_BUCKET_NAME;
        case 'sigma':
            return REACT_APP_SIGMA_BUCKET_NAME;
        case 'urbacon':
            return REACT_APP_URBACON_BUCKET_NAME;
        default:
            return REACT_APP_DEV_BUCKET_NAME;
    }
};

export const getCometChatAppId = () => {
    switch (REACT_APP_STAGE) {
        case 'stage':
            return REACT_APP_STAGE_COMET_CHAT_APP_ID;
        case 'production':
            return REACT_APP_PROD_COMET_CHAT_APP_ID;
        case 'sigma':
            return REACT_APP_SIGMA_COMET_CHAT_APP_ID;
        case 'urbacon':
            return REACT_APP_URBACON_COMET_CHAT_APP_ID;
        default:
            return REACT_APP_COMET_CHAT_APP_ID;
    }
};

export const getCometChatAuthKey = () => {
    switch (REACT_APP_STAGE) {
        case 'stage':
            return REACT_APP_STAGE_COMET_CHAT_AUTH_KEY;
        case 'production':
            return REACT_APP_PROD_COMET_CHAT_AUTH_KEY;
        case 'sigma':
            return REACT_APP_SIGMA_COMET_CHAT_AUTH_KEY;
        case 'urbacon':
            return REACT_APP_URBACON_COMET_CHAT_AUTH_KEY;
        default:
            return REACT_APP_COMET_CHAT_AUTH_KEY;
    }
};
