import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Breadcrumb, Layout, Card, Row, Col } from 'antd';
import { fetchTagDetails, fetchImageEquipmentDocs, saveEquipmentDocs } from '../../services';
import { setImageDocs, getEquipmentDocuments } from '../../store/equipment-docs';
import { SelectPackage } from './select-package';
import { ReviewTab } from './tabs/review';
import { ManufactureTab } from './tabs/manufacture';
import { InstallationTab } from './tabs/installation';
import { OperationsTab } from './tabs/operation';
import { PACKAGE_MENU } from '../../utils';
import '../../assets/styles/components/package-detail.scss';

const { Content } = Layout;

const TagDetail = ({ history, taskIbox = false }) => {
    const dispatch = useDispatch();
    const { tagId, projectId } = useParams();
    const {
        tag,
        equipments: { equipmentDocs },
    } = useSelector((state) => state);

    useEffect(() => {
        Promise.all([getTagDetail(tagId), getDocuments(tagId)]);
    }, [tagId]);

    const { operations } = useSelector(({ common }) => common);
    const [isLoading, setLoading] = useState(false);
    const [detail, setDetail] = useState([]);
    const [activeMenu, setActiveMenu] = useState(PACKAGE_MENU[0]);

    const getDocuments = async () => {
        dispatch(getEquipmentDocuments(projectId, tagId));
    };

    const getTagDetail = async (id) => {
        try {
            setLoading(true);
            const response = await fetchTagDetails(id);
            setDetail(response);
            setLoading(false);
        } catch (ex) {
            setLoading(false);
        }
    };

    const getImageDocs = async (pkgId) => {
        try {
            setLoading(true);
            const response = await fetchImageEquipmentDocs(projectId, pkgId);
            dispatch(setImageDocs(response.data));
            setLoading(false);
        } catch (ex) {
            setLoading(false);
        }
    };

    const getClassName = (menuItem) => {
        return `arrow${activeMenu === menuItem ? '-highlight' : ''}`;
    };

    const uploadEquipmentDocs = async (fileList, key) => {
        try {
            setLoading(true);
            const formData = new FormData();
            fileList.map((file) => formData.append('files', file));
            formData.append('is_package', false);
            formData.append('dir_key', key);
            formData.append('project_id', projectId);
            formData.append('id', tagId);
            await saveEquipmentDocs(projectId, tagId, formData);
            getDocuments(tagId);
            setLoading(false);
        } catch (ex) {
            setLoading(false);
        }
    };

    return (
        <Content>
            <Card className="card_body">
                <div className="main_body" style={{ width: '100%' }}>
                    {!taskIbox && (
                        <div className="bread">
                            <SelectPackage
                                history={history}
                                id={Number(tagId)}
                                projectId={Number(projectId)}
                                list={tag?.list}
                            />
                            <div className="bread">
                                <Breadcrumb separator=">" className="mb-20">
                                    <Breadcrumb.Item>Alectify</Breadcrumb.Item>
                                    <Breadcrumb.Item onClick={() => history.goBack()}>
                                        <span className="cursor-pointer">Tag</span>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item href="">{detail?.name}</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </div>
                    )}
                    <div className="topMenu">
                        <Row>
                            {operations ? (
                                <Col span={6}>
                                    <span className="arrow-highlight">Operations</span>
                                </Col>
                            ) : (
                                PACKAGE_MENU.map((menuItem, index) => {
                                    return (
                                        <Col span={6} key={index}>
                                            <span
                                                className={getClassName(menuItem)}
                                                onClick={() => setActiveMenu(menuItem)}
                                            >
                                                {menuItem}
                                            </span>
                                        </Col>
                                    );
                                })
                            )}
                        </Row>
                    </div>
                    <div>
                        {operations ? (
                            <OperationsTab />
                        ) : (
                            <>
                                {activeMenu === 'Review' && (
                                    <ReviewTab
                                        isLoading={isLoading}
                                        detail={detail}
                                        tagId={tagId}
                                        projectId={projectId}
                                        equipmentDocs={equipmentDocs?.list}
                                        uploadDocs={uploadEquipmentDocs}
                                    />
                                )}
                                {activeMenu === 'Manufacture' && (
                                    <ManufactureTab
                                        isLoading={isLoading}
                                        equipments={equipmentDocs}
                                        uploadDocs={uploadEquipmentDocs}
                                    />
                                )}
                                {activeMenu === 'Shipment' && (
                                    <InstallationTab
                                        isLoading={isLoading}
                                        equipmentDocs={equipmentDocs?.list}
                                        uploadDocs={uploadEquipmentDocs}
                                    />
                                )}
                                {activeMenu === 'Operations' && (
                                    <OperationsTab
                                        isLoading={isLoading}
                                        equipmentDocs={equipmentDocs?.list}
                                        uploadDocs={uploadEquipmentDocs}
                                    />
                                )}
                            </>
                        )}
                    </div>
                </div>
            </Card>
        </Content>
    );
};

export default TagDetail;
