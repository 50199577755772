import { http } from '../utils/http';

export const fetchSinglelineDetails = async (projectId, slineId, type) => {
    try {
        const response = await http.get(
            `/projects/${projectId}/singlelines/${slineId}/?type=${type}`,
        );
        return response.data;
    } catch (ex) {
        console.log({ ...ex });
    }
};

export const fetchTagsBySline = async (projectId, slineId) => {
    try {
        const response = await http.get(`/projects/${projectId}/singlelines/${slineId}/tags/`);
        return response.data;
    } catch (ex) {
        console.log({ ...ex });
    }
};

export const fetchSlinesByProject = async (projectId) => {
    try {
        const response = await http.get(`/projects/${projectId}/singlelines/`);
        return response.data;
    } catch (ex) {
        console.log(ex);
    }
};

export const fetchCalibratedDetailsBySlines = async (projectId, slineId, type) => {
    try {
        const response = await http.get(
            `/projects/${projectId}/singlelines/${slineId}/calibrated/?type=${type}`,
        );
        return response.data;
    } catch (ex) {
        console.log(ex);
    }
};

export const fetchCalibratedBySlines = async (projectId, slineId, body) => {
    try {
        const response = await http.post(
            `/projects/${projectId}/singlelines/${slineId}/calibrated/`,
            body,
        );
        return response.data;
    } catch (ex) {
        console.log(ex);
    }
};

export const updateTagsCalibrated = async (projectId, slineId, body) => {
    try {
        const response = await http.patch(
            `/projects/${projectId}/singlelines/${slineId}/calibrated/`,
            body,
        );
        return response.data;
    } catch (ex) {
        console.log(ex);
    }
};

export const rotateSlineCalibrated = async (projectId, slineId, body) => {
    try {
        const response = await http.put(
            `/projects/${projectId}/singlelines/${slineId}/calibrated/`,
            body,
        );
        return response.data;
    } catch (ex) {
        console.log(ex);
    }
};
