import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Breadcrumb, Layout, Card, Row, Col, Modal, Tooltip } from 'antd';
import { InfoCircleFilled, LeftOutlined, RightOutlined } from '@ant-design/icons';
import {
    fetchPackageDetails,
    fetchImageEquipmentDocs,
    saveEquipmentDocs,
    saveEquipmentDocsForVideos,
} from '../../services';
import { setImageDocs, getEquipmentDocuments } from '../../store/equipment-docs';
import { SelectPackage } from './select-package';
import { PACKAGE_MENU } from '../../utils';
import { ReviewTab } from './tabs/review';
import { ManufactureTab } from './tabs/manufacture';
import { InstallationTab } from './tabs/installation';
import { OperationsTab } from './tabs/operation';
import { CometChatMessages } from '../../../lib/comet-chat';
import { uploadAfterShipmentImages, uploadBeforeShipmentImages } from '../../services/shipment';
import { feature_inbox_groups_chats, feature_review_process } from '../../utils/features';
import { setActiveProjectTab } from '../../store/common';
import flowChart from '../../assets/images/flowChart.png';
import '../../assets/styles/components/package-detail.scss';

const { Content } = Layout;

const PackageDetail = ({ history, taskIbox = false }) => {
    const dispatch = useDispatch();
    const { user } = useSelector(({ auth }) => auth);
    const { pkgId, projectId } = useParams();
    const [isLoading, setLoading] = useState(false);
    const [detail, setDetail] = useState([]);
    const [activeMenu, setActiveMenu] = useState(
        feature_review_process(user.features) ? PACKAGE_MENU[0] : PACKAGE_MENU[1],
    );
    const [check, setCheck] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const {
        packages: { allPackages },
        equipments: { equipmentDocs, loading },
    } = useSelector((state) => state);

    useEffect(() => {
        Promise.all([getPackageDetail(pkgId), getDocuments(pkgId)]);
    }, [pkgId]);

    const getDocuments = () => {
        dispatch(getEquipmentDocuments(projectId, pkgId));
    };

    const getPackageDetail = async () => {
        try {
            setLoading(true);
            const response = await fetchPackageDetails(projectId, pkgId);
            setDetail(response.data);
            setLoading(false);
        } catch (ex) {
            setLoading(false);
        }
    };

    const uploadEquipmentDocs = async (fileList, key) => {
        try {
            setLoading(true);
            const formData = new FormData();
            fileList.map((file) => formData.append('files', file));
            formData.append('is_package', true);
            formData.append('dir_key', key);
            formData.append('project_id', projectId);
            formData.append('id', pkgId);
            await saveEquipmentDocs(projectId, pkgId, formData);
            setLoading(false);
            getDocuments(pkgId);
        } catch (ex) {
            setLoading(false);
        }
    };

    const uploadEquipmentDocsForVideos = async (fileList, key) => {
        try {
            setLoading(true);
            const formData = new FormData();
            fileList.map((file) => formData.append('files', file));
            formData.append('project', projectId);
            formData.append('package', pkgId);
            await saveEquipmentDocsForVideos(formData);
            setLoading(false);
            getDocuments(pkgId);
        } catch (ex) {
            setLoading(false);
        }
    };

    const uploadBeforeImg = async (fileList) => {
        try {
            const formData = new FormData();
            fileList.map((file) => formData.append('files', file));
            const response = await uploadBeforeShipmentImages(projectId, pkgId, formData);
        } catch (ex) {
            throw ex;
        }
    };

    const uploadAfterImg = async (fileList) => {
        try {
            const formData = new FormData();
            fileList.map((file) => formData.append('files', file));
            await uploadAfterShipmentImages(projectId, pkgId, formData);
        } catch (ex) {
            throw ex;
        }
    };

    const getImageDocs = async (pkgId) => {
        try {
            setLoading(true);
            const response = await fetchImageEquipmentDocs(projectId, pkgId);
            dispatch(setImageDocs(response.data));
            setLoading(false);
        } catch (ex) {
            setLoading(false);
        }
    };

    const getClassName = (menuItem) => {
        return `packages-tab-menu ${activeMenu === menuItem ? 'active' : ''}`;
    };

    const commonProps = {
        pkgId,
        uploadDocs: uploadEquipmentDocs,
        equipmentDocs: equipmentDocs.list,
        uploadForVideos: uploadEquipmentDocsForVideos,
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    return (
        <Content>
            <Modal
                title="Flow Chart"
                className="flow-chart-body"
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={null}
            >
                <img className="modal-img" src={flowChart} />
            </Modal>
            <Card className="card_body">
                <div className="main_body" style={{ width: '100%' }}>
                    {!taskIbox && (
                        <div className="bread justify-space-between mb-15">
                            <div className="d-flex align-items-center">
                                <SelectPackage
                                    history={history}
                                    pkgId={pkgId}
                                    list={allPackages}
                                    projectId={projectId}
                                />
                                <div className="bread">
                                    <Breadcrumb separator=">">
                                        <Breadcrumb.Item>Alectify</Breadcrumb.Item>
                                        <Breadcrumb.Item
                                            onClick={() => {
                                                dispatch(setActiveProjectTab('5'));
                                                history.push('/projects');
                                            }}
                                        >
                                            <span className="cursor-pointer">Packages</span>
                                        </Breadcrumb.Item>
                                        <Breadcrumb.Item>{detail?.name}</Breadcrumb.Item>
                                    </Breadcrumb>
                                </div>
                            </div>
                            <div className="d-flex align-items-center">
                                <div className="packages-tabs">
                                    {PACKAGE_MENU.map((menuItem) => {
                                        if (
                                            menuItem === 'Review' &&
                                            !feature_review_process(user.features)
                                        ) {
                                            return null;
                                        }
                                        return (
                                            <span
                                                className={getClassName(menuItem)}
                                                onClick={() => setActiveMenu(menuItem)}
                                            >
                                                {menuItem}
                                            </span>
                                        );
                                    })}
                                </div>
                                <div className="info-icon-main cursor-pointer">
                                    <Tooltip title="Flow Chart">
                                        <InfoCircleFilled
                                            onClick={showModal}
                                            className="info-icon"
                                        />
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                    )}
                    <div>
                        {activeMenu === 'Review' && (
                            <ReviewTab
                                projectId={projectId}
                                detail={{ ...detail }}
                                getPackageDetail={getPackageDetail}
                                {...commonProps}
                                isLoading={isLoading}
                            />
                        )}
                        {activeMenu === 'Manufacturing' && (
                            <ManufactureTab {...commonProps} isLoading={isLoading} />
                        )}
                        {activeMenu === 'Shipment' && (
                            <InstallationTab
                                {...commonProps}
                                uploadAfterImg={uploadAfterImg}
                                uploadBeforeImg={uploadBeforeImg}
                            />
                        )}
                        {activeMenu === 'Operations' && <OperationsTab {...commonProps} />}
                    </div>
                </div>
                {detail?.comet_guid && feature_inbox_groups_chats(user.features) && (
                    <>
                        <input
                            className="checkBox-input"
                            type="checkbox"
                            name=""
                            id="check"
                            checked={check}
                            onChange={() => setCheck(!check)}
                        />
                        <div className="quick-chat">
                            <label htmlFor="check">
                                <span className="show" id="show">
                                    <LeftOutlined />
                                </span>
                                <span className="show" id="hide">
                                    <RightOutlined />
                                </span>
                            </label>
                            <div className="comet-chat-body">
                                <CometChatMessages chatWithGroup={detail?.comet_guid} />
                            </div>
                        </div>
                    </>
                )}
            </Card>
        </Content>
    );
};

export default PackageDetail;
