import {
    UPDATE_APPROVAL,
    GET_APPROVALS_FAILURE,
    GET_APPROVALS_INITIATE,
    GET_APPROVALS_SUCCESS,
    GET_MARKUPS_FAILURE,
    GET_MARKUPS_INITIATE,
    GET_MARKUPS_SUCCESS,
    REMOVE_MARKUP_FILE
} from './types';
import { RESET_STATE } from '../common';
import { APPROVAL_STATUSES } from '../../utils';

const initialState = {
    approvalsLoading: false,
    markupsLoading: false,
    error: null,
    approvals: [],
    markups: [],
    approvalsForReview: [],
};

export const approvalReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_APPROVALS_INITIATE:
            return { ...state, approvalsLoading: true };

        case GET_APPROVALS_SUCCESS:
            const approvalsForReview = payload.filter(
                (approval) =>
                    !approval.is_markup_uploaded &&
                    approval.approval_status === APPROVAL_STATUSES.SEND_FOR_APPROVAL,
            );
            return { ...state, approvalsLoading: false, approvals: payload, approvalsForReview };

        case GET_APPROVALS_FAILURE:
            return { ...state, approvalsLoading: false, error: payload };

        case GET_MARKUPS_INITIATE:
            return { ...state, markupsLoading: true };

        case GET_MARKUPS_SUCCESS:
            return { ...state, markupsLoading: false, markups: payload };

        case GET_MARKUPS_FAILURE:
            return { ...state, markupsLoading: false, error: payload };

        case UPDATE_APPROVAL: {
            const updatedApprovals = state.approvalsForReview.map((approval) => {
                if (approval.id === payload.id) {
                    return { ...approval, ...payload.data };
                }
                return approval;
            });
            return { ...state, approvalsForReview: [...updatedApprovals] };
        }

        case REMOVE_MARKUP_FILE:
            const updatedApprovals = state.approvalsForReview.map((approval) => {
                if (approval.id === payload.id) {
                    delete approval.file;
                    return approval;
                }
                return approval;
            });
            return { ...state, approvalsForReview: [...updatedApprovals] };

        case RESET_STATE:
            return initialState;

        default:
            return state;
    }
};
