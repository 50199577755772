
/* 
--------------------
View Permissions
--------------------
*/

export const can_view_analytics = (permission) => {
    return permission?.can_view_analytics;
};

export const can_view_master_tags = (permission) => {
    return permission?.can_view_master_tags;
};

export const can_view_singlelines = (permission) => {
    return permission?.can_view_singlelines;
};

export const can_view_packages = (permission) => {
    return permission?.can_view_packages;
};

export const can_view_project_document = (permission) => {
    return permission?.can_view_project_document;
};

export const can_view_insights = (permission) => {
    return permission?.can_view_insights;
};

export const can_view_dashboard = (permission) => {
    return permission?.can_view_dashboard;
};

export const can_view_reports = (permission) => {
    return permission?.can_view_master_tags;
};
/* 
--------------------
Edit Permissions
--------------------
*/

export const can_edit_master_tags = (permission) => {
    return permission?.can_edit_master_tags;
};

export const can_edit_packages = (permission) => {
    return permission?.can_edit_packages;
};

export const can_edit_project = (permission) => {
    return permission?.can_edit_project;
};

export const can_edit_project_document = (permission) => {
    return permission?.can_edit_project_document;
};

export const can_edit_singlelines = (permission) => {
    return permission?.can_edit_singlelines;
};

/* 
--------------------
Add Permissions
--------------------
*/

export const can_add_packages = (permission) => {
    return permission?.can_add_packages;
};

export const can_add_project = (permission) => {
    return permission?.can_add_project;
};

export const can_add_project_document = (permission) => {
    return permission?.can_add_project_document;
};