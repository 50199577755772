import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Col, Row, Form, Input, Button, notification } from 'antd';
import { useParams } from 'react-router-dom';
import { resetPassword, verifyResetPasswordToken } from '../../../../app/services';
import authBanner from '../../../../app/assets/images/auth-banner.jpg';
import './style.scss';

const ResetPassword = ({ history }) => {
    const { uidb64, token } = useParams();
    const { user } = useSelector(({ auth }) => auth);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!_.isEmpty(user)) {
            history.push('/projects');
        }
    }, []);

    const onSubmit = async (values) => {
        try {
            setLoading(true);
            const verifyToken = await verifyResetPasswordToken(uidb64, token);
            if (verifyToken.success) {
                const body = {
                    password: values.password,
                    uidb64,
                    token,
                };
                const response = await resetPassword(body);
                if (response) {
                    notification.success({
                        message: 'Password changed',
                        description:
                            'Your password has been successfully changed, please login to your account',
                        duration: 5000,
                    });
                    setTimeout(() => {
                        history.push('/login');
                    }, 2000);
                }
            } else {
                notification.error({
                    message: 'Invalid operation',
                    description: 'The reset link is invalid, please request a new one',
                });
            }
        } catch (error) {
            notification.error({
                message: 'Invalid operation',
                description: 'The reset link is invalid, please request a new one',
            });
        } finally {
            setLoading(false);
            form.resetFields();
        }
    };

    return (
        <Row className="auth-container height-100vh" align="middle">
            <Col span={12}>
                <img src={authBanner} className="auth-image" />
            </Col>
            <Col span={12}>
                <div className="auth-form-container">
                    <div className="auth-form-header">
                        <h3>
                            Welcome to <span className="color-primary">Alectify!</span>
                        </h3>
                        <p>Reset your account password</p>
                    </div>
                    <Form
                        name="basic"
                        layout="vertical"
                        onFinish={onSubmit}
                        onFinishFailed={() => {}}
                        autoComplete="off"
                        form={form}
                    >
                        {' '}
                        <Form.Item
                            label="New Password"
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter your password',
                                },
                                {
                                    min: 6,
                                    message: 'Password must contain atleast 6 characters.',
                                },
                            ]}
                            hasFeedback
                        >
                            <Input.Password />
                        </Form.Item>
                        <Form.Item
                            name="confirmPassword"
                            label="Confirm New Password"
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'Please confirm your password!',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(
                                            new Error(
                                                'The two passwords that you entered do not match!',
                                            ),
                                        );
                                    },
                                }),
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>
                        <Form.Item className="mt-50">
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="btn-lg btn-primary-lg"
                                loading={loading}
                            >
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Col>
        </Row>
    );
};

export default ResetPassword;
