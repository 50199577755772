import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spin, Tabs } from 'antd';
import { UsersIcon } from '../../../../components/users-icon';
import { getApprovals, getMarkups } from '../../../../store/approval';
import { EquipmentDocs } from '../../equipment_docs';
import Overview from '../overview';
import Approval from './approval';
import Status from './status';
import Review from './review';
import Kanban from './kanban';
import { feature_equipment_documents, feature_kanban } from '../../../../utils/features';
import { SubscriptionMessage } from '../../../../components';

const { TabPane } = Tabs;

export const ReviewTab = ({
    detail,
    pkgId = '',
    projectId = '',
    isLoading = false,
    equipmentDocs = [],
    uploadDocs = () => {},
    uploadForVideos = () => {},
    getPackageDetail = () => {},
}) => {
    const dispatch = useDispatch();
    const { approvalsLoading, markupsLoading } = useSelector(({ approval }) => approval);
    const { user } = useSelector(({ auth }) => auth);
    const [activeTab, setActiveTab] = useState('1');
    const [markupDetail, setMarkupDetail] = useState([]);

    const getInitialData = () => {
        Promise.all([dispatch(getApprovals(pkgId)), dispatch(getMarkups(pkgId))]);
    };

    useEffect(() => {
        setActiveTab('1');
        getInitialData();
    }, [pkgId]);

    const UserDetails = () => (
        <UsersIcon
            heading={detail?.name}
            isReleased={detail?.is_released}
            teamMembers={detail?.team_members}
        />
    );

    return (
        <Spin spinning={approvalsLoading || markupsLoading}>
            <Tabs
                tabPosition={'left'}
                activeKey={activeTab}
                defaultActiveKey={activeTab}
                onChange={setActiveTab}
            >
                <TabPane tab="Status" key="1">
                    {UserDetails()}
                    {activeTab === '1' && <Status tags={detail?.tags} pkgId={pkgId} />}
                </TabPane>
                {detail?.is_approval && (
                    <TabPane tab="Approval" key="2">
                        {UserDetails()}
                        {activeTab === '2' && (
                            <Approval
                                pkgId={pkgId}
                                projectId={projectId}
                                isReleased={detail?.is_released}
                                setMarkupDetail={setMarkupDetail}
                            />
                        )}
                    </TabPane>
                )}
                {detail?.is_approval && (
                    <TabPane tab="Review" key="3">
                        {UserDetails()}
                        {activeTab === '3' && (
                            <Review
                                pkgId={pkgId}
                                detail={detail}
                                projectId={projectId}
                                isReleased={detail?.is_released}
                                getPackageDetail={getPackageDetail}
                                setMarkupDetail={setMarkupDetail}
                            />
                        )}
                    </TabPane>
                )}
                <TabPane tab="Analytics" key="4">
                    <Overview />
                </TabPane>
                {detail?.is_approval && feature_kanban(user.features) && (
                    <TabPane tab="Kanban" key="5">
                        <Kanban
                            projectId={projectId}
                            pkgId={pkgId}
                            markupDetail={markupDetail}
                            setMarkupDetail={setMarkupDetail}
                        />
                    </TabPane>
                )}

                <TabPane tab="Documents" key="6">
                    {feature_equipment_documents(user.features) ? (
                        <EquipmentDocs
                            pkgId={pkgId}
                            projectId={projectId}
                            data={equipmentDocs}
                            isLoading={isLoading}
                            uploadDocs={uploadDocs}
                            uploadForVideos={uploadForVideos}
                        />
                    ) : (
                        <SubscriptionMessage />
                    )}
                </TabPane>
            </Tabs>
        </Spin>
    );
};
