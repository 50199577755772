import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import { Collapse, Space, Form, Input, Button, Row, Col, Empty, Modal } from 'antd';
import { saveAs } from 'file-saver';
import { PdfEditor } from '../../../components/pdf-editor';
import pdfIcon from '../../../assets/images/pdf-icon.png';
import imageIcon from '../../../assets/images/img-icon.png';
import textIcon from '../../../assets/images/text-icon.png';
import '../../../assets/styles/components/documents.scss';

const { Panel } = Collapse;

const Documentation = ({ goBack = () => {}, onSubmit = () => {}, documents = [] }) => {
    const [editModal, setEditModal] = useState(false);
    const [editDoc, setEditDoc] = useState('');

    const downloadDocument = async ({ file_path, file_name }) => {
        const fileExt = file_name.split('.').pop();
        // if (fileExt === 'pdf') {
        //     setEditDoc(file_path);
        //     setEditModal(true);
        // } else {
        saveAs(file_path, file_name);
        // }
    };

    const extractIcon = (fileName) => {
        const fileExt = fileName.split('.').pop();
        if (fileExt === 'pdf') {
            return pdfIcon;
        } else if (['jpeg', 'png', 'jpg', 'svg'].includes(fileExt)) {
            return imageIcon;
        } else {
            return textIcon;
        }
    };

    return (
        <>
            <h2 className="step-heading mb-20">Upload Documentation</h2>
            <Form onFinish={onSubmit}>
                <Space direction="vertical" style={{ width: '100%' }}>
                    {!isEmpty(documents) &&
                        documents.map((project, index) => {
                            return (
                                <>
                                    {project.dir_key === 'single_lines' && (
                                        <h2 className="step-heading mt-10 mb-10">
                                            Bird's Eye View
                                        </h2>
                                    )}
                                    <Collapse
                                        className="alectify-collapse document-collapse"
                                        key={index}
                                    >
                                        <Panel header={project.dir_value}>
                                            <Row gutter={15}>
                                                <Col>
                                                    <Form.Item
                                                        name={project.dir_key}
                                                        valuePropName={project.dir_key}
                                                    >
                                                        <Input
                                                            className="file_upload"
                                                            type="file"
                                                            multiple
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row>
                                                {isEmpty(project.dir_files) ? (
                                                    <Col span={24} className="text-center">
                                                        <Empty />
                                                    </Col>
                                                ) : (
                                                    project.dir_files.map((fileData, idx) => {
                                                        return (
                                                            <Col span={3} key={idx}>
                                                                <div
                                                                    className="folder-view"
                                                                    onClick={() =>
                                                                        downloadDocument(fileData)
                                                                    }
                                                                >
                                                                    <div className="icon">
                                                                        <img
                                                                            src={extractIcon(
                                                                                fileData.file_name,
                                                                            )}
                                                                            style={{
                                                                                maxWidth: '100%',
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className="folder-name">
                                                                        <h4>
                                                                            {fileData.file_name}
                                                                        </h4>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        );
                                                    })
                                                )}
                                            </Row>
                                            {/* <Modal
                                        width="100%"
                                        title="Edit Document"
                                        className="edit-doc-modal"
                                        style={{ top: 0, bottom: 0 }}
                                        visible={editModal}
                                        onOk={() => setEditModal(false)}
                                        onCancel={() => setEditModal(false)}
                                        footer={null}
                                        centered
                                    >
                                        <PdfEditor pdf={editDoc} s3Key={editDoc} key={editDoc} setEditModal={setEditModal} />
                                    </Modal> */}
                                        </Panel>
                                    </Collapse>
                                </>
                            );
                        })}
                </Space>
                <div className="text-right">
                    <Space>
                        <Button
                            className="btn-lg btn-default save-btn mt-30"
                            type="default"
                            htmlType="button"
                            onClick={goBack}
                        >
                            Previous
                        </Button>
                        <Button className="btn-lg save-btn mt-30" type="primary" htmlType="submit">
                            Save & Finish
                        </Button>
                    </Space>
                </div>
            </Form>
        </>
    );
};

export default Documentation;
