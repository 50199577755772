import _ from 'lodash';
import axios from 'axios';
import moment from 'moment';
import Swal from 'sweetalert2';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveAs } from 'file-saver';
import {
    Progress,
    Avatar,
    Select,
    Button,
    Upload,
    Tooltip,
    notification,
    Space,
    Collapse,
    Table,
    Dropdown,
    Menu,
    Modal,
} from 'antd';
import {
    UploadOutlined,
    DownloadOutlined,
    CheckOutlined,
    EyeOutlined,
    DownOutlined,
} from '@ant-design/icons';
import {
    DISPLAY_DATETIME_FORMAT,
    MARKUP_COMMENTS,
    COMMENTS_VALUE,
    makeAvatar,
    datePercentage,
    MARKUP_TYPES,
} from '../../../../utils';
import {
    uploadDocuments,
    releaseMarkup,
    getSignedUrl,
    createNotification,
    fetchMarkup,
} from '../../../../services';
import {
    updateApproval,
    getMarkups,
    getApprovals,
    removeMarkupFile,
} from '../../../../store/approval';
import { PdfEditor } from '../../../../components';

const { Option } = Select;
const { Panel } = Collapse;

const Review = ({
    pkgId,
    projectId,
    isReleased = false,
    getPackageDetail = () => {},
    setMarkupDetail,
}) => {
    const dispatch = useDispatch();
    const { markups, approvalsLoading, markupsLoading, approvalsForReview } = useSelector(
        ({ approval }) => approval,
    );
    const [loading, setLoading] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [currentDocument, setCurrentDocument] = useState('');
    const [fileName, setFileName] = useState('');
    const [currentRecord, setCurrentRecord] = useState({});

    const getInitialData = () => {
        Promise.all([dispatch(getApprovals(pkgId)), dispatch(getMarkups(pkgId))]);
    };

    const uploadPdf = async (blob) => {
        addMarkupInApproval(blob, currentRecord.id, fileName);
    };

    const releaseOne = async (approval) => {
        if (!approval?.document_type) {
            notification.warning({ description: 'Type are required to create markups' });
            return;
        }

        if (approval.document_type === MARKUP_TYPES.APPROVAL && !approval?.markup_file_path && !approval.temp_file_name) {
            notification.warning({ description: 'Upload markup file' });
            return;
        }

        if (approval.document_type === MARKUP_TYPES.APPROVAL && !approval?.markup_comment) {
            notification.warning({ description: 'Comments are required to create markups' });
            return;
        }

        const sendForRelease = {
            package: pkgId,
            approval: approval.id,
            is_markup_uploaded: true,
            document_type: approval?.document_type,
            markup_comment: approval?.markup_comment,
        };

        await releaseMarkup(pkgId, [sendForRelease]);
        const markups = await fetchMarkup(projectId, pkgId);

        setMarkupDetail(markups.data);
        sendNotificationForMarkup('Markup files have been uploaded');
        getInitialData();
    };

    const sendNotificationForMarkup = async (msg) => {
        const message = {
            package: pkgId,
            message: msg,
        };
        await createNotification(projectId, message);
    };

    const handleDownload = async (filePath) => {
        if (filePath) {
            const key = `${projectId}/approvalprocess/${pkgId}/${filePath}`;
            const url = await getSignedUrl(key);
            await axios.get(url.data, { headers: { 'Content-Disposition': 'attachment' } });
            saveAs(url.data, filePath);
        }
    };

    const handlePdfEditor = async (filePath, fileName) => {
        if (filePath) {
            const key = `${projectId}/approvalprocess/${pkgId}/${filePath}`;
            const url = await getSignedUrl(key);
            setCurrentDocument(url.data);
            setFileName(`${fileName}`);
            setEditModal(true);
        }
    };

    const handleView = async (filePath) => {
        if (filePath) {
            const key = `${projectId}/approvalprocess/${pkgId}/${filePath}`;
            const url = await getSignedUrl(key);
            window.open(url.data, '_blank');
        }
    };

    const onReleaseAll = async () => {
        let payload = approvalsForReview.filter((approval) => approval.markup_comment);
        if (payload.length !== approvalsForReview.length) {
            notification.warning({ description: 'Comments are required to create markups' });
            setLoading(false);
            return;
        }

        Swal.fire({
            icon: 'warning',
            title: '',
            text: 'Once Released You will not be able to upload any more Approval drawings or Markups. Do you want to proceed?',
            showCancelButton: true,
            confirmButtonText: 'Proceed',
            backdrop: true,
            confirmButtonColor: '#16aaff',
        }).then(async (result) => {
            if (result.isConfirmed) {
                setLoading(true);
                let reqBody = [];
                for (const approval of payload) {
                    const sendForRelease = {
                        package: pkgId,
                        is_released: true,
                        approval: approval.id,
                        is_markup_uploaded: true,
                        markup_comment: approval.markup_comment,
                    };
                    reqBody.push(sendForRelease);
                }
                if (_.isEmpty(payload) && _.isEmpty(approvalsForReview)) {
                    reqBody = [{ is_released: true }];
                }
                const released = await releaseMarkup(pkgId, reqBody);
                const markups = await fetchMarkup(projectId, pkgId);

                setMarkupDetail(markups.data);
                if (released?.success) {
                    sendNotificationForMarkup(
                        'The Package is released and the review process is closed now',
                    );
                    getInitialData();
                    getPackageDetail();
                }
                setLoading(false);
            }
        });
    };

    const addCommentInApproval = (markup_comment, approvalId) => {
        dispatch(updateApproval(approvalId, { markup_comment }));
    };

    const addTypeInMarkup = (document_type, markupId) => {
        dispatch(updateApproval(markupId, { document_type }));
    };

    const removeMarkup = (approvalId) => {
        dispatch(removeMarkupFile(approvalId));
    };

    const addMarkupInApproval = async (file, approvalId, temp_file_name) => {
        try {
            setLoading(true);
            dispatch(updateApproval(approvalId, { file, temp_file_name }));
            const formData = new FormData();
            formData.append('files', file, `${temp_file_name}`);
            formData.append('project_id', projectId.toString());
            formData.append('generate_markup', true);
            const response = await uploadDocuments(projectId, pkgId, formData);
            const reqBody = {
                approval: approvalId,
                markup_file_path: response.data[0]?.path,
                markup_file_name: response.data[0]?.fileName,
            };
            await releaseMarkup(pkgId, [reqBody]);
            setLoading(false);
        } catch (ex) {
            console.log(ex);
            setLoading(false);
        }
    };

    const MARKUP_COLUMNS = [
        {
            title: 'Type',
            dataIndex: 'document_type',
        },
        {
            title: 'Original Received',
            dataIndex: 'approval_file_name',
            render: (_, record) => (
                <Space>
                    <Tooltip title={`View ${record?.approval_file_name}`}>
                        <EyeOutlined
                            className="icon-inner-delete"
                            onClick={() => handleView(record.approval_file_path)}
                        />
                    </Tooltip>
                    <Tooltip title={`Download ${record?.approval_file_name}`}>
                        <DownloadOutlined
                            className="icon-inner-delete"
                            onClick={() => handleDownload(record.approval_file_path)}
                        />
                    </Tooltip>
                    {record.approval_file_name}
                </Space>
            ),
        },
        {
            title: 'Markup Uploaded',
            dataIndex: 'markup_file_name',
            render: (markup_file_name, markup) =>
                !_.isEmpty(markup_file_name) && (
                    <Space>
                        <Tooltip title={`View ${markup.markup_file_name}`}>
                            <EyeOutlined
                                className="icon-inner-delete"
                                onClick={() => handleView(markup.markup_file_path)}
                            />
                        </Tooltip>
                        <Tooltip title={`Download ${markup.markup_file_name}`}>
                            <DownloadOutlined
                                className="icon-inner-delete"
                                onClick={() => handleDownload(markup.markup_file_path)}
                            />
                        </Tooltip>
                        {markup.markup_file_name}
                    </Space>
                ),
        },
        {
            title: 'Comments',
            dataIndex: 'markup_comment',
            render: (comment) => COMMENTS_VALUE[comment],
        },
        {
            title: 'Due Date',
            dataIndex: 'approval_due_date',
            render: (value, record) => (
                <>
                    {moment(value).format(DISPLAY_DATETIME_FORMAT)}
                    <Progress
                        style={{ width: '20' }}
                        percent={datePercentage({
                            endDate: record.approval_due_date,
                            startDate: record.created_at,
                        })}
                        showInfo={false}
                    />
                </>
            ),
        },
        {
            title: 'Submitted at',
            dataIndex: 'updated_at',
            render: (value) => moment(value).format(DISPLAY_DATETIME_FORMAT),
        },
        {
            title: 'Submitted by',
            align: 'center',
            dataIndex: 'approval',
            render: (approval) => (
                <Tooltip title={`${approval?.account?.first_name} ${approval?.account?.last_name}`}>
                    <Avatar
                        className="circle-icon m-auto"
                        src={
                            approval?.account?.image ||
                            makeAvatar(
                                approval?.account?.first_name[0],
                                approval?.account?.last_name[0],
                            )
                        }
                    />
                </Tooltip>
            ),
        },
    ];

    const APPROVAL_COLUMNS = [
        {
            title: 'Type',
            dataIndex: 'document_type',
            width: 140,
            render: (_, record) => (
                <Select
                    style={{ width: '120px' }}
                    placeholder="Select type *"
                    onChange={(val) => addTypeInMarkup(val, record.id)}
                >
                    {Object.values(MARKUP_TYPES).map((item, index) => {
                        return (
                            <Option key={index} value={item}>
                                {item}
                            </Option>
                        );
                    })}
                </Select>
            ),
        },
        {
            title: 'Original Received',
            dataIndex: 'approval',
            render: (_, record) => (
                <Space>
                    <Tooltip title={`View ${record.approval_file_name}`}>
                        <EyeOutlined
                            className="icon-inner-delete"
                            onClick={() => handleView(record.approval_file_path)}
                        />
                    </Tooltip>
                    <Tooltip title={`Download ${record.approval_file_name}`}>
                        <DownloadOutlined
                            className="icon-inner-delete"
                            onClick={() => handleDownload(record.approval_file_path)}
                        />
                    </Tooltip>
                    {record.approval_file_name}
                </Space>
            ),
        },
        {
            title: 'Upload Markup',
            dataIndex: 'approval_file_name',
            render: (_, record) => (
                <>
                    <p style={{ marginBottom: 5 }}>
                        {record.temp_file_name || record.markup_file_name}
                    </p>
                    {record?.approval_file_name?.split('.').pop() === 'pdf' ? (
                        <Dropdown
                            overlay={() => (
                                <Menu>
                                    <Menu.Item
                                        key="1"
                                        onClick={() => {
                                            handlePdfEditor(
                                                record?.markup_file_path ||
                                                    record?.approval_file_path,
                                                record?.markup_file_name ||
                                                    record?.approval_file_name,
                                            );
                                            setCurrentRecord(record);
                                        }}
                                    >
                                        Edit & Save Markup
                                    </Menu.Item>
                                    <Menu.Item
                                        key="2"
                                        disabled={
                                            record.temp_file_name &&
                                            record.approval_file_name.split('.').pop() === 'pdf'
                                        }
                                    >
                                        <Upload
                                            beforeUpload={() => false}
                                            showUploadList={false}
                                            maxCount={1}
                                            onChange={(e) => {
                                                e.file.status !== 'removed' &&
                                                    addMarkupInApproval(
                                                        e.file,
                                                        record.id,
                                                        e.file.name,
                                                    );
                                            }}
                                            onRemove={() => removeMarkup(record.id)}
                                        >
                                            Upload From Local
                                        </Upload>
                                    </Menu.Item>
                                </Menu>
                            )}
                        >
                            <Button>
                                Add Markup <DownOutlined />
                            </Button>
                        </Dropdown>
                    ) : (
                        <Upload
                            beforeUpload={() => false}
                            showUploadList={false}
                            maxCount={1}
                            onChange={(e) => {
                                e.file.status !== 'removed' &&
                                    addMarkupInApproval(e.file, record.id, e.file.name);
                            }}
                            onRemove={() => removeMarkup(record.id)}
                        >
                            <Button icon={<UploadOutlined />}> Click to Upload </Button>
                        </Upload>
                    )}
                </>
            ),
        },
        {
            title: 'Comments',
            dataIndex: 'markup_comment',
            width: 250,
            render: (_, record) => (
                <>
                    {record?.document_type === MARKUP_TYPES.APPROVAL ? (
                        <Select
                            style={{ width: '100%' }}
                            placeholder="Select comment *"
                            onChange={(val) => addCommentInApproval(val, record.id)}
                        >
                            {MARKUP_COMMENTS.map((item, index) => {
                                return (
                                    <Option key={index} value={item.value}>
                                        {item.label}
                                    </Option>
                                );
                            })}
                        </Select>
                    ) : (
                        '-'
                    )}
                </>
            ),
        },
        {
            title: 'Due Date',
            dataIndex: 'approval_due_date',
            render: (value, record) => (
                <>
                    {moment(value).format(DISPLAY_DATETIME_FORMAT)}
                    <Progress
                        style={{ width: '20' }}
                        percent={datePercentage({
                            endDate: record.approval_due_date,
                            startDate: record.created_at,
                        })}
                        showInfo={false}
                    />
                </>
            ),
        },
        {
            title: 'Submitted by',
            align: 'center',
            dataIndex: 'approval',
            render: (_, record) => (
                <Tooltip title={`${record?.account?.first_name} ${record?.account?.last_name}`}>
                    <Avatar
                        className="circle-icon m-auto"
                        src={
                            record?.account?.image ||
                            makeAvatar(
                                record?.account?.first_name[0],
                                record?.account?.last_name[0],
                            )
                        }
                    />
                </Tooltip>
            ),
        },
        // {
        //     title: 'Submitted at',
        //     dataIndex: 'created_at',
        //     render: (value) => moment(value).format(DISPLAY_DATETIME_FORMAT),
        // },
        {
            title: 'Action',
            align: 'center',
            dataIndex: 'action',
            render: (_, record) => (
                <Button
                    type="primary"
                    shape="round"
                    size="small"
                    onClick={() => releaseOne(record)}
                >
                    Relese
                </Button>
            ),
        },
    ];

    const onClosePdfEditor = () => {
        setEditModal(false);
        setFileName('');
        setCurrentDocument('');
    };

    return (
        <div className="mb-40">
            <Collapse
                bordered={false}
                defaultActiveKey={[isReleased ? '2' : '1']}
                className="site-collapse-custom-collapse"
            >
                {!isReleased && (
                    <Panel
                        key="1"
                        header="Documents For Review"
                        className="site-collapse-custom-panel"
                    >
                        <Table
                            rowKey="id"
                            loading={approvalsLoading || loading}
                            columns={APPROVAL_COLUMNS}
                            dataSource={approvalsForReview}
                            pagination={approvalsForReview?.length > 10}
                        />
                        <div className="text-right mt-10">
                            <Button
                                type="primary"
                                onClick={onReleaseAll}
                                loading={approvalsLoading || loading}
                            >
                                Release All
                            </Button>
                        </div>
                    </Panel>
                )}
                <Panel
                    key="2"
                    header="Submitted Documents"
                    className="site-collapse-custom-panel-grey"
                >
                    <Table
                        rowKey="id"
                        dataSource={markups}
                        loading={markupsLoading || loading}
                        columns={MARKUP_COLUMNS}
                        pagination={markups.length > 10}
                    />
                </Panel>
            </Collapse>
            <Modal
                width="100%"
                title="Edit Document"
                className="edit-doc-modal"
                style={{ top: 0, bottom: 0 }}
                visible={editModal}
                onOk={() => setEditModal(false)}
                onCancel={() => setEditModal(false)}
                footer={null}
                centered
            >
                <PdfEditor
                    s3Key={currentDocument}
                    pdf={currentDocument}
                    uploadPdf={uploadPdf}
                    setEditModal={setEditModal}
                    onClosePdfEditor={onClosePdfEditor}
                />
            </Modal>
        </div>
    );
};

export default Review;
