import _ from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { EditOutlined, EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import {
    Table,
    Tooltip,
    Button,
    Space,
    Typography,
    Popconfirm,
    DatePicker,
    Form,
    Switch,
    Alert,
    Input,
} from 'antd';
import {
    CSV_DATE_FORMAT,
    DATE_FIELDS,
    DISPLAY_DATE_FORMAT,
    INPUT_DATE_FORMAT,
} from '../../../utils';

const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
}) => {
    let inputNode = null;
    if (inputType === 'datepicker') {
        inputNode = <DatePicker format={INPUT_DATE_FORMAT} />;
    } else {
        inputNode = <Input />;
    }

    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item name={dataIndex} style={{ margin: 0 }}>
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

const ManageEquipments = ({
    tags = [],
    goBack = () => {},
    setTags = () => {},
    onFinish = () => {},
    equipmentErrors = [],
}) => {
    const { operations } = useSelector(({ common }) => common);
    const [showDesc, setShowDesc] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState('');

    const isEditing = (record) => record.key === editingKey;

    const handleApprovalDrawing = (isChecked, record) => {
        const newTags = tags.map((tag) => {
            if (tag.name === record.name) {
                return {
                    ...tag,
                    approval_dwg: isChecked,
                };
            }
            return tag;
        });
        setTags([...newTags]);
    };

    const columns = [
        {
            title: 'Tag Name',
            dataIndex: 'name',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            render: (data, record) =>
                showDesc.includes(record.name) ? (
                    <>
                        {data}{' '}
                        <Tooltip title="Hide Description">
                            <EyeInvisibleOutlined
                                className="cursor-pointer"
                                onClick={() => hideDescription(record.name)}
                            />
                        </Tooltip>
                    </>
                ) : (
                    <Tooltip title="Show Description">
                        <EyeOutlined
                            className="cursor-pointer"
                            onClick={() => setShowDesc([...showDesc, record.name])}
                        />
                    </Tooltip>
                ),
        },
        {
            title: 'Approval',
            dataIndex: 'approval_dwg',
            render: (status, record) => (
                <Switch
                    checked={status}
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    onChange={(checked, event) => {
                        event.stopPropagation();
                        handleApprovalDrawing(checked, record);
                    }}
                />
            ),
        },
        {
            title: 'Manufacture',
            dataIndex: 'manufacture',
            render: (data) => data || '',
            editable: true,
            inputType: 'manufacture',
        },
        {
            title: 'Order Date',
            dataIndex: 'order_date',
            render: (data) =>
                data ? moment(data, CSV_DATE_FORMAT).format(DISPLAY_DATE_FORMAT) : '',
            editable: true,
            inputType: 'datepicker',
        },
        {
            title: 'Aprroval Date',
            dataIndex: 'approval_date',
            render: (data) =>
                data ? moment(data, CSV_DATE_FORMAT).format(DISPLAY_DATE_FORMAT) : '',
            editable: true,
            inputType: 'datepicker',
        },
        {
            title: 'Release Date',
            dataIndex: 'release_date',
            render: (data) =>
                data ? moment(data, CSV_DATE_FORMAT).format(DISPLAY_DATE_FORMAT) : '',
            editable: true,
            inputType: 'datepicker',
        },
        // {
        //     title: 'Final Date',
        //     dataIndex: 'final_date',
        //     render: data => (data ? moment(data, CSV_DATE_FORMAT).format(DISPLAY_DATE_FORMAT) : ''),
        //     editable: true,
        //     inputType: 'datepicker',
        // },
        {
            title: 'Test Date',
            dataIndex: 'test_date',
            render: (data) =>
                data ? moment(data, CSV_DATE_FORMAT).format(DISPLAY_DATE_FORMAT) : '',
            editable: true,
            inputType: 'datepicker',
        },
        {
            title: 'Ship Date',
            dataIndex: 'ship_date',
            render: (data) =>
                data ? moment(data, CSV_DATE_FORMAT).format(DISPLAY_DATE_FORMAT) : '',
            editable: true,
            inputType: 'datepicker',
        },
        {
            title: 'Action',
            dataIndex: '',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <Typography.Link
                            onClick={() => save(record.name)}
                            style={{
                                marginRight: 8,
                            }}
                        >
                            Save
                        </Typography.Link>
                        <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                            <a>Cancel</a>
                        </Popconfirm>
                    </span>
                ) : (
                    <Tooltip title="Edit">
                        <EditOutlined disabled={editingKey !== ''} onClick={() => edit(record)} />
                    </Tooltip>
                );
            },
        },
    ];

    const operation_columns = [
        {
            title: 'Tag Name',
            dataIndex: 'name',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            render: (data, record) =>
                showDesc.includes(record.name) ? (
                    <>
                        {data}{' '}
                        <Tooltip title="Hide Description">
                            <EyeInvisibleOutlined
                                className="cursor-pointer"
                                onClick={() => hideDescription(record.name)}
                            />
                        </Tooltip>
                    </>
                ) : (
                    <Tooltip title="Show Description">
                        <EyeOutlined
                            className="cursor-pointer"
                            onClick={() => setShowDesc([...showDesc, record.name])}
                        />
                    </Tooltip>
                ),
        },
        {
            title: 'Manufacture',
            dataIndex: 'manufacture',
            render: (data) => data || '',
            editable: false,
            inputType: 'manufacture',
        },
        {
            title: 'Installation Date',
            dataIndex: 'installation_date',
            render: (data) =>
                data ? moment(data, CSV_DATE_FORMAT).format(DISPLAY_DATE_FORMAT) : '',
            editable: true,
            inputType: 'datepicker',
        },
        {
            title: 'Maintenance (# of days)',
            dataIndex: 'maintenance_duration',
            editable: true,
        },
        {
            title: 'Serial Number',
            dataIndex: 'serial_number',
        },
        {
            title: 'Action',
            dataIndex: '',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <Typography.Link
                            onClick={() => save(record.name)}
                            style={{
                                marginRight: 8,
                            }}
                        >
                            Save
                        </Typography.Link>
                        <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                            <a>Cancel</a>
                        </Popconfirm>
                    </span>
                ) : (
                    <Tooltip title="Edit">
                        <EditOutlined disabled={editingKey !== ''} onClick={() => edit(record)} />
                    </Tooltip>
                );
            },
        },
    ];

    const hideDescription = (name) => {
        const newShowDesc = showDesc.filter((desc) => desc !== name);
        setShowDesc([...newShowDesc]);
    };

    const rowSelection = {
        type: 'checkbox',
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedTags([...selectedRows.map((row) => row.name)]);
        },
        getCheckboxProps: (record) => ({
            name: record.name,
        }),
    };

    const edit = (record) => {
        Object.keys(record).forEach((key) => {
            if (DATE_FIELDS.includes(key)) {
                record[key] = record[key] ? moment(record[key]) : null;
            }
        });
        form.setFieldsValue({ ...record });
        setEditingKey(record.key);
    };

    const cancel = () => {
        setEditingKey('');
    };

    const save = async (name) => {
        try {
            const row = await form.validateFields();
            Object.keys(row).forEach((key) => {
                if (DATE_FIELDS.includes(key)) {
                    row[key] = row[key]
                        ? moment(row[key], INPUT_DATE_FORMAT).format(CSV_DATE_FORMAT)
                        : null;
                }
            });
            const newData = [...tags];
            const index = newData.findIndex((item) => name === item.name);
            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, { ...item, ...row });
                setTags([...newData]);
                setEditingKey('');
            } else {
                setEditingKey('');
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const mergedColumns = (operations ? operation_columns : columns).map((col, indx) => {
        col.key = indx + 1;
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.inputType || 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    const onNext = () => {
        onFinish({ tags });
    };

    return (
        <>
            <h2 className="step-heading mb-20">Edit Equipment Information</h2>
            {!_.isEmpty(equipmentErrors) && (
                <Alert
                    message="Tags Errors"
                    showIcon
                    className="mb-20"
                    description={equipmentErrors.map((error, idx) => (
                        <span
                            className="d-block"
                            key={idx}
                            dangerouslySetInnerHTML={{ __html: error.message }}
                        ></span>
                    ))}
                    type="error"
                />
            )}
            {/* {selectedTags.length > 0 && (
                <div className="mb-10 text-right">
                    <Space>
                        <Button>Delete ({selectedTags.length})</Button>
                    </Space>
                </div>
            )} */}

            <Form form={form} component={false}>
                <Table
                    rowSelection={{
                        ...rowSelection,
                    }}
                    components={{
                        body: {
                            cell: EditableCell,
                        },
                    }}
                    dataSource={tags.map((tag, idx) => ({ ...tag, key: idx + 1 }))}
                    columns={mergedColumns}
                    className="taglist-table editable-row"
                    size="small"
                    pagination={tags.length > 10}
                />
            </Form>
            <div className="text-right">
                <Space>
                    <Button
                        className="btn-lg btn-default save-btn mt-30"
                        type="default"
                        htmlType="button"
                        onClick={goBack}
                    >
                        Previous
                    </Button>
                    <Button
                        className="btn-lg btn-primary-lg save-btn mt-30"
                        type="primary"
                        onClick={onNext}
                    >
                        Next
                    </Button>
                </Space>
            </div>
        </>
    );
};

export default ManageEquipments;
