import React from 'react';
import { useState } from 'react';
import { Tabs } from 'antd';

import Analytics from './analytics';
import Status from './status';
import { EquipmentDocs } from '../../equipment_docs';

const { TabPane } = Tabs;

export const ManufactureTab = ({
    isLoading = false,
    equipmentDocs = [],
    uploadDocs = () => {},
    pkgId,
}) => {
    const [activeTab, setActiveTab] = useState('1');
    return (
        <Tabs tabPosition={'left'} defaultActiveKey={activeTab} onChange={setActiveTab}>
            <TabPane className="side_tabs" tab=" Status" key="1">
                <Status
                    isLoading={isLoading}
                    uploadEquipmentDocs={uploadDocs}
                />
            </TabPane>
            <TabPane className="side_tabs" tab="Analytics" key="2">
                <Analytics />
            </TabPane>

            <TabPane tab="Documents" key="4">
                <EquipmentDocs
                    data={equipmentDocs}
                    pkgId={pkgId}
                    isLoading={isLoading}
                    uploadDocs={uploadDocs}
                />
            </TabPane>
        </Tabs>
    );
};
