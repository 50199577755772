
/* 
--------------------
Features Utils
--------------------
*/

export const feature_birds_eye_view = (feature) => {
    return feature?.birds_eye_view;
}

export const feature_birds_eye_view_tags = (feature) => {
    return feature?.birds_eye_view_tags;
}

export const feature_project_documents = (feature) => {
    return feature?.project_documents;
}

export const feature_equipment_documents = (feature) => {
    return feature?.equipment_documents;
}

export const feature_create_project = (feature) => {
    return feature?.create_project;
}

export const feature_manage_project = (feature) => {
    return feature?.manage_project;
}

export const feature_master_tags = (feature) => {
    return feature?.master_tags;
}

export const feature_reports = (feature) => {
    return feature?.reports;
}

export const feature_notifications = (feature) => {
    return feature?.notifications;
}

export const feature_insights_gantt_chart = (feature) => {
    return feature?.insights_gantt_chart;
}

export const feature_insights_gantt_chart_filter = (feature) => {
    return feature?.insights_gantt_chart_filter;
}

export const feature_review_process = (feature) => {
    return feature?.review_process;
}

export const feature_kanban = (feature) => {
    return feature?.kanban;
}

export const feature_inbox_groups_chats = (feature) => {
    return feature?.inbox_groups_chats;
}

export const feature_inbox_one_to_one_chats = (feature) => {
    return feature?.inbox_one_to_one_chats;
}
