import { combineReducers } from 'redux';
import { commonReducer } from './common';
import { singlelineReducer } from './singleline';
import { tagReducer } from './tags';
import { packageReducer } from './package';
import { approvalReducer } from './approval';
import { permissionReducer } from './permission';
import { authReducer } from './auth';
import { equipmentDocsReducer } from './equipment-docs';
import { documentReducer } from './documents';

export default combineReducers({
    auth: authReducer,
    common: commonReducer,
    singleLine: singlelineReducer,
    tags: tagReducer,
    packages: packageReducer,
    approval: approvalReducer,
    permission: permissionReducer,
    equipments: equipmentDocsReducer,
    documents: documentReducer
});
