import _ from 'lodash';
import { Empty, Radio } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ContainerOutlined } from '@ant-design/icons';
import { SidebarListItem } from '../../../components';
import { PROJECTS } from '../../../utils';
import { PROJECT_SETUP } from '../../../store/common';
import * as Permissions from '../../../utils/permissions';
import { feature_manage_project } from '../../../utils/features';

const ProjectsList = ({
    projects,
    onProjectClickHandler,
    activeProject,
    onProjectDBClickHandler,
    filtered,
}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { permissions } = useSelector(({ permission }) => permission);
    const { user } = useSelector(({ auth }) => auth);

    const onEditHandler = (ev, project) => {
        ev.stopPropagation();
        onProjectClickHandler(project);
        dispatch({ type: PROJECT_SETUP, payload: false });
        history.push(`${PROJECTS}/${project.id}?activeTab=1`);
    };

    return !_.isEmpty(projects) ? (
        projects.map((project) => (
            <SidebarListItem
                key={project?.id}
                title={project?.name}
                icon={ContainerOutlined}
                onDoubleClick={() => onProjectDBClickHandler(project.id, project.name)}
                addActiveClass={activeProject?.id === project?.id}
                onClick={() => onProjectClickHandler(project)}
                onEdit={(ev) => onEditHandler(ev, project)}
                editBtn={feature_manage_project(user.features)}
                isDraft={project.is_draft}
            />
        ))
    ) : (
        <Empty description="Project not found" />
    );
};

export default ProjectsList;
