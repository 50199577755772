import styled from 'styled-components';
import { Popover, Badge, Tag } from 'antd';
import { useRef } from 'react';

const Wrapper = styled.div`
    position: absolute;
    width: 50px;
    height: 37px;
    background-image: url(https://icon-library.com/images/pin-icon-png/pin-icon-png-9.jpg);
    background-size: contain;
    background-repeat: no-repeat;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    cursor: grab;
    font-weight: bold;
    text-align: center;
`;

export const Marker = ({ text = '', description = '', onClick = () => {} }) => {
    return (
        <>
            <Popover content={description} title={text} trigger="hover">
                <Wrapper alt={text} onClick={onClick}>
                    {text ? (
                        <Tag
                            color="#2db7f5"
                            style={{
                                marginTop: '35px',
                                position: 'relative',
                                width: 'auto',
                            }}
                        >
                            {text}
                        </Tag>
                    ) : (
                        // <Badge.Ribbon
                        //     text={text}
                        //     style={{
                        //         marginTop: '25px',
                        //         backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        //     }}
                        // ></Badge.Ribbon>
                        ''
                    )}
                </Wrapper>
            </Popover>
        </>
    );
};
