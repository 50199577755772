import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import * as L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { memo, useEffect, useRef } from 'react';
import { LayerGroup, Map, useLeaflet } from 'react-leaflet';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { fetchCalibratedBySlines } from '../../services';
import { feature_birds_eye_view_tags } from '../../utils/features';
import { MapMarkers } from './map-markers';
import './styles.css';

const MemorizeSinglelineViewer = ({
    stlineImage,
    imageHeight,
    imageWidth,
    slineId,
    tags = [],
    coords = [],
    setCoords,
    setFourCorners,
    slines,
    markersDraggable,
    fourCorners,
}) => {
    const { user } = useSelector(({ auth }) => auth);
    const mapRef = useRef(null);
    const divRef = useRef(null);
    const history = useHistory();
    const { pathname } = useLocation();
    const { activeProject } = useSelector(({ common }) => common);
    const mapLeaflet = useLeaflet();

    useEffect(() => {
        mapRef?.current?.leafletElement.setMaxZoom(markersDraggable ? 0 : 3);
    }, [markersDraggable]);

    useEffect(() => {
        if (stlineImage) {
            setImageOverlay();
        }
    }, [stlineImage]);

    const getCalibrated = async (data) => {
        const response = await fetchCalibratedBySlines(activeProject.id, slineId, data);

        const tagWithCoords = tags.map((tag) => {
            const coords = response.data.coords.find((coord) => {
                if (coord.tag.includes(tag.id)) {
                    return coord;
                }
            });
            let coordsChanges = coords;
            if (coords.x_axis === 0 && coords.y_axis === 0) {
                const xAxis = Math.random() * (data.dst_ne.x - data.dst_sw.x) + data.dst_sw.x;
                const yAxis = Math.random() * (data.dst_ne.y - data.dst_sw.y) + data.dst_sw.y;
                coordsChanges = { ...coords, x_axis: parseInt(xAxis), y_axis: parseInt(yAxis) };
            }

            return {
                ...tag,
                coords: coordsChanges,
            };
        });
        setCoords([...tagWithCoords]);
    };

    const setImageOverlay = async () => {
        const map = mapRef?.current?.leafletElement;

        const aspectRatio = (divRef.current.clientHeight * imageWidth) / imageHeight;
        var southWest = map.unproject([0, divRef.current.clientHeight]);
        var northEast = map.unproject([aspectRatio, 0]);
        var bounds = new L.LatLngBounds(southWest, northEast);

        if (fourCorners?.image) {
            map.removeLayer(fourCorners.image);
        }
        const image = new L.imageOverlay(stlineImage, bounds).addTo(map);

        setTimeout(async () => {
            const getNorthEast = map.latLngToLayerPoint(image.getBounds().getNorthEast());

            const getNorthWest = map.latLngToLayerPoint(image.getBounds().getNorthWest());

            const getSouthEast = map.latLngToLayerPoint(image.getBounds().getSouthEast());

            const getSouthWest = map.latLngToLayerPoint(image.getBounds().getSouthWest());
            setFourCorners({
                image: image,
                dst_ne: {
                    x: getNorthEast.x,
                    y: getNorthEast.y,
                },
                dst_nw: {
                    x: getNorthWest.x,
                    y: getNorthWest.y,
                },
                dst_se: {
                    x: getSouthEast.x,
                    y: getSouthEast.y,
                },
                dst_sw: {
                    x: getSouthWest.x,
                    y: getSouthWest.y,
                },
            });
            await getCalibrated({
                device_type: 'WEB',
                dst_ne: {
                    x: getNorthEast.x,
                    y: getNorthEast.y,
                },
                dst_nw: {
                    x: getNorthWest.x,
                    y: getNorthWest.y,
                },
                dst_se: {
                    x: getSouthEast.x,
                    y: getSouthEast.y,
                },
                dst_sw: {
                    x: getSouthWest.x,
                    y: getSouthWest.y,
                },
            });
        }, 0);

        map.fitBounds(image.getBounds());
    };

    const onNext = () => {
        const findIndex = slines.findIndex((sline) => sline === pathname);
        let newPathName = slines[findIndex + 1];

        if (findIndex === slines.length - 1) {
            newPathName = slines[0];
        }
        history.push({
            pathname: newPathName,
            state: activeProject?.name,
        });
    };

    const onPrev = () => {
        const findIndex = slines.findIndex((sline) => sline === pathname);
        let newPathName = slines[findIndex - 1];

        if (findIndex === 0) {
            newPathName = slines[slines.length - 1];
        }
        history.push({
            pathname: newPathName,
            state: activeProject?.name,
        });
    };

    return (
        <div ref={divRef}>
            <Map
                ref={mapRef}
                crs={L.CRS.Simple}
                style={{ height: 'calc(100vh - 213px)', width: '100%', marginBottom: '6px' }}
                scrollWheelZoom={!markersDraggable}
                dragging={!markersDraggable}
                maxZoom={3}
            >
                <LayerGroup>
                    <div className="left-arrow-align">
                        <LeftOutlined style={{ fontSize: '28px' }} onClick={onPrev} />
                    </div>
                    <div className="right-arrow-align">
                        <RightOutlined style={{ fontSize: '28px' }} onClick={onNext} />
                    </div>

                    {feature_birds_eye_view_tags(user.features) && tags.length && coords.length && (
                        <MapMarkers
                            coords={coords}
                            setCoords={setCoords}
                            mapRef={mapRef}
                            markersDraggable={markersDraggable}
                        />
                    )}
                </LayerGroup>
            </Map>
        </div>
    );
};

export const SinglelineViewer = memo(MemorizeSinglelineViewer);
