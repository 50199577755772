import React from 'react';
import moment from 'moment';
import { Modal, Row, Col, Typography } from 'antd';
import { DISPLAY_DATE_FORMAT, renderFrequency, SERVER_DATE_FORMAT } from '../../../utils';

const { Text } = Typography;

const EquipmentModal = (props) => {
    const { isModalvisible, setIsModalVisible, record } = props;

    const renderDate = (serverDate) => {
        return serverDate ? moment(serverDate, SERVER_DATE_FORMAT).format(DISPLAY_DATE_FORMAT) : '';
    };

    const rendeManufacturer = (data) => {
        if (typeof data === 'string') {
            return data;
        }
        return data?.name || record.manufacture_info?.name || '';
    };

    return (
        <Modal
            title="Equipment Details"
            centered
            visible={isModalvisible}
            onOk={() => setIsModalVisible(false)}
            onCancel={() => setIsModalVisible(false)}
            width={900}
            footer={[]}
        >
            <Row>
                {/* Line 1 */}
                <Col xl={12} lg={12} sm={24} className="gutter-row">
                    <Text>
                        <span style={{ fontWeight: 'bold' }}>Tag Name</span>: {record.name}
                    </Text>
                </Col>
                <Col style={{ marginBottom: 10 }} lg={12} sm={24} className="gutter-row">
                    <Text>
                        <span style={{ fontWeight: 'bold' }}>Description</span>:{' '}
                        {record.description}
                    </Text>
                </Col>

                {/* Line 2 */}
                <Col style={{ marginBottom: 10 }} lg={12} sm={24} className="gutter-row">
                    <Text>
                        <span style={{ fontWeight: 'bold' }}>Reference</span>:{' '}
                        {record.reference_number}
                    </Text>
                </Col>
                <Col lg={12} sm={24} className="gutter-row">
                    <Text>
                        <span style={{ fontWeight: 'bold' }}>Serial Number</span>:{' '}
                        {record.serial_number}
                    </Text>
                </Col>

                {/* Line 3 */}
                <Col style={{ marginBottom: 10 }} lg={12} sm={24} className="gutter-row">
                    <Text>
                        <span style={{ fontWeight: 'bold' }}>Manufacturer</span>:{' '}
                        {rendeManufacturer(record.manufacture)}
                    </Text>
                </Col>
                <Col lg={12} sm={24} className="gutter-row">
                    <Text>
                        <span style={{ fontWeight: 'bold' }}>Asset Type</span>: {record.asset_type}
                    </Text>
                </Col>

                {/* Line 4 */}

                <Col style={{ marginBottom: 10 }} lg={12} sm={24} className="gutter-row">
                    <Text>
                        <span style={{ fontWeight: 'bold' }}>Group</span>: {record.group}
                    </Text>
                </Col>
                <Col style={{ marginBottom: 10 }} lg={12} sm={24} className="gutter-row">
                    <Text>
                        <span style={{ fontWeight: 'bold' }}>Warranty Date</span>:{' '}
                        {record.warranty_date}
                    </Text>
                </Col>
            </Row>
        </Modal>
    );
};

export default EquipmentModal;
