import React from 'react';
import ReactDOM from 'react-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { store, persistor } from './app/store';
import 'antd/dist/antd.css';
import './index.scss';
import App from './App';
import { CometChat } from '@cometchat-pro/chat';
import { APP_COMET_CHAT_APP_ID } from './app/comet-chat/configs';
const { REACT_APP_COMET_CHAT_REGION } = process.env;

const appSetting = new CometChat.AppSettingsBuilder()
    .subscribePresenceForFriends()
    .setRegion(REACT_APP_COMET_CHAT_REGION)
    .build();
CometChat.init(APP_COMET_CHAT_APP_ID, appSetting).then(
    () => {
        // console.log('Initialization completed successfully');
        // You can now call login function.
    },
    (error) => {
        // console.log('Initialization failed with error:', error);
        // Check the reason for error and take appropriate action.
    },
);

ReactDOM.render(
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <App />
        </PersistGate>
    </Provider>,
    document.getElementById('root'),
);
