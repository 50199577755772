import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { Layout, notification, Spin } from 'antd';
import { fetchProjectDocs, fetchProjectDetail, updateProject } from '../../../services';
import BasicInfo from './basic-info';
import UserAccess from './user-access';
import ManageEquipments from './manage-equipments';
import Packages from './packages';
import { PROJECT_API_ENUM, showNotification } from '../../../utils';

const { Content } = Layout;

const steps = [
    {
        title: 'Project Information',
        id: 1,
    },
    {
        title: 'Users Access',
        id: 2,
    },
    {
        title: 'Manage Checkpoints',
        id: 3,
    },
    {
        title: 'Manage Area',
        id: 4,
    },
];

const CreateEditProject = ({ history }) => {
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const { createNewProject } = useSelector(({ common }) => common);
    const { projectId } = useParams();
    const [current, setCurrent] = useState(+params.get('activeTab') || 1);
    const [formValues, setFormValues] = useState({});
    const [existingUsers, setExistingUsers] = useState([]);
    const [loader, setLoader] = useState(false);
    const [showTip, setShowTip] = useState(false);
    const [tags, setTags] = useState({
        meta: {},
        data: [],
        currentPage: 1,
        pageSize: 10,
    });
    const [users, setUsers] = useState([]);
    const [organizations, setOrganizations] = useState([]);
    const [equipmentErrors, setEquipmentErrors] = useState([]);
    const [documents, setDocuments] = useState([]);

    const getProjectDetail = async () => {
        try {
            setLoader(true);
            const response = await fetchProjectDetail(PROJECT_API_ENUM.BASIC_INFO, projectId);
            setFormValues({ ...response.data });
            setLoader(false);
        } catch (error) {
            setLoader(false);
            notification.error({ message: "Project doesn't exist", duration: 5 });
        }
    };

    const getProjectDocuments = async () => {
        try {
            const docs = [
                {
                    dir_files: [],
                    dir_key: 'single_lines',
                    dir_value: 'Single Lines',
                    help: 'The engineering diagram depecting how the equipments are connected together (sometimes called single line, three line or wiring diagram)',
                },
            ];
            setDocuments(docs);
        } catch (e) {
            console.log('Error while fetching project docs : ', e);
        }
    };

    useEffect(() => {
        if (projectId) {
            getProjectDetail();
        }
    }, [projectId]);

    const goBack = () => {
        setCurrent(current - 1);
        history.replace({
            pathname: `/projects/${projectId}`,
            search: `?activeTab=${current - 1}`,
        });
    };

    const onFinish = (values) => {
        setFormValues({ ...formValues, ...values });
        if (projectId) {
            history.replace({
                pathname: `/projects/${projectId}`,
                search: `?activeTab=${current + 1}`,
            });
        }
        if (current < 4) {
            setCurrent(current + 1);
        }
    };

    const actionCompleted = async () => {
        await updateProject(PROJECT_API_ENUM.BASIC_INFO, projectId, { is_draft: false });

        notification.success({
            message: 'Project Updated',
            description: 'The project has been successfully updated',
        });
        setCurrent(0);
        setLoader(false);
        history.push('/projects');
    };

    const onTabClick = (item) => {
        if (projectId) {
            setCurrent(item.id);

            history.replace({
                pathname: `/projects/${projectId}`,
                search: `?activeTab=${item.id}`,
            });
        }
    };

    const onSubmit = async (values) => {
        try {
            actionCompleted();
        } catch (error) {
            console.log('error', error);
            setLoader(false);
            setShowTip(false);
        }
    };

    return (
        <Content>
            <div style={{ width: '80%', margin: 'auto' }}>
                <div className="page-content-container">
                    <div className="project-steps">
                        <div className="project-step first-step">
                            {createNewProject ? 'New Project' : 'Modify Project'}
                        </div>
                        {steps.map((item) => (
                            <div
                                className={`project-step cursor-pointer ${
                                    item.id === current ? 'active' : ''
                                }`}
                                key={item.title}
                                onClick={() => onTabClick(item)}
                            >
                                {item.title}
                            </div>
                        ))}
                    </div>
                    <div className="steps-content mt-30">
                        <Spin
                            spinning={loader}
                            tip={showTip ? 'Please wait, it will take a while' : ''}
                        >
                            {current === 1 ? (
                                <BasicInfo
                                    basicInfo={formValues}
                                    onFinish={onFinish}
                                    projectId={projectId}
                                    history={history}
                                    setLoader={setLoader}
                                    modify={!createNewProject}
                                />
                            ) : current === 2 ? (
                                <UserAccess
                                    existingUsers={existingUsers}
                                    organizations={organizations}
                                    setOrganizations={setOrganizations}
                                    users={users}
                                    goBack={goBack}
                                    setUsers={setUsers}
                                    onFinish={onFinish}
                                    projectId={projectId}
                                    setLoader={setLoader}
                                />
                            ) : current === 3 ? (
                                <ManageEquipments
                                    tags={tags}
                                    goBack={goBack}
                                    setTags={setTags}
                                    onFinish={onFinish}
                                    equipmentErrors={equipmentErrors}
                                    projectId={projectId}
                                    setLoader={setLoader}
                                    setEquipmentErrors={setEquipmentErrors}
                                    modify={!createNewProject}
                                    isDraft={formValues.is_draft}
                                />
                            ) : current === 4 ? (
                                <Packages
                                    goBack={goBack}
                                    users={users}
                                    onFinish={onSubmit}
                                    projectId={projectId}
                                    setLoader={setLoader}
                                    modify={!createNewProject}
                                />
                            ) : null}
                        </Spin>
                    </div>
                </div>
            </div>
        </Content>
    );
};

export default CreateEditProject;
