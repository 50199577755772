export const dates = {
    'Approval Date': '20/12/2021',
    'Release Date': '22/12/21',
    'Final Date': '24/12/21',
    'Test Date': '26/12/21',
    'Ship Date': '28/12/21',
};
export const timlineChartConfig = {
    chart: {
        type: 'timeline',
    },
    xAxis: {
        labels: {
            formatter: function () {
                return dates[this.value];
            },
        },
    },
    yAxis: {
        visible: false,
    },
    title: {
        text: 'Timeline',
    },
    subtitle: {
        text: 'These are the original estimatations and commitments',
    },
    exporting: {
        enabled: false,
    },
    series: [
        {
            data: [
                {
                    color: '#1dbf73',
                    name: 'Approval Date',
                },
                {
                    color: '#1dbf73',
                    name: 'Release Date',
                },
                {
                    color: '#1dbf73',
                    name: 'Final Date',
                },
                {
                    color: 'grey',
                    name: 'Test Date',
                },
                {
                    color: 'grey',
                    name: 'Ship Date',
                },
            ],
        },
    ],
};

export const breackdownChartConfig = {
    data: [
        {
            name: 'Estimation',
            type: 'Approval Date',
            date: '10/10/2021',
            value: 10,
        },
        {
            name: 'After Est.',
            type: 'Approval Date',
            date: '22/10/2021',
            value: 22,
        },
        {
            name: 'Estimation',
            type: 'Release Date',
            date: '30/10/2021',
            value: 30,
        },
        {
            name: 'After Est.',
            type: 'Release Date',
            date: '5/11/2021',
            value: 35,
        },
        {
            name: 'Estimation',
            type: 'Final Date',
            date: '10/11/2021',
            value: 15,
        },
        {
            name: 'Before Est.',
            type: 'Final Date',
            date: '12/11/2021',
            value: 12,
        },
        {
            name: 'Estimation',
            type: 'Test Date',
            date: '15/11/2021',
            value: 25,
        },
        {
            name: 'Before Est.',
            type: 'Test Date',
            date: '12/11/2021',
            value: 18,
        },
        {
            name: 'Estimation',
            type: 'Ship Date',
            date: '15/11/2021',
            value: 15,
        },
        {
            name: 'Before Est.',
            type: 'Ship Date',
            date: '12/11/2021',
            value: 12,
        },
    ],
    isGroup: true,
    isStack: true,
    xField: 'type',
    yField: 'value',
    seriesField: 'name',
    groupField: 'date',
    minColumnWidth: 40,
    columnStyle: {
        radius: [4, 4, 0, 0],
    },
    color: ({ name }) => {
        if (name === 'After Est.') {
            return '#e64235';
        } else if (name === 'Before Est.') {
            return '#51aa55';
        }
        return 'gray';
    },
    tooltip: {
        fields: ['date', 'type', 'value', 'name'],
        formatter: (data, zain) => {
            return {
                title: data.date,
                name: data.name + ' - ' + data.date,
                value: data.value + ' days',
            };
        },
    },
    label: {
        position: 'top',
        formatter: function (data) {
            return data.value + ' \ndays';
        },
    },
};
