import { http } from '../utils/http';

export const fetchMarkup = async (projectId, pkgId) => {
    try {
        const response = await http.get(`/projects/${projectId}/package/${pkgId}/markup-detail/`);
        return response.data;
    } catch (ex) {
        console.log(ex);
    }
};

export const fetchMarkupsDetails = async (markupId) => {
    try {
        const response = await http.get(`/kanban-detail/${markupId}/tasks/`);
        return response.data;
    } catch (ex) {
        console.log(ex);
    }
};

export const updateMarkup = async (markupId, body) => {
    try {
        const response = await http.patch(`/markup-detail/${markupId}/`, body);
        return response.data;
    } catch (ex) {
        console.log(ex);
    }
};

export const createComment = async (body) => {
    try {
        const response = await http.post(`/task/comment/`, body);
        return response.data;
    } catch (ex) {
        console.log(ex);
    }
};
