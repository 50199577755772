import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { Row, Col, Tabs, Spin, Breadcrumb, Layout, Switch, Carousel, Button } from 'antd';
import {
    ContainerOutlined,
    EditOutlined,
    LeftCircleOutlined,
    RightCircleOutlined,
    RotateLeftOutlined,
    RotateRightOutlined,
} from '@ant-design/icons';
import { SinglelineViewer, TagListView, Sidebar, SidebarListItem } from '../../../components';
import {
    fetchCalibratedDetailsBySlines,
    fetchSinglelineDetails,
    fetchSlinesByProject,
    rotateSlineCalibrated,
    updateTagsCalibrated,
} from '../../../services';
import './styles.scss';
import { PROJECTS, SLINES } from '../../../utils';
import { setActiveProjectTab } from '../../../store/common';

const { TabPane } = Tabs;
const { Content } = Layout;

const SinglelineDetail = ({ history }) => {
    const { slineId, projectId } = useParams();
    const { operations, activeProject } = useSelector(({ common }) => common);
    const params = useLocation();
    const [activeTab, setActiveTab] = useState('1');
    const [datesView, setDatesView] = useState(false);
    const [detail, setDetail] = useState({});
    const [loader, setLoader] = useState(false);
    const [slinesLoader, setSlinesLoader] = useState(false);
    const [slines, setSlines] = useState([]);
    const [slinesUrls, setSlinesUrls] = useState([]);
    const [markersDraggable, setMarkersDraggable] = useState(false);
    const [btnText, setBtnText] = useState('Edit');
    const [tagSaveLoader, setTagSaveLoader] = useState(false);
    const [coords, setCoords] = useState([]);
    const [fourCorners, setFourCorners] = useState({});

    const [activeSline, setActiveSline] = useState();
    const dispatch = useDispatch();
    useEffect(() => {
        console.log('activeTab', activeTab);
        console.log('bar bar chala');
        getSlines();
        getCalibratedDetailsBySlines(activeSline || slineId);
    }, [slineId]);

    const getCalibratedDetailsBySlines = async (slineID) => {
        setLoader(true);
        const response = await fetchCalibratedDetailsBySlines(
            projectId,
            slineID,
            operations ? 'operations' : 'construction',
        );
        setDetail({ ...response });
    };

    const getSlines = async () => {
        setSlinesLoader(true);
        const response = await fetchSlinesByProject(projectId);
        const slineUrl = response.map(
            (res) => `${PROJECTS}/${activeProject.id}${SLINES}/${res.id}`,
        );

        setSlinesUrls([...slineUrl]);
        setSlines([...response]);
        setSlinesLoader(false);
    };

    const onSlineSelect = (slineID) => {
        setActiveSline(slineID);
        getCalibratedDetailsBySlines(slineID);
    };

    const updateTags = async () => {
        setTagSaveLoader(true);
        const coordsArr = coords.map(({ coords }) => ({
            id: coords.id,
            x_axis: coords.x_axis,
            y_axis: coords.y_axis,
        }));

        const fourCornersData = {
            ...fourCorners,
            image: '',
        };

        await updateTagsCalibrated(activeProject.id, slineId, {
            ...fourCornersData,
            coords: coordsArr,
        });
        setTagSaveLoader(false);
    };

    const rotateSline = async (rotationDegree) => {
        setTagSaveLoader(true);

        await rotateSlineCalibrated(activeProject.id, slineId, {
            rotation_degree: rotationDegree,
        });

        await getSlines();
        await getCalibratedDetailsBySlines(activeSline || slineId);

        setTagSaveLoader(false);
    };

    const editAndSaveTagHandler = (e) => {
        if (btnText === 'Edit') {
            setBtnText('Save');
        } else {
            updateTags();
            setBtnText('Edit');
        }
        setMarkersDraggable(!markersDraggable);
    };

    return (
        <Content className="content-width-sidebar">
            <Row>
                {/* <Col span={5}>
                    <Sidebar heading="Related Single Lines" loader={slinesLoader}>
                        {slines &&
                            slines.map((sline) => (
                                <SidebarListItem
                                    key={sline?.id}
                                    title={sline?.name}
                                    icon={ContainerOutlined}
                                    addActiveClass={detail?.sline?.id === sline?.id}
                                    onClick={() => onSlineSelect(sline?.id)}
                                />
                            ))}
                    </Sidebar>
                </Col> */}
                <Col span={24}>
                    <div
                        className="mt-10"
                        style={{
                            float: 'right',
                        }}
                    >
                        {slinesUrls.findIndex((sline) => sline === params.pathname) + 1}/
                        {slinesUrls.length}
                    </div>
                    <div className="mt-10" />
                    <Breadcrumb separator=">" className="mb-20 ml-20">
                        <Breadcrumb.Item>Projects</Breadcrumb.Item>
                        <Breadcrumb.Item onClick={() => history.push(PROJECTS)}>
                            <span className="cursor-pointer">{params?.state}</span>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            className="cursor-pointer"
                            onClick={() => {
                                dispatch(setActiveProjectTab('2'));
                                history.push(PROJECTS);
                            }}
                        >
                            Sline
                        </Breadcrumb.Item>
                        <Breadcrumb.Item href="">{detail?.sline?.name}</Breadcrumb.Item>
                    </Breadcrumb>
                    <Button
                        size="small"
                        type="primary"
                        style={{
                            float: 'right',
                            marginTop: '6px',
                            marginRight: '5px',
                            marginBottom: '5px',
                        }}
                        loading={tagSaveLoader}
                        onClick={editAndSaveTagHandler}
                    >
                        {btnText}
                    </Button>
                    <Button
                        shape="circle"
                        style={{
                            float: 'right',
                            marginTop: '2px',
                            marginRight: '2px',
                            marginBottom: '2px',
                            backgroundColor: 'tomato',
                        }}
                        disabled={tagSaveLoader}
                        onClick={() => rotateSline(-90)}
                    >
                        <RotateLeftOutlined />
                    </Button>
                    <Button
                        shape="circle"
                        style={{
                            float: 'right',
                            marginTop: '2px',
                            marginRight: '2px',
                            marginBottom: '2px',
                            backgroundColor: 'tomato',
                        }}
                        disabled={tagSaveLoader}
                        onClick={() => rotateSline(90)}
                    >
                        <RotateRightOutlined />
                    </Button>
                    <div
                        className="page-content-container container-height"
                        style={{
                            height: 'calc(100vh - 150px)',
                            paddingTop: 0,
                            paddingBottom: 0,
                        }}
                    >
                        {/* <Tabs
                            defaultActiveKey={activeTab}
                            onChange={setActiveTab}
                            className="project-detail-tabs"
                            tabBarExtraContent={{
                                right:
                                    activeTab === '2' ? (
                                        <>
                                            <span style={{ color: '#7b7b7b' }}>Progress View</span>
                                            <Switch
                                                style={{ margin: '0 10px' }}
                                                onChange={setDatesView}
                                            />
                                            <span style={{ color: '#7b7b7b' }}>Dates View</span>
                                        </>
                                    ) : null,
                            }}
                        > */}
                        {/* <TabPane tab="Single Line View" key="1"> */}
                        {/* <Spin spinning={loader}> */}
                        <SinglelineViewer
                            images={slines}
                            tags={detail?.tags}
                            slineId={slineId}
                            stlineImage={detail?.sline?.image_url}
                            imageWidth={detail?.sline?.src_se_x}
                            imageHeight={detail?.sline?.src_se_y}
                            setLoader={setLoader}
                            slines={slinesUrls}
                            markersDraggable={markersDraggable}
                            coords={coords}
                            setCoords={setCoords}
                            setFourCorners={setFourCorners}
                            fourCorners={fourCorners}
                        />
                        {/* </Spin> */}
                        {/* </TabPane> */}
                        {/* <TabPane tab="Tag List View" key="2">
                                <TagListView
                                    slineId={activeSline || slineId}
                                    datesView={datesView}
                                />
                            </TabPane> */}
                        {/* </Tabs> */}
                    </div>
                </Col>
            </Row>
        </Content>
    );
};

export default memo(SinglelineDetail);
