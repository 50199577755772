import _ from 'lodash';
import { notification, Tooltip } from 'antd';
import { WarningOutlined } from '@ant-design/icons';

export const makeTitle = (slug) => {
    if (slug) {
        let words = slug.split('_');

        for (let i = 0; i < words.length; i++) {
            const word = words[i];
            words[i] = word.charAt(0).toUpperCase() + word.slice(1);
        }

        return words.join(' ');
    }
    return null;
};

export const camelcaseToTitle = (text) => {
    const result = text?.replace(/([A-Z])/g, ' $1');
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult || text;
};

export const sanitizeText = (value) => {
    return value || 'N/A';
};

export const datePercentage = (dates) => {
    const start = new Date(dates.startDate).getTime();
    const end = new Date(dates.endDate).getTime();
    const today = new Date().getTime();
    const percentage = Math.round(((today - start) / (end - start)) * 100);
    return percentage;
};

export const makeAvatar = (firstChar, secondChar) => {
    return `https://ui-avatars.com/api/?name=${firstChar}${secondChar}`; //background=random
};

export const checkPackageManufactures = (record, page) => {
    if (page === 'create_project') {
        const { team_members } = record;
        const manufacturers =
            team_members &&
            team_members.filter((member) => member.organization_type_name === 'Manufacturer');
        const different_manufacturers = _.unionBy(manufacturers, 'organization_name');
        if (different_manufacturers.length > 1) {
            return (
                <Tooltip title="This package has more than one manufacture">
                    <WarningOutlined style={{ color: 'red', fontSize: 18 }} />
                </Tooltip>
            );
        }
    } else {
        const { team_members } = record;
        const manufacturers = team_members
            .filter((member) => member.organization_type.name === 'Manufacturer')
            .map((member) => ({ ...member, organization: member.organization.name }));
        const different_manufacturers = _.unionBy(manufacturers, 'organization');
        if (different_manufacturers.length > 1) {
            return (
                <Tooltip title="This package has more than one manufacture">
                    <WarningOutlined style={{ color: 'red', fontSize: 18 }} />
                </Tooltip>
            );
        }
    }
};

export const showNotification = (message = '', type = 'success', placement = 'topRight') => {
    return notification[type]({ message, placement });
};

export const filterProjectDocumentsData = (documents, dir_key) => {
    const filteredDocs = documents.filter((document) => {
        if (
            ['single_lines', 'site_layout'].includes(dir_key) &&
            (document.file_name.includes('.csv') || document.file_name.includes('.jpg'))
        ) {
            return;
        }
        return document;
    });

    return filteredDocs;
};

export const renderFrequency = (value) => {
    const frequency = parseInt(value);
    if (!frequency) {
        return "0 Month";
    }
    const frequencies = {
        1: 'Monthly',
        2: 'Bi-Monthly',
        4: 'Quaterly',
        6: 'Semi-Annual',
        12: 'Annual',
    };

    if (frequency in frequencies) {
        return frequencies[frequency];
    }
    if (frequency % 12 !== 0) {
        return `${frequency} Months`;
    }

    const years = Math.floor(frequency / 12);
    return `${years} Years`;
};
