import React from 'react';
import { ViewMode, Gantt } from 'gantt-task-react';
import { ViewSwitcher } from './view-switcher';
import { getStartEndDateForProject, initTasks } from './helper';
import 'gantt-task-react/dist/index.css';
import '../../../assets/styles/components/view-switcher.scss';

//Init
export const GanttChart = ({ heading, children }) => {
    const [view, setView] = React.useState(ViewMode.Month);
    const [tasks, setTasks] = React.useState(initTasks());
    const [isChecked, setIsChecked] = React.useState(true);
    const [hideDates, setHideDates] = React.useState(true);
    let columnWidth = 60;
    if (view === ViewMode.Month) {
        columnWidth = 200;
    } else if (view === ViewMode.Week) {
        columnWidth = 250;
    }

    const handleTaskChange = (task) => {
        console.log('On date change Id:' + task.id);
        let newTasks = tasks.map((t) => (t.id === task.id ? task : t));
        if (task.project) {
            const [start, end] = getStartEndDateForProject(newTasks, task.project);
            const project = newTasks[newTasks.findIndex((t) => t.id === task.project)];
            if (
                project.start.getTime() !== start.getTime() ||
                project.end.getTime() !== end.getTime()
            ) {
                const changedProject = { ...project, start, end };
                newTasks = newTasks.map((t) => (t.id === task.project ? changedProject : t));
            }
        }
        setTasks(newTasks);
    };

    const handleTaskDelete = (task) => {
        const conf = window.confirm('Are you sure about ' + task.name + ' ?');
        if (conf) {
            setTasks(tasks.filter((t) => t.id !== task.id));
        }
        return conf;
    };

    const handleProgressChange = async (task) => {
        setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
        console.log('On progress change Id:' + task.id);
    };

    const handleDblClick = (task) => {
        alert('On Double Click event Id:' + task.id);
    };

    const handleSelect = (task, isSelected) => {
        console.log(task.name + ' has ' + (isSelected ? 'selected' : 'unselected'));
    };

    const handleExpanderClick = (task) => {
        setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
        console.log('On expander click Id:' + task.id);
    };

    return (
        <div>
            <h1 className="card-heading d-flex justify-space-between">
                {heading}
                <ViewSwitcher
                    onViewModeChange={(viewMode) => setView(viewMode)}
                    onViewListChange={setIsChecked}
                    hideDates={hideDates}
                    isChecked={isChecked}
                    onViewChange={setHideDates}
                />
            </h1>
            {children}
            {/* <h3>Approval Process Gantt Chart</h3> */}
            <Gantt
                tasks={tasks}
                viewMode={view}
                onDateChange={handleTaskChange}
                onDelete={handleTaskDelete}
                onProgressChange={handleProgressChange}
                onDoubleClick={handleDblClick}
                onSelect={handleSelect}
                onExpanderClick={handleExpanderClick}
                listCellWidth={isChecked ? '300px' : ''}
                columnWidth={columnWidth}
                barBackgroundColor="#233044"
                barProgressColor="#7979ed"
                hideDates={hideDates}
            />
        </div>
    );
};
