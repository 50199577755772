import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { saveAs } from 'file-saver';
import {
    Avatar,
    Tooltip,
    Card,
    Input,
    Row,
    Col,
    Steps,
    Table,
    Modal,
    Button,
    Timeline,
    Spin,
    DatePicker,
    Space,
} from 'antd';
import { ClockCircleOutlined, DownloadOutlined, EyeOutlined } from '@ant-design/icons';
import { makeAvatar, DISPLAY_DATETIME_FORMAT } from '../../../../utils';
import './status.scss';
import { useParams } from 'react-router-dom';
import { fetchConstructionDrawings, fetchEquipmentDocs, getSignedUrl } from '../../../../services';
import axios from 'axios';

export const Status = ({ uploadEquipmentDocs, isLoading }) => {
    const { Step } = Steps;
    const [isShow, setShow] = useState(false);
    const [constructionData, setConstructionData] = useState([]);
    const [loading, setLoading] = useState(false);
    const { pkgId, projectId } = useParams();

    useEffect(() => {
        getConstructionDrawings();
    }, [isLoading]);

    const getConstructionDrawings = async () => {
        try {
            setLoading(true);
            const response = await fetchConstructionDrawings(projectId, pkgId);
            setConstructionData(response.data.dir_files);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    const handleImageUploadDocs = async (fileList, key) => {
        if (!isEmpty(fileList, key)) {
            await uploadEquipmentDocs(fileList, key);
        }
    };

    const handleView = async (file_path) => {
        window.open(file_path, '_blank');
    };

    const handleDownload = async (filePath) => {
        if (filePath) {
            const url = await getSignedUrl(filePath);
            await axios.get(url.data, { headers: { 'Content-Disposition': 'attachment' } });
            saveAs(url.data, filePath);
        }
    };

    const columnData = [
        {
            title: 'Filename',
            dataIndex: 'file_title',
        },
        {
            title: 'Date',
            dataIndex: 'created_at',
            render: (value) => moment(value).format(DISPLAY_DATETIME_FORMAT),
        },
        {
            title: 'Submitted by',
            dataIndex: 'account',
            render: (value) => (
                // <Tooltip title={`${value?.first_name} ${value?.last_name} `}>
                <Avatar
                    className="circle-icon"
                    src={value?.image || makeAvatar(value?.first_name[0], value?.last_name[0])}
                />
                // </Tooltip>
            ),
        },
        {
            title: 'Action',
            dataIndex: 'action',
            render: (value, values) => (
                <Space>
                    <Tooltip title={`View ${values.file_name}`}>
                        <EyeOutlined
                            className="icon-inner-delete"
                            onClick={() => handleView(values.file_url)}
                        />
                    </Tooltip>
                    <Tooltip title={`Download ${values.file_name}`}>
                        <DownloadOutlined
                            className="icon-inner-delete"
                            onClick={() => handleDownload(values.file_path)}
                        />
                    </Tooltip>
                </Space>
            ),
        },
    ];

    return (
        <Spin spinning={isLoading} tip={isLoading ? 'Please wait, it will take a while' : ''}>
            <Card
                className="card-body"
                title="Construction Drawings"
                extra={
                    <Button type="primary" onClick={() => setShow(true)}>
                        Timeline
                    </Button>
                }
            >
                <Modal
                    title="Timeline"
                    visible={isShow}
                    onOk={() => setShow(false)}
                    onCancel={() => setShow(false)}
                >
                    <Timeline>
                        <Timeline.Item>Release date 2015-09-01</Timeline.Item>
                        <Timeline.Item dot={<ClockCircleOutlined style={{ fontSize: '16px' }} />}>
                            Current date 2015-09-01
                        </Timeline.Item>
                        <Timeline.Item>Submission date 2015-09-01</Timeline.Item>
                    </Timeline>
                </Modal>
                <div className="file_upload_wrapper">
                    <Input
                        className="file_upload_field"
                        type="file"
                        multiple
                        onChange={(e) =>
                            handleImageUploadDocs(
                                Object.values(e.target.files),
                                'construction_drawings',
                            )
                        }
                    />
                </div>
                <div className="construction-body">
                    <Table
                        columns={columnData}
                        dataSource={constructionData}
                        pagination={constructionData.length > 10}
                        loading={loading}
                        rowKey="id"
                    />
                </div>
            </Card>
            <Card className="lightColor">
                <Row gutter={[48]}>
                    <Col className="lightColor" span={12}>
                        <h1>Factory Acceptance Test</h1>
                    </Col>
                    <Col className="text-right lightColor" span={12}>
                        <DatePicker />
                    </Col>
                </Row>
            </Card>
        </Spin>
    );
};

export default Status;
