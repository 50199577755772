import _ from 'lodash';
import moment from 'moment';
import {
    Image,
    Button,
    Col,
    Collapse,
    DatePicker,
    Row,
    Space,
    Table,
    Tag,
    Empty,
    Typography,
    Tooltip,
    Avatar,
    Card,
} from 'antd';

import 'gantt-task-react/dist/index.css';
import './operation-status.scss';
import { useState } from 'react';
import { CaretRightOutlined, DownloadOutlined, UserOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';

const { Panel } = Collapse;
const { Text } = Typography;

const { RangePicker } = DatePicker;

const columnsDoc = [
    {
        title: 'File Name',
        dataIndex: 'filename',
    },
    {
        title: 'Files',
        dataIndex: 'files',
        render: (_, record) => (
            <Space>
                <Tooltip title="View">
                    <Image
                        width={45}
                        height={32}
                        src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                    />
                </Tooltip>
                <Tooltip title="Download">
                    <Button
                        className="buttonView"
                        type="default"
                        // onClick={() => handleDownload(record.approval_file_path)}
                    >
                        <DownloadOutlined />
                    </Button>
                </Tooltip>
            </Space>
        ),
    },

    {
        title: 'Shift',
        dataIndex: 'shift',
    },

    {
        title: 'Reported on',
        dataIndex: 'submittedAt',
    },

    {
        title: 'Reported by',
        dataIndex: 'submittedBy',
        render: (_, record) => (
            <Space>
                <Tooltip title="Alex">
                    <Avatar className="center-icon" icon={<UserOutlined />} />
                </Tooltip>
            </Space>
        ),
    },
];

const dataSourceDoc = [
    {
        key: '2',
        filename: 'Test file',
        files: 13,
        shift: 'Day',
        submittedAt: ' 2022-09-09',
        submittedBy: 'Alex',
    },
];

const Status = ({
    detail = {},
    lastRoundData = {},
    historyData = {},
    teamMembers = [],
    tagComments,
    issues,
    tagImages,
    activeTag,
    getWebTagHistory,
}) => {
    const getMembers = (type) => {
        return teamMembers?.filter((user) => user?.organization_type?.name === type);
    };

    const defaultRange = {
        startDate: moment().subtract(30, 'days').format('yyyy-MM-DD'),
        endDate: moment().format('yyyy-MM-DD'),
    };
    const [dateRange, setDateRange] = useState(defaultRange);

    const onChange = function ([startDate, endDate]) {
        setDateRange({
            startDate: startDate.format('yyyy-MM-DD'),
            endDate: endDate.format('yyyy-MM-DD'),
        });
    };

    const handleSummarySearch = () => {
        getWebTagHistory(dateRange.startDate, dateRange.endDate);
    };

    return (
        <>
            <Row gutter={30} className="mt-15">
                <Col span={24}>
                    <div className="card-with-border">
                        <h3 className="heading">Last Round</h3>
                        <Row align="middle">
                            <Col span={12}>
                                <Row>
                                    <Col span={8}>
                                        <p>Date:</p>
                                    </Col>

                                    <Col span={16}>
                                        <p>{lastRoundData.shift_date}</p>
                                    </Col>

                                    <Col span={8}>
                                        <p>Shift:</p>
                                    </Col>

                                    <Col span={16}>
                                        <p>{lastRoundData.shift === 0 ? 'Day' : 'Night'}</p>
                                    </Col>

                                    <Col span={8}>
                                        <p>Comments:</p>
                                    </Col>

                                    <Col span={16}>
                                        <p>{lastRoundData.comments_count}</p>
                                    </Col>
                                    <Col span={8}>
                                        <p>Docs:</p>
                                    </Col>
                                    <Col span={16}>{lastRoundData.docs_count}</Col>
                                    <Col span={8}>
                                        <p>Status:</p>
                                    </Col>
                                    <Col span={16}>
                                        {lastRoundData.issues_count ? (
                                            <Tag color="#f50">Not Good</Tag>
                                        ) : (
                                            <Tag color="#52c41a">All Good</Tag>
                                        )}
                                    </Col>
                                </Row>
                            </Col>

                            {detail?.tag_image_url && (
                                <Col span={12} className="text-center">
                                    <img
                                        src={detail?.tag_image_url}
                                        alt="Tag"
                                        className="tag-image"
                                    />
                                </Col>
                            )}
                        </Row>
                    </div>
                </Col>
            </Row>
            <Row gutter={30} justify="space-around">
                <Col span={24}>
                    <h4 className="card-heading ">History </h4>
                    <div style={{ display: 'flex', marginBottom: '20px' }}>
                        <div>
                            Date Range:
                            <RangePicker
                                size="middle"
                                style={{ marginLeft: '16px', width: '500px' }}
                                defaultValue={[
                                    moment(dateRange.startDate),
                                    moment(dateRange.endDate),
                                ]}
                                onChange={onChange}
                            />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                            }}
                        >
                            <Button
                                style={{
                                    backgroundColor: '#1890ff',
                                    color: '#FFF',
                                    width: '100px',
                                }}
                                onClick={handleSummarySearch}
                            >
                                Search
                            </Button>
                        </div>
                    </div>

                    <div className="card-with-border">
                        <h3 className="heading">Summary </h3>
                        <div>
                            <div className="text-space-between">
                                <div>
                                    <p className="header"># Issues</p>
                                    <p className="text-center">{historyData.issues_count}</p>
                                </div>
                                <div>
                                    <p className="header"># Comments</p>
                                    <p className="text-center">{historyData.comments_count}</p>
                                </div>
                                <div>
                                    <p className="header"># Documents</p>
                                    <p className="text-center">{historyData.comments_count}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <div className="card-with-border isolate-box">
                <h3 className="heading">Issue Detail </h3>

                <Collapse
                    expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                    className="site-custom"
                    expandIconPosition="end"
                >
                    <Panel header={`Dates when Issue Raised`} key="1" className="site-custom">
                        <div className="comments-body custom-scroll-dark">
                            <div className="comment-container custom-description center-container">
                                <Empty description={`Dates not found for ${activeTag?.name}`} />
                            </div>
                        </div>
                    </Panel>
                    <Panel
                        header={`Comments (${tagComments.length})`}
                        key="2"
                        className="site-custom"
                    >
                        <div className="comments-body custom-scroll-dark">
                            {!isEmpty(tagComments) ? (
                                tagComments.map((comment) => {
                                    return (
                                        <div className="issue" key={comment.id}>
                                            <div align="top">
                                                {
                                                    <Text className="date">
                                                        <b
                                                            style={{
                                                                color: '#555',
                                                            }}
                                                        ></b>
                                                        {comment.text}
                                                    </Text>
                                                }
                                            </div>
                                            <div
                                                justify="start"
                                                align="bottom"
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                }}
                                            >
                                                <Text className="date">
                                                    <strong>Added on: </strong>
                                                    {moment(comment.created_at).format(
                                                        'YYYY-MM-DD',
                                                    )}
                                                </Text>

                                                <Text className="date">
                                                    <strong> Reported By: </strong>
                                                    {comment.user_details.name}
                                                </Text>
                                            </div>
                                        </div>
                                    );
                                })
                            ) : (
                                <div className="comment-container custom-description center-container">
                                    <Empty
                                        description={`Comments not found for ${activeTag?.name}`}
                                    />
                                </div>
                            )}
                        </div>
                    </Panel>
                    <Panel
                        header={`Documents (${tagImages.length})`}
                        key="3"
                        className="site-custom"
                    >
                        {!isEmpty(tagImages) ? (
                            <div className="slider_body">
                                <Table columns={columnsDoc} dataSource={dataSourceDoc} />
                            </div>
                        ) : (
                            <div className="comment-container custom-description">
                                <Empty description={`Documents not found for ${activeTag?.name}`} />
                            </div>
                        )}
                    </Panel>
                </Collapse>
            </div>
        </>
    );
};

export default Status;
