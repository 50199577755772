import { http } from '../utils/http';

export const fetchReportForDay = async (projectId, selectedDate) => {
    try {
        let response = await http.get(
            `/projects/${projectId}/web/reports/day/?shift_date=${selectedDate}`,
        );
        return response.data;
    } catch (ex) {
        throw ex;
    }
};

export const fetchMonthlyCalendarData = async (projectId, startOfMonth, endOfMonth) => {
    try {
        let response = await http.get(
            `/projects/${projectId}/web/reports/datewise/?round__shift_date__gte=${startOfMonth}&round__shift_date__lte=${endOfMonth}`,
        );
        return response.data;
    } catch (ex) {
        throw ex;
    }
};

export const fetchGenerateReportData = async (projectId, payload) => {
    try {
        // TODO: need to create a common helper function to prepare a dynamic url
        let url = `/data-rounds/projects/${projectId}/export/tags-report/?`;
        if (payload.shift !== 2) {
            url = `${url}shift=${payload.shift}&`;
        }
        if (payload.package.toLowerCase() !== 'all') {
            url = `${url}package_room__in=${payload.package}&`;
        }
        if (payload.dateRange.startDate) {
            url = `${url}report_date__gte=${payload.dateRange.startDate}&`;
        }
        if (payload.dateRange.endDate) {
            url = `${url}report_date__lte=${payload.dateRange.endDate}`;
        }
        let response = await http.get(url);
        return response.data;
    } catch (ex) {
        throw ex;
    }
};

export const fetchGenerateReportDataV2 = async (projectId, payload) => {
    try {
        // TODO: need to create a common helper function to prepare a dynamic url
        let url = `/projects/${projectId}/web/reports/weekly/?`;
        if (payload.shift !== 2) {
            url = `${url}shift=${payload.shift}&`;
        }
        if (payload.package.toLowerCase() !== 'all') {
            url = `${url}package_room__in=${payload.package}&`;
        }
        if (payload.dateRange.startDate) {
            url = `${url}round__shift_date__gte=${payload.dateRange.startDate}&`;
        }
        if (payload.dateRange.endDate) {
            url = `${url}round__shift_date__lte=${payload.dateRange.endDate}`;
        }
        let response = await http.get(url);
        return response.data;
    } catch (ex) {
        throw ex;
    }
};

export const fetchGenerateDateWiseShiftReportData = async (projectId, shift_date, shift) => {
    try {
        const response = await http.get(
            `/projects/${projectId}/web/reports/date-shift/?shift_date=${shift_date}&shift=${shift}`,
        );
        return response.data;
    } catch (ex) {
        throw ex;
    }
};
