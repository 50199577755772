import { PROJECT_API_ENUM } from '../utils';
import { http } from '../utils/http';

export const fetchTagDetails = async (tagId) => {
    try {
        const response = await http.get(`/tags/${tagId}/`);
        return response.data;
    } catch (ex) {
        console.log(ex);
    }
};

export const fetchTagsByProject = async (projectId, page = 1, pageSize = 10) => {
    try {
        let url = `/projects/${projectId}/tags/?`;
        if (page) {
            url += `page=${page}&per_page=${pageSize}`;
        }

        const response = await http.get(url);
        return response.data;
    } catch (ex) {
        throw ex;
    }
};

export const createTags = async (projectId, body) => {
    try {
        const response = await http.post(`/projects/${projectId}/tags/`, body);
        return response.data;
    } catch (ex) {
        throw ex;
    }
};

export const updateTag = async (projectId, tagId, reqBody) => {
    try {
        const response = await http.patch(`/projects/${projectId}/tags/${tagId}/`, reqBody);
        return response.data;
    } catch (ex) {
        console.log(ex);
    }
};

export const fetchTagsByProjectDropDown = async (projectId, IsReading = false) => {
    try {
        const response = await http.get(
            `/projects/${projectId}/web/${PROJECT_API_ENUM.TAGS}/dropdown/?is_reading_required=${IsReading}`,
        );
        return response.data;
    } catch (ex) {
        throw ex;
    }
};

export const fetchTagsByProjectV2 = async (
    projectId,
    page = 1,
    pageSize = 10,
    searchText,
    searchedColumn,
    ordering = null,
) => {
    let url = `projects/${projectId}/web/${PROJECT_API_ENUM.TAGS}/?page=${page}&per_page=${pageSize}`;
    if (searchedColumn) {
        url += `&${searchedColumn}__icontains=${searchText}`;
    }
    if (ordering) {
        url += `&ordering=${ordering}`;
    }
    try {
        const response = await http.get(url);
        return response.data;
    } catch (ex) {
        throw ex;
    }
};

export const fetchPackages = async (projectId, full_descriptive = true) => {
    try {
        const response = await http.get(
            `/projects/${projectId}/package-rooms/?full_descriptive=${full_descriptive}`,
        );
        return response.data;
    } catch (ex) {
        throw ex;
    }
};

export const createMultiplePackages = async (projectId, body) => {
    try {
        const response = await http.post(`/projects/${projectId}/multiple/package-rooms/`, body);
        return response.data;
    } catch (ex) {
        throw ex;
    }
};

export const fetchTagCompletion = async (projectId) => {
    try {
        const response = await http.get(`/projects/${projectId}/tags-completion/`);
        return response.data;
    } catch (ex) {
        throw ex;
    }
};

export const fetchEquipmentProgressStatistics = async (projectId) => {
    try {
        const response = await http.get(`/projects/${projectId}/project-statistics/`);
        return response.data;
    } catch (ex) {
        throw ex;
    }
};

export const deleteTags = async (projectId, body) => {
    try {
        const response = await http.delete(`/projects/${projectId}/tags/`, body);
        return response.data;
    } catch (ex) {
        console.log(ex);
    }
};

export const clearOldTags = async (projectId) => {
    try {
        const response = await http.delete(`/projects/${projectId}/clear-data/`);
        return response.data;
    } catch (ex) {
        console.log(ex);
    }
};

export const resetTagsOrder = async (projectId) => {
    try {
        const response = await http.post(`/projects/${projectId}/tags/reset-order/`);
        return response.data;
    } catch (ex) {
        console.log(ex);
    }
};
