import React from 'react';
import ReactPlayer from 'react-player';
import './video-player.scss';
import { Col, Row } from 'antd';

const VideoPlayer = ({ videoUrl, punchList }) => {
    const playerRef = React.useRef();

    const onButtonClick = (punchListData) => {
        playerRef.current.seekTo(punchListData.timestamp[0]);
    };

    return (
        <>
            <div className="player-body">
                <Row className="seek-buttons">
                    <Col span={18}>
                        <div className="video-box">
                            <ReactPlayer
                                ref={playerRef}
                                url={videoUrl}
                                playing={false}
                                width="100%"
                                height="100%"
                                controls={true}
                            />
                        </div>
                    </Col>

                    <Col span={6} className="right-side-container">
                        {punchList.map((punch, index) => {
                            return (
                                <div
                                    className="punch_list_body"
                                    key={index}
                                    onClick={() => onButtonClick(punch)}
                                >
                                    <p className="p-text">
                                        <strong>Skip to:</strong>
                                        <span>{punch.question}</span>
                                    </p>
                                </div>
                            );
                        })}
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default VideoPlayer;
