import Swal from 'sweetalert2';
import { useEffect, useState } from 'react';
import {
    Col,
    Input,
    Row,
    Form,
    Select,
    Button,
    Modal,
    Table,
    Tooltip,
    Space,
    AutoComplete,
    Popconfirm,
    notification,
} from 'antd';
import { DeleteTwoTone } from '@ant-design/icons';
import {
    getOrganizations,
    getOrganizationTypes,
    getRoles,
    validateEmail,
    removeUserFromProject,
    addUserIntoProject,
} from '../../../services';
import _ from 'lodash';
import { makeAvatar, confirmUserContent } from '../../../utils';
const { Option } = Select;
const { Option: AutoCompleteOption } = AutoComplete;

const UserAccess = ({
    users = [],
    projectId = null,
    setUsers = () => {},
    existingUsers = [],
    goBack = () => {},
    onFinish = () => {},
    setLoader = () => {},
}) => {
    const [form] = Form.useForm();
    const [form2] = Form.useForm();
    const [newUserRoles, setNewUserRoles] = useState([]);
    const [existingUserModal, setExistingUserModal] = useState(false);
    const [newOrganizations, setNewOrganizations] = useState([]);
    const [organizationTypes, setOrganizationTypes] = useState([]);
    const [orgTypeName, setOrgTypeName] = useState('');
    const [roleName, setRoleName] = useState('');
    const [orgId, setOrgId] = useState('');
    const [disableOrgType, setDisableOrgType] = useState(false);

    const fetchOrganizationTypes = async () => {
        try {
            const result = await getOrganizationTypes();
            setOrganizationTypes(result.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchOrganizationTypes();
    }, []);

    const columns = [
        {
            title: 'First Name',
            dataIndex: 'first_name',
            render: (text, record) => (
                <Tooltip placement="topLeft" title={text}>
                    <p className="mb-0 table-row-name-last-desc">{text}</p>
                </Tooltip>
            ),
        },
        {
            title: 'Last Name',
            dataIndex: 'last_name',
            render: (text, record) => (
                <Tooltip placement="topLeft" title={text}>
                    <p className="mb-0 table-row-name-last-desc">{text}</p>
                </Tooltip>
            ),
        },
        {
            title: 'Email',
            dataIndex: 'email',
        },
        {
            title: 'Organization',
            dataIndex: 'organization_name',
            render: (text, record) => (
                <Tooltip placement="topLeft" title={text}>
                    <p className="mb-0 table-row-name-last-desc">{text}</p>
                </Tooltip>
            ),
        },
        {
            title: 'Organization Type',
            dataIndex: 'organization_type_name',
        },
        {
            title: 'Role',
            dataIndex: 'role_name',
        },
        {
            title: '',
            dataIndex: '',
            render: (_, record) => (
                <Tooltip title="Remove User">
                    <Popconfirm
                        title="Are you sure?"
                        onConfirm={(ev) => {
                            ev.stopPropagation();
                            removeUser(record.id);
                        }}
                    >
                        <DeleteTwoTone
                            twoToneColor="#f56666"
                            style={{ fontSize: 18, cursor: 'pointer' }}
                        />
                    </Popconfirm>
                </Tooltip>
            ),
        },
    ];

    const addExistingUser = (values) => {
        const user = existingUsers.find((usr) => usr.id === values.user);
        user.new = false;
        values.organization = orgId;
        values.organization_type_name = orgTypeName;
        values.role_name = roleName;
        setUsers([...users, { ...user, ...values }]);
        resetState();
    };

    const onTypeChange = async (value, option, cb) => {
        try {
            if (option?.children) {
                setOrgTypeName(option.children);
            }
            form2.setFieldsValue({ role: null });
            form.setFieldsValue({ role: null });
            const result = await getRoles(value);
            setNewUserRoles([...result.data]);
        } catch (error) {
            console.log(error);
        }
    };

    const resetState = () => {
        setDisableOrgType(false);
        setOrgId(null);
        setOrgTypeName(null);
        setRoleName(null);
        setNewOrganizations([]);
        form.resetFields();
        form2.resetFields();
    };

    const addNewUser = async (values) => {
        const localUser = users.find((usr) => usr.email === values.email);
        if (localUser) {
            form2.setFields([{ name: 'email', errors: ['Email already exist'] }]);
            setLoader(false);
            return;
        }
        setLoader(true);
        const userExist = await validateEmail({ email: values.email });
        if (userExist.data) {
            Swal.fire({
                width: 950,
                icon: 'question',
                title: 'Do you want to add this user?',
                html: confirmUserContent(userExist.data),
                showCancelButton: true,
                confirmButtonText: 'Add',
                backdrop: true,
                confirmButtonColor: '#16aaff',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const { organization, organization_type, role } = userExist.data;
                    const user = {
                        ...userExist.data,
                        new: false,
                        organization: organization.id,
                        organization_name: organization.name,
                        organization_type: organization_type.id,
                        organization_type_name: organization.name,
                        role_name: role.name,
                        role: role.id,
                    };
                    await addUserIntoProject(projectId, user);
                    setUsers([...users, user]);
                    resetState();
                    setLoader(false);
                }
            });
        } else if (!userExist.data && !localUser) {
            values.new = true;
            values.organization = orgId;
            values.organization_type_name = orgTypeName;
            values.role_name = roleName;
            values.image_url = makeAvatar(values.first_name[0], values.last_name[0]);
            const response = await addUserIntoProject(projectId, values);
            values.id = response.data.id;
            notification.success({ message: response.message });
            setUsers([...users, values]);
            resetState();
            setLoader(false);
        }
    };

    const removeUser = async (userId) => {
        const newUsers = users.filter((user) => user.id !== userId);
        setUsers([...newUsers]);
        const response = await removeUserFromProject(projectId, userId);
        notification.success({ message: response.message });
    };

    const onNext = () => {
        onFinish({ users });
    };

    const onSelect = (val, option) => {
        setOrgId(Number(option.key));
        onTypeChange(Number(option.org_type_id));
        setOrgTypeName(option.org_type);
        setDisableOrgType(true);
        form2.setFieldsValue({ organization_type: option.org_type_id });
        form.setFieldsValue({ organization_type: option.org_type_id });
    };

    const onSearch = _.debounce(async (val) => {
        try {
            setDisableOrgType(false);
            const results = await getOrganizations(val);
            setNewOrganizations([...results.data]);
        } catch (error) {
            console.log(error);
        }
    }, 400);

    return (
        <>
            <h2 className="step-heading">
                Manage Users & Access Control
                {/* <Button
                    className="btn-default"
                    type="default"
                    htmlType="button"
                    onClick={() => setExistingUserModal(true)}
                    >
                    Add Existing Users
                </Button> */}
            </h2>
            <Form form={form2} layout="vertical" onFinish={addNewUser} validateTrigger="onSubmit">
                <Row gutter={10}>
                    <Col span={22}>
                        <Row gutter={10}>
                            <Col span={4}>
                                <Form.Item
                                    label="First Name"
                                    name="first_name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the first name',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    label="Last Name"
                                    name="last_name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the last name',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    label="Email Address"
                                    name="email"
                                    rules={[
                                        {
                                            type: 'email',
                                            message: 'The input is not valid E-mail!',
                                        },
                                        {
                                            required: true,
                                            message: 'Please enter the email',
                                        },
                                    ]}
                                    // hasFeedback
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    label="Organization Name"
                                    name="organization_name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select the organization type',
                                        },
                                    ]}
                                >
                                    <AutoComplete onSelect={onSelect} onSearch={onSearch}>
                                        {newOrganizations.map((org) => (
                                            <AutoComplete.Option
                                                key={org?.id}
                                                value={org?.name}
                                                org_type={org?.org_type?.name}
                                                org_type_id={org?.org_type?.id}
                                            >
                                                {org?.name}
                                            </AutoComplete.Option>
                                        ))}
                                    </AutoComplete>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    label="Organization Type"
                                    name="organization_type"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select the organization type',
                                        },
                                    ]}
                                >
                                    <Select
                                        style={{ width: '100%' }}
                                        placeholder="Please select"
                                        onChange={onTypeChange}
                                        disabled={disableOrgType}
                                    >
                                        {organizationTypes.map((org) => (
                                            <Option key={org.id} value={org.id}>
                                                {org.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    label="Role"
                                    name="role"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select the role',
                                        },
                                    ]}
                                >
                                    <Select
                                        style={{ width: '100%' }}
                                        placeholder="Please select"
                                        onSelect={(_, option) => setRoleName(option?.children)}
                                    >
                                        {newUserRoles.map((role) => (
                                            <Option key={role.id} value={role.id}>
                                                {role.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={2}>
                        <Form.Item>
                            <Button
                                style={{ width: '100%' }}
                                type="primary"
                                className="mt-30"
                                htmlType="submit"
                            >
                                Add User
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Table
                dataSource={users.map((item, idx) => ({ ...item, key: idx }))}
                columns={columns}
                className="taglist-table"
                size="small"
                pagination={users.length > 10}
            />
            <div className="text-right">
                <Space>
                    <Button
                        className="btn-lg btn-default save-btn mt-30"
                        type="default"
                        htmlType="button"
                        onClick={goBack}
                    >
                        Previous
                    </Button>
                    <Button
                        className="btn-lg btn-primary-lg save-btn mt-30"
                        type="primary"
                        onClick={onNext}
                    >
                        Save & Next
                    </Button>
                </Space>
            </div>
            <Modal
                visible={existingUserModal}
                onCancel={() => {
                    setExistingUserModal(false);
                    resetState();
                }}
                title="Add Existing User"
                width={900}
                okText="Add User"
                onOk={form.submit}
            >
                <Form layout="vertical" onFinish={addExistingUser} form={form}>
                    <Row gutter={15}>
                        <Col span={6}>
                            <Form.Item
                                label="Exisiting Users"
                                name="user"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select the user',
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Please select"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().includes(input.toLowerCase())
                                    }
                                    allowClear
                                >
                                    {existingUsers.map((user) => (
                                        <Option key={user.id} value={user.id}>
                                            {`${user.first_name} (${user.email})`}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Organization Name"
                                name="organization_name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select the organization type',
                                    },
                                ]}
                            >
                                <AutoComplete onSelect={onSelect} onSearch={onSearch}>
                                    {newOrganizations.map((org) => (
                                        <AutoComplete.Option
                                            key={org.id}
                                            value={org?.name}
                                            org_type={org?.org_type?.name}
                                            org_type_id={org?.org_type?.id}
                                        >
                                            {org?.name}
                                        </AutoComplete.Option>
                                    ))}
                                </AutoComplete>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Organization Type"
                                name="organization_type"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select the organization type',
                                    },
                                ]}
                            >
                                <Select
                                    style={{ width: '100%' }}
                                    placeholder="Please select"
                                    onChange={onTypeChange}
                                    disabled={disableOrgType}
                                >
                                    {organizationTypes.map((org) => (
                                        <Option key={org.id} value={org.id}>
                                            {org.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Role"
                                name="role"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select the role',
                                    },
                                ]}
                            >
                                <Select
                                    style={{ width: '100%' }}
                                    placeholder="Please select"
                                    onSelect={(_, option) => setRoleName(option?.children)}
                                >
                                    {newUserRoles.map((role) => (
                                        <Option key={role.id} value={role.id}>
                                            {role.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
};

export default UserAccess;
