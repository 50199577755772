import { Collapse, Space, Form, Input, Button, Row, Col } from 'antd';
import { PROJECT_ATTACHMENTS } from '../../../utils/constants';
const { Panel } = Collapse;

const Documentation = ({ goBack = () => {}, onSubmit = () => {} }) => {
    return (
        <>
            <h2 className="step-heading mb-20">Upload Documentation</h2>
            <Form onFinish={onSubmit}>
                <Space direction="vertical" style={{ width: '100%' }}>
                    {PROJECT_ATTACHMENTS.map((project) => {
                        return (
                            <>
                                {project.dir_key === 'single_lines' && (
                                    <h2
                                        key="birds-eye-view-heading"
                                        className="step-heading mt-10 mb-10"
                                    >
                                        Bird's Eye View
                                    </h2>
                                )}
                                <Collapse
                                    className="alectify-collapse document-collapse"
                                    key={project.order}
                                >
                                    <Panel header={project.heading}>
                                        <Row gutter={15}>
                                            <Col>
                                                <Form.Item
                                                    name={project.dir_key}
                                                    valuePropName={project.dir_key}
                                                >
                                                    <Input
                                                        className="file_upload"
                                                        type="file"
                                                        multiple
                                                    />
                                                    {/* <Button className='upload-button'>
                                                    <UploadOutlined />
                                                    Upload File
                                                </Button> */}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Panel>
                                </Collapse>
                            </>
                        );
                    })}
                </Space>
                <div className="text-right">
                    <Space>
                        <Button
                            className="btn-lg btn-default save-btn mt-30"
                            type="default"
                            htmlType="button"
                            onClick={goBack}
                        >
                            Previous
                        </Button>
                        <Button className="btn-lg save-btn mt-30" type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Space>
                </div>
            </Form>
        </>
    );
};

export default Documentation;
