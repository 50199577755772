import _ from 'lodash';
import axios from 'axios';
import moment from 'moment';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveAs } from 'file-saver';
import {
    Alert,
    Form,
    Button,
    Collapse,
    DatePicker,
    Row,
    Tooltip,
    Col,
    Input,
    Spin,
    Empty,
    Avatar,
    Upload,
    Table,
    Space,
} from 'antd';
import { DeleteOutlined, DownloadOutlined, EyeOutlined } from '@ant-design/icons';
import { APPROVAL_STATUSES, DISPLAY_DATETIME_FORMAT, makeAvatar } from '../../../../utils';
import { getApprovals } from '../../../../store/approval';
import {
    uploadDocuments,
    getSignedUrl,
    createNotification,
    sendForApproval,
    fetchMarkup,
} from '../../../../services';

const { Panel } = Collapse;

const Approval = ({ pkgId, projectId, isReleased = false, setMarkupDetail }) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { approvals, approvalsLoading } = useSelector(({ approval }) => approval);
    const [isDuplicate, setDuplicate] = useState(false);
    const [loader, setLoader] = useState(false);
    const [approvalValues, setApprovalsValues] = useState([]);
    const [editModal, setEditModal] = useState(false);
    const [editDoc, setEditDoc] = useState('');
    const [s3Key, setS3Key] = useState('');

    let formObj = {
        path: '',
        fileName: '',
        dueDate: '',
        notes: '',
        package_room: pkgId,
    };

    const filterApprovals = (status) => {
        return approvals.filter((approval) => approval.approval_status === status);
    };

    const submittedApprovals = filterApprovals(APPROVAL_STATUSES.SEND_FOR_APPROVAL);

    const handleFileChange = async (event) => {
        const newRow = {
            ...formObj,
            file: event.file,
            fileName: event.file.name,
        };

        await setApprovalsValues([...approvalValues, newRow]);
    };

    const disabledPreviousDates = (current) => {
        return current && current < moment().endOf('day');
    };

    const handleDateChange = (date, i) => {
        const tempAppovals = [...approvalValues];
        tempAppovals[i]['approval_due_date'] = date.format('YYYY-MM-DD');
        setApprovalsValues([...tempAppovals]);
    };

    const handleChangeNotes = (val, i) => {
        const tempAppovals = [...approvalValues];
        tempAppovals[i]['notes'] = val.trim();
        setApprovalsValues(tempAppovals);
    };

    const handleDeleteRow = (i) => {
        console.log('handleDeleteRow', i);
        let tempForm = [...approvalValues];
        if (tempForm.length === 1) {
            tempForm = [];
        }
        tempForm.splice(i, 1);
        setApprovalsValues([...tempForm]);
        form.resetFields();
    };

    const uploadDocumentsAndSendForApproval = async () => {
        const formData = new FormData();
        approvalValues.map(({ file }) => formData.append('files', file));
        formData.append('project_id', projectId.toString());
        await handleUploadDocuments(formData);
    };

    const sendNotificationForApproval = async (approvalValues) => {
        let approvalMessage = 'The Review process has started and {} have been sent for approval';
        const uploadedDocuments = approvalValues.map((doc) => doc.fileName);
        const files =
            uploadedDocuments.length > 1 ? uploadedDocuments.join(', ') : uploadedDocuments[0];
        if (approvals.length == 0) {
            approvalMessage = approvalMessage.replace('{}', files);
        } else {
            approvalMessage = files + ' have been sent for approval';
        }
        const message = {
            package: pkgId,
            message: approvalMessage,
        };
        await createNotification(projectId, message);
    };

    const handleSubmit = async () => {
        let duplicate = false;
        const uploadedDocuments = approvals.map((doc) => doc.file_name);
        approvalValues.map((file) => {
            if (uploadedDocuments.includes(file.fileName)) {
                setDuplicate(true);
                duplicate = true;
            }
        });
        if (!duplicate) {
            setLoader(true);
            uploadDocumentsAndSendForApproval();

            sendNotificationForApproval(approvalValues);
        }
    };

    const handleUploadDocuments = async (data) => {
        try {
            const approvalDocuments = await uploadDocuments(projectId, pkgId, data);
            const mapFileDetails = approvalValues.map((item, index) => {
                return {
                    ...item,
                    approval_file_name: approvalDocuments.data[index]['fileName'],
                    approval_file_path: approvalDocuments.data[index]['path'],
                };
            });
            await submitForApproval(mapFileDetails);
            setLoader(false);
        } catch (ex) {
            console.log(ex);
            setLoader(false);
        }
    };

    const submitForApproval = async (data) => {
        try {
            await sendForApproval(pkgId, data);
            dispatch(getApprovals(pkgId));
            const markups = await fetchMarkup(projectId, pkgId);

            setMarkupDetail(markups.data);
            resetState();
        } catch (ex) {
            console.log(ex);
            resetState();
        }
    };

    const resetState = () => {
        setLoader(false);
        form.resetFields();
        setApprovalsValues([]);
    };

    const handleDownload = async (filePath) => {
        if (!_.isEmpty(filePath)) {
            const key = `${projectId}/approvalprocess/${pkgId}/${filePath}`;
            const url = await getSignedUrl(key);
            await axios.get(url.data, { headers: { 'Content-Disposition': 'attachment' } });
            saveAs(url.data, filePath);
        }
    };

    const handleView = async (filePath) => {
        if (!_.isEmpty(filePath)) {
            const key = `${projectId}/approvalprocess/${pkgId}/${filePath}`;
            const url = await getSignedUrl(key);
            window.open(url.data, '_blank');
        }
    };

    const APPROVAL_COLUMNS = [
        {
            title: 'File Name',
            dataIndex: 'approval_file_name',
        },
        {
            title: 'Files',
            dataIndex: 'approval_file_name',
            render: (_, record) => (
                <Space>
                    <Tooltip title="View">
                        <Button
                            className="buttonView"
                            type="default"
                            onClick={() => handleView(record.approval_file_path)}
                        >
                            <EyeOutlined />
                        </Button>
                    </Tooltip>
                    <Tooltip title="Download">
                        <Button
                            className="buttonView"
                            type="default"
                            onClick={() => handleDownload(record.approval_file_path)}
                        >
                            <DownloadOutlined />
                        </Button>
                    </Tooltip>
                </Space>
            ),
        },
        {
            title: 'Note',
            dataIndex: 'notes',
        },
        {
            title: 'Due Date',
            dataIndex: 'approval_due_date',
            render: (value) => moment(value).format(DISPLAY_DATETIME_FORMAT),
        },
        {
            title: 'Submitted at',
            dataIndex: 'created_at',
            render: (value) => moment(value).format(DISPLAY_DATETIME_FORMAT),
        },
        {
            title: 'Submitted by',
            dataIndex: 'account',
            render: (_, record) => (
                <Tooltip title={`${record.account.first_name} ${record.account.last_name}`}>
                    <Avatar
                        className="circle-icon center-icon"
                        src={
                            record.account.image ||
                            makeAvatar(record.account.first_name[0], record.account.last_name[0])
                        }
                    />
                </Tooltip>
            ),
        },
    ];

    return (
        <Collapse
            bordered={false}
            defaultActiveKey={['2']}
            className="site-collapse-custom-collapse"
        >
            {!isReleased && (
                <Panel
                    key="2"
                    header="Submit Approval Documents"
                    className="site-collapse-custom-panel"
                >
                    <Spin
                        spinning={loader || approvalsLoading}
                        tip={loader || approvalsLoading ? 'Please wait, it will take a while' : ''}
                    >
                        <div>
                            <div className="card">
                                <Form
                                    form={form}
                                    layout="horizontal"
                                    labelCol={{ span: 8 }}
                                    onFinish={() => handleSubmit()}
                                >
                                    <div className="file_upload_wrapper">
                                        <Upload
                                            beforeUpload={() => false}
                                            showUploadList={false}
                                            onChange={handleFileChange}
                                            maxCount={1}
                                            className="full-width"
                                        >
                                            <Button className="full-width" type="primary">
                                                Upload Files
                                            </Button>
                                        </Upload>
                                    </div>
                                    <Row gutter={16}>
                                        <div className="uploaded_file">
                                            {isDuplicate && (
                                                <Alert
                                                    message="Duplicate file detected"
                                                    showIcon
                                                    description="It seems that, You have selected file which is already uploaded under this package."
                                                    type="error"
                                                    className="mt-15"
                                                    action={
                                                        <Button
                                                            size="small"
                                                            danger
                                                            onClick={(e) => setDuplicate(false)}
                                                        >
                                                            Close
                                                        </Button>
                                                    }
                                                />
                                            )}
                                            {!_.isEmpty(approvalValues) &&
                                                approvalValues.map((item, index) => (
                                                    <div className="child-upload" key={index}>
                                                        <Col span={7}>
                                                            <Form.Item>
                                                                <Tooltip
                                                                    title={
                                                                        item.file_name ||
                                                                        item.fileName
                                                                    }
                                                                >
                                                                    <p className="text-body">
                                                                        {item.file_name ||
                                                                            item.fileName}
                                                                    </p>
                                                                </Tooltip>
                                                            </Form.Item>
                                                        </Col>

                                                        <Col span={7}>
                                                            <Form.Item
                                                                name={`note${index}`}
                                                                rules={[
                                                                    {
                                                                        required: false,
                                                                        message: '',
                                                                    },
                                                                ]}
                                                            >
                                                                <Input
                                                                    style={{
                                                                        width: '100%',
                                                                    }}
                                                                    className="buttonView"
                                                                    placeholder="Type notes here..."
                                                                    onChange={(e) =>
                                                                        handleChangeNotes(
                                                                            e.target.value,
                                                                            index,
                                                                        )
                                                                    }
                                                                />
                                                            </Form.Item>
                                                        </Col>

                                                        <Col span={7}>
                                                            <Form.Item
                                                                name={`dueDate${index}`}
                                                                hasFeedback
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: '',
                                                                    },
                                                                ]}
                                                            >
                                                                <DatePicker
                                                                    style={{
                                                                        width: '100%',
                                                                    }}
                                                                    className="mt-0 buttonView"
                                                                    onChange={(e) =>
                                                                        handleDateChange(e, index)
                                                                    }
                                                                    placeholder="Select Due Date"
                                                                    disabledDate={
                                                                        disabledPreviousDates
                                                                    }
                                                                />
                                                            </Form.Item>
                                                        </Col>

                                                        <Col span={1}>
                                                            <Tooltip
                                                                title={`Delete ${item.fileName}`}
                                                            >
                                                                <DeleteOutlined
                                                                    onClick={(e) =>
                                                                        handleDeleteRow(index)
                                                                    }
                                                                />
                                                            </Tooltip>
                                                        </Col>
                                                    </div>
                                                ))}
                                        </div>
                                    </Row>
                                    {!_.isEmpty(approvalValues) && (
                                        <div className="bottom-btn">
                                            <Form.Item>
                                                <div>
                                                    <Button
                                                        className="btn-lg normal-btn mt-30 ml-0 text-white"
                                                        type="primary"
                                                        htmlType="submit"
                                                    >
                                                        Submit for review
                                                    </Button>
                                                </div>
                                            </Form.Item>
                                        </div>
                                    )}
                                </Form>
                            </div>
                        </div>
                    </Spin>
                </Panel>
            )}
            <Panel header="Submitted Documents" key="3" className="site-collapse-custom-panel-grey">
                <Table
                    columns={APPROVAL_COLUMNS}
                    dataSource={submittedApprovals}
                    pagination={submittedApprovals.length > 10}
                />
            </Panel>
        </Collapse>
        // /* <Modal
        //     width="100%"
        //     className="edit-doc-modal"
        //     style={{ top: 0, bottom: 0 }}
        //     title="Edit Document"
        //     visible={editModal}
        //     onOk={() => setEditModal(false)}
        //     onCancel={() => setEditModal(false)}
        // >
        //     <PdfEditor
        //         pdf={editDoc}
        //         s3Key={s3Key}
        //         key={s3Key}
        //         setEditModal={setEditModal}
        //     />
        // </Modal> */
    );
};

export default Approval;
