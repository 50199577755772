import { ACTIVE_PROJECT, ACTIVE_PROJECT_TAB, PROJECT_DOCS, TITLE_STATE } from './types';

export const setActiveProject = (project) => (dispatch) => {
    dispatch({ type: ACTIVE_PROJECT, payload: project });
};

export const setActiveProjectTab = (tabKey) => (dispatch) => {
    dispatch({ type: ACTIVE_PROJECT_TAB, payload: tabKey });
};

export const setProjectDocs = (data) => (dispatch) => {
    dispatch({ type: PROJECT_DOCS, payload: data });
};

export const setTitleValue = (data) => (dispatch) => {
    dispatch({ type: TITLE_STATE, payload: data });
};
