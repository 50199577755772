import { fetchApprovals, fetchMarkups } from '../../services';
import {
    UPDATE_APPROVAL,
    GET_APPROVALS_INITIATE,
    GET_APPROVALS_SUCCESS,
    GET_APPROVALS_FAILURE,
    GET_MARKUPS_INITIATE,
    GET_MARKUPS_SUCCESS,
    GET_MARKUPS_FAILURE,
    REMOVE_MARKUP_FILE,
} from './types';

export const getApprovals = (pkgId) => async (dispatch) => {
    try {
        dispatch({ type: GET_APPROVALS_INITIATE });
        const response = await fetchApprovals(pkgId);
        dispatch({ type: GET_APPROVALS_SUCCESS, payload: response.data });
    } catch (error) {
        dispatch({ type: GET_APPROVALS_FAILURE, payload: error.data });
    }
};

export const getMarkups = (pkgId) => async (dispatch) => {
    try {
        dispatch({ type: GET_MARKUPS_INITIATE });
        const response = await fetchMarkups(pkgId);
        dispatch({ type: GET_MARKUPS_SUCCESS, payload: response.data });
    } catch (error) {
        dispatch({ type: GET_MARKUPS_FAILURE, payload: error.data });
    }
};

export const updateApproval = (id, data) => (dispatch) => {
    dispatch({ type: UPDATE_APPROVAL, payload: { id, data } });
};

export const removeMarkupFile = (id) => (dispatch) => {
    dispatch({ type: REMOVE_MARKUP_FILE, payload: { id } });
};
