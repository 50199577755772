import { http, fileHttp } from '../utils/http';

export const fetchServiceHistories = async (projectId, pkgId, tagId, status, contract_type) => {
    try {
        const response = await http.get(
            `/projects/${projectId}/package-rooms/${pkgId}/service-history/tag/${tagId}/?status=${status}&contract_type=${contract_type}`,
        );
        return response.data;
    } catch (ex) {
        throw ex;
    }
};

export const addAssigneeInServiceHistory = async (projectId, pkgId, serviceId, assigneeId) => {
    try {
        const response = await http.patch(
            `/projects/${projectId}/package-rooms/${pkgId}/service-history/${serviceId}/assignee/`,
            { assigneeId },
        );
        return response.data;
    } catch (ex) {
        throw ex;
    }
};

export const removeAssigneeFromServiceHistory = async (projectId, pkgId, serviceId, assigneeId) => {
    try {
        const response = await http.delete(
            `/projects/${projectId}/package-rooms/${pkgId}/service-history/${serviceId}/assignee/`,
            { assigneeId },
        );
        return response.data;
    } catch (ex) {
        throw ex;
    }
};

export const updateServiceHistory = async (projectId, pkgId, serviceId, body) => {
    try {
        const response = await http.patch(
            `/projects/${projectId}/package-rooms/${pkgId}/service-history/${serviceId}/`,
            body,
        );
        return response.data;
    } catch (ex) {
        throw ex;
    }
};

export const uploadServiceHistoryDoc = async (projectId, data) => {
    try {
        const response = await fileHttp.post(`projects/${projectId}/service-history/upload/`, data);
        return response.data;
    } catch (ex) {
        throw ex;
    }
};
