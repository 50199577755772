import {
    ApiOutlined,
    AppstoreOutlined,
    ClusterOutlined,
    DoubleRightOutlined,
    DownOutlined,
    EnvironmentOutlined,
    FireFilled,
    FolderOpenOutlined,
    InboxOutlined,
    UnorderedListOutlined,
    UploadOutlined,
    DownloadOutlined,
    AreaChartOutlined,
    CopyOutlined,
} from '@ant-design/icons';
import {
    Breadcrumb,
    Button,
    Col,
    Dropdown,
    Empty,
    Layout,
    Menu,
    Modal,
    Radio,
    Row,
    Spin,
    Switch,
    Tabs,
    Tooltip,
    Upload,
    message,
} from 'antd';
import _ from 'lodash';
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SearchBox, Sidebar, TagListView, SubscriptionMessage } from '../../../components';
import {
    fetchProjects,
    fetchSlinesByProject,
    saveProjectDocs,
    generateKanbanAllReport,
    generateMasterTagReport,
    fetchTagsByProjectV2,
} from '../../../services';
import { MAP_VIEW, PROJECT_SETUP, SWITCHED_TO_OPERATIONS } from '../../../store/common';
import {
    setActiveProject,
    setActiveProjectTab,
    setProjectDocs,
} from '../../../store/common/actions';
import { setPermissions } from '../../../store/permission';
import { PROJECTS, showNotification } from '../../../utils';
import * as Permissions from '../../../utils/permissions';
import CreateProject from '../create';
import Insights from './insights';
import MapListing from './map-listing';
import Packages from './packages';
import ProjectDocs from './project-docs';
import {
    feature_birds_eye_view,
    feature_create_project,
    feature_master_tags,
    feature_reports,
    feature_project_documents,
} from '../../../utils/features';
import ProjectsList from './projects';
import SlinesList from './slines';
import TaskInbox from './task-inbox';
import Rounds from './rounds';
import { getProjectDocuments } from '../../../store/documents';
import NewDocuments from './new-documents';
import { ViewReports } from './view-reports';
import TagAnalytics from './tag-analytics';

const { TabPane } = Tabs;
const { Content } = Layout;

const ProjectListing = ({ history }) => {
    const dispatch = useDispatch();
    const { user } = useSelector(({ auth }) => auth);
    const { permissions } = useSelector(({ permission }) => permission);
    const { activeProject, activeProjectTab, operations, projectDocs, mapView } = useSelector(
        ({ common }) => common,
    );
    const [center, setCenter] = useState([]);
    const [slines, setSlines] = useState([]);
    const [projects, setProjects] = useState([]);
    const [slineLoader, setSlineLoader] = useState(false);
    const [docsLoader, setDocsLoader] = useState(false);
    const [projectLoader, setProjectLoader] = useState(false);
    const [createProjectModal, setCreateProjectModal] = useState(false);
    const [createPackageVisible, setCreatePackageVisible] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [filteredSlines, setFilteredSlines] = useState([]);
    const [projectSearchText, setProjectSearchText] = useState('');
    const [filteredProjects, setFilteredProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState('');
    const [hideSidebar, setHideSidebar] = useState(false);
    const [documentsToUpload, setDocumentsToUpload] = useState([]);
    const [uploadMode, setUploadMode] = useState(false);
    const [mainLoader, setMainLoader] = useState(false);
    const [tempProject, setTempProject] = useState({});
    const [checkpointsData, setCheckpointsData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [infoFetched, setInfoFetched] = useState(false);

    const getProjects = async (operations) => {
        try {
            setProjectLoader(true);
            const response = await fetchProjects(operations ? 'operations' : 'construction');
            if (response) {
                dispatch(setActiveProject(activeProject || response.data[0]));
                setTempProject(activeProject || response.data[0]);
                onProjectClickHandler(
                    activeProject?.id || response.data[0].id,
                    activeProject?.name || response.data[0].name,
                );
                setProjects([...response.data]);
                setProjectLoader(false);
            }
        } catch (ex) {
            setProjectLoader(false);
        }
    };

    const onProjectListClickHandler = (project) => {
        setCenter([project.latitude || 37.0902, project.longitude || -95.7129]);
        dispatch(setActiveProject(project));
    };

    useEffect(() => {
        getProjects(operations);
        setCenter([37.0902, -95.7129]);
    }, [operations]);

    const onProjectClickHandler = async (projectId, projectName) => {
        setSelectedProject(projectId);
        if (projectId) {
            try {
                setDocumentsToUpload([]);
                setUploadMode(false);
                dispatch(setPermissions(user.id, projectId));
                setTempProject({ id: projectId, name: projectName });
                dispatch(setActiveProject({ id: projectId, name: projectName }));
                // Promise.all([fetchProjectDocuments(projectId), getSinglelines(projectId)]);
            } catch (ex) {}
        }
    };

    const searchByTags = (value) => {
        setSlineLoader(true);
        new Promise((resolve, reject) => {
            setTimeout(() => {
                const filtered = slines.filter((sline) => {
                    const tagFound = sline.tags.find((tag) =>
                        tag.name.toLowerCase().includes(value.toLowerCase()),
                    );
                    if (tagFound) {
                        return sline;
                    }
                });
                resolve(filtered);
            }, 1000);
        }).then((filtered) => {
            setFilteredSlines([...filtered]);
            setSlineLoader(false);
        });
    };

    const searchProject = (value) => {
        setProjectLoader(true);
        new Promise((resolve, reject) => {
            setTimeout(() => {
                const filtered = projects.filter((project) =>
                    project.name.toLowerCase().includes(value.toLowerCase()),
                );
                resolve(filtered);
            }, 1000);
        }).then((filtered) => {
            setFilteredProjects([...filtered]);
            setProjectLoader(false);
        });
    };

    const fetchProjectDocuments = async (projectId) => {
        if (feature_project_documents(user.features)) {
            dispatch(getProjectDocuments(projectId));
        }
    };

    useEffect(() => {
        if (infoFetched) {
            setInfoFetched(false);
        }
        if (activeProject) {
            fetchCheckpointsData();
        }
    }, [activeProject]);

    const fetchCheckpointsData = async (event) => {
        const fetchTags = await fetchTagsByProjectV2(activeProject.id, 1, 10, '', '');
        setCheckpointsData(fetchTags.data);
        setTotalCount(fetchTags.meta.total_count);
        setInfoFetched(true);
    };

    const toggleInfoFetched = () => {
        setInfoFetched(false);
    };

    return (
        <Spin
            spinning={mainLoader}
            style={{ minHeight: 200 }}
            tip={mainLoader ? 'Please wait, it will take a while' : ''}
        >
            <Content
                className={
                    hideSidebar ? 'position-relative mb-0 mt-0 ml-20' : 'content-width-sidebar'
                }
            >
                {hideSidebar && (
                    <Tooltip title="Open Projects" placement="right">
                        <div
                            className="sidebar-toggler open"
                            onClick={() => setHideSidebar(!hideSidebar)}
                        >
                            <DoubleRightOutlined />
                        </div>
                    </Tooltip>
                )}

                <Row gutter={30}>
                    <Col span={hideSidebar ? 0 : 5}>
                        <Sidebar
                            addNewBtn={feature_create_project(user.features)}
                            customChildren={
                                <>
                                    <div className="switch-body">
                                        <div
                                            className={`map-toggle-btn ${
                                                mapView ? 'active-toggle' : ''
                                            }`}
                                            onClick={() =>
                                                dispatch({
                                                    type: MAP_VIEW,
                                                })
                                            }
                                        >
                                            <EnvironmentOutlined
                                                style={{ fontSize: '1rem', marginRight: '4px' }}
                                            />
                                            <span>Map</span>
                                        </div>

                                        <div
                                            className={`map-toggle-btn ${
                                                !mapView ? 'active-toggle' : ''
                                            }`}
                                            onClick={() =>
                                                dispatch({
                                                    type: MAP_VIEW,
                                                })
                                            }
                                        >
                                            <UnorderedListOutlined
                                                style={{ fontSize: '1rem', marginRight: '4px' }}
                                            />
                                            <span className="text-custom-switch">Detail</span>
                                        </div>
                                    </div>
                                    <SearchBox
                                        placeholder="Search ..."
                                        style={{ margin: '17px 0' }}
                                        value={projectSearchText}
                                        onChange={(ev) => {
                                            setProjectSearchText(ev.target.value);
                                            searchProject(ev.target.value);
                                        }}
                                    />
                                </>
                            }
                            loader={projectLoader}
                            addNewText="Add New Project"
                            addNewOnClick={() => {
                                dispatch({ type: PROJECT_SETUP, payload: true });
                                history.push(`${PROJECTS}/create`);
                            }}
                            toggleSidebar={() => setHideSidebar(!hideSidebar)}
                            // addNewOnClick={() => setCreateProjectModal(true)}
                        >
                            <ProjectsList
                                activeProject={activeProject}
                                filtered={!projectLoader && projectSearchText}
                                onProjectDBClickHandler={(projectId, name) => {
                                    dispatch({
                                        type: MAP_VIEW,
                                        payload: false,
                                    });
                                    dispatch(setActiveProjectTab('2'));
                                    onProjectClickHandler(projectId, name);
                                }}
                                onProjectClickHandler={(project) => {
                                    dispatch(setActiveProjectTab('1'));
                                    onProjectClickHandler(project.id, project.name);
                                    onProjectListClickHandler(project);
                                }}
                                projects={projectSearchText ? filteredProjects : projects}
                            />
                        </Sidebar>
                    </Col>

                    <Col span={hideSidebar ? 24 : 19} className="fixed-right">
                        {mapView ? (
                            <MapListing
                                onProjectClickHandler={(projectId, name) => {
                                    dispatch(setActiveProjectTab('1'));
                                    onProjectClickHandler(projectId, name);
                                    dispatch({ type: MAP_VIEW });
                                }}
                                projects={projectSearchText ? filteredProjects : projects}
                                centerLocation={center}
                                activeProject={activeProject}
                            />
                        ) : (
                            <>
                                <div className="mt-20" />
                                <div className="breadcrumb-with-heading">
                                    <Breadcrumb separator=">">
                                        <Breadcrumb.Item>Alectify</Breadcrumb.Item>
                                        <Breadcrumb.Item>
                                            {activeProject?.name || 'Projects'}
                                        </Breadcrumb.Item>
                                    </Breadcrumb>
                                </div>

                                <Spin spinning={projectLoader}>
                                    <div
                                        className={projects.length > 0 ? '' : 'no-projects-wrapper'}
                                    >
                                        {projects.length > 0 && !_.isEmpty(permissions) ? (
                                            <div>
                                                <Tabs
                                                    defaultActiveKey={activeProjectTab}
                                                    activeKey={uploadMode ? '' : activeProjectTab}
                                                    onChange={(tabId) => {
                                                        dispatch(setActiveProjectTab(tabId));
                                                        setUploadMode(false);
                                                    }}
                                                    className="project-detail-tabs white-bg-tabs nested-tabs"
                                                    animated={false}
                                                    onTabClick={(key, event) => {
                                                        if (key == 4) fetchCheckpointsData();
                                                    }}
                                                >
                                                    {Permissions.can_view_insights(permissions) && (
                                                        <TabPane
                                                            tab={
                                                                <span>
                                                                    <FireFilled
                                                                        style={{ fontSize: 20 }}
                                                                    />
                                                                    Insights
                                                                </span>
                                                            }
                                                            key="1"
                                                            className="custom-tab"
                                                        >
                                                            <Insights projectId={tempProject?.id} />
                                                        </TabPane>
                                                    )}

                                                    {Permissions.can_view_master_tags(
                                                        permissions,
                                                    ) && (
                                                        <TabPane
                                                            tab={
                                                                <span>
                                                                    <ApiOutlined
                                                                        style={{ fontSize: 20 }}
                                                                    />
                                                                    Checkpoints
                                                                </span>
                                                            }
                                                            key="4"
                                                        >
                                                            {feature_master_tags(user.features) ? (
                                                                <div
                                                                    className="page-content-container"
                                                                    style={{
                                                                        padding: 0,
                                                                        overflow: 'hidden',
                                                                        height: 'auto',
                                                                    }}
                                                                >
                                                                    {activeProject &&
                                                                        infoFetched && (
                                                                            <TagListView
                                                                                activeProjectId={
                                                                                    activeProject?.id
                                                                                }
                                                                                masterTagView
                                                                            />
                                                                        )}
                                                                </div>
                                                            ) : (
                                                                <SubscriptionMessage />
                                                            )}
                                                        </TabPane>
                                                    )}
                                                    {Permissions.can_view_packages(permissions) && (
                                                        <TabPane
                                                            tab={
                                                                <span>
                                                                    <AppstoreOutlined
                                                                        style={{ fontSize: 20 }}
                                                                    />{' '}
                                                                    Area
                                                                </span>
                                                            }
                                                            key="5"
                                                        >
                                                            {activeProject?.id &&
                                                                activeProjectTab === '5' && (
                                                                    <Packages
                                                                        setCreatePackageVisible={
                                                                            setCreatePackageVisible
                                                                        }
                                                                        createPackageVisible={
                                                                            createPackageVisible
                                                                        }
                                                                        projectId={
                                                                            activeProject?.id
                                                                        }
                                                                    />
                                                                )}
                                                        </TabPane>
                                                    )}
                                                    {Permissions.can_view_reports(permissions) && (
                                                        <TabPane
                                                            tab={
                                                                <span>
                                                                    <CopyOutlined
                                                                        style={{ fontSize: 20 }}
                                                                    />
                                                                    Reports
                                                                </span>
                                                            }
                                                            key="6"
                                                        >
                                                            {1 ? (
                                                                <>
                                                                    {activeProject && (
                                                                        <ViewReports
                                                                            projectId={
                                                                                tempProject?.id
                                                                            }
                                                                        />
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <SubscriptionMessage />
                                                            )}
                                                        </TabPane>
                                                    )}

                                                    <TabPane
                                                        tab={
                                                            <span>
                                                                <InboxOutlined
                                                                    style={{ fontSize: 20 }}
                                                                />
                                                                Rounds
                                                            </span>
                                                        }
                                                        key="8"
                                                    >
                                                        <Rounds projectId={activeProject?.id} />
                                                    </TabPane>
                                                    {Permissions.can_view_analytics(
                                                        permissions,
                                                    ) && (
                                                        <TabPane
                                                            tab={
                                                                <span>
                                                                    <AreaChartOutlined
                                                                        style={{ fontSize: 20 }}
                                                                    />
                                                                    Analytics
                                                                </span>
                                                            }
                                                            key="7"
                                                        >
                                                            <div>
                                                                {activeProject && (
                                                                    <TagAnalytics
                                                                        projectId={tempProject?.id}
                                                                    />
                                                                )}
                                                            </div>
                                                        </TabPane>
                                                    )}
                                                </Tabs>
                                            </div>
                                        ) : !projectLoader && projects.length === 0 ? (
                                            <Empty
                                                style={{ fontSize: 16, color: '#777' }}
                                                description="No projects found, please create"
                                                className="no-projects-empty"
                                                imageStyle={{ height: 230 }}
                                            >
                                                {feature_create_project(user.features) && (
                                                    <Button
                                                        className="btn-lg btn-primary-lg save-btn"
                                                        type="primary"
                                                        onClick={() =>
                                                            history.push(`${PROJECTS}/create`)
                                                        }
                                                    >
                                                        Create New
                                                    </Button>
                                                )}
                                            </Empty>
                                        ) : !projectLoader && _.isEmpty(permissions) ? (
                                            <Empty
                                                style={{ fontSize: 16, color: '#777' }}
                                                description="You don't have permissions for this project"
                                                className="no-projects-empty"
                                                imageStyle={{ height: 230 }}
                                            ></Empty>
                                        ) : null}
                                    </div>
                                </Spin>
                            </>
                        )}
                    </Col>
                </Row>
                <Modal
                    title={'Create Project'}
                    visible={createProjectModal}
                    onOk={() => setCreateProjectModal(false)}
                    onCancel={() => setCreateProjectModal(false)}
                    width={800}
                    centered
                    footer={null}
                >
                    <CreateProject
                        loadProjects={() => {
                            getProjects(operations);
                            setCreateProjectModal(false);
                        }}
                    />
                </Modal>
            </Content>
        </Spin>
    );
};

export default memo(ProjectListing);
