export const confirmUserContent = (user) => {
    let html = '<p>User with this email already exists, do you want to add them?</p>';
    html += '<div class="ant-table ant-table-small ant-table-container">';
    html += '<table>';
    html += '<thead class="ant-table-thead">';
    html += '<tr>';
    html += '<th></th>';
    html += '<th>First Name</th>';
    html += '<th>Last Name</th>';
    html += '<th>Email</th>';
    html += '<th>Organization Name</th>';
    html += '<th>Organization Type</th>';
    html += '<th>Role</th>';
    html += '</tr>';
    html += '</thead>';
    html += '<tbody class="ant-table-tbody">';
    html += '<tr class="ant-table-row">';
    html +=
        '<td class="ant-table-cell">' +
        '<img style="width:30px; border-radius: 50%;" src="' +
        user.image +
        '" />' +
        '</td>';
    html += '<td class="ant-table-cell">' + user.first_name + '</td>';
    html += '<td class="ant-table-cell">' + user.last_name + '</td>';
    html += '<td class="ant-table-cell">' + user.email + '</td>';
    html += '<td class="ant-table-cell">' + user.organization.name + '</td>';
    html += '<td class="ant-table-cell">' + user.organization_type.name + '</td>';
    html += '<td class="ant-table-cell">' + user.role.name + '</td>';
    html += '</tr>';
    html += '</tbody>';
    html += '</table>';
    html += '</div>';
    return html;
};
