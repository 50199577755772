import { useEffect, useState } from 'react';
import {
    Button,
    Form,
    Input,
    Layout,
    message,
    Modal,
    Popconfirm,
    Space,
    Switch,
    Table,
    Tag,
    Tooltip,
    Select,
    Row,
    Col,
    Checkbox,
} from 'antd';
import {
    deleteInactivePackages,
    fetchAdminProjects,
    hardDeleteProject,
    login,
    reActivateProject,
    softDeleteProject,
    createCompany,
    createBranch,
    createBranchAdmin,
    fetchCompanies,
    fetchBranches,
    getRoles,
    getOrganizationTypes,
    fetchFeatures,
    updateFeature,
    fetchUsersWithBranches,
    updateBranch,
} from '../../../services';
import { DeleteTwoTone, EditOutlined, EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';

const { Content } = Layout;
const { Option } = Select;

const ProjectsAdmin = () => {
    const [form] = Form.useForm();
    const [companyForm] = Form.useForm();
    const [companyBranchForm] = Form.useForm();
    const [branchAdminForm] = Form.useForm();
    const [changeBranchForm] = Form.useForm();
    const [type, setType] = useState('active');
    const [loading, setLoading] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [projects, setProjects] = useState([]);
    const [authorizeModal, setAuthorizeModal] = useState(false);
    const [companyModal, setCompanyModal] = useState(false);
    const [branchModal, setBranchModal] = useState(false);
    const [branchAdminModal, setBranchAdminModal] = useState(false);
    const [changeBranchModal, setChangeBranchModal] = useState(false);
    const [activeUser, setActiveUser] = useState({});
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [branches, setBranches] = useState([]);
    const [organizationTypes, setOrganizationTypes] = useState([]);
    const [roles, setRoles] = useState([]);
    const [features, setFeatures] = useState([]);
    const [featuresLoading, setFeaturesLoading] = useState(false);
    const [allUsers, setAllUsers] = useState([]);

    const getFeatures = async () => {
        try {
            setFeaturesLoading(true);
            const response = await fetchFeatures();
            setFeatures([...response.data]);
            setFeaturesLoading(false);
        } catch (error) {
            setFeaturesLoading(false);
            console.log(error);
        }
    };

    const getAdminProjects = async () => {
        try {
            setLoading(true);
            const response = await fetchAdminProjects(type);
            console.log(response.data);
            setProjects([...response.data]);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const softDelete = async (projectId) => {
        try {
            if (isSuperAdmin) {
                setLoading(true);
                const response = await softDeleteProject(projectId);
                message.success(response.message);
                getAdminProjects();
            } else {
                message.error("You're not authorized");
            }
        } catch (error) {
            setLoading(false);
        }
    };

    const activateProject = async (projectId) => {
        try {
            if (isSuperAdmin) {
                setLoading(true);
                const response = await reActivateProject(projectId);
                message.success(response.message);
                getAdminProjects();
            } else {
                message.error("You're not authorized");
            }
        } catch (error) {
            setLoading(false);
        }
    };

    const hardDelete = async (projectId) => {
        try {
            if (isSuperAdmin) {
                setLoading(true);
                const response = await hardDeleteProject(projectId);
                message.success(response.message);
                getAdminProjects();
            } else {
                message.error("You're not authorized");
            }
        } catch (error) {
            setLoading(false);
        }
    };

    const hardDeleteInactivePackages = async () => {
        try {
            if (isSuperAdmin) {
                setLoading(true);
                const response = await deleteInactivePackages();
                message.success(response.message);
                setLoading(false);
            } else {
                message.error("You're not authorized");
            }
        } catch (ex) {
            setLoading(false);
        }
    };

    const onAuthorizeSubmit = async (values) => {
        try {
            setLoading(true);
            const response = await login(values);
            if (response.data.is_superuser) {
                setIsSuperAdmin(true);
                setAuthorizeModal(false);
                message.success('Authorized');
            } else {
                setIsSuperAdmin(false);
                message.error("You're not authorized");
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            message.error('Invalid credentials');
        }
    };

    const getCompanies = async () => {
        try {
            const response = await fetchCompanies();
            setCompanies([...response.data]);
        } catch (error) {
            console.log(error);
        }
    };

    const getBranches = async (companyId) => {
        try {
            branchAdminForm.setFieldsValue({ branch: null });
            const response = await fetchBranches(companyId);
            setBranches([...response.data]);
        } catch (error) {
            console.log(error);
        }
    };

    const fetchOrganizationTypes = async () => {
        try {
            const respone = await getOrganizationTypes();
            setOrganizationTypes(respone.data);
        } catch (error) {
            console.log(error);
        }
    };

    const getUsers = async () => {
        const response = await fetchUsersWithBranches();
        setAllUsers(response.data);
    };

    const onTypeChange = async (value) => {
        try {
            branchAdminForm.setFieldsValue({ role: null });
            const result = await getRoles(value);
            setRoles([...result.data]);
        } catch (error) {
            console.log(error);
        }
    };

    const onFeatureChange = async (key, value, featureId) => {
        try {
            console.log(key, value, featureId);
            setFeaturesLoading(true);
            const response = await updateFeature(featureId, { [key]: value });
            const newData = features.map((feature) => {
                if (feature.id === featureId) {
                    return response.data;
                }
                return feature;
            });
            setFeatures([...newData]);
            setFeaturesLoading(false);
        } catch (error) {
            setFeaturesLoading(false);
        }
    };

    const onCreateCompany = async (values) => {
        try {
            setFormLoading(true);
            const response = await createCompany(values);
            message.success(response.message);
            setFormLoading(false);
            setCompanyModal(false);
            getCompanies();
            companyForm.resetFields();
        } catch (error) {
            setFormLoading(false);
            console.log(error);
        }
    };

    const onCreateBranch = async (values) => {
        try {
            setFormLoading(true);
            const response = await createBranch(values);
            message.success(response.message);
            setFormLoading(false);
            setBranchModal(false);
            companyBranchForm.resetFields();
            getFeatures();
        } catch (error) {
            setFormLoading(false);
            console.log(error);
        }
    };

    const onCreateBranchAdmin = async (values) => {
        try {
            setFormLoading(true);
            values.is_branch_admin = true;
            await createBranchAdmin(values);
            message.success('Branch Admin Created');
            setFormLoading(false);
            setBranchAdminModal(false);
            companyBranchForm.resetFields();
        } catch (error) {
            setFormLoading(false);
            const apiErrors = error?.response?.data?.data;
            if (apiErrors) {
                const errors = Object.keys(apiErrors).map((key) => ({
                    name: key,
                    errors: apiErrors[key],
                }));
                branchAdminForm.setFields(errors);
            }
        }
    };

    const onUpdateBranch = async (values) => {
        try {
            setFormLoading(true);
            await updateBranch({ branch: values.branch, user: activeUser.id });
            message.success('Branch Updated');
            setFormLoading(false);
            setChangeBranchModal(false);
            changeBranchForm.resetFields();
            setActiveUser({});
            await getUsers();
        } catch (error) {
            setFormLoading(false);
            const apiErrors = error?.response?.data?.data;
            if (apiErrors) {
                const errors = Object.keys(apiErrors).map((key) => ({
                    name: key,
                    errors: apiErrors[key],
                }));
                changeBranchForm.setFields(errors);
            }
        }
    };

    useEffect(() => {
        Promise.all([getCompanies(), fetchOrganizationTypes(), getFeatures(), getUsers()]);
        !isSuperAdmin && setAuthorizeModal(true);
    }, []);

    useEffect(() => {
        getAdminProjects();
    }, [type]);

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Description',
            dataIndex: 'description',
        },
        {
            title: 'Type',
            dataIndex: 'is_operational',
            render: (_, record) => (
                <Tag color="blue">{record?.is_operational ? 'Operations' : 'Construction'}</Tag>
            ),
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            render: (_, record) => (
                <Space>
                    {type === 'active' ? (
                        <Tooltip title="De Activate">
                            <EyeInvisibleOutlined
                                style={{ fontSize: 18, cursor: 'pointer' }}
                                onClick={() => softDelete(record.id)}
                            />
                        </Tooltip>
                    ) : (
                        <Tooltip title="Activate">
                            <EyeOutlined
                                style={{ fontSize: 18, cursor: 'pointer' }}
                                onClick={() => activateProject(record.id)}
                            />
                        </Tooltip>
                    )}
                    <Tooltip title="Delete">
                        <Popconfirm
                            okText="Yes"
                            onConfirm={() => hardDelete(record.id)}
                            title="Are your sure?"
                        >
                            <DeleteTwoTone
                                twoToneColor="#f56666"
                                style={{ fontSize: 18, cursor: 'pointer' }}
                            />
                        </Popconfirm>
                    </Tooltip>
                </Space>
            ),
        },
    ];

    const featureColumns = [
        {
            title: 'Company',
            dataIndex: 'branch',
            render: (branch) => branch.company.name,
        },
        {
            title: 'Branch',
            dataIndex: 'branch',
            render: (branch) => branch.name,
        },
        {
            title: "Bird's Eye (PDF)",
            dataIndex: 'birds_eye_view',
            render: (value, record) => (
                <Checkbox
                    checked={value}
                    onChange={(ev) =>
                        onFeatureChange('birds_eye_view', ev.target.checked, record.id)
                    }
                />
            ),
        },
        {
            title: "Bird's Eye (Tags)",
            dataIndex: 'birds_eye_view_tags',
            render: (value, record) => (
                <Checkbox
                    checked={value}
                    onChange={(ev) =>
                        onFeatureChange('birds_eye_view_tags', ev.target.checked, record.id)
                    }
                />
            ),
        },
        {
            title: 'Project Documents',
            dataIndex: 'project_documents',
            render: (value, record) => (
                <Checkbox
                    checked={value}
                    onChange={(ev) =>
                        onFeatureChange('project_documents', ev.target.checked, record.id)
                    }
                />
            ),
        },
        {
            title: 'Equipment Documents',
            dataIndex: 'equipment_documents',
            render: (value, record) => (
                <Checkbox
                    checked={value}
                    onChange={(ev) =>
                        onFeatureChange('equipment_documents', ev.target.checked, record.id)
                    }
                />
            ),
        },
        {
            title: 'Create Project',
            dataIndex: 'create_project',
            render: (value, record) => (
                <Checkbox
                    checked={value}
                    onChange={(ev) =>
                        onFeatureChange('create_project', ev.target.checked, record.id)
                    }
                />
            ),
        },
        {
            title: 'Manage Project',
            dataIndex: 'manage_project',
            render: (value, record) => (
                <Checkbox
                    checked={value}
                    onChange={(ev) =>
                        onFeatureChange('manage_project', ev.target.checked, record.id)
                    }
                />
            ),
        },
        {
            title: 'Master Tags',
            dataIndex: 'master_tags',
            render: (value, record) => (
                <Checkbox
                    checked={value}
                    onChange={(ev) => onFeatureChange('master_tags', ev.target.checked, record.id)}
                />
            ),
        },
        {
            title: 'Notifications',
            dataIndex: 'notifications',
            render: (value, record) => (
                <Checkbox
                    checked={value}
                    onChange={(ev) =>
                        onFeatureChange('notifications', ev.target.checked, record.id)
                    }
                />
            ),
        },
        {
            title: 'Gantt Chart',
            dataIndex: 'insights_gantt_chart',
            render: (value, record) => (
                <Checkbox
                    checked={value}
                    onChange={(ev) =>
                        onFeatureChange('insights_gantt_chart', ev.target.checked, record.id)
                    }
                />
            ),
        },
        {
            title: 'Gant Chart Filters',
            dataIndex: 'insights_gantt_chart_filter',
            render: (value, record) => (
                <Checkbox
                    checked={value}
                    onChange={(ev) =>
                        onFeatureChange('insights_gantt_chart_filter', ev.target.checked, record.id)
                    }
                />
            ),
        },
        {
            title: 'Review Process',
            dataIndex: 'review_process',
            render: (value, record) => (
                <Checkbox
                    checked={value}
                    onChange={(ev) =>
                        onFeatureChange('review_process', ev.target.checked, record.id)
                    }
                />
            ),
        },
        {
            title: 'Kanban',
            dataIndex: 'kanban',
            render: (value, record) => (
                <Checkbox
                    checked={value}
                    onChange={(ev) => onFeatureChange('kanban', ev.target.checked, record.id)}
                />
            ),
        },
        {
            title: 'Inbox Group Chats',
            dataIndex: 'inbox_groups_chats',
            render: (value, record) => (
                <Checkbox
                    checked={value}
                    onChange={(ev) =>
                        onFeatureChange('inbox_groups_chats', ev.target.checked, record.id)
                    }
                />
            ),
        },
        {
            title: 'Inbox One to One Chats',
            dataIndex: 'inbox_one_to_one_chats',
            render: (value, record) => (
                <Checkbox
                    checked={value}
                    onChange={(ev) =>
                        onFeatureChange('inbox_one_to_one_chats', ev.target.checked, record.id)
                    }
                />
            ),
        },
    ];

    const usersColumn = [
        {
            title: 'Branch',
            dataIndex: 'branch',
            render: (branch) => branch?.name || '-',
        },
        {
            title: 'Full Name',
            dataIndex: 'first_name',
            render: (_, record) => `${record.first_name} ${record.last_name}`,
        },
        {
            title: 'Email',
            dataIndex: 'email',
        },
        {
            title: 'Branch Admin',
            dataIndex: 'is_branch_admin',
            render: (value) => <Tag color="blue">{value ? 'Yes' : 'No'}</Tag>,
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            render: (_, record) => (
                <Space>
                    <Tooltip title="Edit Branch">
                        <EditOutlined
                            onClick={async () => {
                                await getBranches(record.branch?.company.id);
                                setActiveUser(record);
                                setChangeBranchModal(true);
                            }}
                            style={{ fontSize: 18, cursor: 'pointer', color: '#16AAFF' }}
                        />
                    </Tooltip>
                </Space>
            ),
        },
    ];

    return (
        <Content className="fixed-layout">
            <div className="d-flex justify-space-between">
                <Button
                    type="primary"
                    danger
                    onClick={hardDeleteInactivePackages}
                    disabled={loading}
                >
                    Delete All In Active Packages
                </Button>
                <Space>
                    <Button type="primary" onClick={() => setCompanyModal(true)}>
                        Create Company
                    </Button>
                    <Button type="primary" onClick={() => setBranchModal(true)}>
                        Create Branch
                    </Button>
                    <Button type="primary" onClick={() => setBranchAdminModal(true)}>
                        Create Branch Admin
                    </Button>
                    <Switch
                        checkedChildren="Active"
                        unCheckedChildren="In Active"
                        defaultChecked
                        onChange={(checked) => setType(checked ? 'active' : 'inactive')}
                    />
                </Space>
            </div>
            <Table columns={columns} loading={loading} dataSource={projects} />
            <h1>Features</h1>
            <Table
                rowKey="id"
                dataSource={features}
                columns={featureColumns}
                loading={featuresLoading}
            />
            <h1>Users with Branches</h1>
            <Table rowKey="id" dataSource={allUsers} columns={usersColumn} />
            <Modal
                visible={authorizeModal}
                okText="Authorize"
                onOk={form.submit}
                cancelButtonProps={{ style: { display: 'none' } }}
                closable={false}
                centered
                mask
                confirmLoading={loading}
            >
                <Form
                    form={form}
                    name="basic"
                    layout="vertical"
                    onFinish={onAuthorizeSubmit}
                    onFinishFailed={() => {}}
                    validateTrigger="onSubmit"
                >
                    <Form.Item
                        label="Email Address"
                        name="email"
                        rules={[
                            {
                                type: 'email',
                                message: 'The input is not valid E-mail!',
                            },
                            {
                                required: true,
                                message: 'Please enter email',
                            },
                        ]}
                    >
                        <Input placeholder="Enter email address" />
                    </Form.Item>

                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter password',
                            },
                            {
                                min: 6,
                                message: 'Password must contain atleast 6 characters.',
                            },
                        ]}
                    >
                        <Input.Password placeholder="Enter password" />
                    </Form.Item>
                </Form>
            </Modal>
            {/* ============= Company Modal ============ */}
            <Modal
                visible={companyModal}
                okText="Create Company"
                onOk={companyForm.submit}
                onCancel={() => setCompanyModal(false)}
                centered
                mask
                confirmLoading={formLoading}
            >
                <Form
                    form={companyForm}
                    name="basic"
                    layout="vertical"
                    onFinish={onCreateCompany}
                    onFinishFailed={() => {}}
                    validateTrigger="onSubmit"
                >
                    <Form.Item
                        label="Company Name"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Required',
                            },
                        ]}
                    >
                        <Input placeholder="Company Name" />
                    </Form.Item>
                </Form>
            </Modal>
            {/* ============= Branch Modal ============ */}
            <Modal
                visible={branchModal}
                okText="Create Branch"
                onOk={companyBranchForm.submit}
                onCancel={() => setBranchModal(false)}
                centered
                mask
                confirmLoading={formLoading}
            >
                <Form
                    form={companyBranchForm}
                    name="basic"
                    layout="vertical"
                    onFinish={onCreateBranch}
                    onFinishFailed={() => {}}
                    validateTrigger="onSubmit"
                >
                    <Form.Item
                        label="Company Name"
                        name="company"
                        rules={[
                            {
                                required: true,
                                message: 'Required',
                            },
                        ]}
                    >
                        <Select>
                            {companies.map((company) => (
                                <Option key={company.id} value={company.id}>
                                    {company.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Branch Name"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Required',
                            },
                        ]}
                    >
                        <Input placeholder="Branch Name" />
                    </Form.Item>
                </Form>
            </Modal>
            {/* ============= Branch Admin Modal ============ */}
            <Modal
                visible={branchAdminModal}
                okText="Create Admin"
                onOk={branchAdminForm.submit}
                onCancel={() => setBranchAdminModal(false)}
                centered
                mask
                confirmLoading={formLoading}
            >
                <Form
                    form={branchAdminForm}
                    name="basic"
                    layout="vertical"
                    onFinish={onCreateBranchAdmin}
                    onFinishFailed={() => {}}
                    validateTrigger="onSubmit"
                >
                    <Row gutter={30}>
                        <Col span={12}>
                            <Form.Item
                                label="Company Name"
                                name="company"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <Select onChange={getBranches}>
                                    {companies.map((company) => (
                                        <Option key={company.id} value={company.id}>
                                            {company.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Branch"
                                name="branch"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <Select>
                                    {branches.map((branch) => (
                                        <Option key={branch.id} value={branch.id}>
                                            {branch.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="First Name"
                                name="first_name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your first name',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Last Name"
                                name="last_name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your last name',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="Email Address"
                                name="email"
                                rules={[
                                    {
                                        type: 'email',
                                        message: 'The input is not valid E-mail!',
                                    },
                                    {
                                        required: true,
                                        message: 'Please enter your email',
                                    },
                                ]}
                                hasFeedback
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="Organization Name"
                                name="organization_name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter the organization name',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Type"
                                name="organization_type"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select the type',
                                    },
                                ]}
                            >
                                <Select onChange={onTypeChange}>
                                    {organizationTypes.map((org) => (
                                        <Select.Option key={org.id} value={org.id}>
                                            {org.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Role"
                                name="role"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select the role',
                                    },
                                ]}
                            >
                                <Select>
                                    {roles.map((role) => (
                                        <Select.Option key={role.id} value={role.id}>
                                            {role.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your password',
                                    },
                                    {
                                        min: 6,
                                        message: 'Password must contain atleast 6 characters.',
                                    },
                                ]}
                                hasFeedback
                            >
                                <Input.Password />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="confirmPassword"
                                label="Confirm Password"
                                dependencies={['password']}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please confirm your password!',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(
                                                new Error(
                                                    'The two passwords that you entered do not match!',
                                                ),
                                            );
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>

            {/* ============= Change Branch ============ */}
            <Modal
                visible={changeBranchModal}
                okText="Update"
                onOk={changeBranchForm.submit}
                onCancel={() => setChangeBranchModal(false)}
                centered
                mask
                confirmLoading={formLoading}
            >
                <Form
                    form={changeBranchForm}
                    name="basic"
                    layout="vertical"
                    onFinish={onUpdateBranch}
                    onFinishFailed={() => {}}
                    validateTrigger="onSubmit"
                    initialValues={{
                        company: activeUser?.branch?.company.id,
                        branch: activeUser?.branch?.id,
                    }}
                >
                    <Row gutter={30}>
                        <Col span={12}>
                            <Form.Item
                                label="Company Name"
                                name="company"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <Select onChange={getBranches}>
                                    {companies.map((company) => (
                                        <Option key={company.id} value={company.id}>
                                            {company.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Branch"
                                name="branch"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <Select value={activeUser?.branch?.id}>
                                    {branches.map((branch) => (
                                        <Option key={branch.id} value={branch.id}>
                                            {branch.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </Content>
    );
};

export default ProjectsAdmin;
