import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Card, Table, Avatar, Space } from 'antd';
import { fetchContacts } from '../../services';
import { makeAvatar } from '../../utils';

function Contacts() {
    const [contactList, setContactList] = useState([]);
    const [loader, setLoader] = useState(false);

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <Space>
                    <Avatar
                        className="circle-icon"
                        src={record?.image || makeAvatar(record.first_name[0], record.last_name[0])}
                    />
                    <p className="text-body mb-0">
                        {record.first_name} {record.last_name}
                    </p>
                </Space>
            ),
        },
        {
            title: 'Type',
            dataIndex: 'organization_type',
            render: (organization_type) =>
                !_.isEmpty(organization_type) ? organization_type?.name : '',
        },
        {
            title: 'Role',
            dataIndex: 'role',
            render: (role) => (!_.isEmpty(role) ? role?.name : ''),
        },
        {
            title: 'Organization',
            dataIndex: 'organization',
            render: (organization) => (!_.isEmpty(organization) ? organization?.name : ''),
        },
        {
            title: 'Contact number',
            dataIndex: 'contact_number',
        },

        {
            title: 'Email',
            dataIndex: 'email',
        },
    ];

    useEffect(() => {
        getContacts();
    }, []);

    const getContacts = async () => {
        try {
            setLoader(true);
            const response = await fetchContacts();
            setContactList([...response.data]);
        } catch (error) {
            console.log(error);
        } finally {
            setLoader(false);
        }
    };

    return (
        <Card>
            <Table
                loading={loader}
                columns={columns}
                dataSource={contactList}
                pagination={contactList.length > 10}
            />
        </Card>
    );
}
export default Contacts;
