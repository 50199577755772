import _ from 'lodash';
import moment from 'moment';
import { Avatar, Popconfirm, Space, Table, Tooltip } from 'antd';
import { DeleteTwoTone, DownloadOutlined } from '@ant-design/icons';
import { DISPLAY_DATETIME_FORMAT, filterProjectDocumentsData } from '../../utils';

export const DocumentsListing = ({
    documents = [],
    directoryName = '',
    shouldShowDeleteButton = true,
    deleteDocument = () => {},
    downloadDocument = () => {},
}) => {
    const columns = [
        {
            title: 'File Name',
            dataIndex: 'file_name',
        },
        {
            title: 'Uploaded at',
            dataIndex: 'created_at',
            render: (value) => (value ? moment(value).format(DISPLAY_DATETIME_FORMAT) : ''),
        },
        {
            title: 'Uploaded by',
            align: 'center',
            dataIndex: 'account',
            render: (account) =>
                !_.isEmpty(account) ? (
                    <Tooltip title={`${account?.first_name} ${account?.last_name}`}>
                        <Avatar src={account?.image} />
                    </Tooltip>
                ) : (
                    ''
                ),
        },
        {
            title: 'Actions',
            dataIndex: 'action',
            align: 'center',
            render: (_, record) => (
                <Space>
                    <Tooltip title="Download">
                        <DownloadOutlined
                            style={{ fontSize: 18, cursor: 'pointer' }}
                            onClick={() => downloadDocument(record)}
                        />
                    </Tooltip>
                    {shouldShowDeleteButton && (
                        <Tooltip title="Delete">
                            <Popconfirm
                                okText="Yes"
                                onConfirm={() => deleteDocument(record.id)}
                                title="Are your sure?"
                            >
                                <DeleteTwoTone
                                    twoToneColor="#f56666"
                                    style={{ fontSize: 18, cursor: 'pointer' }}
                                />
                            </Popconfirm>
                        </Tooltip>
                    )}
                </Space>
            ),
        },
    ];

    return (
        !_.isEmpty(documents) && (
            <Table
                rowKey="id"
                columns={columns}
                pagination={false}
                dataSource={filterProjectDocumentsData(documents, directoryName)}
            />
        )
    );
};
