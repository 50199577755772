import { Button, Col, Row, Select, Spin, Typography } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { MyDateRangePicker } from '../../../components/date-range-selector';
import { fetchAnalyticsByProject, fetchTagsByProjectDropDown } from '../../../services';
import { SHIFT_OPTIONS } from '../../../utils';
import Analytics from '../../operations/package/detail/analytics';
import '../../operations/package/detail/analytics.scss';
const { Option } = Select;

const TagAnalytics = ({ projectId }) => {
    const [tags, setTags] = useState([]);
    const [dates, setDates] = useState({
        startDate: moment().format('yyyy-MM-DD'),
        endDate: moment().format('yyyy-MM-DD'),
    });
    const [activeTag, setActiveTag] = useState({});
    const [shift, setShift] = useState(0);
    const [tagAnalytics, setTagAnalytics] = useState([]);
    const [analyticsLoader, setAnalyticsLoader] = useState(false);

    const fetchAnalytics = async (tagId) => {
        try {
            setAnalyticsLoader(true);
            const response = await fetchAnalyticsByProject(
                projectId,
                tagId,
                shift,
                dates.startDate,
                dates.endDate,
            );
            if (response && response.data.length) {
                const data = response.data
                    .sort((a, b) => new Date(b.shift_date) - new Date(a.shift_date))
                    .map(({ shift_date, shift, reading_key }) => [shift_date, reading_key]);

                setTagAnalytics([...data]);
            }
            setAnalyticsLoader(false);
        } catch (error) {
            setAnalyticsLoader(false);
            console.log(error);
        }
    };

    const fetchTags = async (projId) => {
        const response = await fetchTagsByProjectDropDown(projId, true);
        if (response && response.data.length) {
            setActiveTag(response.data[0]);
            fetchAnalytics(response.data[0].id);
            setTags(response.data);
        }
    };

    const tagChangeHandler = (val) => {
        const tag = tags.find(({ id }) => id === val);
        setActiveTag(tag);
    };

    useEffect(() => {
        setDates({
            startDate: moment().format('yyyy-MM-DD'),
            endDate: moment().format('yyyy-MM-DD'),
        });
        setTags([]);
        setShift(0);
        fetchTags(projectId);
    }, [projectId]);

    return (
        <div className="analytics-tab">
            <Row className="analytics-content" justify="start" align="bottom" gutter={[10]}>
                <Col span={3}>
                    <Typography.Text style={{ marginRight: '8px', marginTop: '1.5%' }}>
                        Report Type:
                    </Typography.Text>
                    <Select size="middle" value={'Weekly'} disabled style={{ width: 150 }}>
                        <Option key={'Weekly'} value={'Weekly'}>
                            Weekly
                        </Option>
                    </Select>
                </Col>

                <Col span={3}>
                    <Typography.Text style={{ marginRight: '8px', marginTop: '1.5%' }}>
                        Checkpoints:
                    </Typography.Text>
                    <Select
                        size="middle"
                        onChange={tagChangeHandler}
                        style={{ width: 150 }}
                        value={activeTag.id}
                    >
                        {tags.map((tag) => (
                            <Option key={tag.id} value={tag.id}>
                                {tag.name}
                            </Option>
                        ))}
                    </Select>
                </Col>

                <Col span={3}>
                    <Typography.Text style={{ marginRight: '8px', marginTop: '1.5%' }}>
                        Shift:
                    </Typography.Text>
                    <Select
                        size="middle"
                        value={shift}
                        onChange={(val) => setShift(val)}
                        style={{ width: 150 }}
                    >
                        {SHIFT_OPTIONS &&
                            SHIFT_OPTIONS.length &&
                            SHIFT_OPTIONS.map((shft) => (
                                <Option key={shft.id} value={shft.id}>
                                    {shft.name}
                                </Option>
                            ))}
                    </Select>
                </Col>

                <Col span={5}>
                    <Typography.Text style={{ marginRight: '8px', marginTop: '1.5%' }}>
                        Range:
                    </Typography.Text>
                    <MyDateRangePicker
                        dateRange={{ dates }}
                        parentCallback={(val) => setDates(val)}
                    />
                </Col>
                <Col span={5}>
                    <Button type="primary" onClick={() => fetchAnalytics(activeTag.id)}>
                        Update Chart
                    </Button>
                </Col>
            </Row>
            <Spin spinning={analyticsLoader}>
                <div style={{ marginTop: '30px' }}>
                    <h4
                        style={{
                            color: '#555',
                            fontSize: '20px',
                        }}
                    >
                        {activeTag?.name} ({activeTag?.description})
                    </h4>
                    <Analytics tagAnalytics={tagAnalytics} />
                </div>
            </Spin>
        </div>
    );
};

export default TagAnalytics;
