import {
    ACTIVE_PROJECT,
    ACTIVE_PROJECT_TAB,
    HIDE_LOADER,
    SHOW_LOADER,
    SHOW_NOTIFICATION,
    RESET_STATE,
    SWITCHED_TO_OPERATIONS,
    PROJECT_DOCS,
    PROJECT_SETUP,
    MAP_VIEW,
    TITLE_STATE,
} from './types';

const initialState = {
    loader: false,
    notification: {
        open: false,
        message: '',
        description: '',
    },
    error: null,
    activeProject: null,
    activeProjectTab: '1',
    operations: true,
    projectDocs: [],
    mapView: true,
    createNewProject: true,
    titleValue: 'Construction',
};

export const commonReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SHOW_LOADER:
            return { ...state, loader: true };

        case HIDE_LOADER:
            return { ...state, loader: true };

        case SHOW_NOTIFICATION:
            return { ...state, notification: { ...payload } };

        case ACTIVE_PROJECT:
            return { ...state, activeProject: payload };

        case ACTIVE_PROJECT_TAB:
            return { ...state, activeProjectTab: payload };

        case SWITCHED_TO_OPERATIONS:
            return { ...state, operations: payload };

        case PROJECT_DOCS:
            return { ...state, projectDocs: payload };

        case PROJECT_SETUP:
            return { ...state, createNewProject: payload };

        case MAP_VIEW:
            return { ...state, mapView: payload ? payload : !state.mapView };
        case RESET_STATE:
            return initialState;

        case TITLE_STATE:
            return { ...state, titleValue: payload };

        default:
            return state;
    }
};
