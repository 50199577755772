export function initTasks() {
    const currentDate = new Date();
    const tasks = [
        {
            start: new Date('2021-09-01'),
            end: new Date('2022-01-25'),
            name: 'SWGR-MVA',
            id: 'SWGR-MVA',
            progress: 90,
            type: 'project',
            hideChildren: false,
            styles: {
                progressColor: '#f29e4c',
            },
            // styles: {
            //   backgroundColor: '#fcfcfc',
            // }
        },
        {
            start: new Date('2021-09-01'),
            end: new Date('2021-10-03'),
            name: 'Order Date',
            id: 'Task 0',
            progress: 100,
            type: 'task',
            project: 'SWGR-MVA',
        },
        {
            start: new Date('2021-09-01'),
            end: new Date('2021-10-10'),
            name: 'Order Date Actual (+7 days)',
            id: 'Task 00',
            progress: 100,
            // dependencies: ["Task 0"],
            type: 'task',
            project: 'SWGR-MVA',
            styles: {
                progressColor: '#f29e4c',
            },
        },
        {
            start: new Date('2021-10-03'),
            end: new Date('2021-11-09'),
            name: 'Approval Drawings',
            id: 'Task 1',
            progress: 100,
            dependencies: ['Task 0'],
            type: 'task',
            project: 'SWGR-MVA',
        },

        {
            start: new Date('2021-10-10'),
            end: new Date('2021-11-21'),
            name: 'Approval Drawings Actual (+12 days)',
            id: 'Task 11',
            progress: 100,
            dependencies: ['Task 00'],
            type: 'task',
            project: 'SWGR-MVA',
            styles: {
                progressColor: '#f29e4c',
            },
        },
        {
            start: new Date('2021-11-09'),
            end: new Date('2021-12-1'),
            name: 'Release For Manufacture',
            id: 'Task 2',
            progress: 100,
            dependencies: ['Task 1'],
            type: 'task',
            project: 'SWGR-MVA',
        },
        {
            start: new Date('2021-11-21'),
            end: new Date('2021-12-13'),
            name: 'Release For Manufacture Actual (+12 days)',
            id: 'Task 22',
            progress: 100,
            dependencies: ['Task 11'],
            type: 'task',
            project: 'SWGR-MVA',
            styles: {
                progressColor: '#f29e4c',
            },
        },
        {
            start: new Date('2021-12-1'),
            end: new Date('2021-12-27'),
            name: 'Test Date',
            id: 'Task 3',
            progress: 100,
            dependencies: ['Task 2'],
            type: 'task',
            project: 'SWGR-MVA',
        },
        {
            start: new Date('2021-12-13'),
            end: new Date('2022-01-8'),
            name: 'Test Date Actual (+13 days)',
            id: 'Task 33',
            progress: 90,
            dependencies: ['Task 22'],
            type: 'task',
            project: 'SWGR-MVA',
            styles: {
                progressColor: '#f29e4c',
            },
        },
        {
            start: new Date('2021-12-27'),
            end: new Date('2022-01-2'),
            name: 'Ship Date',
            id: 'Task 4',
            type: 'task',
            progress: 100,
            dependencies: ['Task 3'],
            project: 'SWGR-MVA',
        },
        {
            start: new Date('2022-01-8'),
            end: new Date('2022-01-17'),
            name: 'Ship Date Predicted (+15 days)',
            id: 'Task 44',
            type: 'task',
            progress: 0,
            dependencies: ['Task 33'],
            project: 'SWGR-MVA',
            styles: {
                progressColor: '#f29e4c',
            },
        },
        {
            start: new Date('2022-01-2'),
            end: new Date('2022-01-10'),
            name: 'Delivery Date',
            id: 'Task 5',
            type: 'task',
            progress: 100,
            dependencies: ['Task 4'],
            project: 'SWGR-MVA',
        },
        {
            start: new Date('2022-01-17'),
            end: new Date('2022-01-25'),
            name: 'Delivery Date Predicted (+15 days)',
            id: 'Task 55',
            type: 'task',
            progress: 0,
            dependencies: ['Task 44'],
            project: 'SWGR-MVA',
            styles: {
                progressColor: '#f29e4c',
            },
        },
        {
            start: new Date('2022-01-10'),
            end: new Date('2022-01-15'),
            name: 'Milestone',
            id: 'Task 6',
            progress: currentDate.getMonth(),
            type: 'milestone',
            dependencies: ['Task 5'],
            project: 'SWGR-MVA',
        },
        {
            start: new Date('2022-01-25'),
            end: new Date('2022-01-30'),
            name: 'Milestone Predicted (+15 days)',
            id: 'Task 66',
            progress: currentDate.getMonth(),
            type: 'milestone',
            dependencies: ['Task 55'],
            project: 'SWGR-MVA',
        },
    ];
    return tasks;
}

export function getStartEndDateForProject(tasks, projectId) {
    const projectTasks = tasks.filter((t) => t.project === projectId);
    let start = projectTasks[0].start;
    let end = projectTasks[0].end;

    for (let i = 0; i < projectTasks.length; i++) {
        const task = projectTasks[i];
        if (start.getTime() > task.start.getTime()) {
            start = task.start;
        }
        if (end.getTime() < task.end.getTime()) {
            end = task.end;
        }
    }
    return [start, end];
}
