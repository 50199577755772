import { useEffect, useState } from 'react';
import { Card, Collapse, Input } from 'antd';
import { fetchVideoFiles, uploadVideoFile } from '../../../../services/video-player';
import MultiCarousel from '../../../../components/carousel/multi-carousel';

const { Panel } = Collapse;

const VideoPunchList = ({ pkgId, projectId }) => {
    const [videoData, setVideoData] = useState([]);
    const [uploadLoader, setUploadLoader] = useState(false);

    const getVideoFiles = async () => {
        try {
            setUploadLoader(true);
            const response = await fetchVideoFiles(pkgId);
            setVideoData(response.data.data);
            setUploadLoader(false);
        } catch (error) {
            setUploadLoader(false);
            throw error;
        }
    };

    const uploadHandler = async (file, pkgId) => {
        try {
            setUploadLoader(true);
            const formData = new FormData();
            formData.append('video_file', file[0]);
            formData.append('package_id', pkgId);
            formData.append('project_id', projectId);
            await uploadVideoFile(formData);
            setUploadLoader(false);
            getVideoFiles();
        } catch (error) {
            setUploadLoader(false);
            throw error;
        }
    };

    useEffect(() => {
        getVideoFiles();
    }, [pkgId]);

    return (
        <Collapse bordered={false} className="tagsBody mt-20" defaultActiveKey={['1']}>
            <Panel className="tagsBodyPanel" header={<b>List of Videos</b>} key="1">
                <Card className="card-body">
                    <div className="upload-btn-container">
                        <Input
                            className="upload-btn"
                            type="file"
                            accept="video/mp4,video/x-m4v,video/*"
                            onChange={(e) => uploadHandler(e.target.files, pkgId)}
                        />
                    </div>
                    <div className="video-container">
                        <MultiCarousel uploadLoader={uploadLoader} videoData={videoData} />
                    </div>
                </Card>
            </Panel>
        </Collapse>
    );
};

export default VideoPunchList;
