import { Avatar, Badge, Calendar, Col, Image, Modal, Row, Table, Tooltip, Typography } from 'antd';
import moment, { updateLocale } from 'moment';
import { useEffect, useState } from 'react';
import { fetchMonthlyCalendarData, fetchReportForDay, fetchRoundDetails } from '../../services';
import './index.scss';

const { Text, Paragraph } = Typography;
var today = new Date().setUTCHours(23, 59, 59, 999).valueOf();

const columns = [
    {
        title: 'Checkpoints',
        dataIndex: 'tag_name',
    },
    {
        title: 'Operating Range',
        dataIndex: 'tag_description',
    },
    {
        title: 'Issue Reported',
        dataIndex: 'status_type',
    },
    {
        title: 'Readings',
        dataIndex: 'reading_key',
        align: 'center',
    },
    {
        title: 'Active',
        dataIndex: 'tag_active_status',
        align: 'center',
    },
    {
        title: '# Of Documents',
        dataIndex: 'docs_count',
        align: 'center',
    },
    {
        title: '# Of Comments',
        dataIndex: 'comments_count',
        align: 'center',
    },
    {
        title: 'Comments',
        dataIndex: 'comment',
        align: 'center',
    },
];

export const MyFullCalendar = ({ projectId }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedTime, setSelectedTime] = useState('');
    const [shiftInsights, setShiftInsights] = useState([]);
    const [colorData, setColorData] = useState([]);
    const [currentMonth, setCurrentMonth] = useState('');
    const [currentYear, setCurrentYear] = useState('');
    const [isMount, setIsMount] = useState(true);
    const [isRoundDetailModalOpen, setIsRoundDetailModalOpen] = useState(false);
    const [roundDetails, setRoundDetails] = useState([]);

    const fetchShiftData = async (projectId, selectedDate) => {
        const response = await fetchReportForDay(projectId, selectedDate);
        if (response.success) {
            setShiftInsights(response.data);
        }
    };

    const fetchMonthlyData = async (projectId, startOfMonth, endOfMonth) => {
        const response = await fetchMonthlyCalendarData(projectId, startOfMonth, endOfMonth);
        setColorData(response.data);
    };

    useEffect(() => {
        let d = new Date();
        setCurrentMonth(d.getMonth() + 1);
        setCurrentYear(d.getFullYear());
    }, []);

    useEffect(() => {
        const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
        const endOfMonth = moment().endOf('month').format('YYYY-MM-DD');
        fetchMonthlyData(projectId, startOfMonth, endOfMonth);
    }, [projectId]);

    useEffect(() => {
        // to avoid un-neccessary re render on the first mount
        if (isMount) {
            setIsMount(false);
            return;
        }
        fetchShiftData(projectId, selectedDate);
    }, [selectedDate]);

    const openModal = (day) => {
        const month = day.format('M');
        const year = day.format('yyyy');

        if (month != currentMonth || year != currentYear) {
            setCurrentMonth(month);
            setCurrentYear(year);
            return;
        }

        setSelectedDate(day.format('YYYY-MM-DD'));
        setSelectedTime(day.format('hh:mm A'));

        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setSelectedTime('');
    };

    const onFullRender = (date) => {
        updateLocale('en', { weekdaysMin: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'] });
        let day_number = date.format('D');
        let day = date.format('yyyy-MM-DD');
        // let badgeType = '';
        for (var d of colorData) {
            if (d.shift_date === day) {
                //     if (d.issues_count > 0) {
                //         badgeType = 'error';
                //     } else if (d.docs_count > 0 || d.comments_count > 0) {
                //         badgeType = 'warning';
                //     } else {
                //         badgeType = 'success';
                //     }
                return (
                    // <div>
                    //     {badgeType === 'error' ? (
                    //         <div style={{ marginTop: '55px' }}>
                    //             {d.issues_count !== 0 && d.issues_count !== null ? (
                    //                 <Tooltip title={`Issues ${d.issues_count}`}>
                    //                     <p status={badgeType} className="calendar-cell-text">
                    //                         <Badge status={badgeType} /> Issues
                    //                         <strong> ({d.issues_count})</strong>
                    //                     </p>
                    //                 </Tooltip>
                    //             ) : (
                    //                 ''
                    //             )}
                    //         </div>
                    //     ) : badgeType === 'warning' ? (
                    //         <div className="calendar-cell">
                    //             {d.docs_count !== 0 && d.docs_count !== null ? (
                    //                 <Tooltip title={`Documents ${d.docs_count} `}>
                    //                     <p status={badgeType} className="calendar-cell-text">
                    //                         <Badge status={badgeType} /> Documents
                    //                         <strong> ({d.docs_count})</strong>
                    //                     </p>
                    //                 </Tooltip>
                    //             ) : (
                    //                 ''
                    //             )}

                    //             {d.comments_count !== 0 && d.comments_count !== null ? (
                    //                 <Tooltip title={`Comments ${d.comments_count}`}>
                    //                     <p className="calendar-cell-text">
                    //                         <Badge status={badgeType} /> Comments
                    //                         <strong> ({d.comments_count})</strong>
                    //                     </p>
                    //                 </Tooltip>
                    //             ) : (
                    //                 ''
                    //             )}
                    //         </div>
                    //     ) : (
                    //         <></>
                    //     )}
                    // </div>
                    <div>
                        <div className="calendar-cell">
                            <Tooltip
                                title={`Documents ${d.docs_count}, Issues ${d.issues_count}, Comments ${d.comments_count}`}
                            >
                                <p className="calendar-cell-text">
                                    <span>
                                        <Badge status={'warning'} /> Documents ({d.docs_count})
                                    </span>
                                    <br />
                                    <span>
                                        <Badge status={'error'} /> Issues ({d.issues_count})
                                    </span>
                                    <br />
                                    <span>
                                        <Badge status={'success'} /> Comments ({d.comments_count})
                                    </span>
                                </p>
                            </Tooltip>
                        </div>
                    </div>
                );
            }
        }
    };

    const datesDisabled = (d) => {
        return d.endOf('d').valueOf() > today ? true : false;
    };

    const getMonthString = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];
    const getName = (name) => {
        const fullName = name.split(' ');
        const initials = fullName?.shift()?.charAt(0) + fullName?.pop()?.charAt(0);
        return initials?.toUpperCase();
    };

    const onPanelChange = async (value, mode) => {
        const startOfMonth = moment(value).startOf('month').format('YYYY-MM-DD');
        const endOfMonth = moment(value).endOf('month').format('YYYY-MM-DD');
        await fetchMonthlyData(projectId, startOfMonth, endOfMonth);
    };

    const showModal = async (record) => {
        try {
            const response = await fetchRoundDetails(projectId, record.round);
            const data = response.data.map(
                ({
                    id,
                    tag,
                    status_type,
                    reading_key,
                    tag_active_status,
                    comments_count,
                    docs_count,
                    tagroundcommentv2_set,
                }) => ({
                    id,
                    tag_name: tag.name,
                    tag_description: tag.description,
                    reading_key,
                    tag_active_status,
                    status_type,
                    comments_count,
                    docs_count,
                    comment: tagroundcommentv2_set?.text,
                }),
            );
            setIsModalVisible(false);
            setIsRoundDetailModalOpen(true);
            setRoundDetails(data);
        } catch (ex) {
            console.log(ex);
        }
    };

    return (
        <div className="date-container">
            <h4
                style={{
                    borderBottom: '1px solid #0098EF',
                    paddingTop: '8px',
                    color: '#555',
                    fontSize: '20px',
                }}
            >
                Monthly Issues
            </h4>

            <Row
                align="top"
                justify="center"
                style={{ marginBottom: '60px', marginTop: '40px', backgroundColor: 'white' }}
            >
                <Text style={{ fontSize: '18px', color: '#555' }}>
                    {getMonthString[currentMonth - 1]} {currentYear}
                </Text>
            </Row>

            <Calendar
                onSelect={openModal}
                dateCellRender={onFullRender}
                // disabledDate={datesDisabled}
                onPanelChange={onPanelChange}
            />
            <Modal
                centered={true}
                title="Detailed Information"
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={null}
                width="800px"
            >
                <Row justify="space-evenly" className="date-container">
                    <Col span={12} style={{ padding: '8px 8px' }}>
                        Date: {moment(selectedDate).format('DD-MMM-YYYY')}
                    </Col>
                    <Col span={12} style={{ padding: '8px 0' }}>
                        {/* Time: {selectedTime} */}
                    </Col>
                </Row>
                {shiftInsights.length ? (
                    shiftInsights.map((record, index) => (
                        <Col key={index} onClick={() => showModal(record)}>
                            <Row style={{ marginTop: '16px' }}>
                                <Col span={8}>
                                    <Text strong>{record.shift === 0 ? 'Day' : 'Night'}</Text>
                                </Col>
                                <Col span={16}>
                                    <Row type={'flex'} align={'middle'} justify={'space-between'}>
                                        <Avatar
                                            src={
                                                record.user.image ? (
                                                    <Image src={record.user.image} alt="operator" />
                                                ) : null
                                            }
                                            style={{ backgroundColor: '#16AAFF', color: '#fff' }}
                                            size={36}
                                        >
                                            {getName(record.user.operator_name)}
                                        </Avatar>
                                        {record.issues_count ? (
                                            <Badge
                                                count={'Not Good'}
                                                style={{
                                                    backgroundColor: '#ff4d4f',
                                                    width: '70px',
                                                }}
                                            />
                                        ) : (
                                            <Badge
                                                count={'All good'}
                                                style={{
                                                    backgroundColor: '#52c41a',
                                                    width: '70px',
                                                }}
                                            />
                                        )}
                                    </Row>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '8px', borderBottom: '2px solid #16AAFF' }}>
                                <Col span={8}></Col>
                                <Col span={16}>
                                    <Row type={'flex'} align={'middle'} justify={'space-between'}>
                                        <Paragraph>
                                            <Text>Comments: </Text> {record.comments_count}
                                        </Paragraph>
                                        <Paragraph>
                                            <Text>Docs: </Text> {record.docs_count}
                                        </Paragraph>
                                        {record.issues_count ? (
                                            <Paragraph>
                                                <Text>Reported Issues: </Text> {record.issues_count}
                                            </Paragraph>
                                        ) : (
                                            <></>
                                        )}
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    ))
                ) : (
                    <Row style={{ marginTop: '16px', marginLeft: '5px' }}>
                        <Text italic strong>
                            No Data found...
                        </Text>
                    </Row>
                )}
            </Modal>

            <Modal
                visible={isRoundDetailModalOpen}
                centered={true}
                title={'Round Details'}
                onCancel={() => {
                    setIsRoundDetailModalOpen(false);
                }}
                footer={null}
                width={800}
                className="taglist-popup"
            >
                <Table
                    size="small"
                    columns={columns}
                    pagination={roundDetails.length > 10}
                    className="popup-taglist-table"
                    dataSource={roundDetails.map((tag) => ({
                        ...tag,
                        key: tag?.id || tag?.name,
                    }))}
                />
            </Modal>
        </div>
    );
};
