import React from 'react';
import flash from '../../../../assets/images/arc_flash.jpg';
import { Card } from 'antd';

function Arc() {
    return (
        <Card title="Arc Flash Zone - Auto Detection">
            <img src={flash} alt="arc_flash" />
        </Card>
    );
}

export default Arc;
