import { SearchOutlined } from '@ant-design/icons';
import {
    Avatar,
    Button,
    Col,
    Form,
    Input,
    notification,
    Row,
    Space,
    Switch,
    Table,
    Tooltip,
} from 'antd';
import _ from 'lodash';
import moment from 'moment';
import { memo, useEffect, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { fetchTagsByProjectV2 } from '../../services';
import { getMasterTags, getTagsBySline, updateMasterTag } from '../../store/tags';
import { DATES_KEYS, INPUT_DATE_FORMAT, PROJECTS, SERVER_DATE_FORMAT } from '../../utils';
import * as Permissions from '../../utils/permissions';
import ApprovalDatesModal from './approvalDatesModal';
import ShipDateModal from './shipDateModal';
import TagDetailModal from './tagDetail';
import UpdateStatusModal from './updateStatusModal';

const MemorizedTagListView = ({ activeProjectId, masterTagView }) => {
    const dispatch = useDispatch();
    const searchInput = useRef();
    const history = useHistory();
    const { projectId } = useParams();
    const { operations } = useSelector(({ common }) => common);
    const { permissions } = useSelector(({ permission }) => permission);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchColumn] = useState('');
    const [ordering, setOrdering] = useState(null);
    const [detailModal, setDetailModal] = useState(false);
    const [approvalDrawingModal, setApprovalDrawingModal] = useState(false);
    const [activeTag, setActiveTag] = useState(null);
    const [shipDateModal, setShipDateModal] = useState(false);
    const [updateStatusModal, setUpdateStatusModal] = useState(false);
    const [checkPoints, setCheckPoints] = useState({
        data: [],
        fetching: false,
        total: 0,
        current: 1,
        pageSize: 10,
    });
    const [onChangeCalled, setOnChangeCalled] = useState(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        getTags(1, checkPoints.pageSize, searchedColumn, ordering);
    }, [activeProjectId]);

    const updateTagCallback = () => {
        notification.success({
            message: 'Successfully updated.',
            placement: 'topRight',
        });
        if (masterTagView) {
            dispatch(getMasterTags(activeProjectId));
        } else {
            dispatch(getTagsBySline(projectId, activeProjectId));
        }
    };

    const onTagUpdate = async (data, cb) => {
        Object.keys(data).forEach((key) => {
            if (DATES_KEYS.includes(key) && !_.isEmpty(data[key])) {
                data[key] = moment(moment(data[key], INPUT_DATE_FORMAT)).format(SERVER_DATE_FORMAT);
            }
        });
        dispatch(
            updateMasterTag(data, () => {
                updateTagCallback();
                cb && cb();
            }),
        );
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8, width: 200 }}>
                <Row gutter={5}>
                    <Col span={24}>
                        <Input
                            ref={searchInput}
                            placeholder={`Search ${dataIndex}`}
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                if (!onChangeCalled) {
                                    setOnChangeCalled(true);
                                }
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                            }}
                            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            style={{ marginBottom: 8, display: 'block' }}
                        />
                    </Col>
                    <Col span={12}>
                        <Button
                            onClick={() => handleReset(clearFilters, confirm)}
                            size="small"
                            style={{ width: '100%' }}
                        >
                            Reset
                        </Button>
                    </Col>
                    <Col span={12}>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: '100%' }}
                        >
                            Search
                        </Button>
                    </Col>
                </Row>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : '#838383',
                    fontSize: '18px',
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchColumn(dataIndex);
        getTags(1, checkPoints.pageSize, selectedKeys[0], dataIndex, ordering);
    };

    const handleReset = (clearFilters, confirm) => {
        if (!onChangeCalled) {
            setOnChangeCalled(true);
        }
        clearFilters();
        confirm();
        setSearchText('');
        setSearchColumn('');
        getTags(1, checkPoints.pageSize, '', '', ordering);
    };

    const progressViewColumns = [
        {
            title: 'Serial #',
            dataIndex: 'serial_number',
            width: 100,
            responsive: ['xl'],
            sorter: () => {},
        },
        {
            title: 'Checkpoints',
            dataIndex: 'name',
            width: 150,
            ...getColumnSearchProps('name'),
            render: (text, record) => (
                <Space size="middle">
                    <Avatar
                        src={
                            record.tag_image_url ||
                            'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSlBRzUn5IDV4IYZHfu5NUJ7OaY9WEMTTWOQQ&usqp=CAU'
                        }
                    />
                    <Tooltip placement="topLeft" title={text}>
                        <p className="mb-0 tagName-master-td">{text}</p>
                    </Tooltip>
                </Space>
            ),
        },
        {
            title: 'Operating Range',
            dataIndex: 'description',
            responsive: ['xl'],
            width: 150,

            render: (text, record) => {
                return {
                    props: {
                        className: 'description-td',
                    },
                    children: (
                        <Tooltip placement="topLeft" title={text}>
                            {text}
                        </Tooltip>
                    ),
                };
            },
        },
        {
            title: 'Reading Reqd',
            dataIndex: 'is_reading_required',
            width: 120,
            align: 'center',
            render: (status, record) => {
                return {
                    props: {
                        className: 'approval-design-td',
                    },
                    children: (
                        <Switch
                            checked={status}
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            onChange={(checked, event) => {
                                event.stopPropagation();
                                if (Permissions.can_edit_master_tags(permissions)) {
                                    handleApprovalDrawing(checked, record);
                                }
                            }}
                            disabled
                        />
                    ),
                };
            },
            // filters: [
            //     { text: 'Yes', value: true },
            //     { text: 'No', value: false },
            // ],
            // onFilter: (value, record) => record.approval_dwg === value,
        },
        {
            title: 'Last Update Reqd',
            dataIndex: 'is_last_updated_required',
            width: 120,
            align: 'center',
            render: (status, record) => {
                return {
                    props: {
                        className: 'approval-design-td',
                    },
                    children: (
                        <Switch
                            checked={status}
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            onChange={(checked, event) => {
                                event.stopPropagation();
                                if (Permissions.can_edit_master_tags(permissions)) {
                                    handleApprovalDrawing(checked, record);
                                }
                            }}
                            disabled
                        />
                    ),
                };
            },
            // filters: [
            //     { text: 'Yes', value: true },
            //     { text: 'No', value: false },
            // ],
            // onFilter: (value, record) => record.approval_dwg === value,
        },
        {
            title: '# comments',
            dataIndex: 'comments_count',
            align: 'center',
            width: 100,
            sorter: () => {},
            render: (text) => <p>{text || '0'}</p>,
        },
        {
            title: '# Documents',
            dataIndex: 'docs_count',
            align: 'center',
            width: 100,
            sorter: () => {},
            render: (text) => <p>{text || '0'}</p>,
        },
        {
            title: '# Issues',
            dataIndex: 'issues_count',
            width: 100,
            align: 'center',
            render: (text) => <p>{text || '0'}</p>,
            sorter: () => {},
        },
    ];

    const handleApprovalDrawing = (checked, record) => {
        onTagUpdate(
            { id: record.id, is_reading_required: checked, projectId: record.project },
            () => {
                if (checked) {
                    setApprovalDrawingModal(true);
                    setActiveTag({ ...record });
                }
            },
        );
    };

    const detailModalHandler = () => {
        setDetailModal(!detailModal);
    };

    const dateSubmissionHandler = (values) => {
        onTagUpdate({ id: activeTag.id, ...values, projectId: activeTag?.project });
        setApprovalDrawingModal(false);
        setActiveTag(null);
    };

    const statusUpdateHandler = (values) => {
        onTagUpdate({ id: activeTag.id, ...values, projectId: activeTag?.project });
        setUpdateStatusModal(false);
        setActiveTag(null);
    };

    const handleShipDateValue = (values) => {
        onTagUpdate({ id: activeTag.id, ...values, projectId: activeTag?.project });
        setShipDateModal(false);
        setActiveTag(null);
    };

    const getTags = async (current, pageSize, searchText, searchedColumn, ordering = null) => {
        setCheckPoints({ ...checkPoints, fetching: true });
        const response = await fetchTagsByProjectV2(
            activeProjectId,
            current,
            pageSize,
            searchText,
            searchedColumn,
            ordering,
        );
        setCheckPoints({
            ...checkPoints,
            fetching: false,
            data: response.data,
            total: response.meta.total_count,
            current,
            pageSize,
        });
    };

    const onChangePageAndSorting = async (pagination, _, sorter) => {
        const { column, order } = sorter;
        const { current, pageSize } = pagination;
        let newOrdering = null;
        if (column) {
            newOrdering = order === 'ascend' ? column.dataIndex : '-' + column.dataIndex;
        }
        if (
            current !== checkPoints.currentPage ||
            newOrdering !== ordering ||
            pageSize !== checkPoints.pageSize
        ) {
            setOrdering(newOrdering);
            await getTags(current, pageSize, searchText, searchedColumn, newOrdering);
        }
    };

    const onTagClickHandler = (record) => {
        let url = `${PROJECTS}/${record?.project}/package/${record?.packageroom[0]}`;
        if (operations) {
            url = `${PROJECTS}/${record?.project}/operations/package/${record?.packageroom[0]}`;
        }
        history.push(url);
    };

    return (
        <>
            <Form component={false}>
                <Table
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: (event) => {
                                // onTagClickHandler(record);
                            },
                        };
                    }}
                    size="small"
                    rowKey={'id'}
                    loading={checkPoints.fetching}
                    columns={progressViewColumns}
                    dataSource={checkPoints.data}
                    className="taglist-table"
                    onChange={onChangePageAndSorting}
                    pagination={{
                        total: checkPoints.total,
                        current: checkPoints.currentPage,
                        pageSize: checkPoints.pageSize,
                        showSizeChanger: true,
                    }}
                />
            </Form>

            <TagDetailModal
                title={activeTag?.name || 'N/A'}
                visible={detailModal}
                onSubmit={detailModalHandler}
                detail={activeTag}
                onCancel={() => {
                    setActiveTag(null);
                    setDetailModal(false);
                }}
            />

            <ApprovalDatesModal
                onCancel={() => setApprovalDrawingModal(false)}
                visible={approvalDrawingModal}
                onSubmit={dateSubmissionHandler}
                initialValues={{ ...activeTag }}
            />

            <ShipDateModal
                onCancel={() => {
                    setActiveTag(null);
                    setShipDateModal(false);
                }}
                visible={shipDateModal}
                initialValues={{ ...activeTag }}
                onSubmit={handleShipDateValue}
            />

            <UpdateStatusModal
                onCancel={() => setUpdateStatusModal(false)}
                visible={updateStatusModal}
                onSubmit={statusUpdateHandler}
                initialValues={{ ...activeTag }}
            />
        </>
    );
};

export const TagListView = memo(MemorizedTagListView);
