import { CaretRightOutlined, FileImageOutlined } from '@ant-design/icons';
import { Avatar, Card, Col, Collapse, Modal, Row, Table, Tooltip } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import '../../../assets/styles/components/task-inbox.scss';
import {
    fetchInsights,
    fetchInsightsDatefromRound,
    fetchInsightsDateWise,
    fetchRoundDetails,
} from '../../../services';
import { setActiveProjectTab } from '../../../store/common';
import { makeAvatar } from '../../../utils';

const Insights = ({ projectId }) => {
    const dispatch = useDispatch();
    const { allPackages } = useSelector(({ packages }) => packages);
    const history = useHistory();
    const [insights, setInsights] = useState([]);
    const [insightsLoading, setInsightsLoading] = useState(false);
    const [lastWeekSummary, setLastWeekSummary] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState('Tags Detail');
    const [roundDetails, setRoundDetails] = useState([]);

    const cardLoader = () => {
        return (
            <>
                <Col span={6}>
                    <Card
                        className="mb-30"
                        style={{ height: 134 }}
                        loading={insightsLoading}
                    ></Card>
                </Col>
                <Col span={6}>
                    <Card
                        className="mb-30"
                        style={{ height: 134 }}
                        loading={insightsLoading}
                    ></Card>
                </Col>
                <Col span={6}>
                    <Card
                        className="mb-30"
                        style={{ height: 134 }}
                        loading={insightsLoading}
                    ></Card>
                </Col>
                <Col span={6}>
                    <Card
                        className="mb-30"
                        style={{ height: 134 }}
                        loading={insightsLoading}
                    ></Card>
                </Col>
            </>
        );
    };

    const docLink = (arr) => {
        arr.forEach((element, index) => {
            arr[index] = (
                <a target="_blank" href={element} title="Open in a new tab">
                    <FileImageOutlined /> image_{index + 1}
                </a>
            );
        });
        return arr;
    };
    const columns = [
        {
            title: 'Checkpoints',
            dataIndex: 'tag_name',
        },
        {
            title: 'Operating Range',
            dataIndex: 'tag_description',
        },
        {
            title: 'Issue Reported',
            dataIndex: 'status_type',
        },
        {
            title: 'Readings',
            dataIndex: 'reading_key',
            align: 'center',
        },
        {
            title: 'Active',
            dataIndex: 'tag_active_status',
            align: 'center',
        },
        {
            title: '# Of Documents',
            dataIndex: 'docs_count',
            align: 'center',
        },
        {
            title: '# Of Comments',
            dataIndex: 'comments_count',
            align: 'center',
        },
        {
            title: 'Comments',
            dataIndex: 'comment',
            align: 'center',
        },
        {
            title: 'Critical Issue',
            dataIndex: 'critical_issue',
            align: 'center',
        },
        {
            title: 'Known Issue',
            dataIndex: 'known_issue',
            align: 'center',
        },
    ];

    const mapInsightsData = (fetchLastWeekRounds, startDate, endDate) => {
        const grouped = _.groupBy(fetchLastWeekRounds.data, (lwr) => lwr.shift_date);
        const data = [];
        let date = endDate;
        for (let i = 0; i <= 6; i++) {
            if (grouped[date]?.length) {
                if (grouped[date].length === 2) {
                    data.push(grouped[date][0]);
                    data.push(grouped[date][1]);
                }

                if (grouped[date].length === 1) {
                    data.push({
                        ...(grouped[date][0].shift === 1
                            ? grouped[date][0]
                            : { id: null, shift: 1, shift_date: date }),
                    });
                    data.push({
                        ...(grouped[date][0].shift === 0
                            ? grouped[date][0]
                            : { id: null, shift: 0, shift_date: date }),
                    });
                }
            } else {
                data.push({ id: null, shift: 1, shift_date: date });
                data.push({ id: null, shift: 0, shift_date: date });
            }

            date = moment(date).subtract(1, 'days').format('YYYY-MM-DD');
        }

        return data;
    };

    const getInsights = async () => {
        try {
            const responseFromRound = await fetchInsightsDatefromRound(projectId);
            const lastWeekDate = moment(responseFromRound.data?.shift_date)
                .subtract(6, 'days')

                .format('YYYY-MM-DD');
            setInsightsLoading(true);
            const response = await fetchInsights(projectId);
            const fetchLastWeekRounds = await fetchInsightsDateWise(
                projectId,
                responseFromRound.data?.shift_date,
                lastWeekDate,
            );

            const data = mapInsightsData(
                fetchLastWeekRounds,
                lastWeekDate,
                responseFromRound.data?.shift_date,
            );
            setLastWeekSummary([...data]);
            setInsights([...response.data]);
            setInsightsLoading(false);
        } catch (ex) {
            setInsightsLoading(false);
            console.log(ex);
        }
    };

    const showModal = async (lws) => {
        try {
            const response = await fetchRoundDetails(projectId, lws.id);
            const data = response.data.map(
                ({
                    id,
                    tag,
                    status_type,
                    reading_key,
                    tag_active_status,
                    comments_count,
                    docs_count,
                    tagroundcommentv2_set,
                    critical_issue,
                    known_issue,
                }) => ({
                    id,
                    tag_name: tag.name,
                    tag_description: tag.description,
                    reading_key,
                    tag_active_status,
                    status_type,
                    comments_count,
                    docs_count,
                    comment: tagroundcommentv2_set?.text,
                    critical_issue: critical_issue ? 'Yes' : 'No',
                    known_issue: known_issue ? 'Yes' : 'No',
                }),
            );
            setIsModalOpen(true);
            setModalTitle('Round Details');
            setRoundDetails(data);
        } catch (ex) {
            console.log(ex);
        }
    };

    useEffect(() => {
        getInsights();
    }, [projectId]);

    return (
        <div className="dashboard-container">
            <Row gutter={15} className="card-tab">
                <Col span={24}>
                    <h4 className="card-heading "> Assets Summary </h4>
                </Col>
                {!insightsLoading
                    ? insights.map((stat) => {
                          if (stat.key === 'total' || stat.key === 'package') {
                              return (
                                  <Col xs={20} sm={16} md={12} lg={8} xl={6} key={stat.key}>
                                      <div
                                          className={`page-content-container dashboard-card colorful-card  cursor-pointer`}
                                          style={{
                                              background: `linear-gradient(to right, rgb(226, 236, 246) 0%, rgb(255,255,255, 0.9) 100%)`,
                                          }}
                                          onClick={() => {
                                              if (stat.key === 'total') {
                                                  dispatch(setActiveProjectTab('4'));
                                              } else if (stat.key === 'package') {
                                                  dispatch(setActiveProjectTab('5'));
                                              }
                                          }}
                                      >
                                          <h4
                                              className="card-heading custom-card-heading"
                                              style={{
                                                  color: '#073956',
                                                  letterSpacing: '1px',
                                              }}
                                          >
                                              {stat.label}
                                          </h4>
                                          <h1
                                              className="dashboard-card-text"
                                              style={{
                                                  color: '#073956',
                                              }}
                                          >
                                              {stat.value}
                                          </h1>
                                      </div>
                                  </Col>
                              );
                          }
                      })
                    : cardLoader()}
            </Row>

            <Row gutter={15} className="card-tab">
                <Col span={24}>
                    <h4 className="card-heading"> Lifecycle Summary </h4>
                </Col>
                {!insightsLoading
                    ? insights.map((stat) => {
                          if (
                              stat.key === 'issue' ||
                              stat.key === 'pdoc' ||
                              stat.key === 'comment'
                          ) {
                              return (
                                  <Col xs={20} sm={16} md={12} lg={8} xl={8} key={stat.key}>
                                      <div
                                          className={`page-content-container dashboard-card colorful-card cursor-pointer`}
                                          style={{
                                              background: `linear-gradient(to right, rgb(206, 246, 250) 0%, rgb(255,255,255, 0.9) 100%)`,
                                          }}
                                          onClick={() => {
                                              if (
                                                  stat.key === 'issue' ||
                                                  stat.key === 'comment' ||
                                                  stat.key === 'pdoc'
                                              ) {
                                                  dispatch(setActiveProjectTab('4'));
                                                  //   if (allPackages.length) {
                                                  //       history.push(
                                                  //           `${PROJECTS}/${projectId}/operations${PACKAGE}/${allPackages[0].id}`,
                                                  //       );
                                                  //   }
                                              }
                                          }}
                                      >
                                          <h4
                                              className="card-heading custom-card-heading"
                                              style={{
                                                  color: '#073956',
                                                  letterSpacing: '1px',
                                              }}
                                          >
                                              {stat.label}
                                          </h4>
                                          <h1
                                              className="dashboard-card-text"
                                              style={{
                                                  color: '#073956',
                                              }}
                                          >
                                              {stat.value}
                                          </h1>
                                      </div>
                                  </Col>
                              );
                          }
                      })
                    : cardLoader()}
            </Row>

            <Row gutter={15} className="card-tab">
                <Col span={24}>
                    <Collapse
                        expandIcon={({ isActive }) => (
                            <CaretRightOutlined rotate={isActive ? 90 : 0} />
                        )}
                        expandIconPosition="end"
                        className="site-custom"
                    >
                        <Collapse.Panel
                            header={`Last Week's Summary`}
                            key="1"
                            className="site-custom card-heading"
                        >
                            <Row gutter={15}>
                                {lastWeekSummary.map((lws, index) => {
                                    return lws.id != null ? (
                                        <Col xs={20} sm={16} md={12} lg={8} xl={6} key={lws.id}>
                                            <div
                                                className={`page-content-container dashboard-card colorful-card cursor-pointer `}
                                                style={{ background: '#DFF6FF' }}
                                                onClick={() => {
                                                    showModal(lws);
                                                }}
                                            >
                                                <div className="last-week-summary-card">
                                                    <div className="last-week-summary-heading">
                                                        <h4>
                                                            {moment(lws.shift_date)
                                                                .format('DD-MMM')
                                                                .toUpperCase()}
                                                            {'  '}
                                                            {lws.shift === 0 ? 'DAY' : 'NIGHT'}
                                                        </h4>
                                                    </div>
                                                </div>
                                                <div className="last-week-summary-card">
                                                    <div className="last-round-text">
                                                        <p>Operator</p>
                                                        <Tooltip
                                                            title={`${lws.user?.first_name} ${lws.user?.last_name}`}
                                                        >
                                                            {lws.user?.profile_url ? (
                                                                <Avatar
                                                                    size={24}
                                                                    src={lws.user?.profile_url}
                                                                />
                                                            ) : (
                                                                <Avatar
                                                                    size={24}
                                                                    src={makeAvatar(
                                                                        lws.user?.first_name[0],
                                                                        lws.user?.last_name[0],
                                                                    )}
                                                                    style={{
                                                                        marginRight: '-5px',
                                                                    }}
                                                                />
                                                            )}
                                                        </Tooltip>
                                                    </div>
                                                    <div className="last-round-text">
                                                        <p>Issues Reported</p>
                                                        <p>{lws.issues_count}</p>
                                                    </div>

                                                    <div className="last-round-text">
                                                        <p>Start time</p>
                                                        <p>
                                                            {lws.start_time
                                                                ? `${
                                                                      lws.start_time.split(':')[0]
                                                                  }:${lws.start_time.split(':')[1]}`
                                                                : '-'}
                                                        </p>
                                                    </div>
                                                    <div className="last-round-text">
                                                        <p>End time</p>
                                                        <p>
                                                            {lws.end_time
                                                                ? `${lws.end_time.split(':')[0]}:${
                                                                      lws.end_time.split(':')[1]
                                                                  }`
                                                                : '-'}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    ) : (
                                        <Col xs={20} sm={16} md={12} lg={8} xl={6}>
                                            <div
                                                className={`page-content-container dashboard-card colorful-card cursor-pointer `}
                                                style={{ background: '#DFF6FF' }}
                                            >
                                                <div className="last-week-summary-card">
                                                    <h4 className="last-week-summary-heading">
                                                        {moment(lws.shift_date)
                                                            .format('DD-MMM')
                                                            .toUpperCase()}
                                                        {'  '}
                                                        {lws.shift === 0 ? 'DAY' : 'NIGHT'} <br />
                                                        (ROUND NOT STARTED)
                                                    </h4>
                                                </div>
                                            </div>
                                        </Col>
                                    );
                                })}
                            </Row>
                        </Collapse.Panel>
                    </Collapse>
                </Col>
            </Row>

            <Modal
                visible={isModalOpen}
                title={modalTitle}
                onCancel={() => {
                    setIsModalOpen(false);
                }}
                footer={null}
                width={1000}
                className="taglist-popup"
            >
                <Table
                    size="small"
                    columns={columns}
                    pagination={roundDetails.length > 10}
                    className="popup-taglist-table"
                    dataSource={roundDetails.map((tag) => ({
                        ...tag,
                        key: tag?.id || tag?.name,
                    }))}
                />
            </Modal>
        </div>
    );
};

export default memo(Insights);
