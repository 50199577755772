import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import {
    Table,
    Button,
    Input,
    Tooltip,
    Row,
    Col,
    Collapse,
    notification,
    Space,
    Checkbox,
    Avatar,
    Popconfirm,
    Popover,
    Form,
    Select,
    Switch,
} from 'antd';
import {
    SearchOutlined,
    DeleteTwoTone,
    PlusOutlined,
    QuestionCircleOutlined,
    EditOutlined,
    RestOutlined,
} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import CreatePackageModal from '../listing/create-package';
import {
    deletePackage,
    createPackage,
    removeMemberFromPackage,
    addMemberInPackage,
    updateProject,
    fetchProjectDetail,
    fetchAllPackages,
    updatePackage,
    fetchTagsByProject,
    resetPackagesOrder,
} from '../../../services';
import {
    checkPackageManufactures,
    DATE_STATUSES,
    makeAvatar,
    PROJECT_API_ENUM,
} from '../../../utils';
import { useSelector } from 'react-redux';

const { Option } = Select;
const { Panel } = Collapse;

const Packages = ({
    projectId = null,
    goBack = () => {},
    onFinish = () => {},
    setLoader = () => {},
    modify,
}) => {
    const [form] = Form.useForm();
    const searchInput = useRef();
    const [createPackageVisible, setCreatePackageVisible] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchColumn] = useState('');
    const [activePackage, setActivePackage] = useState({});
    const [allInOnePackage, setAllInOnePackage] = useState(false);
    const [individualPackages, setIndividualPackages] = useState({
        meta: {},
        data: [],
    });
    const [roomPackages, setRoomPackages] = useState({
        meta: {},
        data: [],
        currentPage: 1,
        pageSize: 10,
    });
    const [ordering, setOrdering] = useState(null);

    const [tags, setTags] = useState([]);
    const [displayAddMembers, setDisplayAddMembers] = useState('');
    const [users, setUsers] = useState([]);

    useEffect(() => {
        getInitialData();
    }, []);

    const getInitialData = async () => {
        Promise.all([
            fetchRoomPackages(
                roomPackages.currentPage,
                roomPackages.pageSize,
                searchText,
                searchedColumn,
                ordering,
            ),
            fetchUsers(),
            fetchTags(),
        ]);
    };
    const fetchTags = async () => {
        try {
            setLoader(true);
            const response = await fetchAllPackages(projectId, true);
            setTags(response.data.map((data) => ({ ...data, id: data.tags[0]?.id })));
            setLoader(false);
        } catch (error) {}
    };

    const fetchRoomPackages = async (
        page = 1,
        pageSize = 10,
        searchText,
        searchedColumn,
        ordering = null,
    ) => {
        try {
            setLoader(true);
            const response = await fetchAllPackages(
                projectId,
                undefined,
                page,
                pageSize,
                searchText,
                searchedColumn,
                ordering,
            );
            setRoomPackages({
                data: [...response.data],
                meta: { ...response.meta },
                currentPage: page,
                pageSize: pageSize,
            });
            setLoader(false);
        } catch (error) {}
    };

    const fetchIndividualPackages = async (page = 1, searchText, searchedColumn) => {
        try {
            setLoader(true);
            const response = await fetchAllPackages(
                projectId,
                true,
                page,
                searchText,
                searchedColumn,
            );
            setIndividualPackages({
                data: [...response.data],
                meta: { ...response.meta },
            });
            setLoader(false);
        } catch (error) {}
    };

    const fetchUsers = async () => {
        const response = await fetchProjectDetail(PROJECT_API_ENUM.USERS, projectId);
        setUsers([...response.data]);
    };

    const showNotification = (message = '', type = 'success', placement = 'topRight') => {
        notification[type]({
            message,
            placement,
        });
    };

    const getColumnSearchProps = (dataIndex, type) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8, width: 200 }}>
                <Row gutter={5}>
                    <Col span={24}>
                        <Input
                            ref={searchInput}
                            placeholder={`Search ${dataIndex}`}
                            value={selectedKeys[0]}
                            onChange={(e) =>
                                setSelectedKeys(e.target.value ? [e.target.value] : [])
                            }
                            onPressEnter={() =>
                                handleSearch(selectedKeys, confirm, dataIndex, type)
                            }
                            style={{ marginBottom: 8, display: 'block' }}
                        />
                    </Col>
                    <Col span={12}>
                        <Button
                            onClick={() => handleReset(clearFilters, confirm)}
                            size="small"
                            style={{ width: '100%' }}
                        >
                            Reset
                        </Button>
                    </Col>
                    <Col span={12}>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex, type)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: '100%' }}
                        >
                            Search
                        </Button>
                    </Col>
                </Row>
            </div>
        ),

        filterIcon: (filtered) => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : '#838383', fontSize: '18px' }} />
        ),

        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',

        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current.select(), 100);
            }
        },

        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex, type) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchColumn(dataIndex);
        // if (type === 'individual') {
        //     fetchIndividualPackages(1, selectedKeys[0], dataIndex);
        // }

        if (type === 'roomPackages') {
            fetchRoomPackages(1, roomPackages.pageSize, selectedKeys[0], dataIndex, ordering);
        }
    };

    const handleReset = (clearFilters, confirm) => {
        clearFilters();
        confirm();
        setSearchText('');
        fetchRoomPackages(1, roomPackages.pageSize, '', '', ordering);
    };

    const onCreatePackage = async (data, cb) => {
        try {
            setLoader(true);
            setCreatePackageVisible(false);
            if (data.tags.length === 0) {
                showNotification('Tags are required package create failed', 'error');
            } else {
                let allTeamMembers = [];
                data.tags.forEach(({ team_members }) => {
                    allTeamMembers = [...allTeamMembers, ...team_members];
                });
                const team_members = _.uniqBy(allTeamMembers, 'id');
                // data.team_members = team_members.map(({ id }) => id);
                delete data.team_member;
                data.tags = data.tags.map(({ id }) => id);

                await createPackage(projectId, data);
                showNotification('Package successfully created');
            }
        } catch (error) {
        } finally {
            cb && cb();
            await getInitialData();
        }
    };

    const onUpdatePackage = async (data, id) => {
        try {
            setLoader(true);
            setCreatePackageVisible(false);
            delete data.team_member;
            await updatePackage(projectId, id, data);
            showNotification('Package successfully modified');
        } catch (ex) {
            showNotification('Package update failed', 'error');
        } finally {
            setActivePackage({});
            await getInitialData();
            setLoader(false);
        }
    };

    const onDeletePackage = async (pkg) => {
        try {
            setLoader(true);
            setCreatePackageVisible(false);
            await deletePackage(projectId, pkg.id);
            showNotification('Package successfully deleted');
        } catch (ex) {
            console.log(ex);
        } finally {
            setActivePackage({});
            // await Promise.all([fetchRoomPackages(), fetchIndividualPackages()]);
            await getInitialData();
        }
    };

    const onCancel = () => {
        setActivePackage({});
        setCreatePackageVisible(false);
    };

    const onAddMemberInPackage = async (values) => {
        try {
            const { pkg, member_id } = values;
            const user = users.find((user) => user.id === member_id);
            const isUserExist = pkg.team_members.some((team_member) => team_member.id === user.id);
            if (isUserExist) {
                form.setFields([{ name: 'member_id', errors: ['User already exist'] }]);
                return;
            }
            setLoader(true);
            const response = await addMemberInPackage(projectId, pkg.id, member_id);
            showNotification(response.message);
            setDisplayAddMembers('');
            form.resetFields();
            // await Promise.all([fetchRoomPackages(), fetchIndividualPackages()]);
            await fetchRoomPackages(
                roomPackages.currentPage,
                roomPackages.pageSize,
                searchText,
                searchedColumn,
                ordering,
            );
        } catch (error) {
            console.log(error);
        }
    };

    const resetOrder = async () => {
        await resetPackagesOrder(projectId);
        await getInitialData();
    };

    const addMembersContent = (pkg) => {
        return (
            <Form
                form={form}
                style={{ width: 200 }}
                layout="vertical"
                onFinish={(values) => onAddMemberInPackage({ ...values, pkg: pkg })}
            >
                <Form.Item name="member_id" rules={[{ required: true, message: 'Please select' }]}>
                    <Select
                        showSearch
                        placeholder="Please select"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().includes(input.toLowerCase())
                        }
                        allowClear
                        style={{ width: '100%' }}
                    >
                        {users.map((user) => (
                            <Option key={user.id} value={user.id}>
                                {`${user.first_name} ${user.last_name}`}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <div className="text-right mt-15">
                    <Space>
                        <Button type="default" onClick={() => setDisplayAddMembers('')}>
                            Cancel
                        </Button>
                        <Button type="primary" htmlType="submit">
                            Add
                        </Button>
                    </Space>
                </div>
            </Form>
        );
    };

    const removeMember = async (memberId, pkgId) => {
        try {
            setLoader(true);
            const response = await removeMemberFromPackage(projectId, pkgId, memberId);
            showNotification(response.message);
            // await Promise.all([fetchRoomPackages(), fetchIndividualPackages()]);
            await fetchRoomPackages(
                roomPackages.currentPage,
                roomPackages.pageSize,
                searchText,
                searchedColumn,
                ordering,
            );
            setDisplayAddMembers('');
        } catch (error) {
            setLoader(false);
            if (error?.response?.data?.message)
                showNotification(error.response.data.message, 'error');
        }
    };

    const groupsPackageColumns = [
        {
            title: '',
            dataIndex: 'warning',
            width: 0,
            render: (_, record) => checkPackageManufactures(record),
        },
        {
            title: 'Serial #',
            dataIndex: 'serial_number',
            width: 150,
            sorter: (a, b) => a.serial_number - b.serial_number,
        },
        {
            title: 'Room Name',
            dataIndex: 'name',
            key: 'name',
            width: 250,
            ...getColumnSearchProps('name', 'roomPackages'),
        },
        {
            title: 'Operating Range',
            key: 'description',
            dataIndex: 'description',
            render: (text, record) => {
                return {
                    props: {
                        className: 'description-td',
                    },
                    children: (
                        <Tooltip placement="topLeft" title={text}>
                            {text}
                        </Tooltip>
                    ),
                };
            },
        },
        {
            title: 'Not in Room',
            dataIndex: 'is_individual',
            width: 150,
            render: (value, record) => {
                return (
                    <Switch
                        checked={value ? true : false}
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        onChange={(checked, event) => {}}
                        disabled
                        // disabled={!Permissions.can_edit_master_tags(permissions)}
                    />
                );
            },
            sorter: (a, b) => a.is_individual - b.is_individual,
        },
        {
            title: '# Checkpoints',
            key: 'tags_count',
            dataIndex: 'tags_count',
            sorter: (a, b) => a.tags_count - b.tags_count,
        },
        {
            title: 'Team Members',
            dataIndex: 'team_members',
            key: 'team_members',
            render: (team_members, record) =>
                team_members && (
                    <div className="package-team-members">
                        <Avatar.Group>
                            {team_members.map((member, idx) => (
                                <Tooltip
                                    key={member.email}
                                    title={`${member.first_name} ${member.last_name}`}
                                >
                                    <Popconfirm
                                        onConfirm={() => removeMember(member.id, record.id)}
                                        title="Are your want to remove this user?"
                                    >
                                        <Avatar
                                            className="circle-icon"
                                            src={makeAvatar(
                                                member.first_name[0],
                                                member.last_name[0],
                                            )}
                                        />
                                    </Popconfirm>
                                </Tooltip>
                            ))}

                            <Tooltip title="Add Member">
                                <Popover
                                    placement="rightTop"
                                    title="Add New Member"
                                    content={addMembersContent(record)}
                                    trigger="click"
                                >
                                    <Avatar
                                        className="circle-icon"
                                        style={{
                                            color: '#f56a00',
                                            backgroundColor: '#fde3cf',
                                        }}
                                        onClick={() => setDisplayAddMembers(record.id)}
                                    >
                                        <PlusOutlined />
                                    </Avatar>
                                </Popover>
                            </Tooltip>
                        </Avatar.Group>
                    </div>
                ),
        },
        {
            title: '',
            key: 'edit',
            dataIndex: 'edit',
            render: (_, record) => (
                <>
                    {!record.is_individual ? (
                        <Tooltip title="Delete Package">
                            <Popconfirm
                                onConfirm={() => onDeletePackage(record)}
                                title="Are your want to delete this package?"
                            >
                                <DeleteTwoTone
                                    // onClick={(ev) => {
                                    //     ev.stopPropagation();
                                    //     onDeletePackage(record);
                                    // }}
                                    twoToneColor="#f56666"
                                    style={{ fontSize: 18, cursor: 'pointer' }}
                                />
                            </Popconfirm>
                        </Tooltip>
                    ) : (
                        ''
                    )}
                </>
            ),
        },
        {
            title: '',
            key: 'modify',
            dataIndex: 'edit',
            colSpan: 1,
            align: 'center',
            render: (_, record) => (
                <>
                    <Tooltip title="Edit Package">
                        <EditOutlined
                            onClick={() => {
                                setActivePackage(record);
                                setCreatePackageVisible(true);
                            }}
                            style={{ fontSize: 18, cursor: 'pointer', color: '#16AAFF' }}
                        />
                    </Tooltip>
                </>
            ),
        },
    ];

    const nestedTagsColumn = [
        {
            title: 'Tag Name',
            dataIndex: 'name',
            key: 'name',
            width: 150,
            ...getColumnSearchProps('name', 'tags'),
        },
        {
            title: 'Operating Range',
            dataIndex: 'description',
            key: 'description',
            render: (text, record) => {
                return {
                    props: {
                        className: 'description-td',
                    },
                    children: (
                        <Tooltip placement="topLeft" title={text}>
                            {text}
                        </Tooltip>
                    ),
                };
            },
        },
    ];

    const validatePackageName = (pkgName) => {
        // return roomPackages.data.some((pkg) => pkg.name === pkgName);
        return false;
    };

    const onNext = async () => {
        onFinish({});
    };

    const onChangePageAndSorting = async (pagination, filters, sorter) => {
        let newOrdering = null;
        if (sorter.column) {
            newOrdering =
                sorter.order === 'ascend' ? sorter.column.dataIndex : '-' + sorter.column.dataIndex;
        }
        if (
            pagination.current !== roomPackages.currentPage ||
            newOrdering !== ordering ||
            pagination.pageSize !== roomPackages.pageSize
        ) {
            setOrdering(newOrdering);
            await fetchRoomPackages(
                pagination.current,
                pagination.pageSize,
                searchText,
                searchedColumn,
                newOrdering,
            );
        }
    };

    // const handleAllInOnePackage = async (isChecked) => {
    //     if (isChecked && _.isEmpty(individualPackages)) {
    //         notification.warning({
    //             message: 'No tags found',
    //             description: "You haven't upload or add any tag.",
    //         });
    //         return;
    //     } else if (isChecked && !_.isEmpty(roomPackages)) {
    //         notification.warning({
    //             message: "Can't perform this action",
    //             description:
    //                 "You can't keep all tags in one package because you already created a package below, remove that package if you want to keep all in one package.",
    //             duration: 10,
    //         });
    //         return;
    //     } else if (isChecked) {
    //         let allTeamMembers = [];
    //         individualPackages.forEach(({ team_members }) => {
    //             allTeamMembers = [...allTeamMembers, ...team_members];
    //         });
    //         const team_members = _.uniqBy(allTeamMembers, 'id');
    //         const packageRoom = {
    //             name: 'All In One Package',
    //             description: '',
    //             tags: individualPackages.map(({ id }) => id),
    //             team_members: team_members.map(({ id }) => id),
    //         };
    //         setLoader(true);
    //         await createPackage(projectId, packageRoom);
    //         showNotification('Package successfully created');
    //         await Promise.all([fetchRoomPackages(), fetchIndividualPackages()]);
    //         setLoader(false);
    //     } else {
    //         onDeletePackage(roomPackages[0]);
    //     }
    //     setAllInOnePackage(isChecked);
    // };

    return (
        <>
            <h2 className="step-heading align-item-center align-items-center">
                <span>
                    Manage Areas <br />{' '}
                </span>
                {/* {!modify && ( */}
                <span className="btn-tab">
                    <Button
                        type="primary"
                        onClick={() => setCreatePackageVisible(true)}
                        disabled={allInOnePackage}
                    >
                        <PlusOutlined /> Create Area
                    </Button>
                    <Button
                        type="primary"
                        style={{
                            marginLeft: '2px',
                        }}
                        onClick={() => resetOrder()}
                        danger
                    >
                        <RestOutlined /> Reset Order
                    </Button>
                </span>
                {/* )} */}
            </h2>
            <Space direction="vertical" size={15} style={{ width: '100%' }}>
                <Table
                    rowClassName={(record, index) =>
                        record.is_individual ? 'table-row-dark' : 'table-row-light'
                    }
                    size="small"
                    columns={groupsPackageColumns}
                    onChange={onChangePageAndSorting}
                    pagination={{
                        total: roomPackages.meta.total_count,
                        current: roomPackages.currentPage,
                        pageSize: roomPackages.pageSize,
                        showSizeChanger: true,
                    }}
                    className="taglist-table"
                    expandable={{
                        expandedRowRender: (record) => (
                            <Table
                                rowKey="id"
                                columns={nestedTagsColumn}
                                dataSource={record.tags.map((data, idx) => ({
                                    ...data,
                                    key: data.id,
                                }))}
                                className="ant-table-container"
                                pagination={record.tags.length > 10}
                            />
                        ),
                    }}
                    dataSource={roomPackages.data.map((data, idx) => ({
                        ...data,
                        key: data.id,
                    }))}
                />
            </Space>
            <div className="text-right">
                <Space>
                    <Button
                        className="btn-lg btn-default save-btn mt-30"
                        type="default"
                        htmlType="button"
                        onClick={goBack}
                    >
                        Previous
                    </Button>
                    <Button
                        className="btn-lg btn-primary-lg save-btn mt-30"
                        type="primary"
                        onClick={onNext}
                    >
                        Save & Finish
                    </Button>
                </Space>
            </div>

            {createPackageVisible && (
                <CreatePackageModal
                    title={`${!_.isEmpty(activePackage) ? 'Update' : 'Create'} Bundle`}
                    visible={createPackageVisible}
                    individualRoomsData={tags}
                    initialData={{ ...activePackage }}
                    onSubmit={onCreatePackage}
                    onUpdate={onUpdatePackage}
                    onCancel={onCancel}
                    validatePackageName={validatePackageName}
                    onDelete={onDeletePackage}
                    projectSetup
                />
            )}
        </>
    );
};

export default Packages;
