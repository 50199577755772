import { EditOutlined } from '@ant-design/icons';
import { Badge, Tooltip } from 'antd';

export const SidebarListItem = ({
    title = '',
    onClick = () => {},
    addActiveClass = false,
    icon: Icon,
    editBtn = true,
    onEdit = () => {},
    isDraft,
}) => {
    return (
        <li>
            <div
                className={`list-item-container ${addActiveClass ? 'active' : ''}`}
                onClick={onClick}
            >
                <div className="list-detail">
                    <Icon className="sidebar-list-icon" />
                    <span className="sidebar-list-text">{title}</span>
                </div>
                {isDraft ? (
                    <Badge
                        count={'Draft'}
                        style={{
                            background: '#eb5a46',
                            marginRight: '5px',
                        }}
                    />
                ) : (
                    ''
                )}

                {editBtn && (
                    <Tooltip title="Edit">
                        <button className="edit-icon" onClick={onEdit}>
                            <EditOutlined />
                        </button>
                    </Tooltip>
                )}
            </div>
        </li>
    );
};
