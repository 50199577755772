import { Empty } from "antd";

export const SubscriptionMessage = () => {
    return (
        <div className="subscription-message">
            <Empty
                style={{
                    fontSize: 16,
                    color: '#777',
                }}
                description="Please upgrade your subscription to enjoy this feature!"
                className="no-projects-empty"
                imageStyle={{ height: 230 }}
            ></Empty>
        </div>
    );
};
