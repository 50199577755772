import _ from 'lodash';
import React, { memo, useEffect, useState } from 'react';
import { Row, Col, Spin } from 'antd';
import { DocView } from '../../components/documents/docView';
import folderImg from '../../assets/images/folder.png';

export const EquipmentDocs = memo(({ isLoading, data, uploadDocs, uploadForVideos, pkgId }) => {
    const [show, setShow] = useState(true);
    const [docData, setDocData] = useState({});

    useEffect(() => {
        setDocData({});
        setShow(true);
    }, [pkgId]);

    const onClickFolderHander = (doc) => {
        setShow(!show);
        setDocData(doc);
    };

    const onUploadHandler = async (files, key) => {
        if (key !== 'videos') {
            await uploadDocs(Object.values(files), key);
        } else {
            await uploadForVideos(Object.values(files), key);
        }
        // setShow(!show);
    };

    const goBack = () => {
        setShow(true);
    };

    return (
        <Spin spinning={isLoading} tip={isLoading ? 'Please wait, it will take a while' : ''}>
            {show ? (
                <Row gutter={[40, 16]}>
                    {!_.isEmpty(data) &&
                        data.map((doc, index) => {
                            return (
                                <Col span={3} key={index}>
                                    <div className="icon">
                                        <img
                                            src={folderImg}
                                            style={{ maxWidth: '80%' }}
                                            onClick={() => onClickFolderHander(doc)}
                                        />
                                    </div>
                                    <div className="folder-name">
                                        <p>{doc.dir_value}</p>
                                    </div>
                                </Col>
                            );
                        })}
                </Row>
            ) : (
                <DocView docFiles={docData} uploadHandler={onUploadHandler} goBack={goBack} />
            )}
        </Spin>
    );
});
