import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
    Button,
    Collapse,
    DatePicker,
    Divider,
    Drawer,
    Form,
    List,
    message,
    Modal,
    Popconfirm,
    Select,
    Space,
    Spin,
    Table,
    Tooltip,
    Typography,
    Upload,
} from 'antd';
import {
    APPROVAL_TYPES,
    DOCUMENT_CATEGORIES,
    EQUIPMENT_SUB_FOLDERS,
    makeTitle,
    PROJECT_SUB_FOLDERS,
} from '../../../utils';
import {
    createNotification,
    saveEquipmentDocs,
    saveProjectDocs,
    sendForApproval,
    uploadDocuments,
} from '../../../services';
import { getProjectDocuments } from '../../../store/documents';
import {
    DeleteTwoTone,
    FolderOpenOutlined,
    PlusOutlined,
    QuestionCircleOutlined,
} from '@ant-design/icons';
import '../../../assets/styles/components/documents.scss'
const { Option, OptGroup } = Select;
const { Panel } = Collapse;

const NewDocuments = ({
    projectId,
    documentsToUpload = [],
    hideUploadView = () => {},
    setDocumentsToUpload = () => {},
    onUploadFiles = () => {},
}) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { allPackages } = useSelector(({ packages }) => packages);
    const [docInfoDrawer, setDocInfoDrawer] = useState(false);
    const [selectedRecords, setSelectedRecords] = useState([]);
    const [category, setCategory] = useState('');
    const [equipmentType, setEquipmentType] = useState('');
    const [loader, setLoader] = useState(false);
    const [teamMembers, setTeamMembers] = useState([]);
    const [confirmModal, setConfirmModal] = useState(false);
    const [values, setValues] = useState({});

    const deleteDocument = (uid) => {
        const idx = documentsToUpload.findIndex((item) => item.uid === uid);
        const selectedIdx = selectedRecords.findIndex((item) => item.uid === uid);
        if (idx > -1) {
            documentsToUpload.splice(idx, 1);
            setDocumentsToUpload([...documentsToUpload]);
            message.success('Removed');
        }
        if (selectedIdx > -1) {
            selectedRecords.splice(selectedIdx, 1);
            setSelectedRecords([...selectedRecords]);
        }
    };

    const columns = [
        {
            title: 'File name',
            dataIndex: 'name',
            width: 400,
        },
        {
            title: 'Category',
            dataIndex: 'category',
            width: 200,
            render: (value) => (value ? makeTitle(value) : '-'),
        },
        {
            title: 'Sub Folder',
            dataIndex: 'sub_folder',
            width: 200,
            render: (value) => (value ? makeTitle(value) : '-'),
        },
        {
            title: 'Package',
            dataIndex: 'equipment',
            width: 200,
            render: (value) => (value ? allPackages.find((pkg) => pkg.id === value).name : '-'),
        },
        {
            title: 'Due Date',
            dataIndex: 'approval_due_date',
            render: (value) => (value ? value.format('YYYY-MM-DD') : '-'),
        },
        {
            title: 'Assignee',
            dataIndex: 'assignee',
            render: (value, record) => {
                if (!value) return '-';
                const pkg = allPackages.find((pkg) => pkg.id === record.equipment);
                const assignee = pkg.team_members.find((member) => member.id === value);
                return `${assignee.first_name} ${assignee.last_name}`;
            },
        },
        {
            title: '',
            dataIndex: 'uid',
            render: (value, record) =>
                !record.is_uploaded ? (
                    <Tooltip title="Delete">
                        <Popconfirm
                            okText="Yes"
                            onConfirm={() => deleteDocument(value)}
                            title="Are your sure?"
                        >
                            <DeleteTwoTone
                                twoToneColor="#f56666"
                                style={{ fontSize: 18, cursor: 'pointer' }}
                            />
                        </Popconfirm>
                    </Tooltip>
                ) : null,
        },
    ];

    useEffect(() => {
        if (selectedRecords.length) showDrawer();
        else hideDrawer();
    }, [selectedRecords]);

    const showDrawer = () => setDocInfoDrawer(true);
    const hideDrawer = () => setDocInfoDrawer(false);

    const removeUploadedFiles = () => {
        const nonUploadedDocuments = documentsToUpload.filter((doc) => {
            const isUploaded = selectedRecords.find((item) => item.uid === doc.uid);
            if (!isUploaded) return doc;
        });
        setDocumentsToUpload([...nonUploadedDocuments]);
    };

    const sendNotificationForApproval = async (approvals) => {
        const uploadedDocuments = approvals.map(({ approval_file_name }) => approval_file_name);
        const files =
            uploadedDocuments.length > 1 ? uploadedDocuments.join(', ') : uploadedDocuments[0];
        const approvalMessage = files + ' have been sent for approval';
        const message = {
            package: approvals[0]['package'],
            message: approvalMessage,
        };
        await createNotification(projectId, message);
    };

    const submitForApproval = async () => {
        const { equipment, approval_due_date, assignee } = values;
        const formData = new FormData();
        selectedRecords.map((file) => formData.append('files', file.originFileObj));
        formData.append('project_id', projectId);
        const approvalDocuments = await uploadDocuments(projectId, equipment, formData);
        const approvals = approvalDocuments.data.map((item) => ({
            ...item,
            assignee,
            package: equipment.toString(),
            approval_file_name: item.fileName,
            approval_file_path: item.path,
            approval_due_date: approval_due_date.format('YYYY-MM-DD'),
        }));
        await Promise.all([
            sendForApproval(equipment, approvals),
            sendNotificationForApproval(approvals),
        ]);
    };

    const uploadEquipmentDocuments = async () => {
        const { equipment, sub_folder } = values;
        const formData = new FormData();
        selectedRecords.map((file) => formData.append('files', file.originFileObj));
        formData.append('is_package', true);
        formData.append('dir_key', sub_folder);
        formData.append('project_id', projectId);
        formData.append('id', equipment);
        await saveEquipmentDocs(projectId, equipment, formData);
    };

    const uploadProjectDocuments = async () => {
        const { sub_folder } = values;
        const formData = new FormData();
        selectedRecords.map((file) => formData.append('files', file.originFileObj));
        formData.append('dir_key', sub_folder);
        formData.append('id', projectId);
        await saveProjectDocs(projectId, formData);
    };

    const onSaveDocuments = async (values) => {
        try {
            setValues(values);
            const _documentsToUpload = documentsToUpload.map((item) => {
                const foundDocument = selectedRecords.some((i) => i.uid === item.uid);
                if (foundDocument) {
                    return {
                        ...foundDocument,
                        ...item,
                        ...values,
                        is_uploaded: true,
                    };
                }
                return item;
            });
            setSelectedRecords(
                selectedRecords.map((item) => ({
                    ...item,
                    ...values,
                })),
            );
            setDocumentsToUpload([..._documentsToUpload]);
            setConfirmModal(true);
            // setSelectedRecords([]);
            // hideDrawer();
            // message.success('Saved');

            // onUploadDocuments();
        } catch (error) {
            setLoader(false);
        }
    };

    const resetState = (msg = '') => {
        setLoader(false);
        form.resetFields();
        setDocInfoDrawer(false);
        // removeUploadedFiles();
        setSelectedRecords([]);
        setEquipmentType('');
        setCategory('');
        message.success(msg || 'Documents uploaded');
    };

    const onUploadDocuments = async () => {
        try {
            setLoader(true);
            if (equipmentType === APPROVAL_TYPES.APPROVAL_DRAWINGS) {
                await submitForApproval();
            } else if (category === DOCUMENT_CATEGORIES.EQUIPMENT_DOCUMENTS) {
                await uploadEquipmentDocuments();
            } else {
                await uploadProjectDocuments();
            }
            // await Promise.all(
            //     selectedRecords.map((item) => {
            //         if (item.sub_folder === APPROVAL_TYPES.APPROVAL_DRAWINGS) {
            //             return submitForApproval(item);
            //         } else if (item.category === DOCUMENT_CATEGORIES.EQUIPMENT_DOCUMENTS) {
            //             return uploadEquipmentDocuments(item);
            //         } else {
            //             return uploadProjectDocuments(item);
            //         }
            //     }),
            // );
            resetState();
            setConfirmModal(false);
            dispatch(getProjectDocuments(projectId));
        } catch (error) {
            setLoader(false);
        }
    };

    const onSubmitDocument = () => {
        if (!selectedRecords.length) {
            message.warning('Please select documents to upload');
            return;
        }

        const missingInfo = selectedRecords.some((item) => !item?.category);

        if (missingInfo) {
            message.warning('Please select types for the selected documents ');
            return;
        }
        hideDrawer();
        setConfirmModal(true);
    };

    return (
        <div className="page-content-container full-width">
            <div className="card-heading d-flex justify-space-between">
                <span>
                    Upload Documents
                    <Tooltip title="Upload Pre-Construction & Manufacture related files here">
                        <QuestionCircleOutlined className="cursor-pointer ml-10" />
                    </Tooltip>
                </span>
                {/* <Button type="primary" className="btn-tab" onClick={onSubmitDocument}>
                    Submit Documents
                </Button> */}
                <Space>
                    <Button
                        type="default"
                        shape="round"
                        className="main-upload-btn"
                        onClick={() => setDocumentsToUpload([])}
                    >
                        Clear
                    </Button>
                    <Upload
                        beforeUpload={() => false}
                        onChange={_.debounce(onUploadFiles, 100)}
                        showUploadList={false}
                        multiple
                        fileList={[]}
                    >
                        <Button type="default" shape="round" className="main-upload-btn">
                            <PlusOutlined /> Add Documents
                        </Button>
                    </Upload>
                </Space>
            </div>
            <Collapse
                bordered={false}
                defaultActiveKey={['1', '2']}
                className="site-collapse-custom-collapse"
            >
                <Panel
                    key="1"
                    header="Documents To Upload"
                    className="site-collapse-custom-panel-grey"
                >
                    <Table
                        className="taglist-table"
                        size="small"
                        rowSelection={{
                            type: 'checkbox',
                            selectedRowKeys: selectedRecords.map(({ uid }) => uid),
                            onChange: (_, selectedRows) => {
                                setSelectedRecords(selectedRows);
                            },
                        }}
                        columns={columns}
                        rowKey="uid"
                        dataSource={documentsToUpload.filter((d) => !d?.is_uploaded)}
                        pagination={documentsToUpload.length > 10}
                    />
                </Panel>
                <Panel key="2" header="Uploaded Documents" className="site-collapse-custom-panel">
                    <Table
                        className="taglist-table"
                        size="small"
                        columns={columns.filter((c) => c.dataIndex !== 'uid')}
                        rowKey="uid"
                        dataSource={documentsToUpload.filter((d) => d.is_uploaded)}
                        pagination={documentsToUpload.length > 10}
                    />
                </Panel>
            </Collapse>

            <div className="site-drawer-render-in-current-wrapper">
                <Drawer
                    title="Document Information"
                    placement="right"
                    width={500}
                    onClose={hideDrawer}
                    visible={docInfoDrawer}
                    getContainer={false}
                    mask={false}
                    keyboard
                    footer={
                        <Space>
                            <Button type="primary" loading={loader} onClick={form.submit}>
                                Save
                            </Button>
                            <Button onClick={hideDrawer}>Cancel</Button>
                        </Space>
                    }
                >
                    <Spin spinning={loader}>
                        <Form layout="vertical" form={form} onFinish={onSaveDocuments}>
                            <Form.Item
                                label="Category"
                                name="category"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Please select"
                                    onChange={(value) => {
                                        setCategory(value);
                                        form.setFieldsValue({ sub_folder: '' });
                                    }}
                                >
                                    {Object.values(DOCUMENT_CATEGORIES).map((item) => (
                                        <Option key={item} value={item}>
                                            {makeTitle(item)}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            {category === DOCUMENT_CATEGORIES.PROJECT_DOCUMENTS && (
                                <Form.Item
                                    label="Type"
                                    name="sub_folder"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Required',
                                        },
                                    ]}
                                >
                                    <Select placeholder="Please select">
                                        {Object.values(PROJECT_SUB_FOLDERS).map((item) => (
                                            <Option key={item} value={item}>
                                                {makeTitle(item)}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            )}
                            {category === DOCUMENT_CATEGORIES.EQUIPMENT_DOCUMENTS && (
                                <>
                                    <Form.Item
                                        label="Equipment"
                                        name="equipment"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Required',
                                            },
                                        ]}
                                    >
                                        <Select
                                            placeholder="Please select"
                                            onChange={(value) => {
                                                const pkg = allPackages.find(
                                                    ({ id }) => id === value,
                                                );
                                                setTeamMembers([...pkg.team_members]);
                                                // console.log("teamM: ", teamMembers)
                                            }}
                                        >
                                            {allPackages.map((item) => (
                                                <Option key={item.id} value={item.id}>
                                                    {item.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        label="Type"
                                        name="sub_folder"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Required',
                                            },
                                        ]}
                                    >
                                        <Select
                                            placeholder="Please select"
                                            listHeight={500}
                                            onChange={(value) => setEquipmentType(value)}
                                        >
                                            <OptGroup label="Documents">
                                                {Object.values(EQUIPMENT_SUB_FOLDERS).map(
                                                    (item) => (
                                                        <Option key={item} value={item}>
                                                            {makeTitle(item)}
                                                        </Option>
                                                    ),
                                                )}
                                            </OptGroup>
                                            <OptGroup label="Approval Process">
                                                {Object.values(APPROVAL_TYPES).map((item) => (
                                                    <Option key={item} value={item}>
                                                        {makeTitle(item)}
                                                    </Option>
                                                ))}
                                            </OptGroup>
                                        </Select>
                                    </Form.Item>
                                </>
                            )}
                            {equipmentType === APPROVAL_TYPES.APPROVAL_DRAWINGS && (
                                <>
                                    <Form.Item
                                        label="Due Date"
                                        name="approval_due_date"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Required',
                                            },
                                        ]}
                                    >
                                        <DatePicker style={{ width: '100%' }} />
                                    </Form.Item>
                                    <Form.Item label="Assignee" name="assignee">
                                        <Select placeholder="Please select">
                                            {teamMembers.map((m) => (
                                                <Option
                                                    value={m.id}
                                                    key={m.id}
                                                >{`${m.first_name} ${m.last_name}`}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </>
                            )}
                        </Form>
                        <Divider orientation="left">Selected Documents</Divider>
                        <List
                            size="small"
                            bordered
                            dataSource={selectedRecords}
                            renderItem={(item) => (
                                <List.Item>
                                    <Typography.Text>{item.name}</Typography.Text>
                                </List.Item>
                            )}
                        />
                    </Spin>
                </Drawer>
            </div>

            <Modal
                centered
                closable={false}
                title="Are you sure?"
                visible={confirmModal}
                bodyStyle={{ padding: 10 }}
                width={1000}
                okText="Upload"
                onCancel={() => setConfirmModal(false)}
                maskClosable={false}
                onOk={onUploadDocuments}
                confirmLoading={loader}
            >
                <Table
                    loading={loader}
                    size="small"
                    className="taglist-table"
                    columns={columns.filter((c) => c.dataIndex !== 'uid')}
                    rowKey="uid"
                    dataSource={selectedRecords.map((i) => ({ ...i, key: i.uid }))}
                    pagination={selectedRecords.length > 10}
                />
            </Modal>
        </div>
    );
};

export default NewDocuments;
