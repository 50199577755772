import { DatePicker } from 'antd';
import moment from 'moment';

const { RangePicker } = DatePicker;

export const MyDateRangePicker = ({ dateRange, parentCallback }) => {
    const onChange = function ([startDate, endDate]) {
        parentCallback({
            startDate: startDate.format('yyyy-MM-DD'),
            endDate: endDate.format('yyyy-MM-DD'),
        });
    };

    return (
        <RangePicker
            size="middle"
            defaultValue={[moment(dateRange.startDate), moment(dateRange.endDate)]}
            onChange={onChange}
            getPopupContainer={(trigger) => trigger.parentNode}
        />
    );
};
