import { DollarTwoTone } from '@ant-design/icons';
import { Badge } from 'antd';

export const content = {
    'Remove backdoors as there is a wall': [
        <div
            style={{
                fontSize: '12px',
            }}
        >
            <h3> Recommendations </h3>
            <p>
                1. Consider using back panels for future access.{' '}
                <Badge
                    count={'Finish on time'}
                    style={{ backgroundColor: '#fc9f19', marginBottom: '4px' }}
                />
            </p>
            <p>
                2. Consider using UL891 construction. {'     '}
                <span style={{ fontSize: '16px' }}>
                    <DollarTwoTone twoToneColor="#61bd4f" />
                    <DollarTwoTone twoToneColor="#61bd4f" />
                    <DollarTwoTone twoToneColor="#61bd4f" />
                </span>{' '}
                <Badge
                    count={'Finish early by 23 days'}
                    style={{ backgroundColor: '#61bd4f', marginBottom: '4px' }}
                />
            </p>{' '}
        </div>,
    ],

    'Change 5000 to 4000 A': [
        <div
            style={{
                fontSize: '12px',
            }}
        >
            <h3> Recommendations </h3>
            <p>
                1. Not Advisable as switch gear is rated 5000 A.{'  '}
                <Badge
                    count={'Finish on time'}
                    style={{ backgroundColor: '#fc9f19', marginBottom: '4px' }}
                />
            </p>
        </div>,
    ],

    'Add 2000 Breaker': [
        <div
            style={{
                fontSize: '12px',
            }}
        >
            <h3> Recommendations </h3>
            <p>
                1. Keep 1600 A or below to keep same section width. {'     '}
                <span style={{ fontSize: '16px' }}>
                    <DollarTwoTone twoToneColor="#61bd4f" />
                    <DollarTwoTone twoToneColor="#61bd4f" />
                </span>
            </p>
            <p>
                2. Consider splitting load with two breakers on 101A and 102 A. {'     '}
                <span style={{ fontSize: '16px' }}>
                    <DollarTwoTone twoToneColor="#eb5a46" />
                    <DollarTwoTone twoToneColor="#eb5a46" />
                </span>
            </p>
        </div>,
    ],
};
