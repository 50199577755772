import { Input } from 'antd';
import './styles.scss';

export const SearchBox = ({
    className = '',
    autoFocus = false,
    onChange = () => {},
    placeholder = '',
    style = {},
    value = '',
}) => {
    return (
        <div className={`search-box ${className}`} style={style}>
            <Input
                placeholder={placeholder}
                autoFocus={autoFocus}
                onChange={onChange}
                {...value ? value={value}: null}
            />
        </div>
    );
};
