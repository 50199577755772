import { http } from '../utils/http';

export const fetchTagHistoryDatewise = async (tagId, startDate, endDate) => {
    try {
        const response = await http.get(
            `/projects/tag-data-round-report/${tagId}/?start_date=${startDate}&end_date=${endDate}`,
        );
        return response.data;
    } catch (ex) {
        throw { ...ex };
    }
};

export const fetchWebTagDetails = async (projectId, tagId, section, startDate, endDate) => {
    try {
        const response = await http.get(
            `/projects/${projectId}/web/tags/${tagId}/?section=${section}&start_date=${startDate}&end_date=${endDate}`,
        );
        return response.data;
    } catch (ex) {
        throw { ...ex };
    }
};
