import GoogleMapReact from 'google-map-react';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Marker } from '../../../components';
import { MAP_VIEW } from '../../../store/common';
import { setActiveProject } from '../../../store/common/actions';

const MapListing = ({ setMapShow, onProjectClickHandler, projects, centerLocation }) => {
    const dispatch = useDispatch();

    const onClick = (obj) => {
        dispatch(setActiveProject(obj));
    };

    return (
        <div style={{ height: '100vh', width: '100%' }}>
            <GoogleMapReact
                bootstrapURLKeys={{
                    key: process.env.REACT_APP_GOOGLE_MAP_KEY_V3,
                    libraries: ['places', 'geometry'],
                }}
                zoom={4}
                center={centerLocation}
            >
                {projects.map(({ id, name, latitude, longitude, description }) => (
                    <Marker
                        key={id}
                        lat={latitude}
                        lng={longitude}
                        onClick={() => {
                            onClick({ id, name, latitude, longitude });
                            onProjectClickHandler(id, name);
                        }}
                        text={name}
                        description={description}
                    />
                ))}
            </GoogleMapReact>
        </div>
    );
};
export default MapListing;
