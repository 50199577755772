import { CaretRightOutlined, DownloadOutlined, UserOutlined } from '@ant-design/icons';
import {
    Avatar,
    Badge,
    Button,
    Col,
    Collapse,
    Empty,
    Image,
    Row,
    Space,
    Spin,
    Tag,
    Tooltip,
    Typography,
} from 'antd';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import moment from 'moment';

const { Panel } = Collapse;
const { Text } = Typography;

const columnsDoc = [
    {
        title: 'File Name',
        dataIndex: 'filename',
    },
    {
        title: 'Files',
        dataIndex: 'files',
        render: (_, record) => (
            <Space>
                <Tooltip title="View">
                    <Image
                        width={45}
                        height={32}
                        src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                    />
                </Tooltip>
                <Tooltip title="Download">
                    <Button
                        className="buttonView"
                        type="default"
                        // onClick={() => handleDownload(record.approval_file_path)}
                    >
                        <DownloadOutlined />
                    </Button>
                </Tooltip>
            </Space>
        ),
    },

    {
        title: 'Shift',
        dataIndex: 'shift',
    },

    {
        title: 'Reported on',
        dataIndex: 'submittedAt',
    },

    {
        title: 'Reported by',
        dataIndex: 'submittedBy',
        render: (_, record) => (
            <Space>
                <Tooltip title="Alex">
                    <Avatar className="center-icon" icon={<UserOutlined />} />
                </Tooltip>
            </Space>
        ),
    },
];

const RoundDetails = ({
    activeRound,
    tagRoundComments,
    tagRoundDocuments,
    roundsLoaders,
    rounds,
    roundDate,
    roundShift,
    roundDetailLoader,
}) => {
    return (
        <Col span={16}>
            <Spin spinning={roundDetailLoader}>
                {activeRound ? (
                    <div
                        className="page-content-container have-nested-containers notifications-content"
                        style={{
                            background: '#F3F8FF',
                        }}
                    >
                        <h4 className="card-heading step-heading">{activeRound?.tag.name}</h4>
                        <div className="notifications-body custom-scroll-dark">
                            <Row align="middle">
                                <Col span={24}>
                                    <Row>
                                        <Col span={6}>
                                            <p>Date</p>
                                        </Col>
                                        <Col span={6}>
                                            <p>{roundDate.format('YYYY-MM-DD')} </p>
                                        </Col>
                                        <Col span={6}>
                                            <p>Reading</p>
                                        </Col>

                                        <Col span={6}>
                                            <p>
                                                {activeRound.reading_key === ''
                                                    ? 'N/A'
                                                    : activeRound.reading_key}
                                            </p>
                                        </Col>

                                        <Col span={6}>
                                            <p>Shift:</p>
                                        </Col>

                                        <Col span={6}>
                                            <p>{roundShift === '0' ? 'Day' : 'Night'}</p>
                                        </Col>

                                        <Col span={6}>
                                            <p>Comments:</p>
                                        </Col>

                                        <Col span={6}>
                                            <p>{activeRound.comments_count}</p>
                                        </Col>
                                        <Col span={6}>
                                            <p>Docs:</p>
                                        </Col>
                                        <Col span={6}>{activeRound.docs_count}</Col>
                                        <Col span={6}>
                                            <p>Issue Reported:</p>
                                        </Col>
                                        <Col span={6}>
                                            {activeRound.status_type === 'Yes' ? (
                                                <Badge
                                                    count={activeRound.status_type}
                                                    style={{
                                                        backgroundColor: '#ff4d4f',
                                                        width: '100%',
                                                    }}
                                                />
                                            ) : activeRound.status_type === 'No' ? (
                                                <Badge
                                                    count={activeRound.status_type}
                                                    style={{
                                                        backgroundColor: '#52c41a',
                                                        width: '100%',
                                                    }}
                                                />
                                            ) : (
                                                <Badge
                                                    count={activeRound.status_type}
                                                    status={'warning'}
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                />
                                            )}
                                        </Col>
                                    </Row>
                                </Col>

                                {activeRound?.tag.image_url && (
                                    <Col span={12} className="text-center">
                                        <img
                                            src={activeRound?.image_url}
                                            alt="Tag"
                                            className="tag-image"
                                        />
                                    </Col>
                                )}
                                <Col
                                    span={24}
                                    style={{
                                        background: '#F3F8FF',
                                    }}
                                >
                                    <Collapse
                                        expandIcon={({ isActive }) => (
                                            <CaretRightOutlined rotate={isActive ? 90 : 0} />
                                        )}
                                        className="site-custom"
                                        expandIconPosition="end"
                                    >
                                        <Panel
                                            header={`Comments (${activeRound.comments_count})`}
                                            key="1"
                                            className="site-custom"
                                        >
                                            <div className="comments-body custom-scroll-dark">
                                                {!_.isEmpty(tagRoundComments) ? (
                                                    tagRoundComments.map((comment) => {
                                                        return (
                                                            <div className="issue" key={comment.id}>
                                                                <div align="top">
                                                                    {
                                                                        <Text className="date">
                                                                            <b
                                                                                style={{
                                                                                    color: '#555',
                                                                                }}
                                                                            ></b>
                                                                            {comment.text}
                                                                        </Text>
                                                                    }
                                                                </div>
                                                                <div
                                                                    justify="start"
                                                                    align="bottom"
                                                                    style={{
                                                                        display: 'flex',
                                                                        justifyContent:
                                                                            'space-between',
                                                                    }}
                                                                >
                                                                    <Text className="date">
                                                                        <strong>Added on: </strong>
                                                                        {moment(
                                                                            comment.created_at,
                                                                        ).format('YYYY-MM-DD')}
                                                                    </Text>

                                                                    <Text className="date">
                                                                        <strong>
                                                                            {' '}
                                                                            Reported By:{' '}
                                                                        </strong>
                                                                        {comment.user_details.name}
                                                                    </Text>
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                ) : (
                                                    <div className="comment-container custom-description center-container">
                                                        <Empty
                                                            description={`Comments not found for ${activeRound.tag?.name}`}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </Panel>
                                        <Panel
                                            header={`Documents (${activeRound.docs_count})`}
                                            key="2"
                                            className="site-custom"
                                        >
                                            <div className="comments-body custom-scroll-dark">
                                                {!_.isEmpty(tagRoundDocuments.files) ? (
                                                    tagRoundDocuments.files.map((file) => {
                                                        return (
                                                            <div
                                                                className="issue"
                                                                key={file.file_name}
                                                            >
                                                                <div align="left">
                                                                    <Text className="date">
                                                                        <b
                                                                            style={{
                                                                                color: '#555',
                                                                            }}
                                                                        ></b>
                                                                        {file.file_name}
                                                                    </Text>
                                                                    <div align="right">
                                                                        <Space>
                                                                            <Tooltip title="View">
                                                                                <Image
                                                                                    width={50}
                                                                                    height={50}
                                                                                    src={
                                                                                        file.signed_url
                                                                                    }
                                                                                />
                                                                            </Tooltip>
                                                                            <Tooltip title="Download">
                                                                                <Button
                                                                                    className="buttonView"
                                                                                    type="default"
                                                                                    onClick={() =>
                                                                                        saveAs(
                                                                                            file.signed_url,
                                                                                            file.file_name,
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <DownloadOutlined />
                                                                                </Button>
                                                                            </Tooltip>
                                                                        </Space>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                ) : (
                                                    <div className="comment-container custom-description center-container">
                                                        <Empty
                                                            description={`Files not found for ${activeRound.tag?.name}`}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                            {/* {!_.isEmpty(tagRoundDocuments) ? (
                                            <div className="slider_body">
                                                <Table
                                                    columns={columnsDoc}
                                                    dataSource={tagRoundDocuments.files}
                                                />
                                            </div>
                                        ) : (
                                            <div className="comment-container custom-description">
                                                <Empty
                                                    description={`Documents not found for ${activeRound?.name}`}
                                                />
                                            </div>
                                        )} */}
                                        </Panel>
                                    </Collapse>
                                </Col>
                            </Row>
                        </div>
                    </div>
                ) : !_.isEmpty(rounds) ? (
                    <div className="placeholder-container custom-description">
                        <Empty description="Select tag to see tag round details" />
                    </div>
                ) : !roundsLoaders && _.isEmpty(rounds) ? (
                    <div className="placeholder-container custom-description">
                        <Empty description="Notifications not found" />
                    </div>
                ) : null}
            </Spin>
        </Col>
    );
};

export default RoundDetails;
