import { http, fileHttp } from '../utils/http';

export const fetchEquipmentDocs = async (projectId, pkgId) => {
    try {
        const response = await http.get(`/projects/${projectId}/package-rooms/${pkgId}/documents/`);
        return response.data;
    } catch (ex) {
        console.log(ex);
    }
};

export const fetchConstructionDrawings = async (projectId, pkgId) => {
    try {
        const response = await http.get(
            `/projects/${projectId}/package-rooms/${pkgId}/documents/construction_drawings/`,
        );
        return response.data;
    } catch (ex) {
        console.log(ex);
    }
};

export const saveEquipmentDocs = async (projectId, pkgId, data) => {
    try {
        const response = await fileHttp.post(
            `/projects/${projectId}/package-rooms/${pkgId}/documents/`,
            data,
        );
        return response.data;
    } catch (ex) {
        console.log(ex);
    }
};

export const saveEquipmentDocsForVideos = async (data) => {
    try {
        const response = await fileHttp.post(`/videos/list/`, data);
        return response.data;
    } catch (ex) {
        console.log(ex);
    }
};

export const fetchImageEquipmentDocs = async (projectId, pkgId) => {
    try {
        const response = await http.get(
            `/projects/${projectId}/package-rooms/${pkgId}/documents/image/`,
        );
        return response.data;
    } catch (ex) {
        console.log(ex);
    }
};
