import React, { useState } from 'react';
import Slider from 'react-slick';
import './multi-carousel.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import VideoPlayer from '../video-player';
import { Spin, Tooltip } from 'antd';

const PreviousBtn = (props) => {
    const { className, onClick } = props;
    return (
        <div className={className} onClick={onClick}>
            <LeftOutlined className="custom-arrows" />
        </div>
    );
};
const NextBtn = (props) => {
    const { className, onClick } = props;

    return (
        <div className={className} onClick={onClick}>
            <RightOutlined className="custom-arrows" />
        </div>
    );
};

const MultiCarousel = ({ videoData, uploadLoader }) => {
    const [url, setUrl] = useState('');
    const [punchList, setPunchList] = useState([]);

    const handleClick = (data) => {
        setUrl(data.s3_url);
        setPunchList(data.punch_list);
    };

    return (
        <>
            <Spin spinning={uploadLoader} tip="Please wait...">
                <Slider
                    dots
                    infinite={false}
                    speed={500}
                    slidesToShow={4}
                    slidesToScroll={4}
                    prevArrow={<PreviousBtn />}
                    nextArrow={<NextBtn />}
                    rows={1}
                >
                    {videoData.map((video, index) => {
                        return (
                            <Tooltip title={video.file_name} key={index}>
                                <img
                                    onClick={() => handleClick(video)}
                                    className="thumbnails"
                                    src={`https://source.unsplash.com/random/320x200?sig=${index}`}   // API is not ready so using this URL for the time being.
                                />
                            </Tooltip>
                        );
                    })}
                </Slider>
                <div className="video-player-main">
                    {url && <VideoPlayer videoUrl={url} punchList={punchList} />}
                </div>
            </Spin>
        </>
    );
};

export default MultiCarousel;
