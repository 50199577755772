import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Layout, notification, Spin } from 'antd';
import { fetchProjectDetail, fetchProjectDocs, saveProjectDocs } from '../../../services';
import BasicInfo from './basic-info';
import UserAccess from './user-access';
import ManageEquipments from './manage-equipments';
import Packages from './packages';
import Documentation from './documentation';

const { Content } = Layout;

const steps = [
    {
        title: 'Project Information',
        id: 1,
    },
    {
        title: 'Users Access',
        id: 2,
    },
    {
        title: 'Manage Equipments',
        id: 3,
    },
    {
        title: 'Manage Packages',
        id: 4,
    },
    {
        title: 'Add Documentation',
        id: 5,
    },
];

const operations_steps = [
    {
        title: 'Project Information',
        id: 1,
    },
    {
        title: 'Users Access',
        id: 2,
    },
    {
        title: 'Manage Equipments',
        id: 3,
    },
    {
        title: 'Add Documentation',
        id: 4,
    },
];

const EditProject = ({ history }) => {
    const { projectId } = useParams();
    const { user } = useSelector(({ auth }) => auth);
    const { operations } = useSelector(({ common }) => common);
    const [current, setCurrent] = useState(1);
    const [formValues, setFormValues] = useState({});
    const [existingUsers, setExistingUsers] = useState([]);
    const [loader, setLoader] = useState(false);
    const [showTip, setShowTip] = useState(false);
    const [tags, setTags] = useState([]);
    const [users, setUsers] = useState([]);
    const [roomPackages, setRoomPackages] = useState([]);
    const [organizations, setOrganizations] = useState([]);
    const [equipmentErrors, setEquipmentErrors] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [individualPackages, setIndividualPackages] = useState([]);

    const getProjectDetail = async () => {
        try {
            setLoader(true);
            const response = await fetchProjectDetail(projectId);
            setFormValues({
                name: response.data.name,
                description: response.data.description,
                owner: response.data?.owner,
            });
            const projectUsers = response.data.account
                .filter((usr) => usr.id !== user.id)
                .map((usr) => ({
                    ...usr,
                    organization_name: usr.organization.name,
                    organization_type_name: usr.organization_type.name,
                    role_name: usr.role.name,
                }));
            setUsers([...projectUsers]);
            setTags([...response.data.tags]);
            setRoomPackages([...response.data.packages]);
            setIndividualPackages([...response.data.individual_packages]);
            setLoader(false);
        } catch (error) {
            notification.error({ message: "Project doesn't exist", duration: 5 });
            // history.push('/projects')
        }
    };

    const getProjectDocuments = async () => {
        try {
            const response = await fetchProjectDocs(projectId);
            setDocuments(response.data);
        } catch (e) {
            console.log('Error while fetching project docs : ', e);
        }
    };

    useEffect(() => {
        getProjectDetail();
        getProjectDocuments();
    }, []);

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    const goBack = () => {
        setCurrent(current - 1);
    };

    const onFinish = (values) => {
        setFormValues({ ...formValues, ...values });
        setCurrent(current + 1);
    };

    const actionCompleted = () => {
        notification.success({
            message: 'Project Updated',
            description: 'The project has been successfully updated',
        });
        setCurrent(0);
        setLoader(false);
        history.push('/projects');
    };

    const uploadProjectDocs = async (values) => {
        try {
            Promise.all(
                Object.keys(values).map(async (key) => {
                    if (!_.isEmpty(values[key])) {
                        const fileList = values[key].target.files;
                        const formData = new FormData();
                        formData.append('dir_key', key);
                        formData.append('id', projectId);
                        Object.values(fileList).forEach((file) => {
                            formData.append('files', file);
                        });
                        await saveProjectDocs(projectId, formData);
                    }
                }),
            ).then(actionCompleted);
        } catch (error) {
            console.log(error);
        }
    };

    const onSubmit = async (values) => {
        try {
            setLoader(true);
            setShowTip(true);
            if (!_.isEmpty(values)) {
                await uploadProjectDocs(values);
            } else {
                actionCompleted();
            }
            setLoader(false);
            setShowTip(false);
        } catch (error) {
            console.log('error', error);
            setLoader(false);
            setShowTip(false);
        }
    };

    return (
        <Content>
            <div style={{ width: '80%', margin: 'auto' }}>
                <div className="page-content-container">
                    <div className="project-steps">
                        {(operations ? operations_steps : steps).map((item) => (
                            <div
                                className={`project-step cursor-pointer ${
                                    item.id === current ? 'active' : ''
                                }`}
                                key={item.title}
                                onClick={() => setCurrent(item.id)}
                            >
                                {item.title}
                            </div>
                        ))}
                    </div>
                    <div className="steps-content mt-30">
                        <Spin
                            spinning={loader}
                            tip={showTip ? 'Please wait, it will take a while' : ''}
                        >
                            {current === 1 ? (
                                <BasicInfo
                                    basicInfo={formValues}
                                    normFile={normFile}
                                    onFinish={onFinish}
                                    projectId={projectId}
                                />
                            ) : current === 2 ? (
                                <UserAccess
                                    existingUsers={existingUsers}
                                    organizations={organizations}
                                    setOrganizations={setOrganizations}
                                    users={users}
                                    goBack={goBack}
                                    setUsers={setUsers}
                                    onFinish={onFinish}
                                    projectId={projectId}
                                    setLoader={setLoader}
                                />
                            ) : current === 3 ? (
                                <ManageEquipments
                                    tags={tags}
                                    goBack={goBack}
                                    setTags={setTags}
                                    onFinish={onFinish}
                                    equipmentErrors={equipmentErrors}
                                    projectId={projectId}
                                    setLoader={setLoader}
                                />
                            ) : current === 4 ? (
                                operations ? (
                                    <Documentation
                                        documents={documents}
                                        goBack={goBack}
                                        onSubmit={onSubmit}
                                        projectId={projectId}
                                    />
                                ) : (
                                    <Packages
                                        goBack={goBack}
                                        tags={tags}
                                        users={users}
                                        setTags={setTags}
                                        roomPackages={roomPackages}
                                        setRoomPackages={setRoomPackages}
                                        individualPackages={individualPackages}
                                        onFinish={onFinish}
                                        projectId={projectId}
                                        setLoader={setLoader}
                                        getProjectDetail={getProjectDetail}
                                    />
                                )
                            ) : current === 5 ? (
                                <Documentation
                                    documents={documents}
                                    goBack={goBack}
                                    onSubmit={onSubmit}
                                    projectId={projectId}
                                />
                            ) : null}
                        </Spin>
                    </div>
                </div>
            </div>
        </Content>
    );
};

export default EditProject;
