import _ from 'lodash';
import { Col, DatePicker, Empty, Row, Select, Spin } from 'antd';
import * as moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import '../../../assets/styles/components/task-inbox.scss';
import { fetchRoundComments, fetchRoundDocuments, fetchRounds } from '../../../services';
import RoundDetails from './round-details';
const { Option } = Select;

const Rounds = ({ projectId }) => {
    const { user } = useSelector(({ auth }) => auth);
    const [roundsLoaders, setRoundsLoader] = useState(false);
    const [rounds, setRounds] = useState([]);
    const [activeRound, setActiveRound] = useState();
    const [tagRoundDocuments, setTagRoundDocuments] = useState({});
    const [tagRoundComments, seTagRoundComments] = useState([]);
    const [roundDate, setRoundDate] = useState(moment());
    const [roundShift, setRoundShift] = useState('0');
    const [roundDetailLoader, setRoundDetailLoader] = useState(false);

    let offset = 1;

    useEffect(() => {
        getRounds(projectId, roundDate, roundShift);
    }, [projectId, roundDate, roundShift]);

    const getRounds = async () => {
        try {
            setRoundsLoader(true);
            const response = await fetchRounds(
                projectId,
                roundDate.format('YYYY-MM-DD'),
                roundShift,
            );
            setRounds([...response.data]);

            setRoundsLoader(false);
        } catch (error) {
            console.log(error);
            setRounds([]);
            setRoundsLoader(false);
        }
    };

    const onRoundDetailsClick = async (round) => {
        try {
            setRoundDetailLoader(true);
            const [comments, documents] = await Promise.all([
                fetchRoundComments(projectId, round.id),
                fetchRoundDocuments(projectId, round.id),
            ]);
            setActiveRound(round);
            seTagRoundComments([...comments.data]);
            setTagRoundDocuments({ ...documents.data });
            setRoundDetailLoader(false);
        } catch (error) {
            setRoundDetailLoader(false);
            seTagRoundComments([]);
            setTagRoundDocuments({});
            console.log(error);
        }
    };

    return (
        <>
            <Row className="row-body">
                <Col span={24}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Spin spinning={roundsLoaders}>
                                <div className="page-content-container" style={{ padding: 0 }}>
                                    <div className="task-inbox-actions">
                                        <Row>
                                            <Col span={12}>
                                                <DatePicker
                                                    onChange={(val) => setRoundDate(val)}
                                                    value={roundDate}
                                                    style={{
                                                        height: '100%',
                                                        borderColor: '#40a9ff',
                                                    }}
                                                />
                                            </Col>
                                            <Col span={12}>
                                                <Select
                                                    value={roundShift}
                                                    style={{
                                                        width: '100%',
                                                        border: '1px solid #40a9ff',
                                                        borderColor: '#40a9ff',
                                                    }}
                                                    onChange={(val) => setRoundShift(val)}
                                                >
                                                    <Option value="0">Day</Option>
                                                    <Option value="1">Night</Option>
                                                </Select>
                                            </Col>
                                        </Row>
                                    </div>

                                    <div
                                        id="scrollableDiv"
                                        className="task-inbox-list custom-scroll-dark"
                                    >
                                        <ul>
                                            {!_.isEmpty(rounds) ? (
                                                rounds.map((round, idx) => (
                                                    <li
                                                        key={idx}
                                                        onClick={() => onRoundDetailsClick(round)}
                                                        id={
                                                            activeRound?.id === round.id
                                                                ? 'active'
                                                                : ''
                                                        }
                                                    >
                                                        <div className="task-inbox-header">
                                                            <h4>{round?.tag.name}</h4>
                                                            <div className="actions"></div>
                                                        </div>
                                                        <p>{round?.tag.description}</p>
                                                    </li>
                                                ))
                                            ) : !roundsLoaders && _.isEmpty(rounds) ? (
                                                <Empty
                                                    description="You don't have any update"
                                                    className="mt-30"
                                                />
                                            ) : null}
                                        </ul>
                                    </div>
                                </div>
                            </Spin>
                        </Col>
                        <RoundDetails
                            activeRound={activeRound}
                            rounds={rounds}
                            roundsLoaders={roundsLoaders}
                            tagRoundComments={tagRoundComments}
                            tagRoundDocuments={tagRoundDocuments}
                            roundShift={roundShift}
                            roundDate={roundDate}
                            roundDetailLoader={roundDetailLoader}
                        />
                    </Row>
                </Col>
            </Row>
        </>
    );
};

export default Rounds;
