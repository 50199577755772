import { Button, Spin, Tooltip } from 'antd';
import { DoubleLeftOutlined, PlusOutlined } from '@ant-design/icons';
import './styles.scss';

export const Sidebar = ({
    children,
    customChildren,
    addNewText = '',
    loader = false,
    addNewBtn = false,
    addNewOnClick = () => {},
    toggleSidebar = () => {},
}) => {
    return (
        <div className="filters-sidebar">
            <div className="sidebar-heading">
                <h4>Daily Rounds</h4>
                {addNewBtn && (
                    <Tooltip title={addNewText}>
                        <Button shape="circle" icon={<PlusOutlined />} onClick={addNewOnClick} />
                    </Tooltip>
                )}
            </div>
            {customChildren}
            <div className="sidebar-listing custom-scroll">
                <Spin spinning={loader}>
                    <ul>{children}</ul>
                </Spin>
            </div>
            <Tooltip title="Hide Projects" placement="right">
                <div className="sidebar-toggler" onClick={toggleSidebar}>
                    <DoubleLeftOutlined />
                </div>
            </Tooltip>
        </div>
    );
};
