import { http, fileHttp } from '../utils/http';

export const fetchProjects = async () => {
    try {
        const response = await http.get(`/projects/`);
        return response.data;
    } catch (ex) {
        throw ex;
    }
};

export const fetchProjectDetail = async (step, id) => {
    try {
        const response = await http.get(`/projects/${id}/?step=${step}`);
        return response.data;
    } catch (ex) {
        throw ex;
    }
};

export const fetchPackagesForArea = async (
    projectId,
    page = 1,
    pageSize = 10,
    searchText,
    searchedColumn,
    ordering = null,
) => {
    let url = `/projects/${projectId}/web/packages/?page=${page}&per_page=${pageSize}`;
    if (searchedColumn) {
        url += `&${searchedColumn}__icontains=${searchText}`;
    }
    if (ordering) {
        url += `&ordering=${ordering}`;
    }
    try {
        const response = await http.get(url);
        return response.data;
    } catch (ex) {
        throw ex;
    }
};

export const fetchPackagesForAreaPaginated = async (id) => {
    try {
        const response = await http.get(
            `/projects/${id}/?page=1&per_page=1&full_descriptive=false/`,
        );
        return response.data;
    } catch (ex) {
        throw ex;
    }
};

export const createProject = async (body) => {
    try {
        const response = await http.post('/projects/', body);
        return response.data;
    } catch (ex) {
        throw ex;
    }
};

export const removeUserFromProject = async (projectId, body) => {
    try {
        const response = await http.delete(`/projects/${projectId}/?type=users`, body);
        return response.data;
    } catch (ex) {
        throw ex;
    }
};

export const addUserIntoProject = async (projectId, body) => {
    try {
        const response = await http.post(`/projects/${projectId}/add-user/`, body);
        return response.data;
    } catch (ex) {
        throw ex;
    }
};

export const updateProject = async (step, projectId, body) => {
    try {
        const response = await http.patch(`/projects/${projectId}/?step=${step}`, body);
        return response.data;
    } catch (ex) {
        throw ex;
    }
};

export const fetchProjectDocs = async (projectId) => {
    try {
        const response = await http.get(`projects/${projectId}/documents/`);
        return response.data;
    } catch (ex) {
        console.log(ex);
    }
};

export const saveProjectDocs = async (projectId, data) => {
    try {
        const response = await fileHttp.post(`projects/${projectId}/documents/`, data);
        return response.data;
    } catch (ex) {
        console.log(ex);
    }
};

export const fetchProjectUsers = async (projectId) => {
    try {
        const response = await http.get(`projects/${projectId}/users/`);
        return response.data;
    } catch (ex) {
        console.log(ex);
    }
};

export const fetchGantChartSeries = async (projectId, type) => {
    try {
        // const response = await http.get(`projects/${projectId}/gantt-chart-data/`);
        const response = await http.get(`projects/${projectId}/gantt-charts/?type=${type}`);
        return response.data;
    } catch (ex) {
        console.log(ex);
    }
};

export const fetchAdminProjects = async (type) => {
    try {
        const response = await http.get(`projects/admin/?type=${type}`);
        return response.data;
    } catch (ex) {
        throw ex;
    }
};

export const softDeleteProject = async (projectId) => {
    try {
        const response = await http.delete(`projects/${projectId}/soft-delete/`);
        return response.data;
    } catch (ex) {
        throw ex;
    }
};

export const hardDeleteProject = async (projectId) => {
    try {
        const response = await http.delete(`projects/${projectId}/hard-delete/`);
        return response.data;
    } catch (ex) {
        throw ex;
    }
};

export const reActivateProject = async (projectId) => {
    try {
        const response = await http.patch(`projects/${projectId}/re-activate/`);
        return response.data;
    } catch (ex) {
        throw ex;
    }
};

export const generateCsvReport = async (projectId, pkgId) => {
    try {
        const response = await http.get(`projects/${projectId}/package/${pkgId}/csv-kanban/`);
        return response.data;
    } catch (ex) {
        throw ex;
    }
};

export const generateKanbanAllReport = async (projectId) => {
    try {
        const response = await http.get(`projects/${projectId}/csv-kanban/`);
        return response.data;
    } catch (ex) {
        throw ex;
    }
};

export const generateMasterTagReport = async (projectId) => {
    try {
        const response = await http.get(`projects/${projectId}/master-tag-csv/`);
        return response.data;
    } catch (ex) {
        throw ex;
    }
};

export const postCriticalTags = async (projectId, body) => {
    try {
        const response = await http.post(`projects/${projectId}/gantt-charts/`, body);
        return response.data;
    } catch (ex) {
        throw ex;
    }
};
