import axios from 'axios';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { Col, Empty, Row, Space, Input, Button } from 'antd';
import { saveAs } from 'file-saver';
import { getSignedUrl } from '../../services';
import { DocumentsListing } from '..';
import pdfIcon from '../../assets/images/pdf-icon.png';
import imageIcon from '../../assets/images/img-icon.png';
import textIcon from '../../assets/images/text-icon.png';
import * as Permissions from '../../utils/permissions';
import '../../assets/styles/components/documents.scss';

export const DocView = ({
    docFiles,
    goBack,
    uploadHandler,
    deleteDocument = () => {},
    hideUploadBtn = false,
}) => {
    const { permissions } = useSelector(({ permission }) => permission);

    const [editModal, setEditModal] = useState(false);
    const [editDoc, setEditDoc] = useState('');

    const extractIcon = (fileName) => {
        const fileExt = fileName.split('.').pop();
        if (fileExt === 'pdf') {
            return pdfIcon;
        } else if (['jpeg', 'png', 'jpg', 'svg'].includes(fileExt)) {
            return imageIcon;
        } else {
            return textIcon;
        }
    };

    const downloadDocument = async ({ file_path, file_name }) => {
        const response = await getSignedUrl(file_path);
        await axios.get(response.data, { headers: { 'Content-Disposition': 'attachment' } });
        saveAs(response.data, file_name);
    };

    return (
        <>
            <h4 className="card-heading d-flex justify-space-between">
                {docFiles.dir_value}
                <div className={hideUploadBtn ? '' : 'upload-main'}>
                    <Space>
                        {Permissions.can_add_project_document(permissions) && !hideUploadBtn && (
                            <Input
                                className="file_upload"
                                type="file"
                                multiple
                                accept={
                                    docFiles.dir_key === 'videos'
                                        ? 'video/*'
                                        : '.gif,.jpg,.jpeg,.png,.doc,.docx,.pdf'
                                }
                                onChange={(e) => uploadHandler(e.target.files, docFiles.dir_key)}
                            />
                        )}
                        <Button disabled={false} onClick={goBack}>
                            Go back
                        </Button>
                    </Space>
                </div>
            </h4>
            <Row>
                <Col span={24} className="text-center">
                    {isEmpty(docFiles?.dir_files) ? (
                        <Empty />
                    ) : (
                        /*   Project document and Equipment document*/
                        <DocumentsListing
                            shouldShowDeleteButton={false}
                            documents={[...docFiles.dir_files]}
                            directoryName={docFiles.dir_key}
                            deleteDocument={deleteDocument}
                            downloadDocument={downloadDocument}
                        />
                    )}
                </Col>
            </Row>
            {/* <Modal
        width="100%"
        title="Edit Document"
        className="edit-doc-modal"
        style={{ top: 0, bottom: 0 }}
        visible={editModal}
        onOk={() => setEditModal(false)}
        onCancel={() => setEditModal(false)}
      >
        <PdfEditor pdf={editDoc} s3Key={editDoc} key={editDoc} setEditModal={setEditModal} />
      </Modal> */}
        </>
    );
};
